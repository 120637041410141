import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Col, Row, Tooltip } from 'antd';
import { Formik, FormikProps } from 'formik';
import _, { get } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
  clearPatchMailingAddress,
  clearPatchPersonalInformation,
  clearPatchPhysicalAddress,
  clearUpdateMailingAddress,
  clearUpdatePersonalInformation,
  clearUpdatePhysicalAddress,
  getPersonalInformation,
  patchMailingAddress,
  patchPersonalInformation,
  patchPhysicalAddress,
  toastMessagesAdd,
  toastMessagesRemove,
  clearToastMessages,
  updateMailingAddress,
  updatePersonalInformation,
  updatePhysicalAddress,
} from '../../../actions';
import FieldWrapper from '../../../lib/FormComponents/FieldWrapper';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { MDivider } from '../../../lib/MDivider/MDivider';
import Spinner from '../../../lib/Miscellaneous/Spinner';
import { MModal } from '../../../lib/MModal/MModal';
import { ScreenBreakpoint } from '../../../styles';
import { SeverityEnum } from '../../../typings/commonTypes';
import { renderAddressMismatchErrorMessage } from '../../../utils/renderAddressMismatchErrorMessage';
import { shouldHideAddress } from '../../../utils/shouldHideAddress';

import { modalContent, ModalStrings, ssnInfo } from './constants';
import { getPersonalInformationSections } from './fields';
import * as Styles from './PersonalInformation.styles';
import { mailingAddressValidation, physicalAddressValidation } from './validations';

const PersonalInformationUnmapped = ({
  personalInformation,
  getPersonalInformation,
  updatePersonalInformation,
  patchPersonalInformation,
  updatePhysicalAddress,
  patchPhysicalAddress,
  updateMailingAddress,
  patchMailingAddress,
}: any) => {
  const dispatch = useDispatch();
  const loadingPersonalInformation = useSelector((state: any) => state.accountDetails?.personalInformation.__requested);

  const succededPatchPersonalInformation = useSelector(
    (state: any) => state?.accountDetails?.patchPersonalInformation?.__succeeded,
  );

  const succededUpdatePersonalInformation = useSelector(
    (state: any) => state?.accountDetails?.updatePersonalInformation?.__succeeded,
  );

  const succededPatchPhysicalAddress = useSelector(
    (state: any) => state?.accountDetails?.patchPhysicalAddress?.__succeeded,
  );

  const failedPatchPhysicalAddress = useSelector((state: any) =>
    Boolean(state.accountDetails.patchPhysicalAddress?.__failed),
  );

  const failedPatchPhysicalAddressMessage = useSelector(
    (state: any) => state.accountDetails.patchPhysicalAddress?.message,
  );

  const failedUpdatePhysicalAddressMessage = useSelector(
    (state: any) => state.accountDetails.updatePhysicalAddress?.message,
  );

  const succededUpdatePhysicalAddress = useSelector(
    (state: any) => state?.accountDetails?.updatePhysicalAddress?.__succeeded,
  );

  const failedUpdatePhysicalAddress = useSelector((state: any) =>
    Boolean(state.accountDetails.updatePhysicalAddress?.__failed),
  );

  const succededPatchMailingAddress = useSelector(
    (state: any) => state?.accountDetails?.patchMailingAddress?.__succeeded,
  );

  const failedPatchMailingAddress = useSelector((state: any) =>
    Boolean(state?.accountDetails?.patchMailingAddress?.__failed),
  );

  const failedUpdateMailingAddress = useSelector((state: any) =>
    Boolean(state.accountDetails.updateMailingAddress?.__failed),
  );

  const failedPatchMailingAddressMessage = useSelector(
    (state: any) => state.accountDetails.patchMailingAddress?.message,
  );

  const failedUpdateMailingAddressMessage = useSelector(
    (state: any) => state.accountDetails.updateMailingAddress?.message,
  );

  const succededUpdateMailingAddress = useSelector(
    (state: any) => state?.accountDetails?.updateMailingAddress?.__succeeded,
  );

  const isPatchPersonalInformationLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.patchPersonalInformation?.__requested),
  );

  const isUpdatePersonalInformationLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.updatePersonalInformation?.__requested),
  );

  const isPatchPhysicalAddressLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.patchPhysicalAddress?.__requested),
  );

  const isUpdatePhysicalAddressLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.updatePhysicalAddress?.__requested),
  );

  const isPatchMailingAddressLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.patchMailingAddress?.__requested),
  );

  const isUpdateMailingAddressLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.updateMailingAddress?.__requested),
  );

  const accountId = useSelector((state: any) => state.accountDetails.accountHolder?.data?.accountId);
  const accountUuid = useSelector((state: any) => state.accountDetails.accountHolder?.data?.id);
  const accountNumber = useSelector((state: any) => state.accountDetails.accountHolder?.data?.accountNumber);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sectionUpdated, setSectionUpdated] = useState('');
  const [openedField, setOpenedField] = useState('');
  const [isFieldEditing, setIsFieldEditing] = useState(false);
  const [modalVisibleName, setModalVisibleName] = useState('');
  const [valuesToSubmit, setValuesToSubmit] = useState({});
  const [showTooltip, setShowTooltip] = useState<NodeJS.Timeout>();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });
  const physicalAddressFormRef = useRef<FormikProps<{}> | null>(null);
  const mailingAddressFormRef = useRef<FormikProps<{}> | null>(null);
  const [failedUpsertPhysicalAddressToastId, setFailedUpsertPhysicalAddressToastId] = useState<string | null>(null);
  const [failedUpsertMailingAddressToastId, setFailedUpsertMailingAddressToastId] = useState<string | null>(null);

  const menu: any = getPersonalInformationSections(personalInformation);

  const isUpsertPersonalInformationLoading = isPatchPersonalInformationLoading || isUpdatePersonalInformationLoading;
  const isUpsertPhysicalAddressLoading = isPatchPhysicalAddressLoading || isUpdatePhysicalAddressLoading;
  const isUpsertMailingAddressLoading = isPatchMailingAddressLoading || isUpdateMailingAddressLoading;

  const isPersonalInformationCompleted = () => Boolean(personalInformation?.dateOfBirth);
  const isPhysicalAddressCompleted = () => !_.isEmpty(personalInformation?.physicalAddress);
  const isMailingAddressCompleted = () => !_.isEmpty(personalInformation?.mailingAddress);

  const anyUpsertRequestSucceded = () =>
    succededPatchPersonalInformation ||
    succededUpdatePersonalInformation ||
    succededPatchPhysicalAddress ||
    succededUpdatePhysicalAddress ||
    succededPatchMailingAddress ||
    succededUpdateMailingAddress;

  const copyToClipboard = () => {
    clearTimeout(showTooltip);
    navigator.clipboard.writeText(accountNumber);
    const timeout = setTimeout(() => setShowTooltip(undefined), 2000);
    setShowTooltip(timeout);
  };

  const initialValues = (fields: any) =>
    Object.keys(fields)
      .map(key => ({
        key: fields[key].props.name,
        value: fields[key].props.value,
      }))
      .reduce(
        (obj, keyValuePair) => ({
          ...obj,
          [keyValuePair.key]: keyValuePair.value,
        }),
        {},
      );

  const onMailingAddressSame = async () => {
    setIsSubmitting(true);
    const physicalAddress = personalInformation.physicalAddress;
    await updateMailingAddress({
      sameAsPhysical: true,
      address1: physicalAddress?.address1,
      address2: physicalAddress?.address2,
      country: physicalAddress?.country,
      city: physicalAddress?.city,
      state: physicalAddress?.state,
      postalCode: physicalAddress?.postalCode,
    });
  };

  const onFieldSubmit = async (values: any) => {
    if (sectionUpdated === 'personal') {
      await patchPersonalInformation(values);

      return;
    }

    if (sectionUpdated === 'physical') {
      const castedValues = physicalAddressValidation.cast(values);
      await patchPhysicalAddress(castedValues);

      return;
    }

    if (sectionUpdated === 'mailing') {
      const castedValues = mailingAddressValidation.cast(values);
      await patchMailingAddress(castedValues);

      return;
    }

    throw new Error(`Unknown patch section. Received ${sectionUpdated}`);
  };

  const onSubmit = async (values: any) => {
    if (sectionUpdated === 'personal') {
      setModalVisibleName(ModalStrings.accountOwnerInfo.modalVisibleName);

      if (personalInformation.middleName) {
        values.middleName = undefined;
      }

      if (personalInformation.suffix) {
        values.suffix = undefined;
      }

      setValuesToSubmit(values);

      return;
    }

    setIsSubmitting(true);

    if (sectionUpdated === 'physical') {
      const castedValues = physicalAddressValidation.cast(values);
      await updatePhysicalAddress(castedValues);

      return;
    }

    if (sectionUpdated === 'mailing') {
      const castedValues = mailingAddressValidation.cast(values);
      await updateMailingAddress(castedValues);

      return;
    }

    throw new Error(`Unknown update section. Received ${sectionUpdated}`);
  };

  const isSaveButtonLoading = (sectionKey: string) => {
    if (sectionKey === 'personal') {
      return isUpsertPersonalInformationLoading;
    }

    if (sectionKey === 'physical') {
      return isUpsertPhysicalAddressLoading;
    }

    if (sectionKey === 'mailing') {
      return isUpsertMailingAddressLoading;
    }

    return false;
  };

  const isSaveButtonDisabled = (sectionKey: string) => {
    if (sectionKey === 'personal') {
      return isUpsertPersonalInformationLoading || loadingPersonalInformation;
    }

    if (sectionKey === 'physical') {
      return isUpsertPhysicalAddressLoading || loadingPersonalInformation;
    }

    if (sectionKey === 'mailing') {
      return isUpsertMailingAddressLoading || loadingPersonalInformation;
    }

    return false;
  };

  const removeUpsertPhysicalAddressToastMessageIfFound = () => {
    if (failedUpsertPhysicalAddressToastId) {
      dispatch(toastMessagesRemove({ key: failedUpsertPhysicalAddressToastId }));
    }
  };

  const removeUpsertMailingAddressToastMessageIfFound = () => {
    if (failedUpsertMailingAddressToastId) {
      dispatch(toastMessagesRemove({ key: failedUpsertMailingAddressToastId }));
    }
  };

  const onPopulatePhysicalAddress = () => {
    if (physicalAddressFormRef.current && failedUpdatePhysicalAddressMessage?.error === 'UspsAddressMismatchError') {
      physicalAddressFormRef.current.setFieldValue('address1', failedUpdatePhysicalAddressMessage.address1);
      physicalAddressFormRef.current.setFieldValue('address2', failedUpdatePhysicalAddressMessage.address2);
      physicalAddressFormRef.current.setFieldValue('city', failedUpdatePhysicalAddressMessage.city);
      physicalAddressFormRef.current.setFieldValue('state', failedUpdatePhysicalAddressMessage.state);
      physicalAddressFormRef.current.setFieldValue('postalCode', failedUpdatePhysicalAddressMessage.postalCode);
    }

    if (physicalAddressFormRef.current && failedPatchPhysicalAddressMessage?.error === 'UspsAddressMismatchError') {
      physicalAddressFormRef.current.setFieldValue('address1', failedPatchPhysicalAddressMessage.address1);
      physicalAddressFormRef.current.setFieldValue('address2', failedPatchPhysicalAddressMessage.address2);
      physicalAddressFormRef.current.setFieldValue('city', failedPatchPhysicalAddressMessage.city);
      physicalAddressFormRef.current.setFieldValue('state', failedPatchPhysicalAddressMessage.state);
      physicalAddressFormRef.current.setFieldValue('postalCode', failedPatchPhysicalAddressMessage.postalCode);
    }
    removeUpsertPhysicalAddressToastMessageIfFound();
  };

  const onPopulateMailingAddress = () => {
    if (mailingAddressFormRef.current && failedUpdateMailingAddressMessage?.error === 'UspsAddressMismatchError') {
      mailingAddressFormRef.current.setFieldValue('address1', failedUpdateMailingAddressMessage.address1);
      mailingAddressFormRef.current.setFieldValue('address2', failedUpdateMailingAddressMessage.address2);
      mailingAddressFormRef.current.setFieldValue('city', failedUpdateMailingAddressMessage.city);
      mailingAddressFormRef.current.setFieldValue('state', failedUpdateMailingAddressMessage.state);
      mailingAddressFormRef.current.setFieldValue('postalCode', failedUpdateMailingAddressMessage.postalCode);
    }

    if (mailingAddressFormRef.current && failedPatchMailingAddressMessage?.error === 'UspsAddressMismatchError') {
      mailingAddressFormRef.current.setFieldValue('address1', failedPatchMailingAddressMessage.address1);
      mailingAddressFormRef.current.setFieldValue('address2', failedPatchMailingAddressMessage.address2);
      mailingAddressFormRef.current.setFieldValue('city', failedPatchMailingAddressMessage.city);
      mailingAddressFormRef.current.setFieldValue('state', failedPatchMailingAddressMessage.state);
      mailingAddressFormRef.current.setFieldValue('postalCode', failedPatchMailingAddressMessage.postalCode);
    }
    removeUpsertMailingAddressToastMessageIfFound();
  };

  useEffect(() => {
    if (failedUpdatePhysicalAddressMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertPhysicalAddressToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedUpdatePhysicalAddressMessage,
            onClick: onPopulatePhysicalAddress,
            section: 'physical',
          }),
        }),
      );
      setFailedUpsertPhysicalAddressToastId(toastId);
    }
  }, [failedUpdatePhysicalAddress]);

  useEffect(() => {
    if (failedPatchPhysicalAddressMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertPhysicalAddressToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedPatchPhysicalAddressMessage,
            onClick: onPopulatePhysicalAddress,
            section: 'physical',
          }),
        }),
      );
      setFailedUpsertPhysicalAddressToastId(toastId);
    }
  }, [failedPatchPhysicalAddress]);

  useEffect(() => {
    if (failedUpdateMailingAddressMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertMailingAddressToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedUpdateMailingAddressMessage,
            onClick: onPopulateMailingAddress,
            section: 'mailing',
          }),
        }),
      );
      setFailedUpsertMailingAddressToastId(toastId);
    }
  }, [failedUpdateMailingAddress]);

  useEffect(() => {
    if (failedPatchMailingAddressMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertMailingAddressToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: uuid(),
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedPatchMailingAddressMessage,
            onClick: onPopulateMailingAddress,
            section: 'mailing',
          }),
        }),
      );
      setFailedUpsertMailingAddressToastId(toastId);
    }
  }, [failedPatchMailingAddress]);

  useEffect(() => {
    if (
      isSubmitting &&
      !isUpsertPersonalInformationLoading &&
      !isUpsertPhysicalAddressLoading &&
      !isUpsertMailingAddressLoading
    ) {
      setIsSubmitting(false);
    }
  }, [
    isPatchPersonalInformationLoading,
    isUpdatePersonalInformationLoading,
    isPatchPhysicalAddressLoading,
    isUpdatePhysicalAddressLoading,
    isPatchMailingAddressLoading,
    isUpdateMailingAddressLoading,
  ]);

  useEffect(() => {
    if (
      !accountId &&
      isPersonalInformationCompleted() &&
      isPhysicalAddressCompleted() &&
      isMailingAddressCompleted() &&
      (succededUpdatePersonalInformation || succededUpdatePhysicalAddress || succededUpdateMailingAddress)
    ) {
      window.gtag('event', 'account_personal_information_complete');
    }

    if (
      !accountId &&
      isPersonalInformationCompleted() &&
      isPhysicalAddressCompleted() &&
      isMailingAddressCompleted() &&
      anyUpsertRequestSucceded()
    ) {
      navigate(`/accounts/${accountUuid}/trusted-contact`);
    }
  }, [
    succededPatchPersonalInformation,
    succededUpdatePersonalInformation,
    succededPatchPhysicalAddress,
    succededUpdatePhysicalAddress,
    succededPatchMailingAddress,
    succededUpdateMailingAddress,
    personalInformation,
  ]);

  useEffect(() => {
    // NOTE: personalInformation.physicalAddress is rehydrated. Once it is removed from rehydration, we can use _.isEmpty()
    if (!personalInformation?.id && !loadingPersonalInformation) {
      getPersonalInformation();
    }

    return () => {
      dispatch(clearPatchPersonalInformation());
      dispatch(clearUpdatePersonalInformation());
      dispatch(clearPatchPhysicalAddress());
      dispatch(clearUpdatePhysicalAddress());
      dispatch(clearPatchMailingAddress());
      dispatch(clearUpdateMailingAddress());
      dispatch(clearToastMessages());
    };
  }, []);

  const showAccountNumber = useMemo(() => {
    return (
      <Row>
        <Col span={18} xs={24} sm={24} className={Styles.formCard}>
          <Row>
            <Col span={12} xs={12} sm={12} className={Styles.fieldLabel}>
              Account Number
            </Col>
            <Col span={12} xs={12} sm={12} className={Styles.accountNumberValue}>
              <Tooltip
                placement='topRight'
                title={
                  <>
                    <i className={`ri-checkbox-circle-fill`} />
                    <span>Account Number Copied to clipboard</span>
                  </>
                }
                open={!!showTooltip}
                overlayClassName={Styles.copiedTooltip}>
                <div className={Styles.displayValue} onClick={copyToClipboard}>
                  {accountNumber}
                </div>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }, [accountNumber, showTooltip]);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.PersonalInformation}
      title={isMobile ? undefined : 'Personal'}>
      {accountNumber && showAccountNumber}
      {loadingPersonalInformation ? (
        <Spinner />
      ) : (
        <>
          {menu.sections.map((section: any) => (
            <Row key={section.sectionKey} data-testid={`account-section-${section.sectionKey}`}>
              <Col className={Styles.formCard} span={18} xs={24} sm={24}>
                {section.sectionLabel === 'Mailing Address' &&
                personalInformation?.mailingAddress?.sameAsPhysical === true ? (
                  <div className={Styles.sectionHeader}>
                    {section.sectionLabel}
                    <span style={{ fontSize: 14, fontWeight: 400 }}>
                      {''} (Mailing address is the same as my physical address)
                    </span>
                  </div>
                ) : (
                  <div className={Styles.sectionHeader}>{section.sectionLabel}</div>
                )}
                <Formik
                  enableReinitialize
                  validateOnChange={false}
                  validateOnBlur={false}
                  innerRef={ref => {
                    if (section.sectionKey === 'physical') {
                      physicalAddressFormRef.current = ref;
                    }

                    if (section.sectionKey == 'mailing') {
                      mailingAddressFormRef.current = ref;
                    }
                  }}
                  initialValues={initialValues(section.sectionFields)}
                  onSubmit={values => {
                    onSubmit(values);
                  }}
                  validationSchema={section.validationSchema}>
                  {form => {
                    const formValues: any = form.values;

                    return (
                      <>
                        {section.sectionFields.map((field: any) => {
                          const JSXRenderableComp = field.component;

                          return shouldHideAddress(formValues.country, field.key) ||
                            (field.key === 'sameAsPhysical' && section.isCompleted) ? null : (
                            <Row key={`${field.key}_frag_`}>
                              {!field.showInRow && (
                                <Col span={12}>
                                  <div className={Styles.fieldLabel}>
                                    {field.label}
                                    {field?.key === 'taxId' && (
                                      <i
                                        className={`${Styles.Tooltip} ri-information-line`}
                                        onClick={() => setModalVisibleName(ModalStrings.ssn.modalVisibleName)}
                                      />
                                    )}
                                  </div>
                                </Col>
                              )}
                              <Col
                                span={field.showInRow ? 24 : 12}
                                style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
                                <FieldWrapper
                                  displayValue={field.displayValue}
                                  name={field.key}
                                  form={form}
                                  openedField={openedField}
                                  value={field.props.value}
                                  initialMode={section.isCompleted || field.readOnly ? 'view' : 'edit'}
                                  isSectionCompleted={section.isCompleted}
                                  readOnly={field.readOnly}
                                  onFieldEdit={(name: string) => {
                                    setOpenedField(name);
                                    setIsFieldEditing(true);
                                    setSectionUpdated(section.sectionKey);
                                  }}
                                  isFieldEditing={isFieldEditing}
                                  onFieldSubmit={async (values: any) => {
                                    setIsSubmitting(true);
                                    setIsFieldEditing(false);
                                    setOpenedField('');
                                    onFieldSubmit(values);
                                  }}
                                  onFieldClose={() => {
                                    setOpenedField('');
                                    form.resetForm();
                                  }}>
                                  <JSXRenderableComp
                                    key={`${field.key}_field_`}
                                    {...form}
                                    {...field.props}
                                    name={field.key}
                                    disabled={field.key === 'sameAsPhysical' && !menu.sections[1].isCompleted}
                                    value={formValues[field.key]}
                                    preChangeHook={(value: any) => {
                                      if (field.key === 'sameAsPhysical' && value === true) {
                                        onMailingAddressSame();
                                      } else if (field.key === 'country' && value !== 'USA') {
                                        form.setFieldValue('city', null);
                                        form.setFieldValue('state', null);
                                      }
                                    }}
                                  />
                                </FieldWrapper>
                              </Col>
                            </Row>
                          );
                        })}
                        <MDivider />
                        {!section.isCompleted && (
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <MButton
                              testId={`account-btn-save-section-${section.sectionKey}`}
                              loading={isSaveButtonLoading(section.sectionKey)}
                              disabled={isSaveButtonDisabled(section.sectionKey)}
                              type='secondary'
                              onClick={() => {
                                form.submitForm();
                                setSectionUpdated(section.sectionKey);
                              }}>
                              Save
                            </MButton>
                          </div>
                        )}
                      </>
                    );
                  }}
                </Formik>
              </Col>
            </Row>
          ))}
          <MModal
            customWidth={520}
            visible={modalVisibleName !== ''}
            title={modalVisibleName === ModalStrings.ssn.modalVisibleName ? ModalStrings.ssn.modalTitle : ''}
            primaryButtonText={
              modalVisibleName === ModalStrings.ssn.modalVisibleName
                ? ModalStrings.ssn.secondaryButtonText
                : ModalStrings.accountOwnerInfo.secondaryButtonText
            }
            tertiaryButtonText={modalVisibleName === ModalStrings.ssn.modalVisibleName ? 'Close' : 'Edit'}
            onPrimaryButtonClick={async () => {
              setIsSubmitting(true);
              updatePersonalInformation(valuesToSubmit);
              setValuesToSubmit({});
              setModalVisibleName('');
            }}
            onTertiaryButtonClick={() => {
              setModalVisibleName('');
            }}
            onClose={() => {
              setModalVisibleName('');
            }}>
            {modalVisibleName === ModalStrings.accountOwnerInfo.modalVisibleName ? modalContent : ssnInfo}
          </MModal>
        </>
      )}
    </MyAccountLayout>
  );
};

const mapStateToProps = (state: any) => ({
  personalInformation: get(state, 'accountDetails.personalInformation.data'),
});

const mapDispatchToProps = {
  getPersonalInformation,
  updatePersonalInformation,
  patchPersonalInformation,
  updatePhysicalAddress,
  patchPhysicalAddress,
  updateMailingAddress,
  patchMailingAddress,
};

export const PersonalInformation = connect(mapStateToProps, mapDispatchToProps)(PersonalInformationUnmapped);
