import CurrencyField, { CurrencyFieldProps } from '../Miscellaneous/FormattedFields/CurrencyField/CurrencyField';

import * as Styles from './CurrencyRangeField.styles';

interface CurrencyRangeFieldProps extends CurrencyFieldProps {
  min?: number;
  max?: number;
}

export const CurrencyRangeField = ({ min, max, ...rest }: CurrencyRangeFieldProps) => {
  return (
    <>
      <CurrencyField value={min} {...rest} />
      <span className={Styles.rangeSeparator}>{'-'}</span>
      <CurrencyField value={max} {...rest} />
    </>
  );
};
