import * as Yup from 'yup';

export const validationMessages = {
  codeRequired: 'Code is required.',
  codeInvalid: 'Confirmation code should contain 6 numbers',
};

export const securityConfirmValidationSchema = Yup.object().shape({
  rememberDevice: Yup.boolean().optional(),
  code: Yup.string().required(validationMessages.codeRequired).min(6, validationMessages.codeInvalid),
});
