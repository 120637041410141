import _ from 'lodash';

import { ExperienceAndTradesPerYear, YearsOfExperience, TradesPerYear } from '../../../constants/accountCompletion';
import { CheckBoxGroupValue } from '../../../lib/FormComponents/CheckBoxGroup/constants';
import { RadioGroupValue } from '../../../lib/FormComponents/RadioGroup/constants';

import * as Styles from './styles';

export const getDisplayValue = (options: Array<RadioGroupValue>, accountDetails: any, objectValue: string) => (
  <div style={{ display: 'column' }}>
    <div className={Styles.displayValueRadio}>
      {
        _.find(options, {
          value:
            !_.isEmpty(accountDetails) && !_.isEmpty(accountDetails[objectValue]) ? accountDetails[objectValue] : null,
        })?.text
      }
    </div>

    <div className={Styles.displayValueRadioSubLabel}>
      {
        _.find(options, {
          value:
            !_.isEmpty(accountDetails) && !_.isEmpty(accountDetails[objectValue]) ? accountDetails[objectValue] : null,
        })?.subLabel
      }
    </div>

    {_.find(options, {
      value: !_.isEmpty(accountDetails) && !_.isEmpty(accountDetails[objectValue]) ? accountDetails[objectValue] : null,
    })?.tip && (
      <div className={'tip'} style={{ marginLeft: 0 }}>
        <span style={{ fontWeight: 700 }}>Tip: </span>&nbsp;{' '}
        {
          _.find(options, {
            value:
              !_.isEmpty(accountDetails) && !_.isEmpty(accountDetails[objectValue])
                ? accountDetails[objectValue]
                : null,
          })?.tip
        }
      </div>
    )}
  </div>
);

export const getFinancialInvestmentExperienceDisplayValue = (
  options: Array<CheckBoxGroupValue>,
  accountDetails: any,
  objectValue: string,
) =>
  !_.isEmpty(accountDetails) &&
  !_.isEmpty(accountDetails[objectValue]) &&
  Object.values(accountDetails[objectValue]).every(objectVal => objectVal !== undefined) ? (
    <div style={{ width: '100%', cursor: 'pointer' }}>
      {Object.keys(accountDetails[objectValue]).map((item: any) => (
        <div
          key={`${item.value}_key`}
          style={{
            display: 'column',
            width: '100%',
            marginBottom: 30,
          }}>
          <div className={Styles.displayValueRadio}>
            {
              _.find(options, {
                value: item,
              })?.text
            }
          </div>

          <div className={Styles.displayValueRadioSubLabel}>
            {
              _.find(options, {
                value: item,
              })?.subLabel
            }
          </div>
          {Object.keys(accountDetails[objectValue][item]).map((objectName: any, index: number) => (
            <div
              key={`${item.value}_${objectName}_key`}
              style={{
                display: 'flex',
                marginBottom: 7,
                marginTop: 11,
                paddingLeft: 10,
                paddingRight: 10,
              }}>
              <div
                className={Styles.fieldLabel}
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}>
                {
                  _.find(ExperienceAndTradesPerYear, {
                    name: objectName,
                  })?.label
                }
              </div>

              <div
                className={Styles.displayValue}
                style={{
                  fontSize: 14,
                  alignItems: 'center',
                  display: 'flex',
                }}>
                {
                  _.find(index === 0 ? TradesPerYear : YearsOfExperience, {
                    value: accountDetails[objectValue][item][objectName],
                  })?.text
                }
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>None</div>
  );
