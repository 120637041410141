import { useEffect } from 'react';

import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { patchUserAddress, clearUserAddressPatch } from '../../../actions';
import { getFormState } from '../../../actions/forms';
import { countries } from '../../../constants/countryCodes';
import { states } from '../../../constants/states';
import Dropdown from '../../../lib/FormComponents/Dropdown';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import * as Styles from '../BaseLayout.styles';
import { ActiveTab } from '../constants';
import { SignUpWithOfferInvestReferrer } from '../SignUpWithOfferInvestReferrer';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { addressValidationSchema } from './validations';

export const Address = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const address = useSelector((state: any) => state.user.patchAddress.data);
  const userData = useSelector((state: any) => state.user.authenticated.data);
  const isAddressRequested = useSelector((state: any) => Boolean(state.user.patchAddress.__requested));
  const hasAddressSucceeded = useSelector((state: any) => Boolean(state.user.patchAddress.__succeeded));

  Object.keys(InitialValues).forEach(key => (InitialValues[key] = userData?.address?.[key] || ''));
  let formState = getFormState(address, InitialValues);

  const onSubmit = (_values: any) => {
    dispatch(patchUserAddress(_values));
  };

  useEffect(() => {
    if (hasAddressSucceeded) {
      window.gtag('event', 'sign_up_address_complete');

      return navigate('/security');
    }
  }, [hasAddressSucceeded]);

  useEffect(() => {
    return () => {
      dispatch(clearUserAddressPatch());
    };
  }, []);

  return (
    <SignUpWithOfferInvestReferrer
      activeTab={ActiveTab.Address}
      tabs={[ActiveTab.Register, ActiveTab.Address, ActiveTab.Security]}>
      <Formik
        validationSchema={addressValidationSchema}
        initialValues={formState}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => {
          onSubmit(values);
        }}>
        {form => {
          return (
            <>
              <div className={Styles.formItemsContainer}>
                <Dropdown
                  tabIndex={0}
                  name={FormFields.country}
                  placeholder={FormStrings[FormFields.country]}
                  options={countries}
                  value={form.values[FormFields.country]}
                  {...form}
                />
                <Dropdown
                  name={FormFields.state}
                  placeholder={FormStrings[FormFields.state]}
                  options={states}
                  value={form.values[FormFields.state]}
                  customStyles={{ marginTop: 24 }}
                  showSearch
                  {...form}
                />
              </div>
              <div className={Styles.buttonsContainer}>
                <MButton
                  testId={'address-btn-next'}
                  onClick={() => {
                    form.submitForm();
                  }}
                  type='secondary'
                  disabled={isAddressRequested || !form.isValid}
                  loading={isAddressRequested}>
                  Next
                </MButton>
              </div>
            </>
          );
        }}
      </Formik>
    </SignUpWithOfferInvestReferrer>
  );
};
