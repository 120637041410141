import * as Yup from 'yup';

import { LiquidNetWorthType, TotalNetWorthType } from '../../../constants/accountCompletion';

const validationMessages = {
  employmentStatusRequired: 'Employment Status is required',
  employmentStatusInvalid: 'Employment Status is invalid',
  employerNameRequired: 'Employer Name is required',
  employerNameInvalid: 'Employer Name is invalid',
  yearsEmployedRequired: 'Years Employed is required',
  yearsEmployedInvalid: 'Years Employed is invalid',

  addressLineOneRequired: 'Address line 1 is required',
  addressLineOneInvalid: 'Adress Line 1 can have a maximum length of 30 characters',
  addressLineTwoInvalid: 'Adress Line 2 can have a maximum length of 30 characters',
  cityRequired: 'City is required',
  cityInvalid: 'City can have a maximum length of 30 characters',
  stateRequired: 'State is required',
  stateInvalid: 'State is invalid',
  postalCodeRequired: 'Postal Code is required',
  postalCodeInvalid: 'Postal Code value should be between 0 and 100000',
  countryRequired: 'Country is required',
  countryInvalid: 'Country is invalid',

  annualIncomeRequired: 'Annual Income is required',
  annualIncomeInvalid: 'Annual Income is invalid',
  totalNetWorthRequired: 'Total Net Worth is required',
  totalNetWorthInvalid: 'Total Net Worth is invalid',
  liquidNetWorthRequired: 'Liquid Net Worth is required',
  liquidNetWorthInvalid: 'Liquid Net Worth is invalid',
  jobTitleRequired: 'Job Title is required',
  jobTitleInvalid: 'Job Title length should not be more than 50 characters',
  taxBracketRequired: 'Tax Bracket is required',
  taxBracketInvalid: 'Tax Bracket is invalid',
  fieldShouldContainOnlyLetters: 'Field should contain only letters',
};

export const assetsValidation = Yup.object().shape({
  annualIncome: Yup.string()
    .required(validationMessages.annualIncomeRequired)
    .notOneOf(['-1'], validationMessages.annualIncomeInvalid)
    .nullable(),
  totalNetWorth: Yup.string()
    .required(validationMessages.totalNetWorthRequired)
    .notOneOf(['-1'], validationMessages.totalNetWorthInvalid)
    .test(
      'compare',
      'Total Net Worth can not be smaller than Liquid Net Worth.',
      function (totalNetWorth: string | any, schema: string | any) {
        if (schema !== undefined && schema !== null) {
          return (
            Object.values(LiquidNetWorthType).findIndex(item => item.value === schema?.parent?.liquidNetWorth) <=
            Object.values(TotalNetWorthType).findIndex(item => item.value === totalNetWorth)
          );
        } else return false;
      },
    )
    .nullable(),
  liquidNetWorth: Yup.string()
    .required(validationMessages.liquidNetWorthRequired)
    .notOneOf(['-1'], validationMessages.liquidNetWorthInvalid)
    .test(
      'compare',
      'Liquid Net Worth can not be greater than Total Net Worth.',
      function (liquidNetWorth: string | any, schema: string | any) {
        if (schema !== undefined && schema !== null) {
          return (
            Object.values(TotalNetWorthType).findIndex(item => item.value === schema?.parent?.totalNetWorth) >=
            Object.values(LiquidNetWorthType).findIndex(item => item.value === liquidNetWorth)
          );
        } else return false;
      },
    )
    .nullable(),
  taxBracket: Yup.string()
    .required(validationMessages.taxBracketRequired)
    .notOneOf(['-1'], validationMessages.taxBracketInvalid)
    .nullable(),
});

export const employmentInformationValidation = Yup.object().shape({
  employmentStatus: Yup.string()
    .required(validationMessages.employmentStatusRequired)
    .notOneOf(['-1'], validationMessages.employmentStatusInvalid)
    .nullable(),
  employerName: Yup.string()
    .when('employmentStatus', {
      is: (employmentStatus: string) => employmentStatus === 'employed',
      then: Yup.string()
        .max(50, validationMessages.employerNameInvalid)
        .nullable()
        .required(validationMessages.employerNameRequired),
    })
    .trim()
    .max(50, validationMessages.employerNameInvalid)
    .nullable(),
  jobTitle: Yup.string()
    .when('employmentStatus', {
      is: (employmentStatus: string) => employmentStatus === 'employed',
      then: Yup.string()
        .required(validationMessages.jobTitleRequired)
        .max(50, validationMessages.jobTitleInvalid)
        .nullable(),
    })
    .trim()
    .max(100, validationMessages.jobTitleInvalid)
    .nullable(),
  yearsEmployed: Yup.string()
    .when('employmentStatus', {
      is: (employmentStatus: string) => employmentStatus === 'employed',
      then: Yup.string()
        .required(validationMessages.yearsEmployedRequired)
        .notOneOf(['-1'], validationMessages.yearsEmployedInvalid)
        .nullable(),
    })
    .notOneOf(['-1'], validationMessages.yearsEmployedInvalid)
    .nullable(),
  address1: Yup.string()
    .trim()
    .max(30, validationMessages.addressLineOneInvalid)
    .when('employmentStatus', {
      is: (employmentStatus: string) => employmentStatus === 'employed',
      then: Yup.string()
        .required(validationMessages.addressLineOneRequired)
        .max(30, validationMessages.addressLineOneInvalid)
        .nullable(),
    })
    .nullable(),

  address2: Yup.string().trim().max(30, validationMessages.addressLineTwoInvalid).nullable(),
  country: Yup.string()
    .when('employmentStatus', {
      is: (employmentStatus: string) => employmentStatus === 'employed',
      then: Yup.string()
        .required(validationMessages.countryRequired)
        .notOneOf(['-1'], validationMessages.countryInvalid)
        .nullable(),
    })
    .nullable(),
  state: Yup.string()
    .when('employmentStatus', {
      is: (employmentStatus: string) => employmentStatus === 'employed',
      then: Yup.string().when('country', {
        is: (country: string) => country === 'USA',
        then: Yup.string().nullable().required(validationMessages.stateRequired),
      }),
    })
    .nullable(),
  city: Yup.string()
    .trim()
    .when('employmentStatus', {
      is: (employmentStatus: string) => employmentStatus === 'employed',
      then: Yup.string().when('country', {
        is: (country: string) => country === 'USA',
        then: Yup.string().required(validationMessages.cityRequired).max(30, validationMessages.cityInvalid).nullable(),
      }),
    })
    .nullable(),
  postalCode: Yup.string()
    .when('employmentStatus', {
      is: (employmentStatus: string) => employmentStatus === 'employed',
      then: Yup.string()
        .required(validationMessages.postalCodeRequired)
        .max(6)
        .test('Digits only', 'Postal Code should have digits only', (value: any) => /^\d+$/.test(value))
        .test(
          'Range size',
          'Postal Code value should be between 0 and 100000',
          (value: any) => 0 < Number(value) && Number(value) < 100000,
        )
        .nullable(),
    })
    .nullable(),
});
