import { css } from '@emotion/css';

import { BorderRadius, ColorConstant, Display, Font, FontWeight, ScreenBreakpoint, Spacing } from '../../../../styles';

export const menuName = css`
  ${Font.h3};
  color: ${ColorConstant.BRAND6};
  ${Spacing.mt64};
  ${Spacing.ml64};
`;

export const formCard = css`
  border: none;
  box-shadow: 0rem 0.25rem 1rem ${ColorConstant.BRAND6}1A;
  ${Spacing.p24};
  ${Spacing.my24};
  ${BorderRadius.xs};
`;

export const offerLogo = css`
  width: 30%;
  width: 96px;
  height: 96px;
  img {
    width: 100%;
    height: inherit;
    ${Spacing.mr24};
    object-fit: contain;
  }
`;

export const offerName = css`
  width: 70%;
  ${FontWeight.semibold};
  ${Font.lg};
  color: ${ColorConstant.GRAY10};
  min-height: 50px;
  ${Spacing.pt12};
  @media (max-width: ${ScreenBreakpoint.laptop.min}) {
    ${Spacing.pl12};
  }
`;

export const divider = css`
  ${Spacing.mb20};
`;

export const desiredAmountLabel = css`
  ${Font.sm};
  color: ${ColorConstant.GRAY7};
`;

export const desiredAmountValue = css`
  ${FontWeight.bold};
  color: ${ColorConstant.GRAY10};
`;

export const tagsContainer = css`
  ${Display.flex};
  ${Spacing.pt16};
`;
