import { css } from '@emotion/css';

import { Spacing, Font, ColorConstant } from '../../../styles';

export const newPasswordNeedsToLabel = css`
  ${Spacing.mt8}
  ${Spacing.mb12}
  ${Font.sm}
  color: ${ColorConstant.GRAY8};
`;

export const passwordStrengthBarContainer = css`
  width: 100%;
`;
