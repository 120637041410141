import * as Yup from 'yup';

const validationMessages = {
  statementDeliveryRequired: 'Statement Delivery is required',
  individualRepresentationsRequired: 'Individual Representations is required',
};

export const disclosuresValidation = Yup.object().shape({
  statementDelivery: Yup.string().required(validationMessages.statementDeliveryRequired).nullable(),
  individualRepresentations: Yup.object()
    .shape({
      stakeholder: Yup.lazy(value => {
        if (value !== undefined) {
          return Yup.object().shape({
            tickerSymbol: Yup.string().trim().required('Required'),
          });
        }

        return Yup.mixed().notRequired().default(undefined);
      }),
      industryEmployed: Yup.lazy(value => {
        if (value !== undefined) {
          return Yup.object().shape({
            firmName: Yup.string().trim().required('Required'),
          });
        }

        return Yup.mixed().notRequired().default(undefined);
      }),
    })
    .default(null)
    .nullable(),
});
