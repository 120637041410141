import moment from 'moment';

import * as Styles from '../../AccountStatus/AccountStatus.styles';
import { getAccountName } from '../../AccountStatus/constants';

import { SubmittedDocumentCompType } from './constants';

const SubmittedDocuments = ({ document }: SubmittedDocumentCompType) => {
  return (
    <>
      <div className={Styles.documentUploadedHeader}>{getAccountName(document?.documentName || '')}</div>
      <div className={Styles.documentUploadedLabel}>
        Document Name:{' '}
        <span className={Styles.documentUploadedValue}>{document.originalFileName || document.fileName}</span>
      </div>
      <div className={Styles.documentUploadedLabel}>
        Status: <span className={Styles.documentUploadedValue}>{document.state?.split(/(?=[A-Z])/).join(' ')}</span>
      </div>
      <div className={Styles.documentUploadedLabel}>
        Date: <span className={Styles.documentUploadedValue}>{moment(document.takenOn).format('MM/DD/YYYY')}</span>
      </div>
    </>
  );
};

export default SubmittedDocuments;
