import { AnyAction } from 'redux';

import { createAction, State, Type } from './utils';

export const getPlaidLinkToken = (accountId: string): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_GET_PLAID_LIK_TOKEN), accountId);

export const getACHRelationships = (accountId: string): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_GET_ACH_RELATIONSHIPS), {
    accountId,
  });

export const getACHRelationshipsSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.CASHIERING_GET_ACH_RELATIONSHIPS), payload);

export const clearACHRelationships = (): AnyAction =>
  createAction(State.actionCleared(Type.CASHIERING_GET_ACH_RELATIONSHIPS), {});

export const createACHRelationship = (accountId: string, payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_CREATE_ACH_RELATIONSHIP), {
    accountId,
    body: payload,
  });

export const createACHRelationshipSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.CASHIERING_CREATE_ACH_RELATIONSHIP), payload);

export const unlinkBankAccount = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_UNLINK_ACH_RELATIONSHIP), payload);

export const editNickname = (accountId: number, relationshipId: number, payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_EDIT_NICKNAME), {
    accountId,
    relationshipId,
    body: payload,
  });

export const depositFunds = (accountId: string, payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_DEPOSIT_FUNDS), {
    accountId,
    body: payload,
  });

export const withdrawFunds = (accountId: string, payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_WITHDRAW_FUNDS), {
    accountId,
    body: payload,
  });

export const getTransfers = (accountId: string, payload?: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_GET_TRANSFERS), {
    accountId,
    queryParams: payload,
  });

export const getTransactionDetails = (accountId: string, transactionId: number): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_GET_TRANSACTION_DETAILS), {
    accountId,
    transactionId,
  });

export const cancelDeposit = (accountId: number, transactionId: number): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_CANCEL_DEPOSIT), {
    accountId,
    transactionId,
  });

export const cancelWithdraw = (accountId: number, transactionId: number): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_CANCEL_WITHDRAW), {
    accountId,
    transactionId,
  });
