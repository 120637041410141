import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export const CompletedAccountGuard = () => {
  const account = useSelector((state: any) => state.accountDetails.accountHolder?.data);

  if (account?.accountId) {
    return <Outlet />;
  }

  return <Navigate to={`/complete-account`} />;
};
