import { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getOfferDetails, getOfferList, offerWatchesDeleteRequested } from '../../actions';
import { OfferWatchDto } from '../../dtos/offerWatches.dtos';
import { useOfferWatches } from '../../hooks/useOfferWatches';
import { MyAccountLayout } from '../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarOtherMenuItemKey } from '../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import Spinner from '../../lib/Miscellaneous/Spinner';
import { MModal } from '../../lib/MModal/MModal';
import { Offer } from '../../models/offers.models';
import { Images } from '../../styles';

import IndicationOfInterestCard from './components/IndicationCard/IndicationOfInterestCard';
import * as Styles from './styles';

export const IndicationsOfInterest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [selectedOfferWatchId, setSelectedOfferWatchId] = useState<string | null>(null);
  const [selectedOfferWatchName, setSelectedOfferWatchName] = useState<string>('');
  const offers: Offer[] = useSelector((state: any) => state.offers?.offersList?.data);

  const { offerWatchList, isOfferWatchListLoading } = useOfferWatches();

  const cancelIndication = (offerWatchId: string, offerWatchName: string) => {
    setSelectedOfferWatchName(offerWatchName);
    setShowAlert(true);
    setSelectedOfferWatchId(offerWatchId);
  };

  const viewOffer = (offerId: string) => {
    dispatch(getOfferDetails(offerId));
    navigate(`/offers/${offerId}`);
  };

  useEffect(() => {
    // TODO: use custom hook for offer list or add loading conditional to avoid dispatching twice the action
    dispatch(getOfferList());
  }, [dispatch]);

  return (
    <MyAccountLayout
      title='Indications of Interest'
      sidebarMenuItemKey={MyAccountSidebarOtherMenuItemKey.IndicationsOfInterest}>
      <Row>
        <Col sm={24} md={24} lg={18}>
          {isOfferWatchListLoading ? (
            <Spinner />
          ) : (
            <>
              {offerWatchList.length === 0 && (
                <div className={Styles.noIndicationsContainer}>
                  <img src={Images.NoIndications} alt='save' style={{ width: 46, height: 54 }} />
                  <p className={Styles.noIndicationText}>
                    You have not indicated interest yet to any
                    <br />
                    of the coming soon offerings.
                  </p>
                </div>
              )}
              {offerWatchList.map((offerWatch: OfferWatchDto) => (
                <IndicationOfInterestCard
                  key={offerWatch.id}
                  offerWatch={offerWatch}
                  offer={offers?.find(item => item.id === offerWatch.offerId)}
                  onCancelIndication={cancelIndication}
                  onViewOffer={viewOffer}
                />
              ))}
            </>
          )}
        </Col>
      </Row>
      <MModal
        customWidth={520}
        visible={showAlert}
        title='Cancel Indication'
        primaryButtonText='Yes'
        secondaryButtonText='No'
        onPrimaryButtonClick={async () => {
          setSelectedOfferWatchName('');
          setSelectedOfferWatchId(null);
          dispatch(offerWatchesDeleteRequested(selectedOfferWatchId as string));
          setShowAlert(false);
        }}
        onSecondaryButtonClick={() => {
          setShowAlert(false);
        }}
        onClose={() => {
          setShowAlert(false);
        }}>
        <div>Are you sure you want to stop receiving updates and notifications for {selectedOfferWatchName}.?</div>
      </MModal>
    </MyAccountLayout>
  );
};
