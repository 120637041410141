import { css } from '@emotion/css';

import { ColorConstant, Display, Font, ScreenBreakpoint, SpaceSize, Spacing } from '../../../styles';

export const progressBarContainer = css`
  ${Spacing.mlAuto}
  ${Spacing.mr24}
  ${Spacing.mt20}
  ${Spacing.mb40}
  color: ${ColorConstant.BRAND6} !important;

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.ml8}
  }
`;

export const stepsContaienr = css`
  ${Display.flex}
`;

export const step = ({ isComplete }: { isComplete: boolean }) => css`
  width: 25%;
  height: ${SpaceSize.is4};
  ${Spacing.mr4}
  background-color: ${isComplete ? ColorConstant.GREEN6 : ColorConstant.BRAND1};
`;

export const stepsCompleted = css`
  ${Font.xs}
  color: ${ColorConstant.GRAY7};
`;
