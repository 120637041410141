import React from 'react';

import { useSelector } from 'react-redux';

import { BacklinkButton } from '../../../lib/Miscellaneous/BacklinkButton/BacklinkButton';
import Card from '../../../lib/Miscellaneous/Card';
import { Images } from '../../../styles';

import { customerAgreementLinks } from './constants';
import * as Styles from './CustomerAgreementReview.styles';

export const CustomerAgreementReview = () => {
  const accountUuid = useSelector((state: any) => state.accountDetails.accountHolder?.data?.id);

  return (
    <div className={Styles.customerAgreementContainer}>
      <div className={Styles.top}>
        <BacklinkButton to={`/accounts/${accountUuid}/sign-application`} label='Return to My Account' />
        <img src={Images.logo} alt='My IPO' className={Styles.logo} />
      </div>
      <div className={Styles.container}>
        <div className={Styles.mainText}>Customer Agreement</div>
        <div className={Styles.subText}>
          Thank you for reviewing the customer agreements before completing the Account application
        </div>
        <Card>
          <div className={Styles.certificationMain}>
            <div>
              {customerAgreementLinks.map(item => (
                <p key={`${item.label}_key`} className={Styles.certificationLink}>
                  <a className={Styles.linkStyle} href={item.url} target='_blank' rel='noreferrer'>
                    {item.label}
                  </a>
                </p>
              ))}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
