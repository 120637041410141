import React from 'react';

import { Link } from 'react-router-dom';

export interface ResourceLinksProps {
  className: string;
  isMobile?: boolean;
  isAuthenticated?: boolean;
}

const ResourceLinks = ({ className, isMobile = false, isAuthenticated = false }: ResourceLinksProps) => {
  return (
    <div className='linkContainer'>
      <div className='linkBox'>
        <Link to='/about' className={className}>
          About
        </Link>
      </div>

      {!(isMobile && isAuthenticated) && (
        <div className='linkBox'>
          <Link to='/offers' className={className}>
            Offers
          </Link>
        </div>
      )}

      <div className='linkBox'>
        <Link to='/faq' className={className}>
          FAQ
        </Link>
      </div>
    </div>
  );
};

export default ResourceLinks;
