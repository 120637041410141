import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import {
  clearedDefaults,
  failedDefaults,
  requestedDefaults,
  succeededDefaults,
  succeededReplacePayload,
} from './utils';

//GET_PERSONAL_INFORMATION
export const personalInformation = (state: any = initialState.accountDetails.personalInformation, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_PERSONAL_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_PERSONAL_INFORMATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_PERSONAL_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_PERSONAL_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const updatePersonalInformation = (
  state: any = initialState.accountDetails.updatePersonalInformation,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_PERSONAL_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_PERSONAL_INFORMATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_PERSONAL_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_PERSONAL_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const patchPersonalInformation = (
  state: any = initialState.accountDetails.patchPersonalInformation,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_PERSONAL_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_PERSONAL_INFORMATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_PERSONAL_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_PERSONAL_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const updateMailingAddress = (state: any = initialState.accountDetails.updateMailingAddress, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_MAILING_ADDRESS):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_MAILING_ADDRESS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_MAILING_ADDRESS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_MAILING_ADDRESS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const patchMailingAddress = (state: any = initialState.accountDetails.patchMailingAddress, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_MAILING_ADDRESS):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_MAILING_ADDRESS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_MAILING_ADDRESS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_MAILING_ADDRESS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const updatePhysicalAddress = (state: any = initialState.accountDetails.updatePhysicalAddress, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_PHYSICAL_ADDRESS):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_PHYSICAL_ADDRESS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_PHYSICAL_ADDRESS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_PHYSICAL_ADDRESS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const patchPhysicalAddress = (state: any = initialState.accountDetails.patchPhysicalAddress, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_PHYSICAL_ADDRESS):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_PHYSICAL_ADDRESS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_PHYSICAL_ADDRESS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_PHYSICAL_ADDRESS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

//GET_TRUSTED_CONTACT
export const trustedContactInformation = (
  state: any = initialState.accountDetails.trustedContactInformation,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_TRUSTED_CONTACT):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_TRUSTED_CONTACT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_TRUSTED_CONTACT):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.GET_TRUSTED_CONTACT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const updateTrustedContact = (state: any = initialState.accountDetails.updateTrustedContact, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_TRUSTED_CONTACT):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_TRUSTED_CONTACT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_TRUSTED_CONTACT):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.UPDATE_TRUSTED_CONTACT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const patchTrustedContact = (state: any = initialState.accountDetails.patchTrustedContact, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_TRUSTED_CONTACT):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_TRUSTED_CONTACT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_TRUSTED_CONTACT):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.PATCH_TRUSTED_CONTACT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const removeTrustedContact = (state: any = initialState.accountDetails.removeTrustedContact, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.REMOVE_TRUSTED_CONTACT):
      return clearedDefaults(state);
    case State.actionRequested(Type.REMOVE_TRUSTED_CONTACT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.REMOVE_TRUSTED_CONTACT):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.REMOVE_TRUSTED_CONTACT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

//GET_SUITABILITY_INFORMATION
export const suitabilityInformation = (
  state: any = initialState.accountDetails.suitabilityInformation,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_SUITABILITY_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_SUITABILITY_INFORMATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_SUITABILITY_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_SUITABILITY_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const updateSuitabilityInformation = (
  state: any = initialState.accountDetails.updateSuitabilityInformation,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_SUITABILITY_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_SUITABILITY_INFORMATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_SUITABILITY_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_SUITABILITY_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const patchSuitabilityInformation = (
  state: any = initialState.accountDetails.patchSuitabilityInformation,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_SUITABILITY_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_SUITABILITY_INFORMATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_SUITABILITY_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_SUITABILITY_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

//GET_FINANCIAL_INFORMATION
export const financialInformation = (state: any = initialState.accountDetails.financialInformation, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_FINANCIAL_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_FINANCIAL_INFORMATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_FINANCIAL_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_FINANCIAL_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const updateFinancialInformationAssets = (
  state: any = initialState.accountDetails.updateFinancialInformationAssets,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_FINANCIAL_INFORMATION_ASSETS):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_FINANCIAL_INFORMATION_ASSETS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_FINANCIAL_INFORMATION_ASSETS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_FINANCIAL_INFORMATION_ASSETS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const patchFinancialInformationAssets = (
  state: any = initialState.accountDetails.patchFinancialInformationAssets,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_FINANCIAL_INFORMATION_ASSETS):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_FINANCIAL_INFORMATION_ASSETS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_FINANCIAL_INFORMATION_ASSETS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_FINANCIAL_INFORMATION_ASSETS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const updateFinancialInformationEmployment = (
  state: any = initialState.accountDetails.updateFinancialInformationEmployment,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const patchFinancialInformationEmployment = (
  state: any = initialState.accountDetails.patchFinancialInformationEmployment,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

//GET_DISCLOSURES
export const disclosures = (state: any = initialState.accountDetails.disclosures, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_DISCLOSURES):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_DISCLOSURES):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_DISCLOSURES):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_DISCLOSURES):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const updateDisclosures = (state: any = initialState.accountDetails.updateDisclosures, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_DISCLOSURES):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_DISCLOSURES):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_DISCLOSURES):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_DISCLOSURES):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const patchDisclosures = (state: any = initialState.accountDetails.patchDisclosures, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_DISCLOSURES):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_DISCLOSURES):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_DISCLOSURES):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_DISCLOSURES):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

// ACCOUNT_SUBMIT
export const accountSubmit = (state: any = initialState.accountDetails.accountSubmit, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ACCOUNT_SUBMIT):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ACCOUNT_SUBMIT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CREATE_ACCOUNT_SUBMIT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ACCOUNT_SUBMIT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

// ACCOUNT_HOLDER
export const accountHolder = (state: any = initialState.accountDetails.accountHolder, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_ACCOUNT_HOLDER):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_ACCOUNT_HOLDER):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_ACCOUNT_HOLDER):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_ACCOUNT_HOLDER):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

// ACCOUNT_AGREEMENT
export const accountAgreements = (state: any = initialState.accountDetails.accountAgreements, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.ACCEPT_ACCOUNT_AGREEMENTS):
    case State.actionCleared(Type.DECLINE_ACCOUNT_AGREEMENTS):
      return clearedDefaults(state);
    case State.actionRequested(Type.ACCEPT_ACCOUNT_AGREEMENTS):
    case State.actionRequested(Type.DECLINE_ACCOUNT_AGREEMENTS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.ACCEPT_ACCOUNT_AGREEMENTS):
    case State.actionSucceeded(Type.DECLINE_ACCOUNT_AGREEMENTS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.ACCEPT_ACCOUNT_AGREEMENTS):
    case State.actionFailed(Type.DECLINE_ACCOUNT_AGREEMENTS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

// ACCOUNT_BALANCE
export const accountBalance = (state: any = initialState.accountDetails.accountBalance, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.ACCOUNTS_GET_BALANCE):
      return clearedDefaults(state);
    case State.actionRequested(Type.ACCOUNTS_GET_BALANCE):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.ACCOUNTS_GET_BALANCE):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.ACCOUNTS_GET_BALANCE):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

// ACCOUNT_VALUE
export const accountValue = (state: any = initialState.accountDetails.accountValue, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.SET_ACCOUNT_VALUE):
      return clearedDefaults(state);
    case State.actionSucceeded(Type.SET_ACCOUNT_VALUE):
      return succeededDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  personalInformation,
  updatePersonalInformation,
  patchPersonalInformation,
  updatePhysicalAddress,
  patchPhysicalAddress,
  updateMailingAddress,
  patchMailingAddress,

  trustedContactInformation,
  updateTrustedContact,
  patchTrustedContact,
  removeTrustedContact,

  suitabilityInformation,
  updateSuitabilityInformation,
  patchSuitabilityInformation,

  financialInformation,
  updateFinancialInformationAssets,
  patchFinancialInformationAssets,
  updateFinancialInformationEmployment,
  patchFinancialInformationEmployment,

  disclosures,
  updateDisclosures,
  patchDisclosures,

  accountAgreements,

  accountSubmit,
  accountHolder,
  accountBalance,
  accountValue,
});
