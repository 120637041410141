import { useEffect } from 'react';

import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { doAddOfferInvestReferrer, doClearOfferDetails, getOfferDetails, redirectToOfferOrderAdd } from 'src/actions';
import Spinner from 'src/lib/Miscellaneous/Spinner';
import { OfferDetails } from 'src/models';

import { ActiveTab } from '../constants';
import { SignUpWithOfferInvestReferrer } from '../SignUpWithOfferInvestReferrer';

import { RegisterForm } from './RegisterForm';

export const Register = () => {
  const dispatch = useDispatch();
  const params = useParams<{ offerId: string }>();

  const offerDetails: OfferDetails | undefined = useSelector((state: any) => state.offers.offerDetails.data);
  const isOfferDetailsLoading: boolean = useSelector((state: any) => Boolean(state.offers.offerDetails.isLoading));

  useEffect(() => {
    if (params.offerId && !isOfferDetailsLoading) {
      dispatch(getOfferDetails(params.offerId));

      return () => {
        dispatch(doClearOfferDetails());
      };
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(offerDetails) && !isOfferDetailsLoading) {
      dispatch(
        doAddOfferInvestReferrer({
          id: offerDetails.id,
          name: offerDetails.name,
          logoUrl: offerDetails.logoUrl ?? undefined,
        }),
      );
      dispatch(redirectToOfferOrderAdd(offerDetails.id));
    }
  }, [offerDetails]);

  if (isOfferDetailsLoading) {
    return <Spinner />;
  }

  return (
    <SignUpWithOfferInvestReferrer
      activeTab={ActiveTab.Register}
      tabs={[ActiveTab.Register, ActiveTab.Address, ActiveTab.Security]}>
      <RegisterForm />
    </SignUpWithOfferInvestReferrer>
  );
};
