import * as Yup from 'yup';

export const formatValidationSchemaError = (
  error: Yup.ValidationError | any,
): Record<string, string | string[]> | any => {
  if (error instanceof Yup.ValidationError) {
    return error.inner.reduce((acc: any, error: any) => {
      acc[error.path] = error.errors;

      return acc;
    }, {});
  }

  return error;
};
