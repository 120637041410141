import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { Formik } from 'formik';

import { uploadInvestigationDocument } from '../../../../actions';
import { MButton } from '../../../../lib/FormComponents/MButton/MButton';
import RadioGroup from '../../../../lib/FormComponents/RadioGroup';
import { UploadedDocument } from '../../../../typings/investigationTypes';
import * as Styles from '../../AccountStatus/AccountStatus.styles';
import { getInvestigationTitleAndDescription } from '../../AccountStatus/constants';
import { validateFile } from '../../utils';

import { InvestigationCompType } from './constants';
import * as InvestigationStyles from './Investigation.styles';

const DOCUMENT_NAME = 'documentName';

const Investigation = ({
  cipCategory,
  options,
  documentSubmitted,
  investigation,
  dispatch,
  accountId,
  uploadProps,
}: InvestigationCompType) => {
  return (
    <div className={InvestigationStyles.container}>
      {getInvestigationTitleAndDescription(cipCategory?.name)}
      <Formik initialValues={{ documentName: options[0].value }} onSubmit={() => {}}>
        {form => {
          return (
            <>
              {!documentSubmitted && (
                <>
                  <div className={Styles.selectDocumentLabel}>Select the following document you plan to upload:</div>
                  <RadioGroup
                    name={DOCUMENT_NAME}
                    value={form.values[DOCUMENT_NAME]}
                    {...form}
                    options={options}
                    showSubComponentOnSelectOnly
                  />
                </>
              )}
              <Upload
                accept='.png, .jpg, .jpeg, .pdf'
                maxCount={1}
                disabled={documentSubmitted !== undefined}
                defaultFileList={investigation?.uploadedDocuments
                  ?.filter((document: UploadedDocument) => document?.fileName === documentSubmitted?.fileName)
                  .map((document: UploadedDocument) => {
                    return {
                      uid: document.miscDocumentId,
                      name: document?.originalFileName || document?.fileName,
                      status: 'uploaded',
                    };
                  })}
                onChange={info => {
                  if (info.file.status === 'done') {
                    form.setFieldValue(form.values[DOCUMENT_NAME], 'uploaded');
                    const formData: any = new FormData();
                    formData.append('file', info.file.originFileObj);
                    formData.append('imageName', info.file.name);
                    formData.append(DOCUMENT_NAME, form.values[DOCUMENT_NAME]);
                    dispatch(uploadInvestigationDocument(accountId, investigation?.investigationId, formData));
                  } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                  }
                }}
                beforeUpload={(file: any) => validateFile(file, dispatch)}
                {...uploadProps}>
                {!documentSubmitted && (
                  <MButton type='secondary'>
                    <UploadOutlined style={{ height: 16, width: 16 }} /> Upload
                  </MButton>
                )}
              </Upload>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Investigation;
