import { css, cx } from '@emotion/css';

export const listRowContainer = cx(
  css`
    width: 100%;
    border-bottom: 1px solid #edf2f7;
    justify-content: center !important;
    align-items: center;
    min-height: 50px;
  `,
);

export const listRowChild = cx(
  css`
    flex: 1 !important;
  `,
);
