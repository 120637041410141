import React, { useEffect, useState } from 'react';

import { Form, Select } from 'antd';
import _ from 'lodash';

import { DatePickerCompType, getDays, getMonths, getYears } from './constants';
import * as Styles from './DateOfBirthPicker.styles';

const { Option } = Select;

export const DateOfBirthPicker = ({
  name,
  value,
  disabled = false,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  preChangeHook,
  postChangeHook,
}: DatePickerCompType) => {
  const initialVal = !_.isEmpty(value) && value?.split('-');

  const [month, setMonth] = useState(initialVal ? initialVal[0] : '');
  const [day, setDay] = useState(initialVal ? initialVal[1] : '');
  const [year, setYear] = useState(initialVal ? initialVal[2] : '');

  const onChangeHooks = () => {
    if (preChangeHook) {
      preChangeHook();
    }
    setFieldTouched(name, true, true);

    if (postChangeHook) {
      postChangeHook();
    }
  };

  useEffect(() => {
    if (!_.isEmpty(year) && !_.isEmpty(month) && !_.isEmpty(day)) {
      setFieldValue(name, `${year}-${month}-${day}`, true);
    }
  }, [day, month, year]);

  const conditionalPropsDay = day ? { defaultValue: day } : {};
  const conditionalPropsMonth = month ? { defaultValue: month } : {};
  const conditionalPropsYear = year ? { defaultValue: year } : {};

  return (
    <Form.Item
      validateStatus={!!errors && errors[name] && 'error'}
      help={!!errors && touched[name] && errors[name]}
      colon={false}
      style={{
        flexDirection: 'column',
        marginBottom: 0,
      }}>
      <div
        style={{
          display: 'flex',
        }}>
        <Select
          getPopupContainer={trigger => trigger.parentElement}
          className={Styles.popupContainer}
          {...conditionalPropsMonth}
          placeholder={'MM'}
          bordered={false}
          disabled={disabled}
          onChange={value => {
            setMonth(value);
            onChangeHooks();
          }}>
          {getMonths().map((option: any) => (
            <Option key={`months_menu_item_${option.value}`} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Select>

        <Select
          getPopupContainer={trigger => trigger.parentElement}
          className={Styles.popupContainer}
          {...conditionalPropsDay}
          placeholder={'DD'}
          bordered={false}
          disabled={disabled}
          onChange={value => {
            setDay(value);
            onChangeHooks();
          }}>
          {getDays().map((option: any) => (
            <Option key={`days_menu_item_${option.value}`} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Select>

        <Select
          getPopupContainer={trigger => trigger.parentElement}
          className={Styles.popupContainer}
          {...conditionalPropsYear}
          placeholder={'YYYY'}
          style={{ width: 200 }}
          bordered={false}
          disabled={disabled}
          onChange={value => {
            setYear(value);
            onChangeHooks();
          }}>
          {getYears().map((option: any) => (
            <Option key={`years_menu_item_${option.value}`} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Select>
      </div>
    </Form.Item>
  );
};

export default DateOfBirthPicker;
