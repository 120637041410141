import React from 'react';

import { Col, Form, Row } from 'antd';
import _ from 'lodash';

import {
  ExperienceAndTradesPerYear,
  InvestmentExperience,
  TradesPerYear,
  YearsOfExperience,
} from '../../../constants/accountCompletion';
import { getErrorMessage } from '../../../utils/helpers';
import CheckBoxComp from '../../FormComponents/CheckBox';
import Dropdown from '../../FormComponents/Dropdown';

const FinancialInvestmentComp = ({ form, name }: any) => {
  const { errors, touched } = form;
  const fieldName = name;

  return (
    <Form.Item
      validateStatus={!!errors && errors[fieldName] && 'error'}
      help={!!errors && touched[fieldName] && getErrorMessage(errors[fieldName])}
      colon={false}
      style={{
        flexDirection: 'column',
        marginBottom: 0,
      }}>
      <div>
        {InvestmentExperience.map((checkBox, index) => (
          <div
            key={checkBox.value}
            style={{
              borderBottomWidth: InvestmentExperience.length - 1 === index ? 0 : 1,
            }}>
            <CheckBoxComp
              {...form}
              name={checkBox.value}
              label={checkBox.text}
              subLabel={checkBox?.subLabel}
              isPartOfGroup
              value={
                form.values &&
                !_.isEmpty(form.values[fieldName]) &&
                form.values[fieldName].hasOwnProperty(checkBox.value) &&
                form.values[fieldName][checkBox.value] !== undefined
                  ? true
                  : null
              }
              customStyles={{ paddingLeft: 7, marginBottom: 21 }}
              customSetFieldValue={(name: any, selectedValue: boolean) => {
                if (selectedValue === true) {
                  form.setFieldValue(
                    fieldName,
                    {
                      ...form.values[fieldName],
                      [name]: {
                        yearsOfExperience: '',
                        tradesPerYear: '',
                      },
                    },
                    true,
                  );
                } else {
                  const tempValues = _.cloneDeep(form.values[fieldName]);
                  delete tempValues[name];
                  form.setFieldValue(fieldName, tempValues, false);
                  form.setTouched({ ...touched, financialInvestmentExperience: undefined }, true);
                }
              }}
            />

            {form.values &&
              !_.isEmpty(form.values[fieldName]) &&
              form.values[fieldName].hasOwnProperty(checkBox.value) &&
              !_.isEmpty(form.values[fieldName][checkBox.value]) && (
                <div style={{ paddingLeft: 7, paddingRight: 7 }} data-testid={'account-financial-' + checkBox.value}>
                  {ExperienceAndTradesPerYear.map(field => {
                    return (
                      <Row key={`${checkBox.value}_${field.name}_key`}>
                        <Col span={12}>
                          <div
                            style={{
                              marginTop: 10,
                              marginBottom: 15,
                            }}>
                            {field.label}
                          </div>
                        </Col>
                        <Col span={12}>
                          <Dropdown
                            {...form}
                            errors={
                              form.values[fieldName][checkBox.value][field.name] === ''
                                ? { [field.name]: 'Required' }
                                : {}
                            }
                            touched={{ [field.name]: true }}
                            name={field.name}
                            options={field.name === 'yearsOfExperience' ? YearsOfExperience : TradesPerYear}
                            placeholder={field.name === 'yearsOfExperience' ? 'Years Of Experience' : 'Trades/Years'}
                            customSetFieldValue={(selectedValue: any) => {
                              form.setFieldValue(
                                fieldName,
                                {
                                  ...{
                                    ...form.values[fieldName],
                                    [checkBox.value]: {
                                      ...form.values[fieldName][checkBox.value],
                                      [field.name]: selectedValue,
                                    },
                                  },
                                },
                                true,
                              );
                            }}
                            value={
                              form.values[fieldName][checkBox.value]
                                ? form.values[fieldName][checkBox.value][field.name]
                                : ''
                            }
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              )}
          </div>
        ))}
      </div>
    </Form.Item>
  );
};

export default FinancialInvestmentComp;
