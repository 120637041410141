import { css, cx } from '@emotion/css';

import { AlignContent, ColorConstant, Display, Font, Spacing } from '../../styles';

export const button = css`
  color: ${ColorConstant.BRAND6};
  ${Display.flex}
  ${AlignContent.center}
  ${Font.sm}
  cursor: pointer;

  &:hover {
    color: ${ColorConstant.BRAND6};
  }

  &:focus {
    color: ${ColorConstant.BRAND6};
  }
`;

export const infoIcon = cx(
  'ri-information-line',
  css(`
  width: 16px;
  height: 16px;
  ${Spacing.mr4}
`),
);
