import React from 'react';

import { cx } from '@emotion/css';
import { Col, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { createOrdersRequested } from '../../../../../../actions';
import {
  TradingBlockOrderActionEnum,
  TradingBlockOrderDurationEnum,
  TradingBlockOrderTypeEnum,
} from '../../../../../../dtos/orders.dtos';
import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import Spinner from '../../../../../../lib/Miscellaneous/Spinner';
import { ModalFormItem } from '../../../../../../models/modal-form-item.model';
import { SecuritiesModalStepsEnum } from '../../../../../../models/securities-modal-steps.enum';

import * as Styles from './styles';

interface ReviewFormProps {
  bindOnSubmitOrder: (submitForm: any) => any;
  onDisclaimerClick?: (step: number) => void;
}

export const ReviewForm = ({ bindOnSubmitOrder, onDisclaimerClick }: ReviewFormProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => !state.orders.createOrdersReviewPositions?.__succeeded);
  const orderToReview = useSelector((state: any) => state.orders.createOrdersReviewPositions?.data);
  const account = useSelector((state: any) => state.accountDetails.accountHolder?.data);

  const getReviewFormFields = (orderToReview: any): ModalFormItem[] => {
    return [
      {
        key: 'shortCode',
        readOnly: true,
        show: true,
        props: {
          label: 'Security',
        },
      },
      {
        key: 'orderType',
        readOnly: true,
        show: true,
        props: {
          label: 'Order Type',
        },
        componentValue: (value: string) => {
          const selectedOrderType = [
            { text: 'Market Order', value: TradingBlockOrderTypeEnum.Market },
            { text: 'Limit Order', value: TradingBlockOrderTypeEnum.Limit },
            { text: 'Stop Market Order', value: TradingBlockOrderTypeEnum.Stop_Market },
            { text: 'Stop Limit Order', value: TradingBlockOrderTypeEnum.Stop_Limit },
            { text: 'Market on Close Order', value: TradingBlockOrderTypeEnum.Market_On_Close },
          ].find((orderType: any) => orderType.value === value);

          return <span>{selectedOrderType?.text || value}</span>;
        },
      },
      {
        key: 'marketPrice',
        readOnly: true,
        show: true,
        componentLabel: () => (
          <Space size={6}>
            <span>Last Price</span>
            <i
              className={cx('ri-question-line', Styles.disclaimerStyle)}
              onClick={() =>
                onDisclaimerClick ? onDisclaimerClick(SecuritiesModalStepsEnum.INFO_DISCLAIMER_4) : null
              }></i>
          </Space>
        ),
        componentValue: (value: string) => <CurrencyField value={Number(value)} />,
      },
      {
        key: 'quantity',
        readOnly: true,
        show: true,
        props: {
          label: 'Shares Held',
        },
      },
      {
        key: 'limitPrice',
        readOnly: true,
        show: ![
          TradingBlockOrderTypeEnum.Market,
          TradingBlockOrderTypeEnum.Market_On_Close,
          TradingBlockOrderTypeEnum.Stop_Market,
        ].includes(orderToReview.orderType),
        componentValue: (value: string) => <CurrencyField value={Number(value)} />,
        props: {
          label: 'Limit Price',
        },
      },
      {
        key: 'stopPrice',
        readOnly: true,
        show: ![
          TradingBlockOrderTypeEnum.Market,
          TradingBlockOrderTypeEnum.Market_On_Close,
          TradingBlockOrderTypeEnum.Limit,
        ].includes(orderToReview.orderType),
        componentValue: (value: string) => <CurrencyField value={Number(value)} />,
        props: {
          label: 'Stop Price',
        },
      },
      {
        key: 'shares',
        readOnly: true,
        show: true,
        props: {
          label: `Shares to ${orderToReview.action === TradingBlockOrderActionEnum.Buy ? 'Buy' : 'Sell'}`,
        },
      },
      {
        key: 'orderDuration',
        readOnly: true,
        show: true,
        props: {
          label: 'Order Expiration',
        },
        componentValue: (value: string) => {
          const selectedOrderDuration = [
            { text: 'End of Day', value: TradingBlockOrderDurationEnum.Day },
            { text: 'Good Until Cancelled', value: TradingBlockOrderDurationEnum.GTC },
          ].find((orderType: any) => orderType.value === value);

          return <span>{selectedOrderDuration?.text || value}</span>;
        },
      },
      {
        key: 'serviceFee',
        readOnly: true,
        show: true,
        componentLabel: () => (
          <Space size={6}>
            <span>Service Fee</span>
            <i
              className={cx('ri-share-box-line', Styles.disclaimerStyle)}
              onClick={() =>
                onDisclaimerClick ? onDisclaimerClick(SecuritiesModalStepsEnum.INFO_DISCLAIMER_5) : null
              }></i>
          </Space>
        ),
        componentValue: (value: string) => <CurrencyField value={Number(value)} />,
      },
      {
        key: 'estimatedTotal',
        readOnly: true,
        show: true,
        componentValue: (value: string) => <CurrencyField value={Number(value)} />,
        props: {
          label: 'Estimated Total',
        },
      },
    ];
  };

  const generateReviewFormFields = () => {
    return getReviewFormFields(orderToReview)
      ?.filter(field => field.show)
      ?.map((field: ModalFormItem) => {
        return (
          <React.Fragment key={field.key}>
            <Col span={10} className={cx(Styles.borderBottom, Styles.fieldLabel)}>
              {field.componentLabel ? field.componentLabel() : <span>{field.props?.label || ''}</span>}
            </Col>
            <Col
              span={14}
              className={cx(Styles.alignRight, Styles.borderBottom, Styles.fieldValue)}
              key={`${field.key}_value`}>
              {field.componentValue ? (
                field.componentValue(orderToReview[field.key])
              ) : (
                <span>{orderToReview[field.key] || field.props?.value || ''}</span>
              )}
            </Col>
          </React.Fragment>
        );
      });
  };

  const submitForm = () => {
    dispatch(createOrdersRequested({ order: orderToReview, accountId: account?.accountId }));
  };

  bindOnSubmitOrder(submitForm);

  if (isLoading) return <Spinner />;

  return (
    <>
      <Row className={Styles.titleWrapper}>
        <Col className={cx(Styles.alignCenter, Styles.subtitleWrapper)}>
          <Space size={10} direction='vertical'>
            <h1>Review Order</h1>
            <span>
              If you experience difficulties, please contact{' '}
              <a href={`mailto:clientservices@myipo.com`}>Client Services representative</a>
              .<br /> Execution price, speed and liquidity are affected by many factors, including market volatility,
              size, price, low volume, type of order and the exchange the shares are traded on.{' '}
              <a
                onClick={() =>
                  onDisclaimerClick ? onDisclaimerClick(SecuritiesModalStepsEnum.INFO_DISCLAIMER_6) : null
                }>
                Read more
              </a>
              .
            </span>
          </Space>
        </Col>
      </Row>
      <Row className={Styles.rowWrapper}>{generateReviewFormFields()}</Row>
    </>
  );
};
