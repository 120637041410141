import { css } from '@emotion/css';
import { ColorConstant, Font, FontWeight, Spacing } from 'src/styles';

export const amountValue = ({ isNegative }: { isNegative: boolean }) => css`
  ${FontWeight.bold}
  ${Font.md}
  color: ${isNegative ? ColorConstant.RED8 : ColorConstant.GREEN8};
`;

export const disclaimerContainer = css`
  ${Font.md}
  ${Spacing.pt8}
  ${Spacing.pb16}
`;

export const alert = css`
  ${Spacing.mt24}
  ${Spacing.p4}
  ${Spacing.ml0}
`;
