import { UserDto } from '../../../../dtos/users.dtos';
import ListRow from '../../../../lib/Miscellaneous/ListRow/ListRow';
import { OfferDetails } from '../../../../models/offers.models';
import IndicationsOfInterestBanner from '../IndicationsOfInterestBanner/IndicationsOfInterestBanner';

import Disclaimer from './Disclaimer';
import * as Styles from './styles';

enum SectionKeys {
  CONTACT_INFO = 'contact_info',
  INVESTMENT_RANGE = 'investment_range',
}

type SectionType = {
  sectionLabel: string;
  sectionKey: SectionKeys;
  fieldKeys: Array<string>;
};

const sections: Array<SectionType> = [
  {
    sectionLabel: 'Contact Information',
    sectionKey: SectionKeys.CONTACT_INFO,
    fieldKeys: ['firstName', 'lastName', 'email', 'phoneNumber', 'state'],
  },
  {
    sectionLabel: 'Investment Range',
    sectionKey: SectionKeys.INVESTMENT_RANGE,
    fieldKeys: ['investmentRanges'],
  },
];

const IndicationOfInterestForm = ({
  form,
  fields,
  userInfo,
  offer,
}: {
  form: any;
  fields: any;
  userInfo: UserDto;
  offer: OfferDetails;
}) => {
  return (
    <div>
      {!userInfo && <IndicationsOfInterestBanner offer={offer} />}

      <div className={Styles.description}>
        Please complete the form below to indicate your desired investment amount. You will receive updates and
        notifications when the Offer becomes for actual investment.
      </div>
      {sections?.map((section: any) => (
        <div key={`${section.sectionKey}_key`}>
          {userInfo && section.sectionKey === SectionKeys.CONTACT_INFO ? null : (
            <div className={Styles.sectionTitle}>{section.sectionLabel}</div>
          )}

          {Object.keys(fields)
            ?.filter(key => section.fieldKeys.includes(key))
            ?.map(key => {
              const JSXRenderableComp = fields[key].component;

              if (fields[key]?.hideField) {
                return null;
              } else {
                return fields[key].isNotListRow ? (
                  <JSXRenderableComp
                    key={`${key}_input_${fields[key].key}`}
                    {...form}
                    {...fields[key].props}
                    value={form?.values[key]}
                  />
                ) : (
                  <ListRow
                    key={`${key}_view_${fields[key].key}`}
                    leftComponent={<div className={Styles.fieldLabel}>{fields[key].label}</div>}
                    rightComponent={
                      <div
                        style={{
                          alignItems: 'center',
                        }}>
                        <JSXRenderableComp
                          key={`${key}_input_${fields[key].key}`}
                          {...form}
                          {...fields[key].props}
                          value={form?.values[key]}
                        />
                      </div>
                    }
                  />
                );
              }
            })}
        </div>
      ))}

      <Disclaimer />
    </div>
  );
};

export default IndicationOfInterestForm;
