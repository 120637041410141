import { css } from '@emotion/css';

import {
  AlignItems,
  BaseStyle,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../../../../styles';

export const fieldLabel = css`
  ${FontWeight.normal}
  ${Font.md}
  color: ${ColorConstant.GRAY8};
`;

export const fieldValue = css`
  ${BaseStyle.pullRight}
  ${Spacing.p0}
  min-width: 4rem !important;
`;

export const rowStyle = css`
  ${JustifyContent.center}
  ${AlignItems.center}
  ${Spacing.mb40}
  height: 3.5rem;
`;

export const offerName = css`
  ${FontWeight.bold}
  ${Font.h1}
  color: ${ColorConstant.GRAY10};
`;

export const sectionTitle = css`
  ${FontWeight.bold}
  ${Font.sm}
  ${Display.flex}
  ${AlignItems.center}
  color: ${ColorConstant.BRAND6};
  ${Spacing.mt40}
  ${Spacing.mb40}
`;

export const container = css`
  ${Spacing.px64}
  ${Spacing.py40}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.p20}
  }
`;

export const offerLogo = css`
  width: 2rem;
  height: 2rem;
  ${Spacing.mr16}
`;

export const fieldsContainer = css`
  ${Spacing.mb20}
`;

export const signatureWrapper = css`
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    .ant-card-body {
      ${Spacing.p8}
    }
  }
`;

export const signature = css`
  height: 2.5rem; !important;
  max-width: 100%;
  width: 100%;
`;

export const buttonsContainer = css`
  ${Spacing.mt24}
`;
