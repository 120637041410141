import { call, put, SagaReturnType, select, takeEvery } from 'redux-saga/effects';
import { isAddressMismatchError } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';

import { doSucceededAccountBalance, getAccountHolderSucceeded, toastMessagesAdd } from '../actions';
import { getAccountHolder as getAccountHolderAction } from '../actions/accountDetails';
import { getAccount } from '../actions/accounts.actions';
import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import { BalancesApi } from '../data-communication/BalancesApi';
import { getClientApi } from '../data-communication/ClientApi';
import { mapAccountBalanceDetailsDtoToModel } from '../mappers/accountBalances.mappers';
import { getRiskToleranceValue } from '../pages/PortfolioCompletion/SuitabilityInformation/fields';
import { AuthReducedState } from '../typings/auth.types';
import { ResponseGenerator, SeverityEnum, TReduxAction } from '../typings/commonTypes';

import { signAccountAgreement } from './users.sagas';
import { getErrorMessage, HttpClient, replacePlaceholders, safeSaga } from './utils';

export function* handleAddressMismatchResponseError({ error, actionType }: { error: any; actionType: string }) {
  yield put({
    type: State.actionFailed(actionType),
    message: { ...JSON.parse(getErrorMessage(error)).address, error: error?.response?.data?.error },
  });

  return;
}

export function* getPersonalInformation() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.ACCOUNT_OWNER_INFORMATION, {
    accountIdentifier: 'individual',
  });
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);
  yield put({
    type: State.actionSucceeded(Type.GET_PERSONAL_INFORMATION),
    payload: response.data,
  });
}

export function* createPersonalInformation(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let bodyToSend = action.payload.data;
  const url = replacePlaceholders(Url.PERSONAL_INFORMATION, {
    accountIdentifier: 'individual',
  });

  if (bodyToSend.firstName) {
    bodyToSend.firstName = undefined;
  }

  if (bodyToSend.lastName) {
    bodyToSend.lastName = undefined;
  }

  if (!bodyToSend.suffix) {
    bodyToSend.suffix = undefined;
  }

  if (!bodyToSend.middleName) {
    bodyToSend.middleName = undefined;
  }

  yield call(HttpClient, 'POST', url, bodyToSend, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Personal Information was successfully saved',
    }),
  );

  yield put({
    type: State.actionSucceeded(Type.UPDATE_PERSONAL_INFORMATION),
  });
  yield getPersonalInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* patchPersonalInformation(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.PERSONAL_INFORMATION, {
    accountIdentifier: 'individual',
  });

  yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: `Personal Information was successfully saved`,
    }),
  );

  yield put({
    type: State.actionSucceeded(Type.PATCH_PERSONAL_INFORMATION),
  });
  yield getPersonalInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* createPhysicalAddress(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.PHYSICAL_ADDRESS_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    yield call(HttpClient, 'POST', url, action.payload.data, authToken);
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.UPDATE_PHYSICAL_ADDRESS });

      return;
    }
    throw error;
  }
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Physical Address Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.UPDATE_PHYSICAL_ADDRESS),
  });
  yield getPersonalInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* patchPhysicalAddress(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.PHYSICAL_ADDRESS_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.PATCH_PHYSICAL_ADDRESS });

      return;
    }
    throw error;
  }
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Physical Address Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.PATCH_PHYSICAL_ADDRESS),
  });
  yield getPersonalInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* createMailingAddress(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.MAILING_ADDRESS_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    yield call(HttpClient, 'POST', url, action.payload.data, authToken);
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.UPDATE_MAILING_ADDRESS });

      return;
    }
    throw error;
  }
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Mailing Address Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.UPDATE_MAILING_ADDRESS),
  });
  yield getPersonalInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* patchMailingAddress(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.MAILING_ADDRESS_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.PATCH_MAILING_ADDRESS });

      return;
    }
    throw error;
  }

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: `Mailing Address Information was successfully saved`,
    }),
  );

  yield put({
    type: State.actionSucceeded(Type.PATCH_MAILING_ADDRESS),
  });
  yield getPersonalInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* getTrustedContact() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.TRUSTED_CONTACT_INFORMATION, {
    accountIdentifier: 'individual',
  });
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);
  yield put({
    type: State.actionSucceeded(Type.GET_TRUSTED_CONTACT),
    payload: response.data,
  });
}

export function* createTrustedContact(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.TRUSTED_CONTACT_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    yield call(HttpClient, 'POST', url, action.payload.data, authToken);
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.UPDATE_TRUSTED_CONTACT });

      return;
    }
    throw error;
  }

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Trusted Contact Information was successfully saved',
    }),
  );

  yield put({
    type: State.actionSucceeded(Type.UPDATE_TRUSTED_CONTACT),
  });
  yield getTrustedContact();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* patchTrustedContact(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.TRUSTED_CONTACT_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.PATCH_TRUSTED_CONTACT });

      return;
    }
    throw error;
  }

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Trusted Contact Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.PATCH_TRUSTED_CONTACT),
  });
  yield getTrustedContact();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* removeTrustedContact() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.TRUSTED_CONTACT_INFORMATION, {
    accountIdentifier: 'individual',
  });
  yield call(HttpClient, 'DELETE', url, undefined, authToken);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Trusted Contact Person was successfully removed',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.REMOVE_TRUSTED_CONTACT),
  });
  yield getTrustedContact();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* getSuitabilityInformation() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.SUITABILITY_INFORMATION, {
    accountIdentifier: 'individual',
  });
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);
  yield put({
    type: State.actionSucceeded(Type.GET_SUITABILITY_INFORMATION),
    payload: response.data,
  });
}

export function* createSuitabilityInformation(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.SUITABILITY_INFORMATION, {
    accountIdentifier: 'individual',
  });

  let bodyToSend = {
    ...action.payload?.data,
    riskTolerance: getRiskToleranceValue(action.payload?.data?.investmentObjectives),
  };

  yield call(HttpClient, 'POST', url, bodyToSend, authToken);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Suitability Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.UPDATE_SUITABILITY_INFORMATION),
  });
  yield getSuitabilityInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* patchSuitabilityInformation(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.SUITABILITY_INFORMATION, {
    accountIdentifier: 'individual',
  });
  let bodyToSend = action.payload.data;

  if (action.payload?.data?.investmentObjectives) {
    bodyToSend = {
      ...action.payload.data,
      riskTolerance: getRiskToleranceValue(action.payload?.data?.investmentObjectives),
    };
  }
  yield call(HttpClient, 'PATCH', url, bodyToSend, authToken);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Suitability Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.PATCH_SUITABILITY_INFORMATION),
  });
  yield getSuitabilityInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* getFinancialInformation() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.FINANCIAL_INFORMATION, {
    accountIdentifier: 'individual',
  });
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);
  yield put({
    type: State.actionSucceeded(Type.GET_FINANCIAL_INFORMATION),
    payload: response.data,
  });
}

export function* createFinancialInformationEmployment(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.EMPLOYMENT_FINANCIAL_INFORMATION, {
    accountIdentifier: 'individual',
  });
  let bodyToSend = {};

  if (['student', 'unemployed', 'retired'].includes(action.payload?.data?.employmentStatus)) {
    bodyToSend = { employmentStatus: action.payload?.data?.employmentStatus };
  } else {
    bodyToSend = {
      employmentStatus: action.payload?.data?.employmentStatus,
      employerName: action.payload?.data?.employerName,
      jobTitle: action.payload?.data?.jobTitle,
      yearsEmployed: action.payload?.data?.yearsEmployed,
      employerAddress: {
        address1: action.payload?.data?.address1,
        address2: action.payload?.data?.address2,
        country: action.payload?.data?.country,
        state: action.payload?.data?.state,
        city: action.payload?.data?.city,
        postalCode: action.payload?.data?.postalCode,
      },
    };
  }

  try {
    yield call(HttpClient, 'POST', url, bodyToSend, authToken);
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT });

      return;
    }
    throw error;
  }

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Employment Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT),
  });
  yield getFinancialInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* patchFinancialInformationEmployment(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.EMPLOYMENT_FINANCIAL_INFORMATION, {
    accountIdentifier: 'individual',
  });

  let bodyToSend = {};

  if (['student', 'unemployed', 'retired'].includes(action.payload?.data?.employmentStatus)) {
    bodyToSend = { employmentStatus: action.payload?.data?.employmentStatus };
  } else {
    bodyToSend = {
      employmentStatus: action.payload?.data?.employmentStatus,
      employerName: action.payload?.data?.employerName,
      jobTitle: action.payload?.data?.jobTitle,
      yearsEmployed: action.payload?.data?.yearsEmployed,
      employerAddress: {
        address1: action.payload?.data?.address1,
        address2: action.payload?.data?.address2,
        country: action.payload?.data?.country,
        state: action.payload?.data?.state,
        city: action.payload?.data?.city,
        postalCode: action.payload?.data?.postalCode,
      },
    };
  }

  try {
    yield call(HttpClient, 'PATCH', url, bodyToSend, authToken);
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT });

      return;
    }
    throw error;
  }

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: `Employment Information was successfully saved`,
    }),
  );

  yield put({
    type: State.actionSucceeded(Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT),
  });
  yield getFinancialInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* createFinancialInformationAssets(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.ASSETS_FINANCIAL_INFORMATION, {
    accountIdentifier: 'individual',
  });
  yield call(HttpClient, 'POST', url, action.payload.data, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Assets Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.UPDATE_FINANCIAL_INFORMATION_ASSETS),
  });
  yield getFinancialInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* patchFinancialInformationAssets(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.ASSETS_FINANCIAL_INFORMATION, {
    accountIdentifier: 'individual',
  });
  const bodyToSend = action.payload.data;

  yield call(HttpClient, 'PATCH', url, bodyToSend, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: `Assets Information was successfully saved`,
    }),
  );

  yield put({
    type: State.actionSucceeded(Type.PATCH_FINANCIAL_INFORMATION_ASSETS),
  });
  yield getFinancialInformation();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* getDisclosures() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.DISCLOSURES_INFORMATION, {
    accountIdentifier: 'individual',
  });
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);
  yield put({
    type: State.actionSucceeded(Type.GET_DISCLOSURES),
    payload: response.data,
  });
}

export function* createDisclosures(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.DISCLOSURES_INFORMATION, {
    accountIdentifier: 'individual',
  });

  let bodyToSend: any = {};

  bodyToSend = {
    statementDelivery: action.payload?.data?.statementDelivery,
    stakeholder: action.payload.data?.individualRepresentations?.stakeholder ?? false,
    industryEmployed: action.payload.data?.individualRepresentations?.industryEmployed ?? false,
  };

  yield call(HttpClient, 'POST', url, bodyToSend, authToken);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Disclosures Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.UPDATE_DISCLOSURES),
  });
  yield getDisclosures();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* patchDisclosures(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.DISCLOSURES_INFORMATION, {
    accountIdentifier: 'individual',
  });

  let bodyToSend: any = {};

  if (action.payload.data?.statementDelivery) {
    bodyToSend = action.payload.data;
  } else {
    bodyToSend = {
      ...bodyToSend,
      stakeholder: action.payload.data?.individualRepresentations?.stakeholder ?? false,
      industryEmployed: action.payload.data?.individualRepresentations?.industryEmployed ?? false,
    };
  }

  yield call(HttpClient, 'PATCH', url, bodyToSend, authToken);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Disclosures Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.PATCH_DISCLOSURES),
  });
  yield getDisclosures();
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* submitAccountApplication(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const signature = action.payload.signature;

  if (signature) {
    yield call(signAccountAgreement, { type: Type.SIGN_ACCOUNT_AGREEMENT, payload: { signature } });
  }

  let url = replacePlaceholders(Url.SUBMIT_ACCOUNT_APPLICATION, {
    accountIdentifier: 'individual',
  });
  yield call(HttpClient, 'PUT', url, undefined, authToken);

  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_SUBMIT),
  });
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Account Application was successfully submitted',
    }),
  );
  yield put(getAccountHolderAction());
  // NOTE: Temporarily until account portfolio starts using accounts state;
  yield put(getAccount());
}

export function* getAccountHolder() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.GET_ACCOUNT_HOLDER, {
    accountIdentifier: 'individual',
  });
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);

  yield put(getAccountHolderSucceeded(response.data));
}

export function* accountsGetBalances(action: TReduxAction) {
  const { accountId } = action.payload;

  const { authToken } = yield select(state => state.auth.data);

  const response: SagaReturnType<BalancesApi['retrieve']> = yield call(getClientApi().balances.retrieve, {
    params: { accountId },
    authToken,
  });

  yield put(doSucceededAccountBalance(mapAccountBalanceDetailsDtoToModel(response)));
}

/**
 * Account Details sagas
 */
export default function* accountDetailsSaga() {
  // PERSONAL_INFORMATION
  yield takeEvery(
    State.actionRequested(Type.GET_PERSONAL_INFORMATION),
    safeSaga(getPersonalInformation, Type.GET_PERSONAL_INFORMATION),
  );
  yield takeEvery(
    State.actionRequested(Type.UPDATE_PERSONAL_INFORMATION),
    safeSaga(createPersonalInformation, Type.UPDATE_PERSONAL_INFORMATION),
  );

  yield takeEvery(
    State.actionRequested(Type.PATCH_PERSONAL_INFORMATION),
    safeSaga(patchPersonalInformation, Type.PATCH_PERSONAL_INFORMATION),
  );

  yield takeEvery(
    State.actionRequested(Type.UPDATE_PHYSICAL_ADDRESS),
    safeSaga(createPhysicalAddress, Type.UPDATE_PHYSICAL_ADDRESS),
  );

  yield takeEvery(
    State.actionRequested(Type.PATCH_PHYSICAL_ADDRESS),
    safeSaga(patchPhysicalAddress, Type.PATCH_PHYSICAL_ADDRESS),
  );

  yield takeEvery(
    State.actionRequested(Type.UPDATE_MAILING_ADDRESS),
    safeSaga(createMailingAddress, Type.UPDATE_MAILING_ADDRESS),
  );

  yield takeEvery(
    State.actionRequested(Type.PATCH_MAILING_ADDRESS),
    safeSaga(patchMailingAddress, Type.PATCH_MAILING_ADDRESS),
  );

  // TRUSTED_CONTACT
  yield takeEvery(
    State.actionRequested(Type.GET_TRUSTED_CONTACT),
    safeSaga(getTrustedContact, Type.GET_TRUSTED_CONTACT),
  );
  yield takeEvery(
    State.actionRequested(Type.UPDATE_TRUSTED_CONTACT),
    safeSaga(createTrustedContact, Type.UPDATE_TRUSTED_CONTACT),
  );
  yield takeEvery(
    State.actionRequested(Type.REMOVE_TRUSTED_CONTACT),
    safeSaga(removeTrustedContact, Type.REMOVE_TRUSTED_CONTACT),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_TRUSTED_CONTACT),
    safeSaga(patchTrustedContact, Type.PATCH_TRUSTED_CONTACT),
  );

  // SUITABILITY_INFORMATION
  yield takeEvery(
    State.actionRequested(Type.GET_SUITABILITY_INFORMATION),
    safeSaga(getSuitabilityInformation, Type.GET_SUITABILITY_INFORMATION),
  );
  yield takeEvery(
    State.actionRequested(Type.UPDATE_SUITABILITY_INFORMATION),
    safeSaga(createSuitabilityInformation, Type.UPDATE_SUITABILITY_INFORMATION),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_SUITABILITY_INFORMATION),
    safeSaga(patchSuitabilityInformation, Type.PATCH_SUITABILITY_INFORMATION),
  );

  // FINANCIAL_INFORMATION
  yield takeEvery(
    State.actionRequested(Type.GET_FINANCIAL_INFORMATION),
    safeSaga(getFinancialInformation, Type.GET_FINANCIAL_INFORMATION),
  );
  yield takeEvery(
    State.actionRequested(Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT),
    safeSaga(createFinancialInformationEmployment, Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT),
    safeSaga(patchFinancialInformationEmployment, Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT),
  );
  yield takeEvery(
    State.actionRequested(Type.UPDATE_FINANCIAL_INFORMATION_ASSETS),
    safeSaga(createFinancialInformationAssets, Type.UPDATE_FINANCIAL_INFORMATION_ASSETS),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_FINANCIAL_INFORMATION_ASSETS),
    safeSaga(patchFinancialInformationAssets, Type.PATCH_FINANCIAL_INFORMATION_ASSETS),
  );

  // DISCLOSURES
  yield takeEvery(State.actionRequested(Type.GET_DISCLOSURES), safeSaga(getDisclosures, Type.GET_DISCLOSURES));
  yield takeEvery(State.actionRequested(Type.UPDATE_DISCLOSURES), safeSaga(createDisclosures, Type.UPDATE_DISCLOSURES));
  yield takeEvery(State.actionRequested(Type.PATCH_DISCLOSURES), safeSaga(patchDisclosures, Type.PATCH_DISCLOSURES));

  // CREATE_ACCOUNT
  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_SUBMIT),
    safeSaga(submitAccountApplication, Type.CREATE_ACCOUNT_SUBMIT),
  );

  // ACCOUNT_HOLDER
  yield takeEvery(State.actionRequested(Type.GET_ACCOUNT_HOLDER), safeSaga(getAccountHolder, Type.GET_ACCOUNT_HOLDER));

  // ACCOUNT_BALANCE
  yield takeEvery(
    State.actionRequested(Type.ACCOUNTS_GET_BALANCE),
    safeSaga(accountsGetBalances, Type.ACCOUNTS_GET_BALANCE),
  );
  yield takeEvery(Type.REFRESH_ACCOUNT_BALANCE, safeSaga(accountsGetBalances, Type.REFRESH_ACCOUNT_BALANCE));
}

// NOTE: switch from beta to prod wss env
