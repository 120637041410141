import { css } from '@emotion/css';
import { AlignItems, Display, ScreenBreakpoint, SpaceSize, Spacing } from 'src/styles';

export const formContent = css`
  width: 100%;
  overflow: hidden;
  ${Spacing.mt16}
  ${Display.flex}
  ${AlignItems.center}
`;

export const phoneNumberContainer = css`
  height: 400px;

  @media (min-width: 1731px) {
    margin-bottom: 12px;
  }

  @media (min-width: ${ScreenBreakpoint.desktop.min}) and (max-width: 1730px) {
    margin-bottom: 14px;
  }
  @media (min-width: ${ScreenBreakpoint.mobile.max}) and (max-width: ${ScreenBreakpoint.laptop.max}) {
    margin-bottom: 14px;
  }
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    margin-bottom: 2px;
  }
`;

export const spinnerHeight = css`
  height: ${SpaceSize.is128} !important;
`;
