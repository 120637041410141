import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getAccount } from '../actions/accounts.actions';
import { AccountDto } from '../dtos/accounts.dtos';
import { CustomHookBaseOptions } from '../models/common.model';

interface UseAccountReturnType {
  account?: AccountDto;
  isAccountLoading: boolean;
  hasAccountError: boolean;
  accountError?: string;
}

export interface UseAccountOptions extends CustomHookBaseOptions {}

export const useAccount = ({ skip }: UseAccountOptions = { skip: false }): UseAccountReturnType => {
  const dispatch = useDispatch();
  const account: AccountDto | undefined = useSelector((state: any) => state.accounts.item?.data);
  const isAccountLoading = useSelector((state: any) => Boolean(state.accounts.item?.__requested));
  const hasAccountError = useSelector((state: any) => Boolean(state.accounts.item?.__failed));
  const accountError = useSelector((state: any) => state.accounts?.item?.__message);

  useEffect(() => {
    if (!account && !isAccountLoading && !skip && !hasAccountError) {
      dispatch(getAccount());
    }
  }, [account, isAccountLoading, dispatch]);

  return {
    account,
    isAccountLoading,
    hasAccountError,
    accountError,
  };
};
