import { css, cx } from '@emotion/css';

import { Color } from '../../../styles';

export const valueContainer = css`
  min-height: 42px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${Color.BRAND.BRAND1};
  }
`;

export const readOnlyValueContainer = cx(
  valueContainer,
  css`
    &:hover {
      background-color: ${Color.GRAYSCALE.GRAY2} !important;
    }
  `,
);

export const displayValue = css`
  text-align: right;
  color: ${Color.GRAYSCALE.GRAY7} !important;
  margin-left: auto;
  width: 100%;
`;

export const editButtons = css`
  background-color: ${Color.GRAYSCALE.GRAY3};
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(60, 60, 60, 0.1);
  cursor: pointer;
`;
