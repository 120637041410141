import { css } from '@emotion/css';

import { Display, FlexDirection, Spacing } from '../../../../styles';

export const container = css`
  ${Display.flex}
  ${FlexDirection.column}
  ${Spacing.mb24}
  .ant-upload-list-item-info {
    ${Spacing.mt0}
  }
`;
