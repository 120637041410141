import React from 'react';

import { Form, Input, Select } from 'antd';

import { InputType, MaskDefinition } from '../Input/constants';
import { getMaskAndRawValue, getMaskForRawValue, MaskAndRaw } from '../Input/masks';

import { findAllowedPhoneNumbers, PhoneNumber2Props } from './constants';
import * as Styles from './styles';

const { Option } = Select;

const MultiPhoneNumbers = ({ name, value, setFieldValue, setFieldTouched, errors, touched }: PhoneNumber2Props) => {
  return (
    <Form.Item
      validateStatus={!!errors && errors[name] && 'error'}
      help={errors[name] && touched[name] && 'At least one phone number is required'}>
      {value &&
        value.map((phone, index) => (
          <div key={`${phone.type}_key`} className={Styles.phoneNumber}>
            {Object.keys(value).length > 0 && index !== 0 && (
              <i
                className='ri-indeterminate-circle-fill'
                style={Styles.deletePhone}
                onClick={() => {
                  const newValues = value.filter(item => item.type !== phone.type);
                  setFieldValue(name, newValues, true);
                }}
              />
            )}
            <Input
              className={Styles.multiPhoneNumbersInput}
              maxLength={14}
              type={InputType.PhoneNumber}
              placeholder='Phone Number'
              bordered={false}
              value={
                value.find(x => x === phone)?.phoneNumber === '' ||
                value.find(x => x === phone)?.phoneNumber === undefined
                  ? ''
                  : getMaskForRawValue(MaskDefinition.PHONE, value.find(x => x === phone)?.phoneNumber ?? '')
              }
              onChange={event => {
                const onlyNumbers = event.target.value.replace(/\D/g, '');
                let maskAndRaw: MaskAndRaw = getMaskAndRawValue(MaskDefinition.PHONE, onlyNumbers);
                const newValue = value.map(item => {
                  if (item?.type === phone?.type && item && phone) {
                    return { ...item, phoneNumber: maskAndRaw.raw ?? '' };
                  }

                  return item;
                });
                setFieldTouched(name);
                setFieldValue(name, newValue, true);
              }}
            />
            <Form.Item validateStatus={!!errors && errors[name] && 'error'} colon={false} className={Styles.formItem}>
              <Select
                getPopupContainer={trigger => trigger.parentElement}
                defaultValue={phone.type}
                value={phone.type}
                bordered={false}
                disabled={findAllowedPhoneNumbers(value).length < 1}
                onChange={dropDownSelected => {
                  const newValue = value.map(item => {
                    if (item?.type === phone?.type && item && phone) {
                      return { ...item, type: dropDownSelected };
                    }

                    return item;
                  });
                  setFieldTouched(name, true, true);
                  setFieldValue(name, newValue, true);
                }}>
                {findAllowedPhoneNumbers(value).map((option: any) => (
                  <Option key={`menu_item_${option}`} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        ))}
      {value.length < 3 && (
        <div
          onClick={() => {
            const newPhone = findAllowedPhoneNumbers(value)[0];
            setFieldValue(
              name,
              [
                ...value,
                {
                  type: newPhone,
                  phoneNumber: '',
                },
              ],
              true,
            );
          }}
          className={Styles.addNumberButton}>
          <i className='ri-add-circle-fill' style={Styles.addPhone} />
          <div>Add Phone Number</div>
        </div>
      )}
    </Form.Item>
  );
};

export default MultiPhoneNumbers;
