/**
 * Effects action states
 */
export const stateTypes = {
  REQUESTED: 'Requested',
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed',
  CLEARED: 'Cleared',
};

/** Authentication actions */
export const AUTH_STATE = 'Authentication';
export const SET_AUTHENTICATION = `${AUTH_STATE}/SET_AUTHENTICATION`;

/**
 * User actions
 */
export const USER_STATE = 'User';
export const SIGN_UP = `${USER_STATE}/SIGN_UP`;
export const PATCH_USER_ADDRESS = `${USER_STATE}/PATCH_USER_ADDRESS`;
export const TOGGLE_MFA_CONFIG = `${USER_STATE}/TOGGLE_MFA_CONFIG`;
export const LOGIN = `${USER_STATE}/Login`;
export const LOGOUT = `${USER_STATE}/LOGOUT`;
export const GET_AUTHENTICATED_USER = `${USER_STATE}/GET_AUTHENTICATED_USER`;
export const UPDATE_AUTHENTICATED_USER = `${AUTH_STATE}/UPDATE_AUTHENTICATED_USER`;
export const CONFIRM_MFA = `${USER_STATE}/ConfirmMfaCode`;
export const RESEND_MFA_CODE = `${USER_STATE}/ResendMfaCode`;
export const FORGOT_PASSWORD = `${USER_STATE}/ForgotPassword`;
export const RESET_PASSWORD = `${USER_STATE}/ResetPassword`;
export const EMAIL_CHANGE = `${USER_STATE}/Email/Change`;
export const EMAIL_CONFIRM = `${USER_STATE}/Email/Confirm`;
export const UPDATE_PHONE_NUMBER = `${USER_STATE}/UPDATE_PHONE_NUMBER`;
export const GET_SIGNATURE = `${USER_STATE}/GET_SIGNATURE`;
export const SIGN_ACCOUNT_AGREEMENT = `${USER_STATE}/SIGN_ACCOUNT_AGREEMENT`;

/**
 * Account actions
 */
export const ACCOUNT_DETAILS = 'AccountDetails';
export const PERSONAL_INFORMATION = 'PersonalInformation';
export const ADDRESS = 'Address';
export const TRUSTED_CONTACT = 'TrustedContactInfo';
export const SUITABILITY_INFORMATION = 'SuitabilityInformation';
export const FINANCIAL_INFORMATION = 'FinancialInformation';
export const DISCLOSURES = 'Disclosures';
export const ACCOUNT_AGREEMENTS = 'AccountAgreements';
export const CUSTOMER_AGREEMENT = 'CustomerAgreement';

export const GET_ACCOUNT = `Account/Get`;
export const GET_ACCOUNT_DETAILS = `${ACCOUNT_DETAILS}/Get`;
export const ACCOUNTS_GET_BALANCE = `${ACCOUNT_DETAILS}/Get/Balance`;
export const REFRESH_ACCOUNT_BALANCE = `${ACCOUNT_DETAILS}/RefreshAccountBalance`;
export const SET_ACCOUNT_VALUE = `${ACCOUNT_DETAILS}/Set/Value`;
export const UPDATE_ACCOUNT_DETAILS = `${ACCOUNT_DETAILS}/Patch`;

export const GET_PERSONAL_INFORMATION = `${PERSONAL_INFORMATION}/Get`;
export const UPDATE_PERSONAL_INFORMATION = `${PERSONAL_INFORMATION}/Update`;
export const PATCH_PERSONAL_INFORMATION = `${PERSONAL_INFORMATION}/Patch`;
export const PATCH_MAILING_ADDRESS = `${ADDRESS}/Mailing/Patch`;
export const UPDATE_MAILING_ADDRESS = `${ADDRESS}/Mailing/Update`;
export const PATCH_PHYSICAL_ADDRESS = `${ADDRESS}/Physical/Patch`;
export const UPDATE_PHYSICAL_ADDRESS = `${ADDRESS}/Physical/Update`;

export const GET_TRUSTED_CONTACT = `${TRUSTED_CONTACT}/Get`;
export const UPDATE_TRUSTED_CONTACT = `${TRUSTED_CONTACT}/Update`;
export const PATCH_TRUSTED_CONTACT = `${TRUSTED_CONTACT}/Patch`;
export const REMOVE_TRUSTED_CONTACT = `${TRUSTED_CONTACT}/Remove`;

export const GET_SUITABILITY_INFORMATION = `${SUITABILITY_INFORMATION}/Get`;
export const UPDATE_SUITABILITY_INFORMATION = `${SUITABILITY_INFORMATION}/Update`;
export const PATCH_SUITABILITY_INFORMATION = `${SUITABILITY_INFORMATION}/Patch`;

export const GET_FINANCIAL_INFORMATION = `${FINANCIAL_INFORMATION}/Get`;
export const UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT = `${FINANCIAL_INFORMATION}/Employment/Update`;
export const PATCH_FINANCIAL_INFORMATION_EMPLOYMENT = `${FINANCIAL_INFORMATION}/Employment/Patch`;
export const UPDATE_FINANCIAL_INFORMATION_ASSETS = `${FINANCIAL_INFORMATION}/Assets/Update`;
export const PATCH_FINANCIAL_INFORMATION_ASSETS = `${FINANCIAL_INFORMATION}/Assets/Patch`;

export const GET_DISCLOSURES = `${DISCLOSURES}/Get`;
export const UPDATE_DISCLOSURES = `${DISCLOSURES}/Update`;
export const PATCH_DISCLOSURES = `${DISCLOSURES}/Patch`;

export const ACCEPT_ACCOUNT_AGREEMENTS = `${ACCOUNT_AGREEMENTS}/Accept`;
export const DECLINE_ACCOUNT_AGREEMENTS = `${ACCOUNT_AGREEMENTS}/Decline`;

export const CREATE_ACCOUNT_SUBMIT = `CreateAccount/Submit`;

export const GET_ACCOUNT_HOLDER = `AccountHolder/Get`;

/**
 * Cashiering actions
 */
export const CASHIERING_GET_PLAID_LIK_TOKEN = 'Cashiering/Plaid/PlaidLinkToken/Get';
// ACH Relationships
export const CASHIERING_ACH_RELATIONSHIPS = 'Cashiering/ACH/ACHRelationships';
export const CASHIERING_GET_ACH_RELATIONSHIPS = `${CASHIERING_ACH_RELATIONSHIPS}/Get`;
export const CASHIERING_CREATE_ACH_RELATIONSHIP = `${CASHIERING_ACH_RELATIONSHIPS}/Create`;
export const CASHIERING_UNLINK_ACH_RELATIONSHIP = `${CASHIERING_ACH_RELATIONSHIPS}/UnlinkBankAccount`;
export const CASHIERING_EDIT_NICKNAME = `${CASHIERING_ACH_RELATIONSHIPS}/EditNickname`;
// Deposits
export const CASHIERING_DEPOSIT_FUNDS = 'Cashiering/ACH/DepositFunds';
// Withdraws
export const CASHIERING_WITHDRAW_FUNDS = 'Cashiering/ACH/WithdrawFunds';
// Transfers
export const CASHIERING_TRANSFERS = 'Cashiering/Transfers';
export const CASHIERING_GET_TRANSFERS = `${CASHIERING_TRANSFERS}/Get`;
export const CASHIERING_CANCEL_DEPOSIT = `${CASHIERING_TRANSFERS}/Deposit/Cancel`;
export const CASHIERING_CANCEL_WITHDRAW = `${CASHIERING_TRANSFERS}/Withdraw/Cancel`;
export const CASHIERING_GET_TRANSACTION_DETAILS = `${CASHIERING_TRANSFERS}/TransactionDetails/Get`;

// Summary
export const SUMMARY = 'Summary';
export const GET_SUMMARY_CASH_AVAILABLE = `${SUMMARY}/CashAvailable/Get`;
export const GET_SUMMARY_PENDING_TRANSACTIONS = `${SUMMARY}/PendingTransactions/Get`;

/**
 * Offers actions
 */
export const OFFER_GET_OFFER_LIST = 'Offer/OfferList/Get';
export const OFFER_GET_OFFER_DETAILS = 'Offer/OfferDetails/Get';

/**
 * Offer Options
 */

export const GET_ALL_OFFER_OPTIONS = 'OfferOptions/GET_ALL_OFFER_OPTIONS';

/**
 * Offer Watches actions
 */
export const OFFER_WATCHES = 'OfferWatches';
export const OFFER_WATCHES_GET_LIST = `${OFFER_WATCHES}/Get/List`;
export const OFFER_WATCHES_CREATE = `${OFFER_WATCHES}/Post/Create`;
export const OFFER_WATCHES_DELETE = `${OFFER_WATCHES}/Post/Delete`;

/**
 * Investigations actions
 */
export const INVESTIGATIONS = 'Investigations';
export const GET_INVESTIGATIONS = `${INVESTIGATIONS}/Get`;
export const UPLOAD_INVESTIGATION = `${INVESTIGATIONS}/Investigation/Upload`;
export const UPLOAD_APPLICATION_INVESTIGATION = `${INVESTIGATIONS}/Application/Investigation/Upload`;

/**
 * Configs
 */
export const CONFIGS = 'Configs';
export const GET_ALL_CONFIGS = `${CONFIGS}/All/Get`;

/**
 * Quote Media
 */
export const QUOTE_MEDIA = 'QuoteMedia';
export const GET_QUOTE_MEDIA = `${QUOTE_MEDIA}/Get`;
export const GET_ENHANCED_QUOTE_MEDIA = `${INVESTIGATIONS}/Get/Enhanced`;
export const GET_CHART_QUOTE_MEDIA = `${INVESTIGATIONS}/Get/Chart`;

/**
 * Orders
 */
export const ORDERS = 'Orders';
export const GET_ORDERS = `${ORDERS}/Get`;
export const GET_ORDERS_ID = `${ORDERS}/Get/ID`;
export const GET_ORDERS_POSITIONS = `${ORDERS}/Get/Positions`;
export const REFRESH_ORDER_POSITIONS = `${ORDERS}/RefreshOrderPositions`;
export const CREATE_ORDERS_REVIEW = `${ORDERS}/Create/Review`;
export const CREATE_ORDERS = `${ORDERS}/Create`;
export const UPDATE_ORDERS = `${ORDERS}/Update`;
export const UPDATE_ORDER_FROM_EVENT = `${ORDERS}/UPDATE_FORM_EVENT`;

/**
 * Offer Orders
 */
export const OFFER_ORDERS = 'OfferOrders';
export const GET_OFFER_ORDERS = `${OFFER_ORDERS}/Get`;
export const GET_OFFER_ORDER_DETAILS = `${OFFER_ORDERS}/GET_OFFER_ORDER_DETAILS`;
export const GET_OFFER_ORDERS_AGGREGATE = `${OFFER_ORDERS}/Get/Aggregate`;
export const CREATE_OFFER_ORDERS = `${OFFER_ORDERS}/Create`;
export const UPDATE_OFFER_ORDER = `${OFFER_ORDERS}/UPDATE_OFFER_ORDER`;
export const DELETE_OFFER_ORDER = `${OFFER_ORDERS}/DELETE_OFFER_ORDER`;
export const ADD_OFFER_ORDER_INTENT = `${OFFER_ORDERS}/Intent/Add`;
export const REMOVE_OFFER_ORDER_INTENT = `${OFFER_ORDERS}/Intent/Remove`;
export const CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT = `${OFFER_ORDERS}/CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT`;
export const UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT = `${OFFER_ORDERS}/UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT`;
export const SUBMIT_CARD_PAYMENT = `${OFFER_ORDERS}/SUBMIT_CARD_PAYMENT`;
export const CONFIRM_CARD_PAYMENT = `${OFFER_ORDERS}/CONFIRM_CARD_PAYMENT`;

/**
 * Toast Messages
 */
export const TOAST_MESSAGES = 'ToastMessages';
export const TOAST_MESSAGES_ADD = `${TOAST_MESSAGES}/Add`;
export const TOAST_MESSAGES_REMOVE = `${TOAST_MESSAGES}/Remove`;
export const CLEAR_TOAST_MESSAGES = `${TOAST_MESSAGES}/Clear`;

/**
 * App
 */
export const APP = 'App';
export const APP_REDIRECT_TO_OFFER_ORDER_ADD = `${APP}/RedirectTo/OfferOrder/Add`;
export const APP_REDIRECT_TO_OFFER_ORDER_REMOVE = `${APP}/RedirectTo/OfferOrder/Remove`;
export const APP_REDIRECT_TO_OFFER_ADD = `${APP}/RedirectTo/Offer/Add`;
export const APP_REDIRECT_TO_OFFER_REMOVE = `${APP}/RedirectTo/Offer/Remove`;
export const APP_REDIRECT_TO_EMAIL_CONFIRM_ADD = `${APP}/RedirectTo/EmailConfirm/Add`;
export const APP_REDIRECT_TO_EMAIL_CONFIRM_REMOVE = `${APP}/RedirectTo/EmailConfirm/Remove`;
export const UPDATE_ONBOARD_STEP = `${APP}/UPDATE_ONBOARD_STEP`;
export const ADD_OFFER_INVEST_REFERRER = `${APP}/ADD_OFFER_INVEST_REFERRER`;
export const REMOVE_OFFER_INVEST_REFERRER = `${APP}/REMOVE_OFFER_INVEST_REFERRER`;

/**
 * Statements
 */
export const GET_TRADE_CONFIRMATION_LIST = 'Statements/GET_TRADE_CONFIRMATION_LIST';
export const DOWNLOAD_TRADE_CONFIRMATION = 'Statements/DOWNLOAD_TRADE_CONFIRMATION';
export const REVOKE_TRADE_CONFIRMATION_DOWNLOADS = 'Statements/REVOKE_TRADE_CONFIRMATION_DOWNLOADS';
export const GET_MONTHLY_STATEMENT_LIST = 'Statements/GET_MONTHLY_STATEMENT_LIST';
export const DOWNLOAD_MONTHLY_STATEMENT = 'Statements/DOWNLOAD_MONTHLY_STATEMENT';
export const REVOKE_MONTHLY_STATEMENT_DOWNLOADS = 'Statements/REVOKE_MONTHLY_STATEMENT_DOWNLOADS';
export const GET_TAX_DOCUMENT_LIST = 'Statements/GET_TAX_DOCUMENT_LIST';
export const DOWNLOAD_TAX_DOCUMENT = 'Statements/DOWNLOAD_TAX_DOCUMENT';
export const REVOKE_TAX_DOCUMENT_DOWNLOADS = 'Statements/REVOKE_TAX_DOCUMENT_DOWNLOADS';
