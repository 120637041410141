import Spinner from '../Miscellaneous/Spinner';
import { MModal } from '../MModal/MModal';

export interface ConfirmActionModalProps {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  title: string;
  type: string;
  action: 'cancel' | 'delete';
}

export const ConfirmActionModal = ({
  title,
  isOpen,
  isLoading,
  type,
  onCancel,
  onOk,
  action = 'cancel',
}: ConfirmActionModalProps) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div>
        Are you sure you want to {action} this {type}?
      </div>
    );
  };

  return (
    <MModal
      closable
      onClose={onCancel}
      title={title}
      visible={isOpen}
      primaryButtonText='Yes'
      secondaryButtonText='No'
      primaryButtonType='primary'
      secondaryButtonType='tertiary'
      onPrimaryButtonClick={onOk}
      onSecondaryButtonClick={onCancel}>
      {renderContent()}
    </MModal>
  );
};
