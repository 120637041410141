export type DatePickerCompType = {
  name: string;
  value?: string;
  disabled?: boolean;
  errors?: any;
  touched?: any;
  setFieldValue: Function;
  setFieldTouched: Function;
  preChangeHook?: any;
  postChangeHook?: any;
};

export const getDays = () =>
  [...Array(31 - 1 + 1).keys()].map(i => {
    return {
      value: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
      text: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
    };
  });

export const getMonths = () =>
  [...Array(12 - 1 + 1).keys()].map(i => {
    return {
      value: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
      text: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
    };
  });

export const getYears = () =>
  [...Array(new Date().getFullYear() - 1910 + 1).keys()].map(i => {
    return { value: `${i + 1910}`, text: `${i + 1910}` };
  });
