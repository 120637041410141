export namespace DateConstant {
  export const DEFAULT_FORMAT = 'yyyy-MM-dd';
  export const US_FORMAT = 'MM/DD/yyyy';
  export const DEFAULT_DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A';
}

export enum ErrorCode {
  BEFORE_LOAD_STRIPE = '1050',
  LOAD_STRIPE = '1051',
  LOAD_STRIPE_ELEMENT = '1057',
  CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT = '1052',
  BEFORE_UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT = '1053',
  UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT = '1054',
  BEFORE_CONFIRM_CARD_PAYMENT = '1055',
  CONFIRM_CARD_PAYMENT = '1056',
  URL_SEARCH_PARAMETER_NOT_FOUND = '1058',
}

export enum ConfirmCardPaymentErrorCause {
  GENERIC_DECLINE = 'generic_decline',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  LOST_CARD = 'lost_card',
  STOLEN_CARD = 'stolen_card',
  EXPIRED_CARD = 'expired_card',
  INCORRECT_CVC = 'incorrect_cvc',
  PROCESSING_ERROR = 'processing_error',
  INCORRECT_NUMBER = 'incorrect_number',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  UNKNOWN = 'unknown',
}

export enum DefinitionConstant {
  CASH_AVAILABLE = 'Cash Available is your cash balance minus any pending cash withdrawals and minus any pending buy orders',
  AVAILABLE_FUNDS_FOR_WITHDRAWAL = 'Funds Available for Withdrawal is your cash balance minus any pending cash withdrawals, minus any pending buy orders and do not include unsettled account funds',
}

export namespace FilterBarConstant {
  export enum ShowFilterCategory {
    DepositTransfer = 'DepositTransfer',
    WithdrawalTransfer = 'WithdrawalTransfer',
    OfferOrder = 'OfferOrder',
    BuyOrder = 'BuyOrder',
    SellOrder = 'SellOrder',
  }

  export enum ByFilterCategory {
    MoneyTransfer = 'MoneyTransfer',
    OfferOrder = 'OfferOrder',
    Order = 'Order',
  }

  export enum SortByCategory {
    Newest = 0,
    Oldest,
  }

  export enum SortByLabel {
    Newest = 'Newest',
    Oldest = 'Oldest',
  }
}

export const NAME_PREFIX_LIST = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Sir', 'Dr'];
