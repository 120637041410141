import { UploadOutlined } from '@ant-design/icons';
import { Col, message, Row, Space, Upload } from 'antd';
import { Formik } from 'formik';

import { uploadApplicationInvestigationDocument } from '../../../../actions';
import { MButton } from '../../../../lib/FormComponents/MButton/MButton';
import RadioGroup from '../../../../lib/FormComponents/RadioGroup';
import { UploadedDocument } from '../../../../typings/investigationTypes';
import * as Styles from '../../AccountStatus/AccountStatus.styles';
import { getInvestigationTitleAndDescription } from '../../AccountStatus/constants';
import { validateFile } from '../../utils';

import { ApplicationInvestigationCompType } from './constants';
const DOCUMENT_NAME = 'documentName';

const ApplicationInvestigation = ({
  appCategory,
  options,
  documentSubmitted,
  application,
  dispatch,
  accountId,
  uploadProps,
}: ApplicationInvestigationCompType) => {
  return (
    <>
      {getInvestigationTitleAndDescription(appCategory?.name)}
      <Formik initialValues={{ documentName: options[0].value }} onSubmit={() => {}}>
        {(form: any) => {
          return (
            <>
              {!documentSubmitted ||
                (appCategory?.requestedDocuments?.length > 1 && (
                  <>
                    <div className={Styles.selectDocumentLabel}>Select the following document you plan to upload:</div>
                    <RadioGroup name={DOCUMENT_NAME} value={form.values[DOCUMENT_NAME]} {...form} options={options} />
                  </>
                ))}
              <Upload
                accept='.png, .jpg, .jpeg, .pdf'
                maxCount={1}
                disabled={documentSubmitted !== undefined}
                defaultFileList={application?.uploadedDocuments
                  ?.filter((document: UploadedDocument) => document?.fileName === documentSubmitted?.fileName)
                  .map((document: UploadedDocument) => {
                    return {
                      uid: document.miscDocumentId,
                      name: document?.originalFileName || document?.fileName,
                      status: 'uploaded',
                    };
                  })}
                onChange={info => {
                  if (info.file.status === 'done') {
                    form.setFieldValue(form.values[DOCUMENT_NAME], 'uploaded');
                    const formData: any = new FormData();
                    formData.append('file', info.file.originFileObj);
                    formData.append('imageName', info.file.name);
                    formData.append(DOCUMENT_NAME, form.values[DOCUMENT_NAME]);
                    dispatch(uploadApplicationInvestigationDocument(accountId, application?.accountHolderId, formData));
                  } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                  }
                }}
                beforeUpload={(file: any) => validateFile(file, dispatch)}
                {...uploadProps}>
                {!documentSubmitted && (
                  <MButton type='secondary'>
                    <UploadOutlined style={{ height: 16, width: 16 }} /> Upload
                  </MButton>
                )}
              </Upload>
            </>
          );
        }}
      </Formik>
      {appCategory?.name === 'AffiliateApproval' && (
        <Row className={Styles.downloadSection}>
          <Col span={24}>
            <a href={'/static/docs/Sample_FINRA_3210_Letter.docx'} target='_blank' rel='noreferrer'>
              <Space>
                <i className={'ri-file-line'}></i>
                <span> Click here to download sample document</span>
              </Space>
            </a>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ApplicationInvestigation;
