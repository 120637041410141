import { css } from '@emotion/css';

import {
  AlignItems,
  BorderRadius,
  Color,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  Spacing,
} from '../../../styles';

export const menuName = css`
  ${Font.h3};
  color: ${Color.BRAND.BRAND6};
  ${Spacing.mt64};
  ${Spacing.ml64};
`;

export const formCard = css`
  border: none;
  box-shadow: 0rem 0.25rem 1rem ${ColorConstant.BRAND6}1A;
  ${Spacing.p24};
  ${Spacing.my24};
  ${BorderRadius.xs};
`;

export const statusRow = css`
  ${Display.flex}
  ${AlignItems.center};
`;

export const statusLabel = css`
  ${FontWeight.bold};
  ${Font.h1};
  ${Spacing.mr24};
  color: ${ColorConstant.GRAY10};
`;

export const statusTag = css`
  ${Display.flex};
  ${FlexDirection.row};
  ${JustifyContent.center};
  ${AlignItems.center};
  ${Spacing.px4};
  ${Spacing.py4};
  ${BorderRadius.xs};
`;

export const contactLabel = css`
  color: ${ColorConstant.GRAY7};
  ${Spacing.mb8};
`;

export const descriptionLabel = css`
  color: ${ColorConstant.GRAY7};
  ${Spacing.py24};
`;

export const selectDocumentLabel = css`
  ${Font.sm};
  color: ${ColorConstant.GRAY8};
  ${Spacing.mt20};
  ${Spacing.mb12};
`;

export const investigationTitle = css`
  ${FontWeight.bold};
  ${Font.sm};
  color: ${ColorConstant.GRAY8};
  ${Spacing.mt24};
`;

export const investigationDescription = css`
  ${Font.sm};
  color: ${ColorConstant.GRAY7};
`;

export const documentUploadedLabel = css`
  ${Font.sm};
  color: ${ColorConstant.GRAY7};
`;

export const documentUploadedValue = css`
  ${FontWeight.bold};
  ${Font.sm};
  color: ${ColorConstant.GRAY7};
`;

export const documentContainer = css`
  ${Spacing.mt24};
`;

export const documentUploadedHeader = css`
  ${FontWeight.bold};
  ${Font.sm};
  color: ${ColorConstant.GRAY8};
  ${Spacing.mb8};
`;

export const downloadSection = css`
  ${Spacing.mt32};
`;
