export enum AccountStatusEnum {
  PENDING,
  ACTIVE,
  REJECTED,
  EXPIRED,
}

export const AccountStatusLabel = new Map<number, string>([
  [AccountStatusEnum.PENDING, 'PENDING'],
  [AccountStatusEnum.ACTIVE, 'ACTIVE'],
  [AccountStatusEnum.REJECTED, 'REJECTED'],
  [AccountStatusEnum.EXPIRED, 'EXPIRED'],
]);
