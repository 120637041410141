import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Col, Row, Tooltip } from 'antd';
import { Formik, FormikProps } from 'formik';
import _, { get } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
  clearPatchFinancialInformationAssets,
  clearPatchFinancialInformationEmployment,
  clearUpdateFinancialInformationAssets,
  clearUpdateFinancialInformationEmployment,
  getFinancialInformation,
  patchFinancialInformationAssets,
  patchFinancialInformationEmployment,
  toastMessagesAdd,
  toastMessagesRemove,
  clearToastMessages,
  updateFinancialInformationAssets,
  updateFinancialInformationEmployment,
} from '../../../actions';
import FieldWrapper from '../../../lib/FormComponents/FieldWrapper';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { MDivider } from '../../../lib/MDivider/MDivider';
import Spinner from '../../../lib/Miscellaneous/Spinner';
import { Color, ScreenBreakpoint } from '../../../styles';
import { SeverityEnum } from '../../../typings/commonTypes';
import { renderAddressMismatchErrorMessage } from '../../../utils/renderAddressMismatchErrorMessage';
import { shouldHideAddress } from '../../../utils/shouldHideAddress';

import {
  checkIfCardOpened,
  checkIfFieldIsHidden,
  checkIsSectionCompleted,
  getFinancialInformationSections,
  onPreChangeHook,
} from './fields';
import * as Styles from './styles';
import { employmentInformationValidation } from './validations';

const FinancialInformationUnmapped = ({
  patchFinancialInformationAssets,
  updateFinancialInformationAssets,
  patchFinancialInformationEmployment,
  updateFinancialInformationEmployment,
  financialInformation,
  getFinancialInformation,
}: any) => {
  const dispatch = useDispatch();
  const loadingFinancialInformation = useSelector((state: any) =>
    Boolean(state.accountDetails?.financialInformation.__requested),
  );

  const succededPatchFinancialInformationAssets = useSelector(
    (state: any) => state?.accountDetails?.patchFinancialInformationAssets?.__succeeded,
  );
  const succededUpdateFinancialInformationAssets = useSelector(
    (state: any) => state?.accountDetails?.updateFinancialInformationAssets?.__succeeded,
  );

  const succededPatchFinancialInformationEmployment = useSelector(
    (state: any) => state?.accountDetails?.patchFinancialInformationEmployment?.__succeeded,
  );

  const failedPatchFinancialInformationEmployment = useSelector(
    (state: any) => state?.accountDetails?.patchFinancialInformationEmployment?.__failed,
  );

  const failedPatchFinancialInformationEmploymentMessage = useSelector(
    (state: any) => state?.accountDetails?.patchFinancialInformationEmployment?.message,
  );

  const succededUpdateFinancialInformationEmployment = useSelector(
    (state: any) => state?.accountDetails?.updateFinancialInformationEmployment?.__succeeded,
  );

  const failedUpdateFinancialInformationEmployment = useSelector(
    (state: any) => state?.accountDetails?.updateFinancialInformationEmployment?.__failed,
  );

  const failedUpdateFinancialInformationEmploymentMessage = useSelector(
    (state: any) => state?.accountDetails?.updateFinancialInformationEmployment?.message,
  );

  const isPatchFinancialInformationAssetsLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.patchFinancialInformationAssets?.__requested),
  );
  const isUpdateFinancialInformationAssetsLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.updateFinancialInformationAssets?.__requested),
  );

  const isPatchFinancialInformationEmploymentLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.patchFinancialInformationEmployment?.__requested),
  );
  const isUpdateFinancialInformationEmploymentLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.updateFinancialInformationEmployment?.__requested),
  );

  const accountId = useSelector((state: any) => state.accountDetails.accountHolder?.data?.accountId);
  const accountUuid = useSelector((state: any) => state.accountDetails.accountHolder?.data?.id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sectionUpdated, setSectionUpdated] = useState('');
  const [isFieldEditing, setIsFieldEditing] = useState(false);
  const [openedField, setOpenedField] = useState('');

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });
  const financialInformationEmploymentFormRef = useRef<FormikProps<{}> | null>(null);
  const [failedUpsertFinancialInformationEmploymentToastId, setFailedUpsertFinancialInformationEmploymentToastId] =
    useState<string | null>(null);

  const menu: any = getFinancialInformationSections(financialInformation);

  const isUpsertFinancialInformationAssetsLoading =
    isPatchFinancialInformationAssetsLoading || isUpdateFinancialInformationAssetsLoading;
  const isUpsertFinancialInformationEmploymentLoading =
    isPatchFinancialInformationEmploymentLoading || isUpdateFinancialInformationEmploymentLoading;

  const isFinancialInformationAssetsCompleted = () => Boolean(financialInformation?.annualIncome);
  const isFinancialInformationEmploymentCompleted = () => Boolean(financialInformation?.employmentStatus);

  const anyUpsertRequestSucceded = () =>
    succededPatchFinancialInformationAssets ||
    succededUpdateFinancialInformationAssets ||
    succededPatchFinancialInformationEmployment ||
    succededUpdateFinancialInformationEmployment;

  const initialValues = (fields: any) =>
    Object.keys(fields)
      .map(key => ({
        key: fields[key].props.name,
        value: fields[key].props.value,
      }))
      .reduce(
        (obj, keyValuePair) => ({
          ...obj,
          [keyValuePair.key]: keyValuePair.value,
        }),
        {},
      );

  const removeUpsertFinancialInformationEmploymentToastMessageIfFound = () => {
    if (failedUpsertFinancialInformationEmploymentToastId) {
      dispatch(toastMessagesRemove({ key: failedUpsertFinancialInformationEmploymentToastId }));
    }
  };

  const onPopulateFinancialInformationEmployerAddress = () => {
    if (
      financialInformationEmploymentFormRef.current &&
      failedUpdateFinancialInformationEmploymentMessage?.error === 'UspsAddressMismatchError'
    ) {
      financialInformationEmploymentFormRef.current.setFieldValue(
        'address1',
        failedUpdateFinancialInformationEmploymentMessage.address1,
      );
      financialInformationEmploymentFormRef.current.setFieldValue(
        'address2',
        failedUpdateFinancialInformationEmploymentMessage.address2,
      );
      financialInformationEmploymentFormRef.current.setFieldValue(
        'city',
        failedUpdateFinancialInformationEmploymentMessage.city,
      );
      financialInformationEmploymentFormRef.current.setFieldValue(
        'state',
        failedUpdateFinancialInformationEmploymentMessage.state,
      );
      financialInformationEmploymentFormRef.current.setFieldValue(
        'postalCode',
        failedUpdateFinancialInformationEmploymentMessage.postalCode,
      );
    }

    if (
      financialInformationEmploymentFormRef.current &&
      failedPatchFinancialInformationEmploymentMessage?.error === 'UspsAddressMismatchError'
    ) {
      financialInformationEmploymentFormRef.current.setFieldValue(
        'address1',
        failedPatchFinancialInformationEmploymentMessage.address1,
      );
      financialInformationEmploymentFormRef.current.setFieldValue(
        'address2',
        failedPatchFinancialInformationEmploymentMessage.address2,
      );
      financialInformationEmploymentFormRef.current.setFieldValue(
        'city',
        failedPatchFinancialInformationEmploymentMessage.city,
      );
      financialInformationEmploymentFormRef.current.setFieldValue(
        'state',
        failedPatchFinancialInformationEmploymentMessage.state,
      );
      financialInformationEmploymentFormRef.current.setFieldValue(
        'postalCode',
        failedPatchFinancialInformationEmploymentMessage.postalCode,
      );
    }
    removeUpsertFinancialInformationEmploymentToastMessageIfFound();
  };

  const onSubmit = async (values: any) => {
    setOpenedField('');
    setIsFieldEditing(false);
    setIsSubmitting(true);

    if (sectionUpdated === 'employment_information') {
      const castedValues = employmentInformationValidation.cast(values);

      if (financialInformation.employmentStatus) {
        await patchFinancialInformationEmployment(castedValues);

        return;
      }

      await updateFinancialInformationEmployment(castedValues);

      return;
    } else {
      if (financialInformation.annualIncome) {
        await patchFinancialInformationAssets(values);

        return;
      }

      await updateFinancialInformationAssets(values);
    }
  };

  useEffect(() => {
    if (failedUpdateFinancialInformationEmploymentMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertFinancialInformationEmploymentToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedUpdateFinancialInformationEmploymentMessage,
            onClick: onPopulateFinancialInformationEmployerAddress,
            section: 'employment',
          }),
        }),
      );
      setFailedUpsertFinancialInformationEmploymentToastId(toastId);
    }
  }, [failedUpdateFinancialInformationEmployment]);

  useEffect(() => {
    if (failedPatchFinancialInformationEmploymentMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertFinancialInformationEmploymentToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedPatchFinancialInformationEmploymentMessage,
            onClick: onPopulateFinancialInformationEmployerAddress,
            section: 'employment',
          }),
        }),
      );
      setFailedUpsertFinancialInformationEmploymentToastId(toastId);
    }
  }, [failedPatchFinancialInformationEmployment]);

  useEffect(() => {
    if (isSubmitting && !isUpsertFinancialInformationAssetsLoading && !isUpsertFinancialInformationEmploymentLoading) {
      setIsSubmitting(false);
    }
  }, [isUpsertFinancialInformationAssetsLoading, isUpsertFinancialInformationEmploymentLoading]);

  useEffect(() => {
    if (
      !accountId &&
      isFinancialInformationAssetsCompleted() &&
      isFinancialInformationEmploymentCompleted() &&
      (succededUpdateFinancialInformationAssets || succededUpdateFinancialInformationEmployment)
    ) {
      window.gtag('event', 'account_financial_information_complete');
    }

    if (
      !accountId &&
      isFinancialInformationAssetsCompleted() &&
      isFinancialInformationEmploymentCompleted() &&
      anyUpsertRequestSucceded()
    ) {
      navigate(`/accounts/${accountUuid}/disclosures`);
    }
  }, [
    succededPatchFinancialInformationAssets,
    succededUpdateFinancialInformationAssets,
    succededPatchFinancialInformationEmployment,
    succededUpdateFinancialInformationEmployment,
    financialInformation,
  ]);

  useEffect(() => {
    if (_.isEmpty(financialInformation) && !loadingFinancialInformation) {
      getFinancialInformation();
    }

    return () => {
      dispatch(clearPatchFinancialInformationAssets());
      dispatch(clearUpdateFinancialInformationAssets());
      dispatch(clearPatchFinancialInformationEmployment());
      dispatch(clearUpdateFinancialInformationEmployment());
      dispatch(clearToastMessages());
    };
  }, []);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.FinancialInformation}
      title={isMobile ? undefined : 'Financial Information'}>
      {loadingFinancialInformation ? (
        <Spinner />
      ) : (
        menu.sections.map((section: any) => (
          <Row key={section.sectionKey}>
            <Col className={Styles.formCard} span={18} xs={24} sm={24} data-testid={'account-' + section.sectionKey}>
              <div className={Styles.sectionHeader}>{section.sectionLabel}</div>
              <Formik
                key={`${section.sectionKey}_frag_`}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues(section.sectionFields)}
                onSubmit={values => {
                  onSubmit(values);
                }}
                innerRef={ref => {
                  if (section.sectionKey === 'employment_information') {
                    financialInformationEmploymentFormRef.current = ref;
                  }
                }}
                validationSchema={section.validationSchema}>
                {form => {
                  const formValues: any = form.values;

                  return (
                    <Fragment key={`${section.sectionKey}_frag_`}>
                      {section.sectionFields.map((field: any) => {
                        const JSXRenderableComp = field.component;

                        return checkIfFieldIsHidden(formValues.employmentStatus, field.key) ||
                          shouldHideAddress(formValues.country, field.key) ? null : (
                          <Fragment key={`key_${field.key}`}>
                            <Row key={`${field.key}_frag_`}>
                              <Col span={12}>
                                <div
                                  className={Styles.fieldLabel}
                                  style={{
                                    marginTop: 10,
                                    marginBottom: 15,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  {formValues.employmentStatus === 'retired' &&
                                  ['employerName', 'jobTitle'].includes(field.key)
                                    ? `Last ${field.label}`
                                    : field.label}
                                  {field?.tooltipText && (
                                    <Tooltip
                                      getTooltipContainer={(triggerNode: any) => triggerNode.parentNode}
                                      placement='top'
                                      title={field?.tooltipText}
                                      color={Color.GRAYSCALE.GRAY1}>
                                      <i style={{ ...Styles.Tooltip }} className={`ri-information-line`} />
                                    </Tooltip>
                                  )}
                                </div>
                              </Col>
                              <Col span={12} style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
                                <FieldWrapper
                                  displayValue={field.displayValue}
                                  name={field.key}
                                  form={form}
                                  value={field.props.value}
                                  openedField={openedField}
                                  initialMode={section.isCompleted || field.readOnly ? 'view' : 'edit'}
                                  isSectionCompleted={checkIsSectionCompleted(
                                    section,
                                    openedField,
                                    formValues,
                                    financialInformation,
                                  )}
                                  readOnly={field.readOnly}
                                  isFieldEditing={isFieldEditing}
                                  onFieldEdit={(name: string) => {
                                    setOpenedField(name);
                                    setIsFieldEditing(true);
                                  }}
                                  onFieldSubmit={async (values: any) => {
                                    setIsSubmitting(true);
                                    setIsFieldEditing(false);
                                    setOpenedField('');

                                    if (section.sectionKey === 'employment_information') {
                                      const castedValues = employmentInformationValidation.cast(values);
                                      await patchFinancialInformationEmployment(castedValues);
                                    } else {
                                      await patchFinancialInformationAssets(values);
                                    }
                                  }}
                                  onFieldClose={() => {
                                    setOpenedField('');
                                    form.resetForm();
                                  }}>
                                  <JSXRenderableComp
                                    key={`${field.key}_field_`}
                                    {...field.props}
                                    {...form}
                                    name={field.key}
                                    value={formValues[field.key]}
                                    preChangeHook={onPreChangeHook(field, form)}
                                  />
                                </FieldWrapper>
                              </Col>
                            </Row>
                            {field.key === 'yearsEmployed' && (
                              <div className={Styles.sectionHeader} style={{ marginTop: 16 }}>
                                Employment Address
                              </div>
                            )}
                          </Fragment>
                        );
                      })}
                      <MDivider />
                      {checkIfCardOpened(section, formValues, financialInformation) && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <MButton
                            testId={`account-btn-save-${section.sectionKey}`}
                            type='secondary'
                            loading={
                              section.sectionKey === 'employment_information'
                                ? isUpsertFinancialInformationEmploymentLoading
                                : isUpsertFinancialInformationAssetsLoading
                            }
                            disabled={
                              section.sectionKey === 'employment_information'
                                ? isUpsertFinancialInformationEmploymentLoading && loadingFinancialInformation
                                : isUpsertFinancialInformationAssetsLoading && loadingFinancialInformation
                            }
                            onClick={() => {
                              form.submitForm();
                              setSectionUpdated(section.sectionKey);
                            }}>
                            Save
                          </MButton>
                        </div>
                      )}
                    </Fragment>
                  );
                }}
              </Formik>
            </Col>
            <Col span={3} />
          </Row>
        ))
      )}
    </MyAccountLayout>
  );
};
const mapStateToProps = (state: any) => ({
  financialInformation: get(state, 'accountDetails.financialInformation.data'),
});

const mapDispatchToProps = {
  getFinancialInformation,
  updateFinancialInformationAssets,
  patchFinancialInformationAssets,
  updateFinancialInformationEmployment,
  patchFinancialInformationEmployment,
};

export const FinancialInformation = connect(mapStateToProps, mapDispatchToProps)(FinancialInformationUnmapped);
