import React, { Fragment, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { Formik } from 'formik';
import _, { get } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import {
  clearPatchDisclosures,
  clearUpdateDisclosures,
  getDisclosures,
  patchDisclosures,
  updateDisclosures,
} from '../../../actions';
import FieldWrapper from '../../../lib/FormComponents/FieldWrapper';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { MDivider } from '../../../lib/MDivider/MDivider';
import Spinner from '../../../lib/Miscellaneous/Spinner';
import { ScreenBreakpoint } from '../../../styles';

import { getDisclosuresSections } from './fields';
import * as Styles from './styles';
import { disclosuresValidation } from './validations';

const DisclosuresUnmapped = ({ disclosures, getDisclosures, updateDisclosures, patchDisclosures }: any) => {
  const dispatch = useDispatch();
  const loadingDisclosuresInformation = useSelector((state: any) =>
    Boolean(state.accountDetails?.disclosures.__requested),
  );
  const succeededUpdateDisclosuresInformation = useSelector(
    (state: any) => state?.accountDetails?.updateDisclosures?.__succeeded,
  );
  const succeededPatchDisclosuresInformation = useSelector(
    (state: any) => state?.accountDetails?.patchDisclosures?.__succeeded,
  );

  const isUpdateDisclosuresLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.updateDisclosures?.__requested),
  );
  const isPatchDisclosuresLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.patchDisclosures?.__requested),
  );

  const accountId = useSelector((state: any) => state.accountDetails.accountHolder?.data?.accountId);
  const accountUuid = useSelector((state: any) => state.accountDetails.accountHolder?.data?.id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sectionUpdated, setSectionUpdated] = useState('');
  const [isFieldEditing, setIsFieldEditing] = useState(false);
  const [openedField, setOpenedField] = useState('');
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const menu: any = getDisclosuresSections(disclosures);

  const isUpsertLoading = isUpdateDisclosuresLoading || isPatchDisclosuresLoading;

  const isDisclosuresInformationCompleted = () => !_.isEmpty(disclosures);

  const anyUpsertRequestSucceded = () => succeededPatchDisclosuresInformation || succeededUpdateDisclosuresInformation;

  const initialValues = (fields: any) =>
    Object.keys(fields)
      .map(key => ({
        key: fields[key].props.name,
        value: fields[key].props.value,
      }))
      .reduce(
        (obj, keyValuePair) => ({
          ...obj,
          [keyValuePair.key]: keyValuePair.value,
        }),
        {},
      );

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    const castedValues = disclosuresValidation.cast(values);
    await updateDisclosures(castedValues, sectionUpdated);
  };

  useEffect(() => {
    if (isSubmitting && !isUpsertLoading) {
      setIsSubmitting(false);
    }
  }, [isPatchDisclosuresLoading, isUpdateDisclosuresLoading]);

  useEffect(() => {
    if (!accountId && isDisclosuresInformationCompleted() && succeededUpdateDisclosuresInformation) {
      window.gtag('event', 'account_disclosures_complete');
    }

    if (!accountId && isDisclosuresInformationCompleted() && anyUpsertRequestSucceded()) {
      navigate(`/accounts/${accountUuid}/account-agreements`);
    }
  }, [succeededPatchDisclosuresInformation, succeededUpdateDisclosuresInformation, disclosures]);

  useEffect(() => {
    if (_.isEmpty(disclosures) && !loadingDisclosuresInformation) {
      getDisclosures();
    }

    return () => {
      dispatch(clearPatchDisclosures());
      dispatch(clearUpdateDisclosures());
    };
  }, []);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.Disclosures}
      title={isMobile ? undefined : 'Disclosures'}>
      {loadingDisclosuresInformation ? (
        <Spinner />
      ) : (
        menu.sections.map((section: any) => (
          <Row key={section.sectionKey}>
            <Col span={18} xs={24} sm={24}>
              <div className={Styles.formCard}>
                <>
                  <Formik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={initialValues(section.sectionFields)}
                    onSubmit={values => {
                      onSubmit(values);
                    }}
                    validationSchema={section.validationSchema}>
                    {form => {
                      const formValues: any = form.values;

                      return (
                        <>
                          {section.sectionFields.map((field: any) => {
                            const JSXRenderableComp = field.component;

                            return (
                              <Fragment key={`${field.key}_row`}>
                                <Row key={`${field.key}_row`}>
                                  <Col span={field.showInRow ? 12 : 24}>
                                    {field.key === 'individualRepresentations' ? (
                                      <div
                                        style={{
                                          marginTop: 16,
                                          marginBottom: 20,
                                        }}>
                                        <span style={{ fontWeight: 'bold' }}>Individual Representations</span> (Please
                                        attest as to whether any of the following apply to you.)
                                      </div>
                                    ) : (
                                      <div
                                        className={Styles.fieldLabel}
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}>
                                        {field.label}
                                      </div>
                                    )}
                                  </Col>

                                  <Col span={field.showInRow ? 12 : 24}>
                                    <FieldWrapper
                                      displayValue={field.displayValue}
                                      name={field.key}
                                      form={form}
                                      value={field.props.value}
                                      openedField={openedField}
                                      initialMode={section.isCompleted || field.readOnly ? 'view' : 'edit'}
                                      isSectionCompleted={section.isCompleted}
                                      isFieldEditing={isFieldEditing}
                                      customValidationSchema={field?.customValidationSchema}
                                      onFieldEdit={(name: string) => {
                                        setOpenedField(name);
                                        setIsFieldEditing(true);
                                      }}
                                      readOnly={field.readOnly}
                                      onFieldSubmit={async (values: any) => {
                                        setIsSubmitting(true);
                                        setIsFieldEditing(false);
                                        setOpenedField('');
                                        setSectionUpdated(section.sectionKey);

                                        const castedValues = disclosuresValidation.cast(values);
                                        await patchDisclosures(castedValues, section.sectionKey);
                                      }}
                                      onFieldClose={() => {
                                        setOpenedField('');
                                        form.resetForm();
                                      }}>
                                      <JSXRenderableComp
                                        key={`${field.key}_field_`}
                                        {...field.props}
                                        {...form}
                                        form={field.passFormAsProp ? form : undefined}
                                        name={field.key}
                                        value={formValues[field.key]}
                                        isSectionCompleted={
                                          field.key === 'stakeholder' || field.key === 'industryEmployed'
                                            ? section.isCompleted
                                            : undefined
                                        }
                                        onSubComponentSubmit={
                                          field.key === 'stakeholder' || field.key === 'industryEmployed'
                                            ? async (values: any) => {
                                                setIsSubmitting(true);
                                                setIsFieldEditing(true);
                                                setSectionUpdated(section.sectionKey);

                                                await patchDisclosures(values, section.sectionKey);
                                              }
                                            : undefined
                                        }
                                      />
                                    </FieldWrapper>
                                  </Col>
                                </Row>
                              </Fragment>
                            );
                          })}
                          <MDivider />
                          {!section.isCompleted && (
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                              <MButton
                                testId={`account-btn-save-${section.sectionKey}`}
                                loading={isUpsertLoading}
                                disabled={loadingDisclosuresInformation || isUpsertLoading}
                                type='secondary'
                                onClick={() => {
                                  form.submitForm();
                                  setSectionUpdated(section.sectionKey);
                                }}>
                                Save
                              </MButton>
                            </div>
                          )}
                        </>
                      );
                    }}
                  </Formik>
                </>
              </div>
            </Col>

            <Col span={3} />
          </Row>
        ))
      )}
    </MyAccountLayout>
  );
};

const mapStateToProps = (state: any) => ({
  disclosures: get(state, 'accountDetails.disclosures.data'),
});

const mapDispatchToProps = {
  getDisclosures,
  updateDisclosures,
  patchDisclosures,
};

export const Disclosures = connect(mapStateToProps, mapDispatchToProps)(DisclosuresUnmapped);
