import {
  AnnualIncome,
  LiquidNetWorth,
  TotalNetWorth,
  FinancialInformationWithRanges,
} from '../models/account-holder.model';

export const annualIncomeBucketToRangeMapper: Record<AnnualIncome, { min: number; max: number }> = {
  lessThan25K: { min: 0, max: 24999 },
  between25KAnd50K: { min: 25000, max: 49999 },
  between50KAnd100K: { min: 50000, max: 99999 },
  between100KAnd200K: { min: 100000, max: 199999 },
  between200KAnd300K: { min: 200000, max: 299999 },
  between300KAnd500K: { min: 300000, max: 499999 },
  between500KAnd1Point2M: { min: 500000, max: 1199999 },
  moreThan1Point2M: { min: 1200000, max: Number.MAX_VALUE },
};

export const totalNetWorthBucketToRangeMapper: Record<TotalNetWorth, { min: number; max: number }> = {
  lessThan50K: { min: 0, max: 49999 },
  between50KAnd100K: { min: 50000, max: 99999 },
  between100KAnd200K: { min: 100000, max: 199999 },
  between200KAnd500K: { min: 200000, max: 499999 },
  between500KAnd1M: { min: 50000, max: 999999 },
  between1MAnd5M: { min: 1000000, max: 4999999 },
  moreThan5M: { min: 5000000, max: Number.MAX_VALUE },
};

export const liquidNetWorthToRangeMapper: Record<LiquidNetWorth, { min: number; max: number }> = {
  lessThan50K: { min: 0, max: 49999 },
  between50KAnd100K: { min: 50000, max: 99999 },
  between100KAnd200K: { min: 100000, max: 199999 },
  between200KAnd500K: { min: 200000, max: 499999 },
  between500KAnd1M: { min: 500000, max: 999999 },
  between1MAnd5M: { min: 1000000, max: 4999999 },
  moreThan5M: { min: 5000000, max: Number.MAX_VALUE },
};

export const mapFinancialInformationBucketsToRanges = ({
  annualIncome,
  totalNetWorth,
  liquidNetWorth,
}: {
  annualIncome: AnnualIncome;
  totalNetWorth: TotalNetWorth;
  liquidNetWorth: LiquidNetWorth;
}): FinancialInformationWithRanges => ({
  annualIncome: annualIncomeBucketToRangeMapper[annualIncome],
  totalNetWorth: totalNetWorthBucketToRangeMapper[totalNetWorth],
  liquidNetWorth: liquidNetWorthToRangeMapper[liquidNetWorth],
});
