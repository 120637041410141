import * as Yup from 'yup';

const validationMessages = {
  electronicConsentRequired: 'Field must be checked',
};

export const accountAgreementsValidation = Yup.object().shape({
  agreeElectronicConsent: Yup.bool()
    .oneOf([true], validationMessages.electronicConsentRequired)
    .required(validationMessages.electronicConsentRequired),
});
