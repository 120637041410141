import { css } from '@emotion/css';

import { ColorConstant, Display, Font, FontWeight, JustifyContent, Spacing, TextAlign } from '../../../../styles';

export const label = css`
  ${FontWeight.semibold};
  ${Font.h2};
  color: ${ColorConstant.GRAY8};
  ${Spacing.my16};
`;

export const transactionContainer = css`
  background-color: ${ColorConstant.GRAY1};
  border-radius: 0.75rem;
  flex: 1;
`;

export const senderReceiverTopLabel = css`
  ${FontWeight.semibold};
  text-transform: uppercase;
  color: ${ColorConstant.GRAY6};
`;

export const senderReceiverBottomLabel = css`
  ${Spacing.mt8};
  color: ${ColorConstant.GRAY10};
`;

export const bankLogo = css`
  width: 1.5rem;
  height: 1.5rem;
`;

export const bankCode = css`
  ${FontWeight.bold};
  ${Font.sm};
  ${Spacing.mt8};
  color: ${ColorConstant.GRAY8};
  ${TextAlign.right};
`;

export const bankNameContainer = css`
  ${Display.flex};
  ${JustifyContent.flexEnd};
  ${Spacing.mt8};
`;

export const bankName = css`
  ${Font.sm};
  ${Spacing.mr4};
  color: ${ColorConstant.GRAY6};
`;

export const nickname = css`
  ${Font.sm};
  color: ${ColorConstant.GRAY6};
`;

export const tableContainer = css`
  width: 100%;
`;

export const viewDetailsButton = css`
  ${Font.sm}
  ${FontWeight.semibold}
`;

export const amountValue = ({ isNegative }: { isNegative: boolean }) => css`
  ${FontWeight.bold}
  ${Font.md}
  color: ${isNegative ? ColorConstant.RED8 : ColorConstant.GREEN8};
`;

export const viewDetailsWrapper = css`
  .ant-modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;
