import { css } from '@emotion/css';

export const icon = css`
  margin-right: 4px;
`;

export const anchor = css`
  display: flex;
  align-items: center;
`;

export const text = css`
  font-size: 14px;
`;
