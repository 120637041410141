export const FormFields = {
  email: 'email',
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  suffix: 'suffix',
  phone: 'phone',
  countryCode: 'countryCode',
  password: 'password',
  confirmPassword: 'confirmPassword',
};

export const FormStrings = {
  [FormFields.email]: 'Email',
  [FormFields.firstName]: 'First Name',
  [FormFields.middleName]: 'Middle Initial or Middle Name',
  [FormFields.lastName]: 'Last Name',
  [FormFields.suffix]: 'Suffix (i.e. Jr, Sr, II, etc.)',
  [FormFields.phone]: 'Phone Number',
  [FormFields.countryCode]: 'Country Code',
  [FormFields.password]: 'Password',
  [FormFields.confirmPassword]: 'Confirm Password',
};
