import { css } from '@emotion/css';

import {
  AlignItems,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../../../../../styles';

export const container = css`
  ${Spacing.px64}
  ${Spacing.py32}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.px32}
    ${Spacing.py16}
  }
`;

export const section = css`
  ${Display.flex}
  ${JustifyContent.spaceBetween}
  ${Spacing.my16}
  ${AlignItems.center}

  @media (max-width: ${ScreenBreakpoint.mobile.min}) {
    ${FlexDirection.column}
    ${AlignItems.flexStart}
  }
`;

export const subTitle = css`
  ${Font.md}
  ${FontWeight.bold}
  color: ${ColorConstant.GRAY8};
  ${Display.flex}
`;

export const paragraph = css`
  ${Font.md}
  ${FontWeight.normal}
  color: ${ColorConstant.GRAY7};
`;

export const infoLinkButton = css`
  ${Spacing.pl0}
`;
