import { useEffect } from 'react';

import { Space } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { toggleMfaConfig, clearMfaConfigToggle, doRemoveOfferInvestReferrer } from '../../../actions';
import { getFormState } from '../../../actions/forms';
import { UserDto } from '../../../dtos/users.dtos';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import Switch from '../../../lib/FormComponents/Switch';
import { OnboardStep } from '../../../models/app.models';
import * as Styles from '../BaseLayout.styles';
import { ActiveTab } from '../constants';
import { SignUpWithOfferInvestReferrer } from '../SignUpWithOfferInvestReferrer';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import * as SecurityStyles from './Security.styles';

export const Security = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData: UserDto = useSelector((state: any) => state.user.authenticated.data);
  const onboardStep: OnboardStep | undefined = useSelector((state: any) => state.app.onboardStep);

  const isRequested = useSelector((state: any) => Boolean(state.user.toggleMfaConfig.__requested));
  const isToggleMfaSucceded = useSelector((state: any) => Boolean(state.user.toggleMfaConfig.__succeeded));

  const registrationSecurity = useSelector((state: any) => state.user.toggleMfaConfig.data);

  const phoneNumber = useSelector((state: any) => state.user.signUp.data?.phoneNumber);

  const availableNumber = phoneNumber || userData.phoneNumber;

  InitialValues[FormFields.enableMFA] = userData.isMfaEnabled;
  const formState = getFormState(registrationSecurity, InitialValues);

  const onSubmit = (_values: any) => {
    dispatch(toggleMfaConfig(_values));
  };

  useEffect(() => {
    if (isToggleMfaSucceded && onboardStep === OnboardStep.MfaConfirm) {
      window.gtag('event', 'sign_up_security_complete', {
        mfa_enabled: true,
      });

      return navigate('/security-confirmation');
    }

    if (isToggleMfaSucceded && onboardStep === OnboardStep.Done) {
      dispatch(doRemoveOfferInvestReferrer());
      window.gtag('event', 'sign_up_security_complete', {
        mfa_enabled: false,
      });

      return navigate('/complete-account');
    }
  }, [isToggleMfaSucceded, onboardStep]);

  useEffect(() => {
    return () => {
      dispatch(clearMfaConfigToggle());
    };
  }, []);

  return (
    <SignUpWithOfferInvestReferrer
      activeTab={ActiveTab.Security}
      tabs={[ActiveTab.Register, ActiveTab.Address, ActiveTab.Security]}
      cardTitle='Multi-factor authentication'
      cardSubtitle='For added security, you must authenticate your identity. By clicking "Next" we will text a
    One Time Security Code to the number below. Do not share this code with anyone.'>
      <Formik
        initialValues={formState}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => {
          onSubmit(values);
        }}>
        {form => {
          return (
            <>
              <div className={Styles.formItemsContainer}>
                <Switch
                  label={FormStrings[FormFields.enableMFA]}
                  name={FormFields?.enableMFA}
                  value={form.values[FormFields.enableMFA]}
                  {...form}
                />
                {form.values?.enableMFA && (
                  <div className={SecurityStyles.yourPhoneNumberIsContainer}>
                    Your phone number is:{' '}
                    {availableNumber && (
                      <span className={SecurityStyles.phoneNumberSpan}>
                        {`XXX - XXX - ${availableNumber.substr(availableNumber.length - 4)}`}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <Space size={8} direction='horizontal' align='center' className={Styles.buttonsContainer}>
                <MButton
                  testId={'security-btn-back'}
                  type='tertiary'
                  onClick={() => {
                    navigate('/address', {
                      state: {
                        title: 'back-from-security',
                      },
                    });
                  }}>
                  Back
                </MButton>
                <MButton
                  testId={'security-btn-next'}
                  onClick={() => {
                    form.submitForm();
                  }}
                  type='secondary'
                  disabled={isRequested || !form.isValid}
                  loading={isRequested}>
                  Next
                </MButton>
              </Space>
            </>
          );
        }}
      </Formik>
    </SignUpWithOfferInvestReferrer>
  );
};
