import { css } from '@emotion/css';

import {
  Color,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
  Display,
  AlignItems,
  FlexDirection,
  JustifyContent,
} from '../../../styles';

export const multiPhoneNumbersInput = css`
  border-bottom: 1px solid #989ea6 !important;
  text-align: right;
  width: 75%;
  border-radius: 0px;
  &:hover {
    border-bottom: 1px solid #3877b0 !important;
  }
  &:nth-child(2) {
    ${Spacing.pl32};
  }
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: 100%;
    ${TextAlign.left};
    ${Spacing.pl0};
  }
`;

export const formItem = css`
  flex-direction: column;
  margin: 0rem;
  width: 5.5rem;
  padding-left: 0.75rem;
  margin-left: auto;
  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    width: 100%;
    ${Spacing.pl0};
  }
`;

export const addNumberButton = css`
  font-weight: 600;
  line-height: 1.3125rem;
  text-align: right;
  color: ${Color.GREEN.GREEN7};
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-top: 0.75rem;
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${JustifyContent.flexStart};
  }
`;

export const deletePhone = {
  fontSize: 20,
  color: Color.RED.RED7,
  cursor: 'pointer',
};

export const addPhone = {
  fontSize: 20,
  fontWeight: 700,
  color: Color.GREEN.GREEN7,
  cursor: 'pointer',
  marginRight: 4,
};

export const phoneNumber = css`
  ${Display.flex};
  ${AlignItems.center};
  ${FlexDirection.row};
  i {
    @media (max-width: ${ScreenBreakpoint.mobile.max}) {
      position: absolute;
      left: 0;
      z-index: 9999;
    }
  }
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.column};
  }
`;
