import { css, cx } from '@emotion/css';

import {
  AlignItems,
  Color,
  ColorConstant,
  Font,
  FontFamily,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
} from '../../../../../../styles';

export const fieldLabel = css`
  ${FontFamily.sourceSansPro};
  ${FontWeight.bold}
  ${Font.md};
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const fieldValue = cx(
  css`
    ${FontFamily.sourceSansPro};
    ${FontWeight.normal};
    ${Font.md};
    color: ${Color.GRAYSCALE.GRAY8};
    ${TextAlign.right};
  `,
);

export const disclaimer = cx(
  css`
    ${FontFamily.sourceSansPro};
    ${FontWeight.normal};
    ${Font.xs};
    color: ${Color.GRAYSCALE.GRAY10};
  `,
);

export const submissionSuccessStatus = css`
  color: ${Color.GREEN.GREEN6};
`;

export const submissionStatus = css`
  ${FontFamily.sourceSansPro};
  ${FontWeight.bold};
  ${Font.md};
  ${TextAlign.center};
  color: ${Color.RED.RED6};
`;

export const submissionFailedStatus = css`
  color: ${Color.RED.RED6};
  ${TextAlign.center};
`;

export const rowStyle = cx(
  css`
    ${JustifyContent.center};
    ${AlignItems.center};
    ${Spacing.mb12};
    height: 56px;
  `,
);

export const offerName = cx(
  css`
    ${FontFamily.sourceSansPro};
    ${FontWeight.bold};
    ${Font.h1};
    color: ${Color.GRAYSCALE.GRAY10};
  `,
);

export const wiringSectionLabel = cx(
  css`
    ${FontFamily.sourceSansPro};
    ${FontWeight.bold};
    ${Font.sm};
    ${AlignItems.center};
    color: ${Color.BRAND.BRAND6};
    ${TextAlign.center};
    ${Spacing.mb12};
  `,
);

export const totalInvestment = cx(
  css`
    ${Font.h1}
    color: ${Color.GRAYSCALE.GRAY8};
    ${TextAlign.center};
    ${Spacing.mb40};
    ${Spacing.mt24};
  `,
);

export const offerLogo = css`
  width: 31px;
  height: 31px;
  ${Spacing.mr16};
`;

export const submissionSucceededLogo = css`
  color: ${Color.GREEN.GREEN7};
  ${Font.h1};
  ${Spacing.mr12};
`;

export const container = css`
  ${Spacing.px64};
  ${Spacing.py40};
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.px20};
  }
`;

export const totalInvestmentValue = css`
  ${FontWeight.bold};
  ${Spacing.pl12};
`;

export const fieldsContainer = css`
  ${Spacing.mb24};
`;

export const wiringContainer = {
  backgroundColor: Color.GRAYSCALE.GRAY2,
  marginTop: 8,
  marginBottom: 8,
  borderRadius: 8,
  padding: 8,
};

export const otherFactors = css`
  color: ${ColorConstant.BRAND6};
  ${FontWeight.bold}
  cursor: pointer;
`;
