import { useRef, useState } from 'react';

import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { doClearUpdatePhoneNumber, doUpdatePhoneNumber } from 'src/actions';
import { countryCodes } from 'src/constants/countryCodes';
import FlagsDropdown from 'src/lib/FormComponents/Dropdown/FlagsDropDown';
import { InputType, MaskType } from 'src/lib/FormComponents/Input/constants';
import Input from 'src/lib/FormComponents/Input/Input';
import { formatNumber } from 'src/lib/FormComponents/Input/masks';
import ConfirmPanel from 'src/lib/Miscellaneous/ConfirmPanel/ConfirmPanel';
import Spinner from 'src/lib/Miscellaneous/Spinner';
import { MModal } from 'src/lib/MModal/MModal';
import { ConfirmPanelType } from 'src/models/confirm-panel.enum';
import { formatValidationSchemaError } from 'src/utils';
import * as Yup from 'yup';

import * as Styles from './UpdatePhoneNumberModal.styles';

interface UpdatePhoneNUmberModalProps {
  isVisible: boolean;
  onClose: () => void;
}

interface UpdatePhoneNumberFormValues {
  countryCode: string;
  phoneNumber: string;
}

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test('compare', 'Phone number is invalid', function (phone: string | any, schema: string | any) {
      if (schema !== undefined && schema !== null) {
        const mask = find(countryCodes, { code: schema?.parent?.countryCode })?.phoneMask;
        const maskLength = formatNumber(mask as string).length;

        return phone?.length === maskLength;
      } else return false;
    }),
});

export const UpdatePhoneNumberModal = ({ isVisible, onClose }: UpdatePhoneNUmberModalProps) => {
  const dispatch = useDispatch();
  const isUpdatePhoneNumberLoading: boolean = useSelector((state: any) => state.user.updatePhoneNumber?.isLoading);
  const hasUpdatePhoneNumberFailed: boolean = useSelector((state: any) => state.user.updatePhoneNumber?.__failed);
  const updatePhoneNumberError: string = useSelector((state: any) => state.user.updatePhoneNumber?.message);
  const updatePhoneNumberSucceeded: boolean = useSelector((state: any) => state.user.updatePhoneNumber?.__succeeded);
  const formRef = useRef<FormikProps<UpdatePhoneNumberFormValues> | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const isRequestCompleted = () => hasUpdatePhoneNumberFailed || updatePhoneNumberSucceeded;

  const _onClose = () => {
    onClose();
    dispatch(doClearUpdatePhoneNumber());
  };

  const onSubmit = (values: UpdatePhoneNumberFormValues) => {
    const dialCode = find(countryCodes, { code: values.countryCode })?.dialCode;
    const phoneNumber = `${dialCode}${values.phoneNumber}`;
    dispatch(doUpdatePhoneNumber({ phoneNumber }));
  };

  const onPrimaryButtonClick = () => {
    if (isRequestCompleted()) {
      _onClose();

      return;
    }
    formRef.current?.submitForm();
  };
  const renderPrimaryButtonText = () => (isRequestCompleted() ? 'Close' : 'Submit');

  const renderPrimaryButtonType = () => (isRequestCompleted() ? 'tertiary' : 'primary');

  const renderUpdatePhoneNumberModalContent = () => {
    if (isUpdatePhoneNumberLoading) {
      return <Spinner customSpinnerStyle={Styles.spinnerHeight} />;
    }

    if (hasUpdatePhoneNumberFailed) {
      return (
        <ConfirmPanel
          type={ConfirmPanelType.ERROR}
          iconSource={'ri-close-circle-line'}
          title={'Something went wrong'}
          description={
            updatePhoneNumberError ?? (
              <>
                Unknown error occurred. Please try again later or contact My IPO at{' '}
                <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>
              </>
            )
          }
        />
      );
    }

    if (updatePhoneNumberSucceeded) {
      return (
        <ConfirmPanel
          type={ConfirmPanelType.SUCCESS}
          iconSource={'ri-chat-check-line'}
          title={'Phone number successfully changed'}
          description={'You have successfully changed your phone number.'}
        />
      );
    }

    return (
      <Formik
        enableReinitialize
        innerRef={form => (formRef.current = form)}
        initialValues={{
          countryCode: 'USA',
          phoneNumber: '',
        }}
        validate={async values => {
          try {
            await validationSchema.validate(values, {
              abortEarly: false,
            });
            setIsFormValid(true);

            return {};
          } catch (error: Yup.ValidationError | any) {
            setIsFormValid(false);

            formatValidationSchemaError(error);
          }
        }}
        onSubmit={onSubmit}>
        {(form: FormikProps<UpdatePhoneNumberFormValues>) => {
          return (
            <Form>
              <Row>
                <Col className={Styles.formContent}>
                  <div className={Styles.phoneNumberContainer}>
                    <FlagsDropdown
                      {...form}
                      name='countryCode'
                      value={form.values.countryCode}
                      customStyles={{ marginBottom: 0 }}
                    />
                  </div>

                  <Input
                    {...form}
                    name='phoneNumber'
                    value={form.values.phoneNumber}
                    placeholder='Phone Number'
                    type={InputType.PhoneNumber}
                    mask={MaskType.Custom}
                    customMask={
                      find(countryCodes, {
                        code: form.values.countryCode,
                      })?.phoneMask
                    }
                    customStyles={{ width: '100%', height: '400px' }} //NOTE: 400px is used to fix the flagsdropdown list not expanding over the modal
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <MModal
      title='Change Phone Number'
      visible={isVisible}
      centered
      customHeight={hasUpdatePhoneNumberFailed ? 300 : updatePhoneNumberSucceeded ? 200 : 150}
      onClose={_onClose}
      primaryButtonText={renderPrimaryButtonText()}
      primaryButtonType={renderPrimaryButtonType()}
      onPrimaryButtonClick={onPrimaryButtonClick}
      isDisabledPrimaryButton={isUpdatePhoneNumberLoading || !isFormValid}>
      {renderUpdatePhoneNumberModalContent()}
    </MModal>
  );
};
