import _ from 'lodash';

import { states } from '../../../constants/accountCompletion';
import { countries } from '../../../constants/countryCodes';
import Dropdown from '../../../lib/FormComponents/Dropdown';
import { InputType } from '../../../lib/FormComponents/Input/constants';
import Input from '../../../lib/FormComponents/Input/Input';
import MultiPhoneNumbers from '../../../lib/FormComponents/MultiPhoneNumbers';
import { PhoneNumber } from '../../../lib/FormComponents/MultiPhoneNumbers/constants';
import { MenuNames } from '../../../models/menus.enum';

import * as Styles from './TrustedContact.styles';
import { trustedContactValidation } from './validations';

export const trustedContactPersonalInformationSectionFields = (trustedContactInfo: any) => [
  {
    label: 'First Name',
    component: Input,
    key: 'firstName',
    displayValue: (
      <div className={Styles.displayValue}>{!_.isEmpty(trustedContactInfo) ? trustedContactInfo.firstName : null}</div>
    ),
    readOnly: false,
    props: {
      name: 'firstName',
      placeholder: 'Enter',
      value: !_.isEmpty(trustedContactInfo) ? trustedContactInfo.firstName : null,
    },
  },
  {
    label: 'Last Name',
    component: Input,
    key: 'lastName',
    readOnly: false,
    displayValue: (
      <div className={Styles.displayValue}>{!_.isEmpty(trustedContactInfo) ? trustedContactInfo.lastName : null}</div>
    ),
    props: {
      name: 'lastName',
      placeholder: 'Enter',
      value: !_.isEmpty(trustedContactInfo) ? trustedContactInfo.lastName : null,
    },
  },

  {
    label: 'Phone Number(s)',
    component: MultiPhoneNumbers,
    key: 'phones',
    readOnly: false,
    displayValue: (
      <div className={Styles.displayValue}>
        {trustedContactInfo?.phones?.map((item: PhoneNumber) => {
          return (
            <div key={`${item?.type}_key`} className={Styles.phoneNumbers}>
              <span className={Styles.displayValue}>{item?.phoneNumber}</span>
              <span style={{ marginLeft: 8, minWidth: 45 }} className={Styles?.phoneType}>
                {item?.type}
              </span>
            </div>
          );
        })}
      </div>
    ),
    props: {
      name: 'phones',
      type: InputType.PhoneNumber,
      placeholder: 'Placeholder',
      value: trustedContactInfo?.phones ? trustedContactInfo?.phones : [{ type: 'Mobile', phoneNumber: '' }],
    },
  },
  {
    label: 'Email',
    component: Input,
    key: 'email',
    readOnly: false,
    displayValue: (
      <div className={Styles.displayValue}>{!_.isEmpty(trustedContactInfo) ? trustedContactInfo.email : null}</div>
    ),
    props: {
      name: 'email',
      placeholder: 'Enter',
      value: !_.isEmpty(trustedContactInfo) ? trustedContactInfo.email : null,
    },
  },
  {
    label: 'Address Line 1',
    isPartOfAddress: true,
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.address1) ? trustedContactInfo.address1 : null}
      </div>
    ),
    component: Input,
    key: 'address1',
    readOnly: false,
    props: {
      name: 'address1',
      placeholder: 'Enter',
      value:
        !_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.address1) ? trustedContactInfo.address1 : null,
    },
  },
  {
    label: 'Address Line  2 (Opt.)',
    isPartOfAddress: true,
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.address2) ? trustedContactInfo.address2 : '-'}
      </div>
    ),
    component: Input,
    key: 'address2',
    readOnly: false,
    props: {
      name: 'address2',
      placeholder: 'Enter',
      value:
        !_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.address2) ? trustedContactInfo.address2 : null,
    },
  },
  {
    label: 'Country',
    isPartOfAddress: true,
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(countries, {
            value:
              !_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.country)
                ? trustedContactInfo.country
                : null,
          })?.text
        }
      </div>
    ),
    component: Dropdown,
    key: 'country',
    readOnly: false,
    props: {
      name: 'country',
      placeholder: 'Select',
      options: countries,
      value:
        !_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.country) ? trustedContactInfo.country : null,
    },
  },
  {
    label: 'City',
    isPartOfAddress: true,
    component: Input,
    key: 'city',
    readOnly: false,
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.city) ? trustedContactInfo.city : null}
      </div>
    ),
    props: {
      name: 'city',
      placeholder: 'Enter',
      value: !_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.city) ? trustedContactInfo.city : null,
    },
  },
  {
    label: 'State',
    isPartOfAddress: true,
    component: Dropdown,
    key: 'state',
    readOnly: false,
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(states, {
            value:
              !_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.state) ? trustedContactInfo.state : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'state',
      placeholder: 'Select',
      showSearch: true,
      options: states,
      value: !_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.state) ? trustedContactInfo.state : null,
    },
  },

  {
    label: 'Postal Code',
    isPartOfAddress: true,
    component: Input,
    key: 'postalCode',
    readOnly: false,
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.postalCode)
          ? trustedContactInfo.postalCode
          : null}
      </div>
    ),
    props: {
      name: 'postalCode',
      type: InputType.Number,
      placeholder: 'Enter',
      value:
        !_.isEmpty(trustedContactInfo) && !_.isEmpty(trustedContactInfo.postalCode)
          ? trustedContactInfo.postalCode
          : null,
    },
  },
];

export const getTrustedContactSections = (trustedContactInfo: any) => {
  return {
    menuName: MenuNames.TRUSTED_CONTACT_PERSON,
    path: 'trusted-contact',
    sections: [
      {
        sectionLabel: 'Personal Information',
        sectionKey: 'trusted_contact',
        sectionFields: [...trustedContactPersonalInformationSectionFields(trustedContactInfo)],
        isCompleted:
          trustedContactInfo === undefined ? true : trustedContactValidation?.isValidSync(trustedContactInfo),
        validationSchema: trustedContactValidation,
      },
    ],
  };
};
