import { css } from '@emotion/css';

import { AlignItems, Display, FlexDirection, Font, JustifyContent, ScreenBreakpoint, Spacing } from '../../../styles';

export const inputColumnLeft = css`
  ${Spacing.mr16}
  width: 50%;

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: 100%;
    ${Spacing.mr0}
    ${Spacing.mb16}
  }
`;

export const inputColumnRight = css`
  ${Spacing.ml16}
  width: 50%;

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: 100%;
    ${Spacing.mr0}
    ${Spacing.mb16}
  }
`;

export const phoneNumberInputContainer = css`
  width: 100%;

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    margin-top: 6px;
  }
  @media (min-width: ${ScreenBreakpoint.mobile.max}) and (max-width: ${ScreenBreakpoint.laptop.max}) {
    margin-top: 4px;
  }
  @media (min-width: ${ScreenBreakpoint.desktop.min}) and (max-width: 1730px) {
    margin-top: 3px;
  }
  @media (min-width: 1730px) {
    margin-top: 2px;
  }
`;

export const emailAndPhoneNumberInputRow = css`
  ${AlignItems.flexEnd}
`;

export const phoneNumberRow = css`
  ${Display.flex}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Font.sm};
  }
`;

export const inputRow = css`
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.spaceBetween}
  ${Spacing.mb24}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.column}
    ${Spacing.mb0}
  }
`;
