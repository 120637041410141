import { OfferOrderStatusLabel } from 'src/models/offer-orders.model';
import { OrderStatusLabel } from 'src/models/orders.models';
import { TransferStatusLabel } from 'src/models/transfers.models';
import { ColorConstant } from 'src/styles';

export const getTransferStatusTagColor = (value: TransferStatusLabel): { background: string; color: string } => {
  let background;
  let color;

  switch (value) {
    case TransferStatusLabel.PENDING:
      background = ColorConstant.GRAY3;
      color = ColorConstant.GRAY7;
      break;

    case TransferStatusLabel.REJECTED:
      background = ColorConstant.RED2;
      color = ColorConstant.RED7;
      break;

    case TransferStatusLabel.COMPLETED:
      background = ColorConstant.GREEN2;
      color = ColorConstant.GREEN7;
      break;

    case TransferStatusLabel.CANCELLED:
      background = ColorConstant.ORANGE2;
      color = ColorConstant.ORANGE7;
      break;

    default:
      background = ColorConstant.BRAND2;
      color = ColorConstant.BRAND7;
  }

  return { background, color };
};

export const getOrderStatusTagColor = (value: OrderStatusLabel): { background: string; color: string } => {
  let background;
  let color;

  switch (value) {
    case OrderStatusLabel.PENDING:
      background = ColorConstant.GRAY3;
      color = ColorConstant.GRAY7;
      break;

    case OrderStatusLabel.REJECTED:
      background = ColorConstant.RED2;
      color = ColorConstant.RED7;
      break;

    case OrderStatusLabel.COMPLETED:
      background = ColorConstant.GREEN2;
      color = ColorConstant.GREEN7;
      break;

    case OrderStatusLabel.CANCELLED:
      background = ColorConstant.ORANGE2;
      color = ColorConstant.ORANGE7;
      break;

    default:
      background = ColorConstant.BRAND2;
      color = ColorConstant.BRAND7;
  }

  return { background, color };
};

export const getOfferOrderStatusTagColor = (value: OfferOrderStatusLabel): { background: string; color: string } => {
  switch (value) {
    case OfferOrderStatusLabel.PendingFunds:
      return {
        background: ColorConstant.GRAY3,
        color: ColorConstant.GRAY7,
      };
    case OfferOrderStatusLabel.PendingOfferClose:
      return {
        background: ColorConstant.ORANGE1,
        color: ColorConstant.GRAY8,
      };
    case OfferOrderStatusLabel.PendingAction:
      return {
        background: ColorConstant.BRAND1,
        color: ColorConstant.BRAND7,
      };
    case OfferOrderStatusLabel.Approved:
      return { background: ColorConstant.BRAND2, color: ColorConstant.BRAND7 };
    case OfferOrderStatusLabel.Complete:
      return { background: ColorConstant.GREEN2, color: ColorConstant.GREEN7 };
    case OfferOrderStatusLabel.PendingFirmCancellation:
      return {
        background: ColorConstant.ORANGE1,
        color: ColorConstant.ORANGE7,
      };
    case OfferOrderStatusLabel.Cancelled:
      return { background: ColorConstant.ORANGE2, color: ColorConstant.ORANGE7 };
    case OfferOrderStatusLabel.Rejected:
      return { background: ColorConstant.RED2, color: ColorConstant.RED7 };
    case OfferOrderStatusLabel.Deleted:
      return { background: ColorConstant.RED2, color: ColorConstant.RED8 };
  }
};
