import React from 'react';

import { Form, Switch as AntSwitch } from 'antd';
import _ from 'lodash';

import '../../../index.css';
import { Color } from '../../../styles';

import { ToggleComponentType } from './constants';
import * as Styles from './styles';

const Switch = ({
  name,
  label = '',
  preChangeHook,
  postChangeHook,
  setFieldValue,
  setFieldTouched,
  disabled = false,
  touched,
  errors,
  value = false,
  customStyles,
}: ToggleComponentType) => {
  const onValChange = (val: boolean) => {
    setFieldTouched(name, true, false);

    if (preChangeHook) {
      preChangeHook();
    }
    setFieldValue(name, val, true);

    if (postChangeHook) {
      postChangeHook();
    }
  };

  const hasLabel = !_.isEmpty(label);

  return (
    <Form.Item
      hasFeedback={!!errors && !!errors[name]}
      validateStatus={!!errors && errors[name] && 'error'}
      help={!!errors && touched[name] && errors[name]}
      colon={false}
      style={{ marginBottom: 0, ...customStyles }}>
      <div className={Styles.rowContainer}>
        {hasLabel && (
          <div className={Styles.labelContainer}>
            <span className={Styles.switchLabel}>{label}</span>
          </div>
        )}
        <div
          style={{
            width: hasLabel ? '20%' : '100%',
          }}>
          <AntSwitch
            style={{
              display: 'flex',
              marginLeft: hasLabel ? 'auto' : 0,
              backgroundColor: value ? Color.BRAND.BRAND6 : '#BEBEBE',
            }}
            className={Styles.switchStyle}
            checked={value}
            disabled={disabled}
            onChange={onValChange}
            onClick={() => {}}
          />
        </div>
      </div>
    </Form.Item>
  );
};

export default Switch;
