import { css } from '@emotion/css';

import {
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontFamily,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../styles';

export const accountSubMenu = css`
  ${Display.flex};
  ${JustifyContent.flexStart};
  ${Spacing.p0};
  ${FlexDirection.column};
  @media (min-width: ${ScreenBreakpoint.laptop.min}) {
    display: none;
  }
`;

export const subMenuLink = css`
  ${FontFamily.sourceSansPro};
  ${Font.lg};
  color: ${ColorConstant.GRAY10};
  ${Spacing.mb24};
`;

export const mobileUserInfoWrapper = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 24px;
`;
