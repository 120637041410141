import { useState } from 'react';

import { useSelector } from 'react-redux';

import { InfoLinkButton } from '../../../../lib/InfoLinkButton/InfoLinkButton';
import { MModal } from '../../../../lib/MModal/MModal';

import * as Styles from './DepositByCheck.styles';

export const DepositByCheck = () => {
  const accountHolder = useSelector((state: any) => state.accountDetails.accountHolder.data);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getAccountHolderInstructions = () => {
    if (accountHolder?.accountNumber) {
      return `Apex Clearing FBO (${accountHolder.primaryAccountHolder.lastName}, 
      ${accountHolder.primaryAccountHolder.firstName}, ${accountHolder.accountNumber})`;
    }

    return `Apex Clearing FBO (last name, first name, full My IPO account number)`;
  };

  return (
    <>
      <InfoLinkButton onClick={() => setIsOpen(true)} className={Styles.infoButton}>
        Deposit Funds By Check
      </InfoLinkButton>
      <MModal
        title='Check Deposit Instructions'
        customHeight={800}
        customWidth={650}
        visible={isOpen}
        secondaryButtonText='OK'
        onSecondaryButtonClick={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}>
        <p>
          Paper check deposits received before 1:00pm CT are processed same day. Please refer to the check funding
          instructions below to avoid having your check returned to you as unacceptable.
        </p>

        <strong>Make check(s) payable to:</strong>
        <p>{getAccountHolderInstructions()}</p>

        <strong>Mail checks to:</strong>
        <p>
          <span>TradingBlock</span>
          <br />
          <span>Attn. Cashiering</span>
          <br />
          <span>311 S Wacker Dr, Suite 1775</span>
          <br />
          <span>Chicago, IL 60606</span>
          <br />
        </p>

        <strong>Acceptable Check Deposits</strong>
        <ul>
          <li>Checks must be payable in U.S. dollars and through a U.S. bank.</li>
          <li>
            Personal checks must be drawn from a bank account in an account owner’s name and must include &quot;Jr&quot;
            or &quot;Sr&quot; if applicable.
          </li>
          <li>
            Checks from joint checking accounts may be deposited into either checking account owner’s My IPO account.
          </li>
          <li>
            Checks from an individual checking account may be deposited into a My IPO joint account if that person is
            one of the account owners.
          </li>
          <li>
            Investment Club checks should be drawn from a checking account in the name of the Investment Club. If a
            member of the Investment Club remits a personal check, the check must be payable to: &quot;Apex Clearing /
            FBO the Investment Club name.&quot; (&quot;FBO&quot; stands for &quot;For the Benefit Of&quot;)
          </li>
        </ul>

        <strong>Unacceptable Check Deposits</strong>

        <ul>
          <li>Cashier’s check</li>
          <li>Money Orders</li>
          <li>Foreign instruments</li>
          <li>Thrift withdrawal orders</li>
          <li>Domestic drafts</li>
          <li>Checks that have been double-endorsed (checks with more than one signature on the back)</li>
          <li>
            Third-party checks not properly made out and endorsed per the rules stated in the &quot;Acceptable
            Deposits&quot; section above
          </li>
          <li>Checks from minors</li>
          <li>Check dated over six months old</li>
          <li>Travelers checks</li>
          <li>Credit card checks</li>
        </ul>
      </MModal>
    </>
  );
};
