import React from 'react';

import { Row } from 'antd';

import { TextAlign } from '../../../../lib/FormComponents/Input/constants';
import Input from '../../../../lib/FormComponents/Input/Input';
import ListRow from '../../../../lib/Miscellaneous/ListRow/ListRow';
import { Images } from '../../../../styles';

import * as Styles from './styles';

function BankLinkCreate({ form }: any) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <img
          alt='bank'
          src={Images.BankPlaceholder}
          style={{
            marginTop: 16,
            marginBottom: 16,
            width: 40,
            height: 40,
          }}
        />
      </div>
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          justifyContent: 'center',
        }}>
        <span className={Styles.finalStepTag}>Final Step</span>
      </div>

      <Row style={{ justifyContent: 'center' }}>
        <div className={Styles.bankLinkSubDisclaimer}>
          You can provide a nickname for your newly re-linked account in order to explicitly identify the linkage beyond
          its default group of attributes. <br />
          You can also add or edit the nickname at any time needed.
        </div>
        <ListRow
          customRowStyle={{ marginTop: 33 }}
          leftComponent={<div className={Styles.fieldLabel}>Nickname</div>}
          rightComponent={
            <div
              style={{
                alignItems: 'center',
              }}>
              <Input
                {...form}
                name='nickname'
                placeholder='Enter nickname'
                textAlign={TextAlign.Right}
                hideBorder
                value={form.values.nickname}
              />
            </div>
          }
        />
      </Row>
    </>
  );
}

export default BankLinkCreate;
