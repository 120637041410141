import React, { useRef, useState } from 'react';

import { Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { FilterBarConstant } from 'src/constants';
import { OfferOrderStatusLabel } from 'src/models/offer-orders.model';
import { OrderStatusLabel } from 'src/models/orders.models';
import * as Yup from 'yup';

import { withdrawFunds } from '../../../../actions';
import { DefinitionConstant } from '../../../../constants/common.constants';
import { OrderConstant } from '../../../../constants/orders.constants';
import { TransferConstant } from '../../../../constants/transfers.constants';
import { OfferOrderStatusDto } from '../../../../dtos/offerOrders.dtos';
import { useAccount } from '../../../../hooks/useAccount';
import { useAccountBalance } from '../../../../hooks/useAccountBalance';
import { useOfferOrders } from '../../../../hooks/useOfferOrders';
import { useOrders } from '../../../../hooks/useOrders';
import NumberInput from '../../../../lib/FormComponents/NumberInput/NumberInput';
import CurrencyField from '../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import ListRow from '../../../../lib/Miscellaneous/ListRow/ListRow';
import { MAlert } from '../../../../lib/Miscellaneous/MAlert/MAlert';
import Spinner from '../../../../lib/Miscellaneous/Spinner';
import { MModal } from '../../../../lib/MModal/MModal';
import { MTooltip } from '../../../../lib/MTooltip/MTooltip';
import { Images } from '../../../../styles';
import { getCurrencyFormatter } from '../../../../utils/getCurrencyFormatter';
import { NewTransferConfirmStep } from '../NewTransferConfirmStep/NewTransferConfirmStep';

import * as Styles from './NewWithdrawalModal.styles';

const formValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Amount is required')
    .positive('Amount must be a positive number')
    .max(
      TransferConstant.MAX_AMOUNT,
      `The maximum transfer amount is ${getCurrencyFormatter().format(TransferConstant.MAX_AMOUNT)}`,
    ),
  hasAvailableFunds: Yup.bool().oneOf([true]),
});

export interface NewWithdrawalFormValues {
  amount: string;
  hasAvailableFunds: boolean;
}

export interface NewWithdrawalModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedBankAccount?: any;
}

const initialFormValues: NewWithdrawalFormValues = {
  amount: '',
  hasAvailableFunds: true,
};

export const NewWithdrawalModal = ({ isOpen, onClose, selectedBankAccount }: NewWithdrawalModalProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState<'withdrawal' | 'confirm'>('withdrawal');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<NewWithdrawalFormValues | null>(null);
  const formRef = useRef<FormikProps<NewWithdrawalFormValues> | null>(null);
  const [height, setHeight] = useState<number>(400);

  const { account } = useAccount();
  const { cashAvailableForWithdrawal, cashAvailable, isAccountBalanceLoading } = useAccountBalance();
  const { offerOrderList, isOfferOrderListLoading } = useOfferOrders();
  const { orderList, isOrderListLoading } = useOrders();

  const uncancelledOfferOrders = offerOrderList.filter(anOfferOrder =>
    [
      OfferOrderStatusDto.PendingFunds,
      OfferOrderStatusDto.PendingAction,
      OfferOrderStatusDto.PendingOfferClose,
    ].includes(anOfferOrder.status.value),
  );

  const uncancelledBuyOrders = orderList.filter(
    anOrder => OrderConstant.UNCANCELLED_STATUS_LIST.includes(anOrder.status.value) && anOrder.action.isBuy,
  );

  const hasAnyUncancelledBuyOrders = () => uncancelledOfferOrders.length > 0 || uncancelledBuyOrders.length > 0;

  const hasAvailableFunds = (amount: string) => {
    if (isNaN(Number(amount))) {
      return true;
    }

    if (cashAvailableForWithdrawal < Number(amount)) {
      return false;
    }

    return true;
  };

  const updateHeightOnAmountChange = (hasAvailableFunds: boolean) => {
    if (hasAvailableFunds) {
      setHeight(450);

      return;
    }
    setHeight(550);
  };

  const renderHasUncancelledOfferOrdersValidationMessage = (
    form: FormikProps<NewWithdrawalFormValues>,
  ): JSX.Element | null => {
    if (!form.values.hasAvailableFunds && uncancelledOfferOrders.length > 0) {
      return (
        <MAlert
          type='error'
          description={
            <p>
              You have open Offer Order(s), please click{' '}
              <a
                onClick={() => {
                  navigate(
                    `/main/history?show=${JSON.stringify([
                      FilterBarConstant.ShowFilterCategory.OfferOrder,
                    ])}&filterBy=${JSON.stringify([
                      `${OfferOrderStatusLabel.PendingFunds}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
                      `${OfferOrderStatusLabel.PendingOfferClose}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
                      `${OfferOrderStatusLabel.PendingAction}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
                    ])}`,
                  );
                }}>
                here{' '}
              </a>
              if you would like to view and/or attempt to cancel any order(s) which might give you sufficient funds to
              proceed with your cash withdrawal.
            </p>
          }
        />
      );
    }

    return null;
  };

  const renderHasUncancelledOrdersValidationMessage = (
    form: FormikProps<NewWithdrawalFormValues>,
  ): JSX.Element | null => {
    if (!form.values.hasAvailableFunds && uncancelledBuyOrders.length > 0) {
      return (
        <MAlert
          type='error'
          description={
            <p>
              You have open Security Buy Order(s), please click{' '}
              <a
                onClick={() => {
                  navigate(
                    `/main/history?show=${JSON.stringify([
                      FilterBarConstant.ShowFilterCategory.BuyOrder,
                    ])}&filterBy=${JSON.stringify([
                      `${OrderStatusLabel.PROCESSING}_${FilterBarConstant.ByFilterCategory.Order}`,
                      `${OrderStatusLabel.PENDING}_${FilterBarConstant.ByFilterCategory.Order}`,
                    ])}`,
                  );
                }}>
                here{' '}
              </a>
              if you would like to view and/or attempt to cancel any order(s) which might give you sufficient funds to
              proceed with your cash withdrawal.
            </p>
          }
        />
      );
    }

    return null;
  };

  const renderHasNoAvailableFundsValidationMessage = (
    form: FormikProps<NewWithdrawalFormValues>,
  ): JSX.Element | null => {
    if (!form.values.hasAvailableFunds && hasAnyUncancelledBuyOrders()) {
      return (
        <>
          {renderHasUncancelledOfferOrdersValidationMessage(form)}
          {renderHasUncancelledOrdersValidationMessage(form)}
        </>
      );
    }

    if (!form.values.hasAvailableFunds) {
      return <MAlert description={'You do not have enough available funds to process this request.'} type='error' />;
    }

    return null;
  };

  const renderWithdrawalStep = () => {
    if (isOfferOrderListLoading || isOrderListLoading || isAccountBalanceLoading) {
      return <Spinner customSpinnerStyle={Styles.spinner(height)} />;
    }

    return (
      <Formik
        enableReinitialize
        validateOnMount
        validate={async values => {
          try {
            await formValidationSchema.validate(values);
            setIsFormValid(true);
          } catch (error: any) {
            setIsFormValid(false);

            return { [error.path]: error.errors };
          }
        }}
        initialValues={formValues ?? initialFormValues}
        innerRef={form => (formRef.current = form)}
        onSubmit={values => {
          setFormValues(values);
        }}>
        {form => {
          return (
            <div>
              <ListRow
                customRowStyle={{ borderBottom: 'none' }}
                leftComponent={
                  <div className={Styles.cashAvailableLabel}>
                    Cash Available
                    <MTooltip title={DefinitionConstant.CASH_AVAILABLE} placement='top' />
                  </div>
                }
                rightComponent={<CurrencyField value={cashAvailable} className={Styles.cashAvailable} />}
              />
              <ListRow
                customRowStyle={{ borderBottom: 'none' }}
                leftComponent={
                  <div className={Styles.cashAvailableLabel}>
                    Funds Available For Withdrawal
                    <MTooltip title={DefinitionConstant.AVAILABLE_FUNDS_FOR_WITHDRAWAL} placement='top' />
                  </div>
                }
                rightComponent={<CurrencyField value={cashAvailableForWithdrawal} className={Styles.cashAvailable} />}
              />
              <div className={Styles.contentView}>
                <ListRow
                  leftComponent={
                    <div>
                      <div className={Styles.senderReceiverTopLabel}>FROM</div>
                      <div className={Styles.senderReceiverBottomLabel}>My IPO</div>
                    </div>
                  }
                  rightComponent={
                    <div>
                      <div>&nbsp;</div>
                      <div className={Styles.bankCode}>{account?.accountNumber}</div>
                    </div>
                  }
                />
                <ListRow
                  leftComponent={
                    <div>
                      <div className={Styles.senderReceiverTopLabel}>TO</div>
                      <div className={Styles.senderReceiverBottomLabel}>
                        {selectedBankAccount.tradingBlockACHRelationship.bankAccountType} (
                        {selectedBankAccount.tradingBlockACHRelationship.nickName})
                      </div>
                    </div>
                  }
                  rightComponent={
                    <div>
                      <div>
                        <div className={Styles.bankNameContainer}>
                          <div className={Styles.bankName}>{selectedBankAccount.bankName}</div>
                          <img
                            alt='bankLogo'
                            src={
                              selectedBankAccount?.tradingBlockPlaidInstitution?.logo
                                ? `data:image/jpeg;base64,${selectedBankAccount?.tradingBlockPlaidInstitution?.logo}`
                                : Images.BankPlaceholder
                            }
                            className={Styles.bankLogo}
                          />
                        </div>
                      </div>
                      <div className={Styles.bankCode}>
                        {selectedBankAccount.tradingBlockACHRelationship.bankAccount}
                      </div>
                    </div>
                  }
                />
                <div className={Styles.amountFieldWrapper}>
                  <div className={Styles.amountLabel}>Withdrawal Amount</div>
                  <NumberInput
                    {...form}
                    placeholder={'0.00'}
                    customStyles={Styles.amountInput}
                    name='amount'
                    value={form.values.amount}
                    setFieldValue={(name: string, value: string) => {
                      form.setFieldValue(name, value);
                      const hasFunds = hasAvailableFunds(value);
                      form.setFieldValue('hasAvailableFunds', hasFunds);
                      updateHeightOnAmountChange(hasFunds);
                    }}
                    step={'0.01'}
                    prefix={'$'}
                    testId={'money-transfer-input-withdrawal'}
                  />
                </div>

                <div className={Styles.noAvailableFundsWrapper}>{renderHasNoAvailableFundsValidationMessage(form)}</div>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  };

  const renderConfirmStep = () => {
    return (
      <NewTransferConfirmStep
        type='withdrawal'
        amount={formValues?.amount ?? ''}
        selectedBankAccount={selectedBankAccount}
        accountNumber={account?.accountNumber}
      />
    );
  };

  const renderContent = () => {
    if (step === 'withdrawal') {
      return renderWithdrawalStep();
    }

    return renderConfirmStep();
  };

  return (
    <MModal
      customWidth={750}
      customHeight={height}
      visible={isOpen}
      title='New Withdrawal'
      primaryButtonText={step === 'withdrawal' ? 'Continue' : 'Transfer'}
      secondaryButtonText={step === 'withdrawal' ? undefined : 'Back'}
      tertiaryButtonText='Close'
      onPrimaryButtonClick={async () => {
        if (step === 'withdrawal') {
          formRef.current?.submitForm();
          setHeight(475);
          setStep('confirm');
        }

        if (step === 'confirm' && formValues) {
          onClose();
          dispatch(
            withdrawFunds(selectedBankAccount.tradingBlockACHRelationship.accountId, {
              DisbursementType: 'PartialBalance',
              RequestedAmount: formValues.amount,
              RelationshipId: selectedBankAccount.tradingBlockACHRelationship.id,
            }),
          );
        }
      }}
      onSecondaryButtonClick={() => {
        setStep('withdrawal');
        setHeight(400);
      }}
      onTertiaryButtonClick={onClose}
      isDisabledPrimaryButton={!isFormValid}
      onClose={onClose}>
      {renderContent()}
    </MModal>
  );
};
