import { css, cx } from '@emotion/css';

import { Color } from '../../../styles';

export const modifySignatureButton = cx(
  css`
    color: ${Color.BRAND.BRAND6};
    font-size: 14;
    margin-top: 8px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
);

export const drawnSignatureContainer = cx(
  css`
    display: flex;
    height: 40px;
    margin-bottom: 19px;
    align-items: center;
    padding-left: 10px;
  `,
);

export const fontSignatureContainer = cx(
  css`
    display: flex;
    height: 40px;
    margin-bottom: 19px;
    align-items: center;
    font-size: 25px;
    padding-left: 10px;
    cursor: pointer;
  `,
);

export const signaturePadContainer = cx(
  css`
    border: 1px solid ${Color.BRAND.BRAND5};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: crosshair;
    margin-right: 8px;
  `,
);

export const drawSignature = css`
  font-weight: 600;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 16px;
  color: ${Color.BRAND.BRAND6};
`;

export const quillPen = {
  fontSize: 21,
  marginRight: 8,
  marginTop: 3,
  color: Color.BRAND.BRAND6,
};

export const penStyle = {
  fontSize: 17,
  marginRight: 8,
  color: Color.BRAND.BRAND6,
};
