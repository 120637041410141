import { css, cx } from '@emotion/css';

import {
  AlignItems,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
} from '../../../../../styles';

export const fieldLabel = css`
  ${FontWeight.normal}
  ${Font.md}
  color: ${ColorConstant.GRAY8};
`;

export const cashAvailableLabelContainer = css`
  ${Display.flex}
`;

export const rowStyle = css`
  ${JustifyContent.center}
  ${AlignItems.center}
  ${Spacing.mb12}
  height: 3.5rem;
`;

export const offerName = css`
  ${FontWeight.bold}
  ${Font.h1}
  color: ${ColorConstant.GRAY10};
`;

export const fieldValue = css`
  ${FontWeight.normal}
  ${Font.md}
  color: ${ColorConstant.GRAY8};
  ${TextAlign.center}
`;

export const offerLogo = css`
  width: 32px;
  height: 32px;
  ${Spacing.mr16}
`;

export const offerOrderValues = cx(
  fieldLabel,
  css`
    ${TextAlign.right}
  `,
);

export const infoLogo = {
  width: 16,
  height: 16,
  marginRight: 8,
};

export const container = css`
  ${Spacing.px64}
  ${Spacing.py40}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.p20}
  }
`;

export const fieldsContainer = css`
  ${Spacing.mb24}
`;

export const totalInvestment = css`
  ${Font.h1}
  ${TextAlign.center}
  ${Spacing.mb40}
  ${Spacing.mt24}
  color: ${ColorConstant.GRAY8};
`;

export const totalInvestmentValue = css`
  ${FontWeight.bold}
  ${Spacing.pl8}
`;

export const investorTypeTooltip = css`
  color: ${ColorConstant.BRAND6};
  ${Font.sm}
  ${Spacing.mt12}
  ${Spacing.mb20}
  ${Spacing.ml24}
  ${Display.block}
  ${AlignItems.center}
  cursor: pointer;
`;

export const accreditedInvestor = css`
  color: ${ColorConstant.BRAND6};
  ${Font.sm}
  ${Spacing.mt12}
  ${Display.flex}
  ${AlignItems.center}
  cursor: 'pointer';
`;

export const nonAccreditContainer = css`
  ${Spacing.mt40}
`;

export const restrictedPersonText = css`
  color: ${ColorConstant.BRAND6};
  ${Font.sm}
  ${Spacing.mt12}
  ${Spacing.mb40}
  ${Display.flex}
  ${AlignItems.center}
  cursor: pointer;
`;
