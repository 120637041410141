import React, { useEffect, useState } from 'react';

import { Col, Row, Space } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearConfirmCardPayment,
  clearCreateOfferOrder,
  clearDeleteOfferOrder,
  clearSubmitCardPayment,
  deleteOfferOrder,
  removeOfferOrderIntent,
} from 'src/actions';
import { UserDto } from 'src/dtos';
import { SecurityDeliveryOption } from 'src/lib';
import { OfferDetails, OfferOrder, OfferOrderIntent } from 'src/models';
import { v4 as uuidv4 } from 'uuid';

import { ConfirmCardPaymentErrorCause } from '../../../../../constants/common.constants';
import { ConfirmCardPaymentError } from '../../../../../errors/ConfirmCardPaymentError';
import { CurrencyRangeField } from '../../../../../lib/CurrencyRangeField/CurrencyRangeField';
import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import CurrencyField from '../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import NumberField from '../../../../../lib/Miscellaneous/FormattedFields/NumberField/NumberField';
import ListRow from '../../../../../lib/Miscellaneous/ListRow/ListRow';
import { Images } from '../../../../../styles';
import * as Styles from '../components/ConfirmOrder/ConfirmOrder.styles';

export const NewOfferOrderFailed = () => {
  const dispatch = useDispatch();
  const user: UserDto = useSelector((state: any) => state.user.authenticated.data);
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const offerOrder: OfferOrder | undefined = useSelector((state: any) => state.offerOrders.create.data);
  const createOfferOrderSucceeded = useSelector((state: any) => Boolean(state.offerOrders.create.__succeeded));

  const confirmCardPaymentError: ConfirmCardPaymentError | undefined = useSelector(
    (state: any) => state.offerOrders.confirmCardPayment.error,
  );

  const [confirmOrderFields, setConfirmOrderFields] = useState<any[]>([]);

  const renderErrorMessage = (error: ConfirmCardPaymentError) => {
    if (error.cause === ConfirmCardPaymentErrorCause.AUTHENTICATION_FAILURE) {
      return (
        <>
          Your credit card payment was declined due to failed authentication. If you would like to try again, please
          re-enter an Order with the same payment information or try a different payment method. Please contact your
          credit card company and/or My IPO at <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a> if
          you continue to experience problems.
        </>
      );
    }

    return error.message;
  };

  useEffect(() => {
    const data: any[] = [
      { label: 'Investor Name', value: `${user.firstName} ${user.lastName}` },
      {
        label: 'Address',
        value: `${user.account?.primaryAccountHolder?.physicalAddress?.address1}, ${user.account?.primaryAccountHolder?.physicalAddress?.city}, ${user.account?.primaryAccountHolder?.physicalAddress?.postalCode}, ${user.account?.primaryAccountHolder?.physicalAddress?.country}`,
      },
      { label: 'Date', value: moment(new Date().toISOString()).format('MM/DD/YYYY') },
    ];

    if (offerOrderIntent.isConditional) {
      data.push({
        label: 'Share Range',
        value: <CurrencyRangeField min={offerOrderIntent.minSharePrice} max={offerOrderIntent.maxSharePrice} />,
      });
    } else {
      data.push(
        {
          label: 'Share Price',
          value: <CurrencyField value={offerOrderIntent.price} />,
        },
        {
          label: 'Share Quantity',
          value: <NumberField value={offerOrderIntent.quantity} />,
        },
      );
    }

    if (offerOrderIntent.isExternal && offerOrderIntent?.securityDeliveryOption) {
      data.push({
        label: 'Security Delivery Option',
        value: (
          <SecurityDeliveryOption
            label={offerOrderIntent.securityDeliveryOption.label}
            tooltip={offerOrderIntent.securityDeliveryOption.tooltip}
          />
        ),
      });
    }

    setConfirmOrderFields(data);
  }, []);

  useEffect(() => {
    if (createOfferOrderSucceeded && offerOrder?.paymentType.isCard) {
      dispatch(deleteOfferOrder({ params: { id: offerOrder.id } }));
    }
  }, [createOfferOrderSucceeded, offerOrder]);

  useEffect(() => {
    window.gtag('event', 'offer_order_error', {
      offer_id: offerDetails.id,
      offer_name: offerDetails.name,
      account_id: offerOrderIntent.accountId,
      offer_order_payment_type: offerOrderIntent.paymentType?.value,
      offer_order_quantity: offerOrderIntent.quantity,
      offer_order_value: offerOrderIntent.totalInvestment, // TODO: add the error message
    });

    return () => {
      dispatch(removeOfferOrderIntent());
      dispatch(clearCreateOfferOrder());
      dispatch(clearDeleteOfferOrder());
      dispatch(clearConfirmCardPayment());
      dispatch(clearSubmitCardPayment());
    };
  }, []);

  return (
    <NewOfferOrderLayout step='create-failed' offerId={offerDetails.id}>
      <Row
        style={{
          flexDirection: 'column',
          marginBottom: 24,
        }}>
        <Col span={24} className={Styles.submissionStatus}>
          <Space size={8} direction='vertical'>
            <i className={'ri-error-warning-line'}></i>
            <span>Submission Failed</span>
          </Space>
        </Col>
        {confirmCardPaymentError && (
          <Col span={24} className={Styles.submissionFailedStatus}>
            <span>{renderErrorMessage(confirmCardPaymentError)}</span>
          </Col>
        )}
      </Row>
      <Row className={Styles.rowStyle}>
        <img
          className={Styles.offerLogo}
          src={offerDetails.logoUrl || Images.DefaultOffering}
          alt={`${offerDetails.name}_logo`}
        />
        <span className={Styles.offerName}>{offerDetails.name}</span>
      </Row>

      <div className={Styles.fieldsContainer}>
        {confirmOrderFields?.map((item: any) => (
          <React.Fragment key={uuidv4()}>
            <ListRow
              customRowStyle={{ borderBottom: 'none' }}
              leftComponent={<div className={Styles.fieldLabel}>{item.label}</div>}
              rightComponent={<div className={Styles.fieldValue}>{item.value}</div>}
            />
          </React.Fragment>
        ))}
        <div className={Styles.totalInvestment}>
          <span>Total Investment</span>
          <CurrencyField value={offerOrderIntent.totalInvestment} className={Styles.totalInvestmentValue} />
        </div>
      </div>
    </NewOfferOrderLayout>
  );
};
