import accountDetails from './accountDetails';
import { registerAccountSagas } from './accounts.sagas';
import cashieringSaga from './cashiering';
import configs from './configs';
import investigations from './investigations';
import offerOrders from './offerOrders.sagas';
import offersSaga from './offers.sagas';
import offerWatches from './offerWatches.sagas';
import orders from './orders.sagas';
import quoteMedia from './quoteMedia';
import registerStatementsSagas from './statements.sagas';
import user from './users.sagas';

/**
 * Register all sagas
 */
export default [
  user,
  registerAccountSagas,
  registerStatementsSagas,
  accountDetails,
  cashieringSaga,
  offersSaga,
  investigations,
  configs,
  quoteMedia,
  orders,
  offerOrders,
  offerWatches,
];
