import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

export const getAccount = (state: any = initialState.accounts.item, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_ACCOUNT):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_ACCOUNT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_ACCOUNT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_ACCOUNT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};
export const accountsReducers = combineReducers({
  item: getAccount,
});
