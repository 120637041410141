export const fieldLabels = [
  { field: 'tradesPerYear', label: 'Trades Per Year' },
  { field: 'yearsOfExperience', label: 'Years of Experience' },
  { field: 'stocks', label: 'Stocks / ETFs' },
  { field: 'margins', label: 'Margin' },
  { field: 'options', label: 'Options Experience' },
  { field: 'commodities', label: 'Commodities Experience' },
  { field: 'bonds', label: 'Bonds Experience' },
  { field: 'stakeholder', label: 'StakeHolder' },
  { field: 'industryEmployed', label: 'Industry Employed' },
  { field: 'tickerSymbol', label: 'Company Ticker Symbol' },
  { field: 'firmName', label: 'Firm Name' },
];
