import { useEffect, useState } from 'react';

import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { doAddRedirectToEmailConfirm } from 'src/actions';
import { UrlSearchParameterNotFoundError } from 'src/errors/UrlSearchParameterNotFoundError';

import * as Styles from './UnauthenticatedEmailConfirm.styles';

export const UnauthenticatedEmailConfirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const confirm = searchParams.get('confirm');
  const updateId = searchParams.get('id');
  const [paramError, setParamError] = useState<UrlSearchParameterNotFoundError | null>(null);

  useEffect(() => {
    if (!updateId) {
      setParamError(
        new UrlSearchParameterNotFoundError({
          name: 'id',
        }),
      );

      return;
    }

    if (!confirm) {
      setParamError(
        new UrlSearchParameterNotFoundError({
          name: 'confirm',
        }),
      );

      return;
    }

    dispatch(doAddRedirectToEmailConfirm({ confirm, updateId: Number(updateId) }));
    navigate('/login');
  }, []);

  const renderContent = () => {
    if (paramError) {
      return <p>{paramError.message}</p>;
    }

    return (
      <>
        <p>Redirecting to Login Page...</p>
        <Spin />
      </>
    );
  };

  return <div className={Styles.container}>{renderContent()}</div>;
};
