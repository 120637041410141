import { DropdownItemType } from '../lib/FormComponents/Dropdown/constants';
import { RadioGroupValue } from '../lib/FormComponents/RadioGroup/constants';

export const maritalStatus: Array<DropdownItemType> = [
  { text: 'Single', value: 'single' },
  { text: 'Married', value: 'married' },
  { text: 'Divorced', value: 'divorced' },
  { text: 'Widowed', value: 'widowed' },
];

export const AnnualIncomeType = [
  {
    text: '$0K-$25K',
    value: 'lessThan25K',
  },
  {
    text: '$25K-$50K',
    value: 'between25KAnd50K',
  },

  {
    text: '$50K-$100K',
    value: 'between50KAnd100K',
  },

  {
    text: '$100K-$200K',
    value: 'between100KAnd200K',
  },

  {
    text: '$200K-$300K',
    value: 'between200KAnd300K',
  },

  {
    text: '$300K-$500K',
    value: 'between300KAnd500K',
  },
  {
    text: '$500K-$1.2M',
    value: 'between500KAnd1Point2M',
  },
  {
    text: '$1.2M+',
    value: 'moreThan1Point2M',
  },
];

export const citizenshipOptions = [
  {
    text: 'U.S. Citizen',
    value: 'USA',
  },
  //TODO: might need later
  // {
  //   text: 'Resident Alien',
  //   value: 'ra',
  // },
];

export const TotalNetWorthType = [
  {
    text: '$0K-$50K',
    value: 'lessThan50K',
  },

  {
    text: '$50K-$100K',
    value: 'between50KAnd100K',
  },

  {
    text: '$100K-$200K',
    value: 'between100KAnd200K',
  },

  {
    text: '$200K-$500K',
    value: 'between200KAnd500K',
  },

  {
    text: '$500K-$1M',
    value: 'between500KAnd1M',
  },
  {
    text: '$1M-$5M',
    value: 'between1MAnd5M',
  },
  {
    text: '$5M+',
    value: 'moreThan5M',
  },
];

export const LiquidNetWorthType = [
  {
    text: '$0K-$50K',
    value: 'lessThan50K',
  },
  {
    text: '$50K-$100K',
    value: 'between50KAnd100K',
  },

  {
    text: '$100K-$200K',
    value: 'between100KAnd200K',
  },

  {
    text: '$200K-$500K',
    value: 'between200KAnd500K',
  },

  {
    text: '$500K-$1M',
    value: 'between500KAnd1M',
  },
  {
    text: '$1M-$5M',
    value: 'between1MAnd5M',
  },
  {
    text: '$5M+',
    value: 'moreThan5M',
  },
];

export const YearsEmployed = [
  { text: 'Less than 1 years', value: 'betweenZeroAndOne' },
  { text: '2 to 5 years', value: 'betweenTwoAndFive' },
  { text: '5 to 10 years', value: 'betweenFiveAndTen' },
  { text: '10 to 20 years', value: 'betweenTenAndTwenty' },
  { text: 'More than 20 years', value: 'moreThanTwenty' },
];

export const EmploymentType = [
  { text: 'Employed', value: 'employed' },
  { text: 'Retired', value: 'retired' },
  { text: 'Student', value: 'student' },
  { text: 'Unemployed', value: 'unemployed' },
];

export const TaxBracket = [
  { text: '10-19%', value: 'bottom' },
  { text: '20-29%', value: 'middle' },
  { text: '30-37%', value: 'top' },
];

export const phones = [
  { text: 'home', value: 'homeNumber' },
  { text: 'mobile', value: 'mobileNumber' },
  { text: 'work', value: 'workNumber' },
];

export const states = [
  { text: 'Alabama', value: 'AL' },
  { text: 'Alaska', value: 'AK' },
  { text: 'Arizona', value: 'AZ' },
  { text: 'Arkansas', value: 'AR' },
  { text: 'California', value: 'CA' },
  { text: 'Colorado', value: 'CO' },
  { text: 'Connecticut', value: 'CT' },
  { text: 'Delaware', value: 'DE' },
  { text: 'District of Columbia', value: 'DC' },
  { text: 'Florida', value: 'FL' },
  { text: 'Georgia', value: 'GA' },
  { text: 'Hawaii', value: 'HI' },
  { text: 'Idaho', value: 'ID' },
  { text: 'Illinois', value: 'IL' },
  { text: 'Indiana', value: 'IN' },
  { text: 'Iowa', value: 'IA' },
  { text: 'Kansas', value: 'KS' },
  { text: 'Kentucky', value: 'KY' },
  { text: 'Louisiana', value: 'LA' },
  { text: 'Maine', value: 'ME' },
  { text: 'Maryland', value: 'MD' },
  { text: 'Massachusetts', value: 'MA' },
  { text: 'Michigan', value: 'MI' },
  { text: 'Minnesota', value: 'MN' },
  { text: 'Mississippi', value: 'MS' },
  { text: 'Missouri', value: 'MO' },
  { text: 'Montana', value: 'MT' },
  { text: 'Nebraska', value: 'NE' },
  { text: 'Nevada', value: 'NV' },
  { text: 'New Hampshire', value: 'NH' },
  { text: 'New Jersey', value: 'NJ' },
  { text: 'New Mexico', value: 'NM' },
  { text: 'New York', value: 'NY' },
  { text: 'North Carolina', value: 'NC' },
  { text: 'North Dakota', value: 'ND' },
  { text: 'Ohio', value: 'OH' },
  { text: 'Oklahoma', value: 'OK' },
  { text: 'Oregon', value: 'OR' },
  { text: 'Pennsylvania', value: 'PA' },
  { text: 'Puerto Rico', value: 'PR' },
  { text: 'Rhode Island', value: 'RI' },
  { text: 'South Carolina', value: 'SC' },
  { text: 'South Dakota', value: 'SD' },
  { text: 'Tennessee', value: 'TN' },
  { text: 'Texas', value: 'TX' },
  { text: 'Utah', value: 'UT' },
  { text: 'Vermont', value: 'VT' },
  { text: 'Virginia', value: 'VA' },
  { text: 'Virgin Islands, U.S.', value: 'VI' },
  { text: 'Washington', value: 'WA' },
  { text: 'West Virginia', value: 'WV' },
  { text: 'Wisconsin', value: 'WI' },
  { text: 'Wyoming', value: 'WY' },
];

export const LiquidityNeeds = [
  {
    text: 'Very Important',
    value: 'veryImportant',
  },
  {
    text: 'Somewhat Important',
    value: 'somewhatImportant',
  },
  {
    text: 'Not Important',
    value: 'notImportant',
  },
];

export const InvestmentExperience = [
  {
    value: 'margins',
    text: 'Margin',
    subLabel: undefined,
  },
  {
    value: 'stocks',
    text: 'Stocks / ETFs',
    subLabel: undefined,
  },
  {
    value: 'options',
    text: 'Options Experience',
    subLabel: undefined,
  },
  {
    value: 'commodities',
    text: 'Commodities Experience',
    subLabel: undefined,
  },
  {
    value: 'bonds',
    text: 'Bonds Experience',
    subLabel: undefined,
  },
];

export const ExperienceAndTradesPerYear = [
  {
    name: 'yearsOfExperience',
    label: 'Years Of Experience',
  },
  { name: 'tradesPerYear', label: 'Trades/Year' },
];

export const IndividualRepresentationsCheckboxes = [
  {
    value: 'stakeholder',
    text: 'Are you a 10% shareholder, director and/or policy maker of a publicly-owned company?',
  },
  {
    value: 'industryEmployed',
    text: 'Are you employed by or a member of a registered broker-dealer, securities or futures exchange, or securities industry regulatory body (e.g., FINRA, NFA)?',
  },
];

export const IndividualRepresentationsSubFields = [
  {
    name: 'tickerSymbol',
    label: 'Company Ticker Symbol(s)',
  },
  { name: 'firmName', label: 'Firm Name' },
];

export const StatementDelivery: Array<RadioGroupValue> = [
  {
    value: 'electronic',
    text: 'Electronic (Free)',
    tooltip: '',
  },
  {
    value: 'paper',
    text: 'Paper (Fees apply)',
    tooltip:
      'Trade Confirmations: $2.00 per confirm Monthly Statement: $5.00 per statement Tax Document: $5.00 per document',
  },
];

export const RiskTolerance: Array<RadioGroupValue> = [
  {
    value: 'low',
    text: 'Low',
    subLabel:
      'I want to preserve my initial principal in this account, with minimal risk, even if that means this account does not generate significant income or returns and may not keep pace with inflation.',
  },
  {
    value: 'medium',
    text: 'Medium',
    subLabel:
      'I am willing to accept some risk to my initial principal and tolerate some volatility to seek higher returns and understand I could lose a portion of the money invested.',
  },
  {
    value: 'high',
    text: 'High',
    subLabel:
      'I am willing to accept the maximum risk to my initial principal to aggressively seek maximum returns and understand I could lose most, or all, of the money invested.',
  },
];

export const InvestmentObjective: Array<RadioGroupValue> = [
  {
    value: 'capitalPreservation',
    text: 'Capital Preservation',
    subLabel: 'Preservation of capital with primary consideration on income.',
    warning:
      'Capital Preservation has Low Risk Tolerance.  In order to qualify for My IPO Offers your Investment Objectives must be "Growth" or "Speculation"',
  },
  {
    value: 'income',
    text: 'Income',
    subLabel: 'A balance between capital appreciation and income with a primary consideration being income.',
    warning:
      'Income has Low Risk Tolerance.  In order to qualify for My IPO Offers your Investment Objectives must be "Growth" or "Speculation"',
  },
  {
    value: 'growthAndIncome',
    text: 'Growth and Income',
    subLabel:
      'Dual Investment strategy that seeks both capital appreciation and current income generated through dividends or interest.',
    warning:
      'Growth and Income has Medium Risk Tolerance.  In order to qualify for My IPO Offers your Investment Objectives must be "Growth" or "Speculation"',
  },
  {
    value: 'growth',
    text: 'Growth',
    subLabel: 'Capital appreciation through quality equity investment and little or no income.',
  },
  {
    value: 'speculation',
    text: 'Speculation',
    subLabel: 'Maximum capital appreciation with higher risk and little to no income',
  },
];

export const TimeHorizon: Array<RadioGroupValue> = [
  {
    value: 'short',
    text: 'Short',
    subLabel: 'I plan on keeping this account open and investing for less than 3 years to achieve my financial goals.',
    warning: 'In order to qualify for My IPO Offers your Time Horizon must be "Average" or "Longest"',
  },
  {
    value: 'average',
    text: 'Average',
    subLabel:
      'I plan on keeping this account open and investing for more than 4 years, but less than 7 years to achieve my financial goals',
  },
  {
    value: 'long',
    text: 'Long',
    subLabel:
      'I plan on keeping this account open and investing for more than 8+ years to achieve my financial goals. ',
  },
];

export const DecisionMaking: Array<RadioGroupValue> = [
  {
    value: 'consult_with_broker',
    text: 'I consult with my broker, investment adviser, CPA, or other financial professional.',
  },
  {
    value: 'my_own_decision_or_consult',
    text: 'I generally make my own decisions and/or consult with my co-applicant(s).',
  },
  {
    value: 'discuss_with_family',
    text: 'I discuss investment decisions with family and/or friends.',
  },
];

export const InvestmentRange: Array<RadioGroupValue> = [
  {
    value: 'less_than_1_3',
    text: 'Less than 1/3 of my financial portfolio',
  },
  {
    value: '1_3_to_2_3',
    text: 'Roughly 1/3 to 2/3 of my financial portfolio',
  },
  {
    value: 'more_than_2_3',
    text: 'More than 2/3 of my financial portfolio',
  },
];

export const FundingSource: Array<RadioGroupValue> = [
  {
    value: 'income',
    text: 'Income',
  },
  {
    value: 'pensionOrRetirement',
    text: 'Pension or retirement savings',
  },
  {
    value: 'fundsFromAnother',
    text: 'Funds from another account',
  },
  {
    value: 'gift',
    text: 'Gift',
  },
  {
    value: 'saleOfBusiness',
    text: 'Sale of business or property',
  },
  {
    value: 'insurancePayout',
    text: 'Insurance payout',
  },
  {
    value: 'inheritance',
    text: 'Inheritance',
  },
  {
    value: 'socialSecurityBenefits',
    text: 'Social Security benefits',
  },
  {
    value: 'homeEquityLineOfCredit',
    text: 'Home Equity Line of Credit/Reverse Mortgage',
  },
  {
    value: 'other',
    text: 'Other',
  },
];

export const YearsOfExperience = [
  { text: '1', value: 'One' },
  { text: '2-3', value: 'TwoToThree' },
  { text: '4-9', value: 'FourToNine' },
  { text: '10+', value: 'TenOrMore' },
];

export const TradesPerYear = [
  { text: '1-5', value: 'OneToFive' },
  { text: '6-10', value: 'SixToTen' },
  { text: '11-20', value: 'ElevenToTwenty' },
  { text: '20+', value: 'MoreThanTwenty' },
];
