import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import App from './App';
import './index.css';
import 'remixicon/fonts/remixicon.css';
import { ToastMessages } from './lib/Miscellaneous/ToastMessages/ToastMessages';
import reportWebVitals from './reportWebVitals';
import store, { persistedStore } from './store';

function registerLegacyServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(
        registration => {
          // eslint-disable-next-line no-console
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        },
        err => {
          // eslint-disable-next-line no-console
          console.log('ServiceWorker registration failed: ', err);
        },
      );
    });
  }
}

// function unregisterLegacyServiceWorker() {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.ready.then(registration => {
//       registration.unregister();
//     });
//     caches
//       .keys()
//       .then(cacheNames => {
//         return Promise.all(
//           cacheNames.map(cacheName => {
//             return caches.delete(cacheName).then(() => {
//               // eslint-disable-next-line no-console
//               console.log(`Cache ${cacheName} deleted`);
//             });
//           }),
//         );
//       })
//       .catch(error => {
//         // eslint-disable-next-line no-console
//         console.error('Error deleting caches:', error);
//       });
//   }
// }

function ApplicationRender() {
  return (
    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistedStore}>
        <App />
        <ToastMessages />
      </PersistGate>
    </Provider>
  );
}
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<ApplicationRender />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerLegacyServiceWorker();
// unregisterLegacyServiceWorker();
