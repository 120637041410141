import React, { useEffect, useState } from 'react';

import { Button, Space } from 'antd';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AuthenticatedUserLoginResponseDto } from 'src/dtos';
import {
  renderTradingBlockSamePasswordErrorMessage,
  renderTradingBlockPasswordExpirationErrorMessage,
} from 'src/utils';
import { v4 as uuid } from 'uuid';

import {
  confirmMfaCode,
  logout,
  resendMfaCode,
  clearConfirmMfaCode,
  clearResendMfaCode,
  toastMessagesAdd,
  toastMessagesRemove,
  setAuthentication,
} from '../../../actions';
import Checkbox from '../../../lib/FormComponents/CheckBox';
import { InputType } from '../../../lib/FormComponents/Input/constants';
import Input from '../../../lib/FormComponents/Input/Input';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { CardLayout } from '../../../lib/Layout/CardLayout/CardLayout';
import { SeverityEnum } from '../../../typings/commonTypes';
import * as Styles from '../BaseLayout.styles';

import { loginMFAValidationSchema } from './validations';

export const LoginMfa = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const email = useSelector((state: any) => state.auth.data?.email);
  const password = useSelector((state: any) => state.auth.data?.password);
  const phoneNumber = useSelector((state: any) => state.auth.data?.phoneNumber);
  const [loginMfaErrorToastId, setLoginMfaErrorToastId] = useState<string | null>(null);

  const failedConfirmMfaCodeMessage = useSelector((state: any) => state.user.confirmMfaCode.message);
  const isConfirmMfaCodeLoading = useSelector((state: any) => Boolean(state.user.confirmMfaCode.__requested));
  const confirmMfaCodeSucceeded = useSelector((state: any) => Boolean(state.user.confirmMfaCode.__succeeded));
  const confirmMfaData: AuthenticatedUserLoginResponseDto | undefined = useSelector(
    (state: any) => state.user.confirmMfaCode.data,
  );

  const isResendMfaCodeLoading = useSelector((state: any) => Boolean(state.user.resendMfaCode.__requested));

  const removeLoginToastMessageIfFound = () => {
    if (loginMfaErrorToastId) {
      dispatch(toastMessagesRemove({ key: setLoginMfaErrorToastId }));
    }
  };

  const onSubmit = (_values: any) => {
    removeLoginToastMessageIfFound();
    dispatch(confirmMfaCode(_values));
  };

  const onResetPasswordRequest = () => {
    navigate('/forgot-password');
  };

  useEffect(() => {
    if (failedConfirmMfaCodeMessage?.error === 'TradingBlockSamePasswordError') {
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderTradingBlockSamePasswordErrorMessage({ onClick: onResetPasswordRequest }),
        }),
      );
      setLoginMfaErrorToastId(toastId);
    }

    if (failedConfirmMfaCodeMessage?.error === 'TradingBlockPasswordExpirationError') {
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderTradingBlockPasswordExpirationErrorMessage({ onClick: onResetPasswordRequest }),
        }),
      );
      setLoginMfaErrorToastId(toastId);
    }
  }, [failedConfirmMfaCodeMessage]);

  useEffect(() => {
    if (confirmMfaCodeSucceeded && !isEmpty(confirmMfaData)) {
      window.gtag('event', 'login', {
        login_method: 'mfa',
        user_properties: {
          user_id: confirmMfaData.user.id,
          user_email: confirmMfaData.user.email,
          user_first_name: confirmMfaData.user.firstName,
          user_last_name: confirmMfaData.user.lastName,
        },
      });
      const { accessToken, idToken, tbToken } = confirmMfaData;
      const authToken = `Bearer ${accessToken}; idToken ${idToken}; tbToken ${tbToken}`;
      dispatch(setAuthentication({ authToken, tbToken }));

      navigate('/');

      return;
    }
  }, [confirmMfaCodeSucceeded]);

  useEffect(() => {
    return () => {
      dispatch(clearConfirmMfaCode());
      dispatch(clearResendMfaCode());
      removeLoginToastMessageIfFound();
    };
  }, []);

  return (
    <CardLayout subtitle='In order to Invest in an Offer, you will first need to “Sign Up” or “Log In” to My IPO'>
      <Formik
        initialValues={{
          code: '',
          rememberDevice: false,
        }}
        validationSchema={loginMFAValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => {
          onSubmit(values);
        }}>
        {form => {
          return (
            <>
              <div className={Styles.formTitle}>Two-factor authentication</div>
              {phoneNumber && (
                <p className={Styles.description}>
                  We sent a six-digit security code to {`XXX - XXX - ${phoneNumber.substr(phoneNumber.length - 4)}`}.
                  Please enter the code in the below box.
                </p>
              )}

              <Input
                name='code'
                tabIndex={0}
                type={InputType.Number}
                maxLength={6}
                value={form.values.code}
                {...form}
                placeholder='Enter Code'
              />

              <Checkbox
                name='rememberDevice'
                label='Remember this device'
                disabled
                value={form.values.rememberDevice}
                {...form}
                hidden
              />

              <Button
                className={Styles.resendCodeButton}
                type='link'
                disabled={isResendMfaCodeLoading || isConfirmMfaCodeLoading}
                loading={isResendMfaCodeLoading}
                onClick={() => {
                  removeLoginToastMessageIfFound();
                  dispatch(resendMfaCode({ email, password }));
                  form.resetForm();
                }}>
                Resend Code
              </Button>

              <Space size={8} direction='horizontal' align='center' className={Styles.buttonsContainer}>
                <MButton
                  type='tertiary'
                  onClick={() => {
                    dispatch(logout());
                    navigate('/login');
                  }}>
                  Back
                </MButton>
                <MButton
                  type='secondary'
                  disabled={isConfirmMfaCodeLoading || isResendMfaCodeLoading || !form.isValid}
                  loading={isConfirmMfaCodeLoading}
                  onClick={() => {
                    form.submitForm();
                  }}>
                  Verify
                </MButton>
              </Space>
            </>
          );
        }}
      </Formik>
    </CardLayout>
  );
};
