import { css } from '@emotion/css';

import {
  AlignItems,
  ColorConstant,
  Display,
  Font,
  FontFamily,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
} from '../../../../../styles';

export const fieldLabel = css`
  ${FontFamily.sourceSansPro}
  ${FontWeight.normal}
  ${Font.lg}
  color: ${ColorConstant.GRAY8};
`;

export const cashAvailableLabelContainer = css`
  ${Display.flex}
`;

export const fieldValue = css`
  ${FontFamily.sourceSansPro}
  ${FontWeight.normal}
  ${Font.lg}
  color: ${ColorConstant.GRAY8};
  ${TextAlign.right}
`;

export const rowStyle = css`
  ${JustifyContent.center}
  ${AlignItems.center}
  ${Spacing.mb12}
  height: 3.5rem;
`;

export const offerName = css`
  ${FontFamily.sourceSansPro}
  ${FontWeight.bold}
  ${Font.h1}
  color: ${ColorConstant.GRAY10};
`;

export const policy = css`
  ${Spacing.mt16}
  color: ${ColorConstant.GRAY7};
`;

export const totalInvestment = css`
  ${Font.lg}
  color: ${ColorConstant.GRAY8};
  ${TextAlign.center}
  ${Spacing.mb40}
  ${Spacing.mt24}
`;

export const container = css`
  ${Spacing.px64}
  ${Spacing.py40}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.p20}
  }
`;

export const offerLogo = css`
  width: 32px;
  height: 32px;
  ${Spacing.mr20}
`;

export const fieldsContainer = css`
  ${Spacing.mb24}
`;

export const totalInvestmentValue = css`
  ${FontWeight.bold}
  ${Spacing.pl12}
`;

export const linkStyle = css`
  color: ${ColorConstant.BRAND6};
  ${FontWeight.bold}
  cursor: pointer;
`;

export const termsAndConditions = css`
  ${Spacing.mt24}
  ${Spacing.mb8}
  ${TextAlign.center}
  ${JustifyContent.center}
  ${Font.md}
  color: ${ColorConstant.GRAY7};
`;

export const buttonsContainer = css`
  ${Spacing.mt24}
`;
