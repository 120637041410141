import { getCurrencyFormatter } from 'src/utils';
import * as Yup from 'yup';

export const standardOfferValidation = ({
  pricePerShare,
  minimumInvestCurrencyAmt,
  allowRestrictedPersons,
}: {
  pricePerShare: number;
  minimumInvestCurrencyAmt: number;
  allowRestrictedPersons: boolean;
}) => {
  let schema = Yup.object().shape({
    quantity: Yup.string()
      .required('Quantity is required.')
      .test(
        'compare',
        `The minimum investment quantity is ${Math.ceil(minimumInvestCurrencyAmt / pricePerShare)}`,
        function (quantity: string | any) {
          if (quantity !== undefined && quantity !== null) {
            return Number(quantity) * pricePerShare >= minimumInvestCurrencyAmt;
          } else return false;
        },
      ),
  });

  if (!allowRestrictedPersons) {
    schema = schema.concat(
      Yup.object().shape({
        isNotRestrictedPerson: Yup.boolean()
          .test('isTrue', 'The offer does not allow investing from restricted persons', value => value === true)
          .required('Restricted Person is required.'),
      }),
    );
  }

  return schema;
};

export const conditionalOfferOrderValidation = ({
  minimumInvestCurrencyAmt,
  allowRestrictedPersons,
}: {
  minimumInvestCurrencyAmt: number;
  allowRestrictedPersons: boolean;
}) => {
  let schema = Yup.object().shape({
    totalInvestment: Yup.string()
      .required('Total Investment is required.')
      .test(
        'compare',
        `The minimum investment amount is ${getCurrencyFormatter().format(Math.ceil(minimumInvestCurrencyAmt))}`,
        function (value: string | any) {
          if (value !== undefined && value !== null) {
            return Number(value) >= minimumInvestCurrencyAmt;
          } else return false;
        },
      ),
  });

  if (!allowRestrictedPersons) {
    schema = schema.concat(
      Yup.object().shape({
        isNotRestrictedPerson: Yup.boolean()
          .test('isTrue', 'The offer does not allow investing from restricted persons', value => value === true)
          .required('Restricted Person is required.'),
      }),
    );
  }

  return schema;
};
