export interface Address {
  address1: string;
  address2?: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
}

// Financial information models
export enum AnnualIncome {
  LessThan25K = 'lessThan25K',
  Between25KAnd50K = 'between25KAnd50K',
  Between50KAnd100K = 'between50KAnd100K',
  Between100KAnd200K = 'between100KAnd200K',
  Between200KAnd300K = 'between200KAnd300K',
  Between300KAnd500K = 'between300KAnd500K',
  Between500KAnd1Point2M = 'between500KAnd1Point2M',
  MoreThan1Point2M = 'moreThan1Point2M',
}

export enum TotalNetWorth {
  LessThan50K = 'lessThan50K',
  Between50KAnd100K = 'between50KAnd100K',
  Between100KAnd200K = 'between100KAnd200K',
  Between200KAnd500K = 'between200KAnd500K',
  Between500KAnd1M = 'between500KAnd1M',
  Between1MAnd5M = 'between1MAnd5M',
  MoreThan5M = 'moreThan5M',
}

export enum LiquidNetWorth {
  LessThan50K = 'lessThan50K',
  Between50KAnd100K = 'between50KAnd100K',
  Between100KAnd200K = 'between100KAnd200K',
  Between200KAnd500K = 'between200KAnd500K',
  Between500KAnd1M = 'between500KAnd1M',
  Between1MAnd5M = 'between1MAnd5M',
  MoreThan5M = 'moreThan5M',
}

export enum TaxBracket {
  Bottom = 'bottom',
  Middle = 'middle',
  Top = 'top',
}

export enum EmploymentStatus {
  Employed = 'employed',
  Retired = 'retired',
  Student = 'student',
  Unemployed = 'unemployed',
}

export enum YearsEmployed {
  BetweenZeroAndOne = 'betweenZeroAndOne',
  BetweenTwoAndFive = 'betweenTwoAndFive',
  BetweenFiveAndTen = 'betweenFiveAndTen',
  BetweenTenAndTwenty = 'betweenTenAndTwenty',
  MoreThanTwenty = 'moreThanTwenty',
}

export interface FinancialInformation {
  nnualIncome?: AnnualIncome;
  totalNetWorth?: TotalNetWorth;
  liquidNetWorth?: LiquidNetWorth;
  taxBracket?: TaxBracket;
  employmentStatus: EmploymentStatus;
  employerName?: string;
  jobTitle?: string;
  yearsEmployed?: YearsEmployed;
  employerAddress?: Address;
}

export interface FinancialInformationRange {
  min: number;
  max: number;
}

export interface FinancialInformationWithRanges {
  annualIncome: FinancialInformationRange;
  totalNetWorth: FinancialInformationRange;
  liquidNetWorth: FinancialInformationRange;
}

export enum AccountHolderMaritalStatusEnum {
  Divorced = 'divorced',
  Married = 'married',
  Single = 'single',
  Widowed = 'widowed',
}
