import { FormFields } from './constants';

export const InitialValues = {
  [FormFields.email]: '',
  [FormFields.firstName]: '',
  [FormFields.lastName]: '',
  [FormFields.phone]: '',
  [FormFields.countryCode]: 'USA',
  [FormFields.password]: '',
  [FormFields.confirmPassword]: '',
};
