import { css } from '@emotion/css';

import { Color } from '../../../../styles';

export const customBanner = css`
  background: ${Color.ORANGE.ORANGE1};
  border: 1px solid ${Color.ORANGE.ORANGE4};
  border-radius: 8px;
  color: ${Color.GRAYSCALE.GRAY7};
  padding: 16px;
  margin-bottom: 16px;
`;

export const bannerTitle = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${Color.GRAYSCALE.GRAY7};
  display: flex;
  align-items: center;
`;

export const bannerText = css`
  line-height: 20px;
  color: ${Color.GRAYSCALE.GRAY7};
  margin-left: 39.5px;
`;

export const infoLogoStyle = {
  fontSize: 21,
  fontWeight: 400,
  marginRight: 17.5,
  marginTop: 3,
  color: Color.ORANGE.ORANGE4,
};
