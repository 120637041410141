import * as Yup from 'yup';

const validationMessages = {
  liquidityNeedsRequired: 'Liquidity Needs is required',
  investmentObjectivesRequired: 'Investment Objectives is required',
  riskToleranceRequired: 'Risk Tolerance is required',
  timeHorizonRequired: 'Time Horizon is required',
  financialExperienceRequired: 'Financial Experience is required',
};

const dropDownsValidation = Yup.object().shape({
  tradesPerYear: Yup.string().required('Required'),
  yearsOfExperience: Yup.string().required('Required'),
});

export const suitabilityInformationValidation = Yup.object().shape({
  liquidityNeeds: Yup.mixed().required(validationMessages.liquidityNeedsRequired).nullable(),
  investmentObjectives: Yup.string().required(validationMessages.investmentObjectivesRequired).nullable(),
  riskTolerance: Yup.string().nullable(),
  timeHorizon: Yup.string().required(validationMessages.timeHorizonRequired).nullable(),
  financialInvestmentExperience: Yup.object()
    .shape({
      margins: Yup.lazy(value => {
        if (value !== undefined) {
          return dropDownsValidation;
        }

        return Yup.mixed().notRequired().default(undefined);
      }),
      stocks: Yup.lazy(value => {
        if (value !== undefined) {
          return dropDownsValidation;
        }

        return Yup.mixed().notRequired().default(undefined);
      }),
      options: Yup.lazy(value => {
        if (value !== undefined) {
          return dropDownsValidation;
        }

        return Yup.mixed().notRequired().default(undefined);
      }),
      bonds: Yup.lazy(value => {
        if (value !== undefined) {
          return dropDownsValidation;
        }

        return Yup.mixed().notRequired().default(undefined);
      }),
      commodities: Yup.lazy(value => {
        if (value !== undefined) {
          return dropDownsValidation;
        }

        return Yup.mixed().notRequired().default(undefined);
      }),
    })
    .notRequired()
    .default(null)
    .nullable(),
});
