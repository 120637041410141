import { css } from '@emotion/css';

import { Color } from '../../../../styles';

export const bankLinkSubDisclaimer = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  color: ${Color.GRAYSCALE.GRAY7};
`;

export const fieldLabel = css`
  font-size: 0.938rem;
  line-height: 1.625rem;
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const finalStepTag = css`
  background: ${Color.GRAYSCALE.GRAY1};
  box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.04), 0rem 0rem 0.125rem rgba(0, 0, 0, 0.06),
    0rem 0rem 0.063rem rgba(0, 0, 0, 0.35);
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: center;
  color: ${Color.GRAYSCALE.GRAY10};
`;
