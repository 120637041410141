import React from 'react';

import { Card, Col, Row, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { UserDto } from 'src/dtos';

import { MButton } from '../../../../lib/FormComponents/MButton/MButton';
import CircleProgressBar from '../../../../lib/Miscellaneous/CircleProgressBar';
import { SpaceWithBreakpoint } from '../../../../lib/SpaceWithBreakpoint/SpaceWithBreakpoint';

import * as Styles from './CompleteAccount.styles';

export const COMPLETE_ACCOUNT_PATH = 'completeAccount';

export const CompleteAccount = () => {
  const navigate = useNavigate();
  const user: UserDto = useSelector((state: any) => state.user.authenticated.data); // TODO: use state.account.selected.id after account reducer is inroduced

  const onCompleteAccount = () => {
    window.gtag('event', 'complete_account');
    navigate(`/accounts/${user.account?.id}/personal-information`);
  };

  return (
    <>
      <Row className={Styles.completeAccountContainer}>
        <Space size={24} direction='vertical' className={Styles.containerWrapper}>
          <Col className={Styles.alignCenter}>
            <span className={Styles.welcomeLabel}>Welcome!</span>
          </Col>
          <Col className={Styles.alignCenter}>
            <Space size={0} direction='vertical'>
              <span className={Styles.welcomeDescription}>
                Thank you for signing up to get access to some of the most exciting IPOs and other Offerings. As a
                registered user we may contact you by email with news and updates on Offerings.
              </span>
              <span className={Styles.welcomeDescription} hidden>
                If you wish to change this, please update your{' '}
                <a
                  onClick={() => alert('This feature is part of next iterations')}
                  className={Styles.communicationPreferencesLink}>
                  Communication Preferences.
                </a>
              </span>
            </Space>
          </Col>
          <Col className={Styles.accountOptions}>
            <Card className={Styles.cardContent}>
              <Row gutter={[, 40]} justify='center' align='middle'>
                <Col span={24}>
                  <CircleProgressBar percent={56} strokeWidth={5} />
                </Col>
                <Col span={24} className={Styles.almostFinished}>
                  <h1>Almost Finished ...</h1>
                  <Row>
                    <Col span={24}>
                      You can start viewing Offers now, but in order to invest you need to complete the account set up.
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <SpaceWithBreakpoint size={8}>
                    <MButton
                      testId={'summary-btn-complete-account'}
                      className={Styles.button}
                      onClick={onCompleteAccount}
                      type='secondary'>
                      Complete Account
                    </MButton>
                    <MButton
                      className={Styles.button}
                      onClick={() => navigate(`/offers`)}
                      testId={'summary-btn-view-offers'}>
                      View Offers
                    </MButton>
                  </SpaceWithBreakpoint>
                </Col>
              </Row>
            </Card>
          </Col>
        </Space>
      </Row>
    </>
  );
};
