import { OfferOrderSecurityDeliveryOptionDto, OfferExternalSecurityDeliveryOptionDto } from 'src/dtos';

import { MTooltip } from '../MTooltip/MTooltip';

export const SecurityDeliveryOption = ({
  label,
  tooltip = '',
}: OfferExternalSecurityDeliveryOptionDto | OfferOrderSecurityDeliveryOptionDto) => {
  if (tooltip) {
    const startIndex = label.indexOf('<tooltip>');
    const endIndex = label.indexOf('</tooltip>');
    const beforeTooltip = label.substring(0, startIndex);
    const betweenTooltip = label.substring(startIndex + '<tooltip>'.length, endIndex);
    const afterTooltip = label.substring(endIndex + '</tooltip>'.length);

    return (
      <div>
        {beforeTooltip}{' '}
        <MTooltip title={tooltip} placement='top' text='underline' showIcon={false}>
          {betweenTooltip}
        </MTooltip>{' '}
        {afterTooltip}
      </div>
    );
  }

  return <>{label}</>;
};
