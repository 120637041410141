import React from 'react';

import { ContextCardEnumTypes } from '../BankAccountsAndFunding/constants';
import BankLinkCreate from '../BankLinkCreate/BankLinkCreate';
import BankLinkSubDisclaimer from '../BankLinkSubDisclaimer/BankLinkSubDisclaimer';
import EditNickname from '../EditNickname/EditNickname';

export interface CashieringModalContentProps {
  form: any;
  data: any;
  contextCardVisible: ContextCardEnumTypes | string;
}

const CashieringModalContent = ({ contextCardVisible, data, form }: CashieringModalContentProps) => {
  if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE) {
    return <BankLinkCreate form={form} />;
  } else if (contextCardVisible === ContextCardEnumTypes.EXISTING_BANK_ERROR) {
    return <div>You have an existing {data.selectedWrongBankType} account.</div>;
  } else if (contextCardVisible === ContextCardEnumTypes.EXISTING_BANK_WARNING) {
    return (
      <div>
        You have an existing {data.selectedWrongBankType} account. Do you want to continue only for the{' '}
        {data.correctBankType}?
      </div>
    );
  } else if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_EDIT) {
    return <EditNickname form={form} />;
  } else {
    // NOTE: context type is ContextCardEnumTypes.BANK_LINK_ACTION
    return <BankLinkSubDisclaimer />;
  }
};

export default CashieringModalContent;
