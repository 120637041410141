import { css, cx } from '@emotion/css';

import { BaseStyle, Color, FlexDirection, ScreenBreakpoint } from '../../../styles';

export const menuName = css`
  font-size: 1.75rem;
  line-height: 2rem;
  color: ${Color.BRAND.BRAND6};
  margin-top: 4rem;
  margin-left: 4.625rem;
`;

export const formCard = css`
  border: none;
  border-radius: 8px;
  box-shadow: 0rem 0.25rem 1rem rgba(2, 85, 163, 0.15);
  padding: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const fieldLabel = css`
  line-height: 1.625rem;
  font-weight: 600;
  color: #3c3c3c;
`;

export const sectionHeader = css`
  margin-bottom: 1.438rem;

  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #3c3c3c;
`;

export const noContactPersonLabel = css`
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #3c3c3c;
`;

export const displayValue = css`
  text-align: right;
  color: ${Color.GRAYSCALE.GRAY7} !important;
  margin-left: auto;
  cursor: pointer;
`;

export const phoneType = css`
  text-align: right;
  color: ${Color.GRAYSCALE.GRAY6} !important;
  margin-left: auto;
`;

export const phoneNumbers = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0rem;
  gap: 0.25rem;
`;

export const linkText = css`
  color: ${Color.BRAND.BRAND6};
  font-size: 0.875rem;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
`;

export const linkContainer = css`
  margin-top: 0.5rem;
  margin-bottom: 2.625rem;
  display: flex;
  align-items: center;
`;

export const fullWidthBtn = css`
  width: 100% !important;
`;

export const addNumberButton = css`
  font-weight: 600;

  line-height: 1.3125rem;
  text-align: right;
  color: ${Color.GREEN.GREEN7};
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-top: 0.75rem;
  margin-bottom: 10px;
`;

export const removeAddressInfo = cx(
  addNumberButton,
  css`
    color: ${Color.RED.RED7};
  `,
);

export const Tooltip = {
  fontSize: 15,
  fontWeight: 400,
  marginRight: 5,
  cursor: 'pointer',
  color: Color.BRAND.BRAND6,
  textDecoration: 'none',
};

export const phonesContainer = css`
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.column};
  }

  .ant-col:nth-child(2) {
    max-width: 100%;
  }
`;

export const buttonsContainer = css`
  ${BaseStyle.level}
`;
