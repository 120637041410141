import { anchor, icon, text } from './styles';
export interface IrsW9FormDownloadLinkProps {
  requestDocument: string;
}

export const IrsW9FormDownloadLink = (): JSX.Element | null => {
  return (
    <a href='https://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank' rel='noreferrer' className={anchor}>
      <i className={`ri-download-2-fill ${icon}`}></i> <span className={text}> Download IRS W9 Form</span>
    </a>
  );
};
