import { Row } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addOfferOrderIntent } from 'src/actions';
import { SecurityDeliveryOption } from 'src/lib';
import { OfferDetails, OfferOrderIntent } from 'src/models';
import * as Yup from 'yup';

import { MButton } from '../../../../../lib/FormComponents/MButton/MButton';
import RadioGroup from '../../../../../lib/FormComponents/RadioGroup';
import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import { Images } from '../../../../../styles';
import { DETAILS_PATH } from '../NewOfferOrderConfirmStep/NewOfferOrderConfirmStep';

import * as Styles from './NewOfferOrderSecurityDeliveryInformationStep.styles';

export const SECURITY_DELIVERY_INFORMATION = 'security-delivery-information';

const securityDeliveryInformationFormValidation = Yup.object().shape({
  securityDeliveryOption: Yup.string().required('Security delivery option is required'),
});

interface SecurityDeliveryInformationFormValues {
  securityDeliveryOption: string;
}

export const NewOfferOrderSecurityDeliveryInformationStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);

  const getSecurityDeliveryOptionList = () => offerDetails.externalDetails?.securityDeliveryOptions ?? [];

  const findSecurityDeliveryOptionListInitialValue = () =>
    getSecurityDeliveryOptionList().findIndex(anOption => anOption.initialValue);

  const onSubmit = (values: SecurityDeliveryInformationFormValues) => {
    const selectedOption = getSecurityDeliveryOptionList()[Number(values.securityDeliveryOption)];
    dispatch(
      addOfferOrderIntent({
        ...offerOrderIntent,
        securityDeliveryOption: {
          label: selectedOption.label,
          tooltip: selectedOption.tooltip,
        },
      }),
    );

    window.gtag('event', 'offer_order_security_delivery_complete', {
      offer_id: offerDetails.id,
      offer_name: offerDetails.name,
      account_id: offerOrderIntent.accountId,
    });

    navigate(`../${DETAILS_PATH}`);
  };

  return (
    <NewOfferOrderLayout
      step='security-delivery'
      description='Please review the information below before completing the order process.'
      hasManySecurityDeliveryOptions
      offerId={offerDetails.id}>
      <Formik<SecurityDeliveryInformationFormValues>
        enableReinitialize
        validateOnChange
        validateOnBlur
        initialValues={{
          securityDeliveryOption: findSecurityDeliveryOptionListInitialValue().toString(),
        }}
        validationSchema={securityDeliveryInformationFormValidation}
        onSubmit={values => onSubmit(values)}>
        {form => {
          return (
            <div>
              <Row className={Styles.rowStyle}>
                <img
                  className={Styles.offerLogo}
                  src={offerDetails.logoUrl || Images.DefaultOffering}
                  alt={`${offerDetails.name}_logo`}
                />
                <span className={Styles.offerName}>{offerDetails.name}</span>
              </Row>

              <div className={Styles.radioGroupContainer}>
                <RadioGroup
                  testId={'radio-group-security-delivery-information'}
                  name='securityDeliveryOption'
                  options={getSecurityDeliveryOptionList().map((anOption, index) => ({
                    value: `${index}`,
                    text: <SecurityDeliveryOption {...anOption} />,
                  }))}
                  value={form.values.securityDeliveryOption}
                  {...form}
                />
              </div>

              <Row style={{ justifyContent: 'center', marginTop: 24 }}>
                <MButton type='tertiary' onClick={() => navigate(-1)}>
                  Back
                </MButton>
                <MButton onClick={() => form.submitForm()} testId={'security-delivery-info-btn-continue'}>
                  Continue
                </MButton>
              </Row>
            </div>
          );
        }}
      </Formik>
    </NewOfferOrderLayout>
  );
};
