import React from 'react';

import * as Styles from './styles';

type MouseTooltipProps = {
  visible: boolean;
  offsetX: number;
  offsetY: number;
  text: string;
  style?: object;
};
type MouseTooltipState = {
  xPosition: number;
  yPosition: number;
  mouseMoved: boolean;
  listenerActive: boolean;
};

class MouseTooltip extends React.Component<MouseTooltipProps, MouseTooltipState> {
  static defaultProps = {
    visible: true,
    offsetX: 0,
    offsetY: 0,
  };

  state: MouseTooltipState = {
    xPosition: 0,
    yPosition: 0,
    mouseMoved: false,
    listenerActive: false,
  };

  componentDidMount() {
    this.addListener();
  }

  componentDidUpdate() {
    this.updateListener();
  }

  componentWillUnmount() {
    this.removeListener();
  }

  getTooltipPosition = ({ clientX: xPosition, clientY: yPosition }: any) => {
    this.setState({
      xPosition,
      yPosition,
      mouseMoved: true,
    });
  };

  addListener = () => {
    window.addEventListener('mousemove', this.getTooltipPosition);
    this.setState({ listenerActive: true });
  };

  removeListener = () => {
    window.removeEventListener('mousemove', this.getTooltipPosition);
    this.setState({ listenerActive: false });
  };

  updateListener = () => {
    const { listenerActive } = this.state;
    const { visible } = this.props;

    if (!listenerActive && visible) {
      this.addListener();
    }

    if (listenerActive && !visible) {
      this.removeListener();
    }
  };

  render() {
    const { xPosition, yPosition, mouseMoved } = this.state;
    const { offsetX, offsetY, style, text, visible } = this.props;

    return (
      <div
        className={Styles.tooltip}
        style={{
          display: visible && mouseMoved ? 'block' : 'none',
          position: 'fixed',
          top: yPosition + offsetY,
          left: xPosition + offsetX,
          ...style,
        }}>
        {text}
      </div>
    );
  }
}

export default MouseTooltip;
