import { css } from '@emotion/css';

import {
  AlignItems,
  BorderRadius,
  Color,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
} from '../../../styles';

export const formCard = css`
  border: none;
  box-shadow: 0rem 0.25rem 1rem rgba(2, 85, 163, 0.15);
  ${Spacing.p24};
  ${Spacing.my24};
  ${BorderRadius.xs};
`;

export const fieldLabel = css`
  ${Font.md};
  ${FontWeight.semibold};
  color: ${Color.GRAYSCALE.GRAY8};
  ${Display.flex};
  ${AlignItems.center};
  ${Spacing.mt16};
  ${Spacing.mb20};
  @media (max-width: ${ScreenBreakpoint.laptop.min}) {
    ${Font.sm};
  }
  @media (max-width: ${ScreenBreakpoint.mobile.min}) {
    width: 90%;
  }
`;

export const sectionHeader = css`
  ${Spacing.mb32};
  ${FontWeight.bold};
  ${Font.h1};
  color: ${ColorConstant.GRAY8};
  @media (max-width: ${ScreenBreakpoint.laptop.min}) {
    ${Font.lg};
  }
`;

export const displayValue = css`
  ${Font.md};
  ${TextAlign.right};
  color: ${Color.GRAYSCALE.GRAY7};
  ${Spacing.mlAuto};
  cursor: pointer;
  @media (max-width: ${ScreenBreakpoint.laptop.min}) {
    ${Font.sm};
  }
`;

export const accountNumberValue = css`
  ${Display.flex};
  ${AlignItems.center};
`;

export const Tooltip = css`
  ${Font.md};
  ${Spacing.ml8};
  cursor: pointer;
  color: ${ColorConstant.BRAND6};
  ${Spacing.mt4};
`;

export const copiedTooltip = css`
  .ant-tooltip-inner {
    ${Display.flex}
    background-color: ${ColorConstant.GRAY2};
    position: relative;
    border: 1px solid ${ColorConstant.GRAY4};
    text-decoration: none;
    border-radius: 3px;
    ${Spacing.p12};
    ${Font.xs}
    > i {
      font-size: 20px;
      ${FontWeight.bold}
      color: ${ColorConstant.GREEN6};
      ${Spacing.mr4};
    }
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: ${ColorConstant.GRAY2};
  }
`;
