import { css } from '@emotion/css';
import { Color, Font, FontWeight, Spacing } from 'src/styles';

export const fieldLabel = css`
  ${FontWeight.normal};
  ${Font.xs};
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const rowWrapper = css`
  ${Spacing.py20};
  .ant-col: first-child {
    height: 70px;
  }
`;

export const inputBottom = css`
  .ant-form-item-control {
    display: inline-grid;
    vertical-align: bottom;
  }
  input {
    height: 42px;
    ${Font.lg};
  }
`;

export const spinnerHeight = css`
  height: 124px !important;
`;
