import React, { useEffect, useRef, useState } from 'react';

import { Form } from 'antd';
import SignatureCanvas from 'react-signature-canvas';

import { Color } from '../../../styles';
import { MModal } from '../../MModal/MModal';

import { SignatureType, SIGNATURE_FONT_STYLES } from './constants';
import * as Styles from './styles';

const Signature = ({ name, setFieldValue, errors, touched, disabled = false, value, fullName }: SignatureType) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [signatureImage, setSignatureImage] = useState('');
  const defaultSignatureFontStyles = 3;
  let sigPad = useRef<any>({});

  let signatureRef = useRef<any>([]);

  useEffect(() => {
    if (signatureRef.current?.length > defaultSignatureFontStyles)
      setFieldValue(name, signatureRef.current[signatureRef.current.length - 1]);
  }, [signatureImage]);

  return (
    <>
      <Form.Item
        validateStatus={!!errors && errors[name] && 'error'}
        help={!!errors && touched[name] && errors[name]}
        colon={false}
        style={{
          marginBottom: 0,
        }}>
        {SIGNATURE_FONT_STYLES.map((font, i) => (
          <div
            data-testid={'account-signature-' + i}
            key={`${font}_key`}
            ref={ref => (signatureRef.current[i] = ref)}
            onClick={() => {
              if (!disabled) setFieldValue(name, signatureRef.current[i]);
            }}
            className={Styles.fontSignatureContainer}
            style={{
              fontFamily: font,
              border: value === signatureRef.current[i] ? `1px solid ${Color.BRAND.BRAND6}` : '1px solid #DFE5F0',
            }}>
            {fullName}
          </div>
        ))}
        {signatureImage && (
          <div
            ref={ref => (signatureRef.current[defaultSignatureFontStyles] = ref)}
            onClick={() => !disabled && setFieldValue(name, signatureRef.current[defaultSignatureFontStyles])}
            className={Styles.drawnSignatureContainer}
            style={{
              border:
                value === signatureRef.current[defaultSignatureFontStyles]
                  ? `1px solid ${Color.BRAND.BRAND6}`
                  : '1px solid #DFE5F0',
            }}>
            <img src={signatureImage} alt='signature' style={{ height: 39, width: 150 }} />
          </div>
        )}

        {signatureRef.current.length > defaultSignatureFontStyles && (
          <div
            onClick={() => {
              if (!disabled) {
                setIsModalVisible(true);
              }
            }}
            className={Styles.modifySignatureButton}>
            <i style={Styles.penStyle} className='ri-pen-nib-line' />
            Modify Signature
          </div>
        )}

        <div>
          <div className={Styles.drawSignature}>OR</div>
          <div
            onClick={() => {
              if (!disabled) {
                setIsModalVisible(true);
              }
            }}
            className={Styles.drawSignature}
            style={{ cursor: 'pointer' }}>
            <i style={Styles.quillPen} className='ri-quill-pen-line' />
            Draw your signature
          </div>
        </div>

        <MModal
          title='Draw your signature'
          visible={isModalVisible}
          secondaryButtonText='Clear Signature'
          primaryButtonText='Save Signature'
          onClose={() => {
            setIsModalVisible(false);
          }}
          onPrimaryButtonClick={() => {
            setIsModalVisible(false);
            setSignatureImage(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
          }}
          onSecondaryButtonClick={() => {
            sigPad.current.clear();
          }}>
          <div className={Styles.signaturePadContainer}>
            <SignatureCanvas ref={sigPad} penColor='black' canvasProps={{ width: 450, height: 200 }} />
          </div>
        </MModal>
      </Form.Item>
    </>
  );
};

export default Signature;
