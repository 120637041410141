import { useState } from 'react';

import { Form, Select } from 'antd';

import { countryCodes } from '../../../constants/countryCodes';

import { FlagsDropDownCompType } from './constants';
import './Dropdown.css';

const { Option } = Select;

const CountriesComponent = ({ name, dialCode, flag }: any) => (
  <div className='countries-dropdown-container'>
    <img src={flag} alt={`${name}`} />
    <span>({dialCode})</span>
  </div>
);

const FlagsDropdown = ({
  name,
  disabled = false,
  errors,
  setFieldValue,
  setFieldTouched,
  preChangeHook,
  postChangeHook,
  customStyles,
  touched,
  value = 'US',
  tabIndex,
}: FlagsDropDownCompType) => {
  const [autoFocusInput, setAutoFocusInput] = useState(false);

  return (
    <Form.Item
      validateStatus={!!errors && errors[name] && 'error'}
      help={!!errors && touched[name] && errors[name]}
      colon={false}
      style={{
        ...customStyles,
      }}>
      <div
        style={{ width: 100 }}
        onFocus={() => {
          if (!disabled) {
            setAutoFocusInput(true);
          }
        }}
        onBlur={() => {
          setAutoFocusInput(false);
        }}>
        <Select
          getPopupContainer={trigger => trigger.parentElement}
          disabled={disabled}
          defaultValue={value}
          bordered={false}
          autoFocus={tabIndex === 0 ? true : autoFocusInput}
          style={{ borderBottomColor: autoFocusInput ? '#0255a3' : undefined }}
          onChange={val => {
            if (preChangeHook) {
              preChangeHook();
            }
            setFieldTouched(name, true, true);
            setFieldValue(name, val, true);

            if (postChangeHook) {
              postChangeHook();
            }
          }}>
          {countryCodes.map(country => {
            return (
              <Option key={`${country.code}_key`} value={country.code}>
                <CountriesComponent name={country.code} dialCode={country.dialCode} flag={country.flag} />
              </Option>
            );
          })}
        </Select>
      </div>
    </Form.Item>
  );
};

export default FlagsDropdown;
