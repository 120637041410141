import React from 'react';

import withRouter from '../../hooks/withRouter';

import * as Styles from './Footer.Styles';

const Component = () => (
  <div>
    <p className={Styles.footerP}>
      Cambria Capital, LLC. dba My IPO Member{' '}
      <a rel='noopener noreferrer' href='https://www.finra.org/' target='_blank'>
        FINRA
      </a>
      &nbsp;|&nbsp;
      <a rel='noopener noreferrer' href='https://www.sipc.org/' target='_blank'>
        SIPC
      </a>
      <br />
      Check the background of this firm on{' '}
      <a rel='noopener noreferrer' href='https://brokercheck.finra.org/firm/summary/133760' target='_blank'>
        FINRA&#39;s BrokerCheck
      </a>
    </p>

    <p className={Styles.footerP}>
      IN MAKING AN INVESTMENT DECISION, INVESTORS MUST RELY ON THEIR OWN EXAMINATION OF THE ISSUER AND THE TERMS OF THE
      OFFERING, INCLUDING THE MERITS AND RISKS INVOLVED. INVESTMENTS LISTED ON MY IPO MAY BE SPECULATIVE, ILLIQUID,
      AND/OR INVOLVE A HIGH DEGREE OF RISK, INCLUDING THE POSSIBLE LOSS OF YOUR ENTIRE INVESTMENT.
    </p>
    <p className={Styles.footerP}>
      My IPO is the online offering platform division of Cambria Capital, LLC. Cambria Capital, LLC dba My IPO is a
      registered Broker/Dealer and member FINRA/SIPC. All securities are only offered by Cambria Capital, LLC doing
      business as My IPO.
    </p>
    <p className={Styles.footerP}>
      Any securities offered on this website have not been recommended or approved by any federal or state securities
      commission or regulatory authority.
    </p>
    <p className={Styles.footerP}>
      By accessing this site and any pages thereof, you agree to be bound by its Terms of Use and Privacy Policy, as may
      be amended from time to time without notice or liability. Although the information provided to you on this site is
      obtained or compiled from sources we believe to be reliable, the content of this website is provided “as is”
      without warranty of any kind (either express or implied).
    </p>
    <p className={Styles.footerP}>
      Financial products listed on myipo.com are only available to residents in the states wherein Cambria is
      registered. Company listings on My IPO and Cambria Capital apps and/or websites are only suitable for investors
      who are familiar with and willing to accept the high risk associated with private investments and early stage
      growth companies.
    </p>
    <p className={Styles.footerP}>
      Potential investors are strongly advised to consult their legal, tax and financial advisors before investing. The
      securities offered on this site are not offered in jurisdictions where public solicitation for offerings is not
      permitted; it is solely your responsibility to comply with the laws and regulations of your country of residence.
    </p>
    <div className={Styles.footerP}>
      <sup>*</sup>My IPO may offer the following types of offerings:
      <div>
        <div className={Styles.offeringTypesRow}>
          <span>• IPOs</span>
          <span style={{ paddingLeft: 15 }}>• Pre-IPOs</span>
          <span style={{ paddingLeft: 15 }}>• SPACs</span>
          <span style={{ paddingLeft: 15 }}>• Preferred Stock</span>
          <span style={{ paddingLeft: 15 }}>• Follow On- Public</span>
          <span style={{ paddingLeft: 15 }}>• Private Placements</span>
          <span style={{ paddingLeft: 15 }}>• Non-Traded REITs</span>
          <span style={{ paddingLeft: 15 }}>• Other Offerings*</span>
        </div>
      </div>
    </div>
  </div>
);

Component.displayName = 'FooterText';

export default withRouter(Component);
