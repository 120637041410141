import React from 'react';

import { Form, Radio as AntRadio, Row, Tooltip } from 'antd';

import './../Radio/Radio.css';
import { Color } from '../../../styles';
import { RadioAlignment } from '../Radio/constants';

import { RadioGroupCompType, RadioGroupValue } from './constants';
import * as Styles from './styles';

const RadioGroup = ({
  name,
  label,
  value,
  disabled,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  preChangeHook,
  postChangeHook,
  options,
  radioAlignment = RadioAlignment.Left,
  divided = false,
  onSubComponentClick,
  inLined = false,
  showSubComponentOnSelectOnly = false,
  testId,
}: RadioGroupCompType) => {
  const alignedRight = radioAlignment === RadioAlignment.Right;

  const onChange = (event: any) => {
    if (preChangeHook) {
      preChangeHook();
    }

    setFieldTouched(name, true, true);
    setFieldValue(name, event.target.value, true);

    if (postChangeHook) {
      postChangeHook();
    }
  };
  const radioGroupStyle = inLined ? { width: '100%', display: 'flex' } : { width: '100%' };

  const renderSubComponent = (option: RadioGroupValue): JSX.Element | null => {
    if (!option.subComponent) {
      return null;
    }

    if (!showSubComponentOnSelectOnly || (showSubComponentOnSelectOnly && option.value === value)) {
      return (
        <div
          style={{
            width: 'fit-content',
          }}
          onClick={onSubComponentClick ? () => onSubComponentClick(option.value) : undefined}>
          {option.subComponent}
        </div>
      );
    }

    return null;
  };

  return (
    <Form.Item
      data-testid={testId}
      validateStatus={!!errors && errors[name] && 'error'}
      help={!!errors && touched[name] && errors[name]}
      colon={false}
      style={{ flexDirection: 'column', marginBottom: 0 }}>
      {label && <p className={'radioLabel'}>{label}</p>}
      <AntRadio.Group
        style={{
          ...radioGroupStyle,
          backgroundColor: errors && errors[name] && touched && touched[name] ? Color.RED.RED1 : undefined,
        }}
        onChange={onChange}
        defaultValue={value}
        value={value}>
        {options.map(option => {
          return (
            <div
              style={{
                marginBottom: 15,
                borderBottom: divided ? '1px solid #EDF2F7' : undefined,
                display: 'grid',
                alignItems: 'center',
              }}
              key={`radio_item_${option.value}`}>
              <Row
                style={
                  alignedRight
                    ? {
                        width: '100%',
                        alignItems: 'center',
                      }
                    : {}
                }>
                {alignedRight && <div className={'radioLabel'}>{option.text}</div>}

                <AntRadio
                  checked
                  disabled={disabled}
                  value={option.value}
                  style={alignedRight ? { marginLeft: 'auto' } : {}}>
                  {!alignedRight && (
                    <div>
                      {option.text}
                      {option?.tooltip && (
                        <Tooltip
                          getTooltipContainer={(triggerNode: any) => triggerNode.parentNode}
                          placement='top'
                          title={option.tooltip}
                          color={Color.GRAYSCALE.GRAY1}>
                          <i style={{ ...Styles.Tooltip }} className={`ri-information-line`} />
                        </Tooltip>
                      )}
                    </div>
                  )}
                  {option.subLabel && <span className={'subLabel'}>{option.subLabel}</span>}
                  {option.tip && value === option.value && (
                    <div className={'tip'}>
                      <span style={{ fontWeight: 700 }}>Tip: </span>&nbsp; {option.tip}
                    </div>
                  )}
                  {option.warning && value === option.value && (
                    <div className={'warning'}>
                      <i className='ri-error-warning-line' />
                      {option.warning}
                    </div>
                  )}
                  {renderSubComponent(option)}
                </AntRadio>
              </Row>
            </div>
          );
        })}
      </AntRadio.Group>
    </Form.Item>
  );
};

export default RadioGroup;
