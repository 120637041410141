import { useState } from 'react';

import { useSelector } from 'react-redux';

import { InfoLinkButton } from '../../../../lib/InfoLinkButton/InfoLinkButton';
import { MModal } from '../../../../lib/MModal/MModal';

import * as Styles from './DepositByWire.styles';

export const DepositByWire = () => {
  const accountHolder = useSelector((state: any) => state.accountDetails.accountHolder.data);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getAccountHolderInstructions = () => {
    if (accountHolder?.accountNumber) {
      return `FFC: ${accountHolder.primaryAccountHolder.firstName} ${accountHolder.primaryAccountHolder.lastName}, 
      ${accountHolder.accountNumber}`;
    }

    return `FFC: Account Holder’s Name, My IPO Account Number`;
  };

  return (
    <>
      <InfoLinkButton onClick={() => setIsOpen(true)} className={Styles.infoButton}>
        Deposit Funds By Wire
      </InfoLinkButton>
      <MModal
        title='Wire Deposit Instructions'
        customHeight={750}
        customWidth={635}
        visible={isOpen}
        secondaryButtonText='OK'
        onSecondaryButtonClick={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}>
        <p>
          Incoming wires are free and must be initiated with your financial institution. Wire transfers typically
          require 1 business day to process. Please instruct your bank to include the sender name on the wire. This
          information must be included for the transfer to be credited to your account. A rejected wire may incur a bank
          fee.
        </p>

        <strong>Third-party Wires:</strong>
        <p>
          We typically do not accept third-party wires. An exception MAY be made if there is confirmation of ownership
          and the relationship between the account holders. Such wire request will be subject to review, require
          verification prior to acceptance and may be returned. Third-party foreign wires are not accepted under any
          circumstances.
        </p>

        <p>
          A wire from a joint bank/brokerage account may be deposited into a account by either or both joint account
          owners. A wire from an individual bank/brokerage account may be deposited into a joint account if that person
          is one of the account owners.
        </p>

        <strong>Please send USD incoming wires as follows:</strong>
        <p>
          <span>BMO Harris Bank</span> <br />
          <span>111 W. Monroe St.</span> <br />
          <span>Chicago, IL 60603</span> <br />
        </p>

        <p>
          <span>ABA: 071000288 (for non-domestic USD wires, use SWIFT Code: HATRUS44)</span>
          <br />
          <span>Acct: 3713286</span>
          <br />
          <span>FBO: Apex Clearing Corporation</span>
          <br />
          <span>{getAccountHolderInstructions()}</span>
          <br />
        </p>

        <strong>IMPORTANT NOTE:</strong>
        <p>
          Without FFC Information, your wire may be rejected. If your bank does not have a specific FFC instruction
          field, enter the Account Holder’s Name and Account Number in the &quot;Message to recipient&quot; or
          &quot;Message to recipient bank&quot; field.
        </p>

        <strong>Please include the address for Apex Clearing on incoming wires:</strong>
        <p>
          <span>Apex Clearing Corporation</span>
          <br />
          <span>350 N St Paul, Ste. 1400</span>
          <br />
          <span>Dallas, TX 75201</span>
          <br />
        </p>
      </MModal>
    </>
  );
};
