import React from 'react';

import { Formik } from 'formik';
import _, { get } from 'lodash';
import { connect } from 'react-redux';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';

import { resetPassword } from '../../../actions';
import { InputType } from '../../../lib/FormComponents/Input/constants';
import Input from '../../../lib/FormComponents/Input/Input';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { CardLayout } from '../../../lib/Layout/CardLayout/CardLayout';
import PasswordStrengthBar from '../../../lib/Miscellaneous/PasswordStrengthBar';
import { BaseStyle, Images, SpaceSize } from '../../../styles';
import * as Styles from '../BaseLayout.styles';

import * as ResetPasswordStyles from './ResetPassword.styles';
import { newPasswordValidationSchema } from './validations';

const ResetPasswordUnmapped = ({ isRequested, isSucceeded, resetPassword }: any): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const firstName = searchParams.get('firstName') ?? '';
  const lastName = searchParams.get('lastName') ?? '';

  return (
    <CardLayout>
      {!isSucceeded
        ? renderResetPasswordForm(isRequested, firstName, lastName, (password: string) =>
            resetPassword({ code, email, password }),
          )
        : renderResetPasswordSubmitted(navigate)}
    </CardLayout>
  );
};

const renderResetPasswordForm = (
  isRequested: boolean,
  firstName: string,
  lastName: string,
  resetPassword: (password: string) => void,
) => {
  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
        firstName,
        lastName,
      }}
      validationSchema={newPasswordValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={values => resetPassword(values.password)}>
      {form => {
        return (
          <>
            <div className={Styles.formTitle}>Enter Your New Password</div>
            <div className={Styles.formItemsContainer}>
              <Input
                {...form}
                tabIndex={0}
                name='password'
                value={form.values.password}
                placeholder='New Password'
                type={InputType.Password}
                hideErrorMessage
              />
              <Input
                {...form}
                name='confirmPassword'
                value={form.values.confirmPassword}
                placeholder='Confirm New Password'
                type={InputType.Password}
                hideErrorMessage
                customStyles={{
                  marginTop: SpaceSize.is24,
                }}
              />
              <div className={ResetPasswordStyles.newPasswordNeedsToLabel}>
                *New password needs to be different from the existing one.
              </div>
              <div className={ResetPasswordStyles.passwordStrengthBarContainer}>
                <PasswordStrengthBar
                  password={form.values.password}
                  confirmPassword={form.values.confirmPassword}
                  firstName={firstName}
                  lastName={lastName}
                  showStrengthLabel
                  showValidationRules
                />
              </div>
            </div>

            <div className={Styles.buttonsContainer}>
              <MButton
                type='secondary'
                loading={isRequested}
                disabled={!form.isValid}
                onClick={() => {
                  form.submitForm();
                }}>
                Save
              </MButton>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const renderResetPasswordSubmitted = (navigate: NavigateFunction) => {
  return (
    <>
      <div className={BaseStyle.level}>
        <div className={Styles.iconContainer}>
          <img src={Images.Completed} alt='Completed' />
        </div>
      </div>
      <p className={Styles.afterActionTitle}>Password Changed!</p>
      <p className={Styles.afterActionSubtitle}>Your password has been successfully changed.</p>
      <div className={Styles.buttonsContainer}>
        <MButton
          type='secondary'
          onClick={() => {
            navigate('/login');
          }}>
          Go To Log In
        </MButton>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  isRequested: _.isDate(get(state, 'user.resetPassword.__requested')),
  isSucceeded: _.isDate(get(state, 'user.resetPassword.__succeeded')),
  hasFailed: _.isDate(get(state, 'user.resetPassword.__failed')),
});

const mapDispatchToProps = {
  resetPassword,
};

export const ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordUnmapped);
