import React from 'react';

import NumericFormat from 'react-number-format';

interface PercentageFieldProps {
  value?: number;
  fixedDecimals?: number;
  className?: string;
}

const PercentageField = ({ value, fixedDecimals = 3, className }: PercentageFieldProps) => {
  return (
    <NumericFormat
      value={value?.toFixed(fixedDecimals) || 0}
      displayType={'text'}
      decimalScale={fixedDecimals}
      className={className}
      suffix={'%'}
      prefix={value && value >= 0 ? '+' : ''}
    />
  );
};

export default PercentageField;
