import _ from 'lodash';

import { fieldLabels } from '../constants/fieldLabels';

export const getErrorMessage = (errors: any) => {
  let returnedError = '';

  if (errors && Object.keys(errors).length > 0) {
    returnedError = Object.keys(errors)
      .map(
        errorKey =>
          `On ${
            _.find(fieldLabels, {
              field: errorKey,
            })?.label
          } field you have to provide a value for ${Object.keys(errors[errorKey])
            .map(
              fieldName =>
                _.find(fieldLabels, {
                  field: fieldName,
                })?.label,
            )
            .join(' and ')}`,
      )
      .join('. ');
  }

  return returnedError;
};
