import { css } from '@emotion/css';

import {
  AlignItems,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontFamily,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../styles';

export const userInfo = css`
  position: relative;
  ${Display.flex};
  ${FlexDirection.column}
  cursor: pointer;
  ${Spacing.py32};
  ${Spacing.px0};
  width: 100%;
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.mt0};
    ${Spacing.pt0};
  }
`;

export const accountInfo = css`
  ${Display.flex};
  ${FlexDirection.row};
  ${AlignItems.center}
  p {
    ${Display.inline};
    ${Spacing.m0};
    ${Spacing.ml12};
    ${Font.md};
    ${FontFamily.sourceSansPro};
    ${FontWeight.semibold};
    color: ${ColorConstant.GRAY8};
    @media (min-width: ${ScreenBreakpoint.laptop.min}) {
      display: none;
    }
  }
`;

export const userCircle = css`
  ${JustifyContent.center};
  ${AlignItems.center};
  ${Display.flex}
  ${FontFamily.sourceSansPro}
  ${FontWeight.bold};
  ${Font.lg};
  color: ${ColorConstant.BRAND6};
  width: 2.75rem;
  height: 2.75rem;
  border: 0.125rem solid ${ColorConstant.BRAND6};
  border-radius: 20.5rem;
  ${Spacing.p16};
`;
