import { css } from '@emotion/css';

import {
  Spacing,
  AlignItems,
  JustifyContent,
  Display,
  ColorConstant,
  Font,
  FontWeight,
  TextAlign,
  BaseStyle,
} from '../../styles';
export const space = css`
  ${Spacing.mr4}
`;
export const alreadyHaveAccountLabel = css`
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
  ${Font.lg}
  ${TextAlign.center}
  ${Spacing.mt16}
`;

export const buttonsContainer = css`
  ${BaseStyle.level}
`;

export const description = css`
  ${Font.lg}
  ${TextAlign.center}
  color: ${ColorConstant.GRAY9};
  ${BaseStyle.clearFix}
`;

export const linkButton = css`
  ${Font.lg}
  ${BaseStyle.clickable}
  ${FontWeight.bold}
  color: ${ColorConstant.BRAND6};
`;

export const link = css`
  ${Font.lg}
  color: ${ColorConstant.BRAND6};
  ${BaseStyle.clickable}
  ${Spacing.mt16}
  ${Display.block}
`;

export const formItemsContainer = css`
  ${Spacing.my20}
`;

export const formTitle = css`
  ${FontWeight.bold}
  ${Font.lg}
  ${Spacing.mb40}
  color: ${ColorConstant.GRAY9};
  ${TextAlign.center}
  width: 100%;
`;

export const iconContainer = css`
  ${Display.flex}
  ${JustifyContent.center}
  ${AlignItems.center}
  width: 5rem;
  height: 5rem;
  background: ${ColorConstant.GREEN1};
  border-radius: 5rem;
  ${Spacing.mt40}
  ${Spacing.mb8}
`;

export const afterActionTitle = css`
  ${Font.md}
  ${FontWeight.bold}
  ${TextAlign.center}
  ${Spacing.mb8}
  color: ${ColorConstant.GREEN7};
`;

export const afterActionSubtitle = css`
  ${Font.sm}
  ${TextAlign.center}
  ${Spacing.mb40}
  color: ${ColorConstant.GRAY7};
  width: 100%;
`;

export const resendCodeButton = css`
  ${Font.md}
  ${Spacing.p0}
  ${Spacing.mt24}
  color: ${ColorConstant.BRAND6};
`;

export const myCrsFormLinkContainer = css`
  ${alreadyHaveAccountLabel}
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
  ${Spacing.mt32}
  a {
    text-decoration: underline;
  }
`;

export const referrerCard = css`
  ${Spacing.mb40}
`;

export const rightSlotText = css`
  ${Font.lg};
  color: ${ColorConstant.GRAY7};
`;

export const referrerSubtitle = css`
  ${Spacing.mb0}
`;

export const referrerSubtitleParagraph = css`
  ${Font.h2}
  ${FontWeight.semibold}
`;

export const referrerStep = css`
  ${Font.h1}
  ${TextAlign.left}
`;
