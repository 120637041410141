import React, { Fragment, useEffect, useState } from 'react';

import { Card, Col, Modal, Popover, Row, Space } from 'antd';
import { useSelector } from 'react-redux';

import { AchBankLinkConstant } from '../../../../constants/achBankLinks.constants';
import { MButton } from '../../../../lib/FormComponents/MButton/MButton';
import { MDivider } from '../../../../lib/MDivider/MDivider';
import { ConfigTypeEnum } from '../../../../models/configs.enum';
import { ConfigBankTransfer, Configs } from '../../../../models/configs.model';
import { Color, Images } from '../../../../styles';
import { YourAchBankLinkNotApprovedAlertMessage } from '../YourAchBankLinkNotApprovedAlertMessage/YourAchBankLinkNotApprovedAlertMessage';
import { YourTransferNotCompletedAlertMessage } from '../YourTransferNotCompletedAlertMessage/YourTransferNotCompletedAlertmessage';

import { AchBankLinkStatusTag } from './AchBankLinkStatusTag';
import * as Styles from './BankAccountItem.styles';
import { ACHRelationshipActionType, achRelationshipsActions } from './constants';

const BankAccountItem = ({ account, onClick, onDeposit, onWithdrawal, onEdit, onUnlink }: any) => {
  const [visible, setIsVisible] = useState(false);
  const configsList: Configs[] = useSelector((state: any) => state.configs?.configsList?.data?.data || null);
  const [hasBankTransferConfig, setHasBankTransferConfig] = useState<ConfigBankTransfer | null>();
  const [modal, modalHolder] = Modal.useModal();

  useEffect(() => {
    const bankTransferConfig: Configs | null =
      configsList?.find((config: Configs) => config.configType === ConfigTypeEnum.BANK_TRANSFER) || null;
    const bankTransferData: ConfigBankTransfer | null = bankTransferConfig?.appConfig?.bankTransfer || null;
    setHasBankTransferConfig(bankTransferData);
  }, [configsList]);

  const isBankLinkPending = () =>
    AchBankLinkConstant.PENDING_STATUS_LIST.includes(account?.tradingBlockACHRelationship.state);

  const handleVisibleChange = (visible: boolean) => {
    setIsVisible(visible);
  };

  const modalContent = (content: string) => {
    return {
      title: 'Bank Account Transfering information!',
      content,
    };
  };

  const onDropDownClick = (action: { type: ACHRelationshipActionType }) => {
    if (action.type === ACHRelationshipActionType.EDIT) {
      setIsVisible(false);
      onEdit();
    } else if (action.type === ACHRelationshipActionType.UNLINK) {
      setIsVisible(false);
      onUnlink();
    }
  };

  const onDepositAction = () => {
    onClick();
    if (hasBankTransferConfig?.disabledInboundFunnel)
      return modal.info(modalContent(hasBankTransferConfig.disabledInboundDisclaimerFunnel || ''));

    return onDeposit();
  };

  const onWithdrawalAction = () => {
    onClick();
    if (hasBankTransferConfig?.disabledOutboundFunnel)
      return modal.info(modalContent(hasBankTransferConfig.disabledOutboundDisclaimerFunnel || ''));

    return onWithdrawal();
  };

  return (
    <Fragment>
      <Card className={Styles.cardWrapper}>
        <Row justify={'center'}>
          <Col>
            <Space direction='horizontal' align='start'>
              <div className={Styles.bankImageContainer}>
                <img
                  alt=''
                  src={
                    account?.tradingBlockPlaidInstitution?.logo
                      ? `data:image/jpeg;base64,${account?.tradingBlockPlaidInstitution?.logo}`
                      : Images.BankPlaceholder
                  }
                  className={Styles.bankImage}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  justifyContent: 'center',
                }}>
                <>
                  <Row>
                    <div className={Styles.bankAccountTypeLabel}>
                      {account.tradingBlockACHRelationship?.bankAccountType}
                    </div>
                    {account?.tradingBlockACHRelationship?.nickName && (
                      <div className={Styles.nicknameLabel}>{`(${account.tradingBlockACHRelationship.nickName})`}</div>
                    )}
                  </Row>
                  <div className={Styles.bankAccountLabel}>
                    {account?.tradingBlockACHRelationship?.bankAccount?.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3')}
                  </div>
                </>
              </div>
              <AchBankLinkStatusTag
                state={account?.tradingBlockACHRelationship.state}
                testId={'link-bank-account-status'}
              />
            </Space>
          </Col>
          <Col flex='auto'>
            <div className={Styles.endTextAlignment} onClick={onClick}>
              <Popover
                getPopupContainer={(trigger: any) => trigger.parentElement}
                content={
                  <div className={Styles.popoverContent}>
                    <div className={Styles.popoverTitle}>What would you like to do</div>
                    <div>
                      {achRelationshipsActions.map((action, index) => {
                        const colorFont =
                          action.type === ACHRelationshipActionType.EDIT
                            ? Color.GRAYSCALE.GRAY8
                            : action.type === ACHRelationshipActionType.UNLINK
                            ? Color.RED.RED6
                            : Color.BRAND.BRAND6;

                        return (
                          <div
                            key={`${action.label}_key`}
                            className={Styles.actionRowContainer}
                            style={{
                              borderBottom: index === achRelationshipsActions.length - 1 ? '0px' : '1px solid #F2F5F7',
                            }}>
                            <div
                              data-testid={`link-bank-account-btn-${action.label.toLowerCase()}`}
                              className={Styles.actionRow}
                              style={{
                                color: colorFont,
                              }}
                              onClick={() => onDropDownClick(action)}>
                              <i className={`${action.img} ${Styles.actionImage}`} style={{ color: colorFont }} />
                              {action.label}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
                trigger='click'
                open={visible}
                onOpenChange={handleVisibleChange}
                placement='bottomRight'>
                <i className={`${Styles.moreVerticalImage} ri-more-2-line`} />
              </Popover>
            </div>
          </Col>
          <MDivider />
          <Col span={24} className={Styles.endTextAlignment}>
            <YourTransferNotCompletedAlertMessage isAchBankLinkPending={isBankLinkPending()} />
            <YourAchBankLinkNotApprovedAlertMessage isAchBankLinkPending={isBankLinkPending()} />
            <Space direction='horizontal'>
              <MButton onClick={onDepositAction} type='secondary' testId={'money-transfers-btn-deposit'}>
                Deposit Funds
              </MButton>
              <MButton onClick={onWithdrawalAction} type='secondary' testId={'money-transfers-btn-withdrawal'}>
                Withdrawal Funds
              </MButton>
            </Space>
          </Col>
        </Row>
      </Card>
      {modalHolder}
    </Fragment>
  );
};

export default BankAccountItem;
