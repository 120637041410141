import * as Yup from 'yup';

export const validationMessages = {
  passwordRequired: 'Password is required.',
  passwordMinCharacters: 'Password should be 8 characters at minimum.',
  passwordMaxCharacters: 'Password should be 24 characters at most.',
  emailNameRequired: 'Email is required.',
  emailNameInvalid: 'Email is invalid.',
};

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().required(validationMessages.emailNameRequired).email(validationMessages.emailNameInvalid),
  password: Yup.string()
    .required(validationMessages.passwordRequired)
    .min(8, validationMessages.passwordMinCharacters)
    .max(24, validationMessages.passwordMaxCharacters),
});
