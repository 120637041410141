import { createContext, useEffect, useState } from 'react';

import { Card, Col, Modal as ModalAnt, Row } from 'antd';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  addOfferOrderIntent,
  createACHRelationship,
  getACHRelationships,
  getConfigs,
  getPlaidLinkToken,
} from '../../../../../../actions';
import { DefinitionConstant } from '../../../../../../constants/common.constants';
import { TransferConstant } from '../../../../../../constants/transfers.constants';
import { useAccountBalance } from '../../../../../../hooks/useAccountBalance';
import { useFindMinimalDepositAmountForInvestment } from '../../../../../../hooks/useFindMinimalDepositAmountForInvestment';
import Dropdown from '../../../../../../lib/FormComponents/Dropdown';
import { MButton } from '../../../../../../lib/FormComponents/MButton/MButton';
import NumberInput from '../../../../../../lib/FormComponents/NumberInput/NumberInput';
import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { MAlert } from '../../../../../../lib/Miscellaneous/MAlert/MAlert';
import Spinner from '../../../../../../lib/Miscellaneous/Spinner';
import { MModal } from '../../../../../../lib/MModal/MModal';
import { MTooltip } from '../../../../../../lib/MTooltip/MTooltip';
import { OpenPlaidLinkBankAccount } from '../../../../../../lib/OpenPlaidLinkBankAccount/OpenPlaidLinkBankAccount';
import { ConfigTypeEnum } from '../../../../../../models/configs.enum';
import { ConfigBankLink, Configs } from '../../../../../../models/configs.model';
import { OfferOrderIntent } from '../../../../../../models/offer-orders.model';
import { OfferDetails } from '../../../../../../models/offers.models';
import { getCurrencyFormatter } from '../../../../../../utils/getCurrencyFormatter';
import { ContextCardEnumTypes } from '../../../../../Cashiering/components/BankAccountsAndFunding/constants';
import BankLinkCreate from '../../../../../Cashiering/components/BankLinkCreate/BankLinkCreate';
import BankLinkSubDisclaimer from '../../../../../Cashiering/components/BankLinkSubDisclaimer/BankLinkSubDisclaimer';

import * as Styles from './AchPayment.styles';

export const ORDER_PATH = 'order';

const ModalContext = createContext<string | null>(null);

const config = {
  title: 'Bank Account Linking information',
  content: <ModalContext.Consumer>{name => `${name}`}</ModalContext.Consumer>,
};

export interface NewDepositFormValues {
  transferAmount?: string;
  bankAccount?: string;
}

export interface AchPaymentProps {
  isValid: (value: boolean) => void;
}

export const AchPayment = ({ isValid }: AchPaymentProps) => {
  const dispatch = useDispatch();

  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const achRelationships = useSelector((state: any) => state.cashiering.achRelationships.data);
  const loadingAchRelationships = useSelector((state: any) => state.cashiering.achRelationships.__requested);
  const loadingCreateAchRelationships = useSelector((state: any) => state.cashiering.createACHRelationship.__requested);
  const accountId = useSelector((state: any) => state.accountDetails?.accountHolder?.data?.accountId);
  const plaidLinkToken = useSelector((state: any) => state.cashiering.plaidLinkToken.data?.linkToken);
  const isPlaidLinkBankAccountTokenLoading = useSelector((state: any) =>
    Boolean(state.cashiering.plaidLinkToken?.__requested),
  );
  const configsList: Configs[] = useSelector((state: any) => state.configs?.configsList?.data?.data || null);
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const [newDepositFormErrors, setNewDepositFormErrors] = useState<NewDepositFormValues>();
  const [newDepositFormInitialValues, setNewDepositFormInitialValues] = useState<NewDepositFormValues>({});
  const [shouldOpenPlaid, setShouldOpenPlaid] = useState<boolean>(false);
  const [bankAccountList, setBankAccountList] = useState<{ text: string; value: string }[]>([]);
  const [hasBankLinkConfig, setHasBankLinkConfig] = useState<ConfigBankLink | null>();
  const [bankLinkPayload, setBankLinkPayload] = useState<any>({});

  const [hasBankAccount, setHasBankAccount] = useState(false);
  const [transferAmount, setTransferAmount] = useState<string>();
  const [contextCardVisible, setContextCardVisible] = useState<ContextCardEnumTypes | null>(null);
  const [modal, modalHolder] = ModalAnt.useModal();
  const { cashAvailable } = useAccountBalance();
  const minimalDepositAmount = useFindMinimalDepositAmountForInvestment();

  const quantity = offerOrderIntent.quantity;

  const hasEnoughMoneyWithDepositAmountOnConditional = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.totalInvestment <= transferAmountNumber;
    } else {
      return offerOrderIntent.totalInvestment <= cashAvailable + transferAmountNumber;
    }
  };

  const hasEnoughMoneyWithDepositAmountOnStandard = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.price * quantity <= transferAmountNumber;
    } else {
      return offerOrderIntent.price * quantity <= cashAvailable + transferAmountNumber;
    }
  };

  const hasEnoughMoneyWithDepositAmount = () =>
    offerOrderIntent.isConditional
      ? hasEnoughMoneyWithDepositAmountOnConditional()
      : hasEnoughMoneyWithDepositAmountOnStandard();

  const findRemainingDepositAmountOnConditionalOffer = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.totalInvestment - transferAmountNumber;
    } else {
      return offerOrderIntent.totalInvestment - cashAvailable - transferAmountNumber;
    }
  };

  const findRemainingDepositAmountOnStandardOffer = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.price * quantity - transferAmountNumber;
    } else {
      return offerOrderIntent.price * quantity - cashAvailable - transferAmountNumber;
    }
  };

  const findRemainingDepositAmount = () =>
    offerOrderIntent.isConditional
      ? findRemainingDepositAmountOnConditionalOffer()
      : findRemainingDepositAmountOnStandardOffer();

  const onSelectBankAccount = (form: FormikProps<NewDepositFormValues>) => (name: string, value: string) => {
    form.setFieldValue(name, value);
    dispatch(
      addOfferOrderIntent({
        ...offerOrderIntent,
        achDeposit: { ...offerOrderIntent.achDeposit, relationshipId: value },
      }),
    );
  };

  const onTransferAmount = (form: FormikProps<NewDepositFormValues>) => (name: string, value: string) => {
    form.setFieldValue(name, value);
    setTransferAmount(value);
    dispatch(
      addOfferOrderIntent({
        ...offerOrderIntent,
        achDeposit: { ...offerOrderIntent.achDeposit, amount: value },
      }),
    );
  };

  const onLinkBankAccount = () => {
    if (hasBankLinkConfig?.disableInFunnel) return modal.info(config);
    setContextCardVisible(ContextCardEnumTypes.BANK_LINK_ACTION);
  };

  const onPlaidSuccess = (plaidBankLinkSuccessPayload: any) => {
    let plaidAccounts = plaidBankLinkSuccessPayload.metadata.accounts.map((item: any) => {
      return {
        bankAccountType: `${item.subtype[0].toUpperCase()}${item.subtype.slice(1)}`,
        plaidAccountId: item.id,
        bankAccountMask: item.mask,
      };
    });

    setBankLinkPayload({
      plaidPublicToken: plaidBankLinkSuccessPayload.publicToken,
      institutionId: plaidBankLinkSuccessPayload.metadata.institution.institution_id,
      bankAccounts: plaidAccounts,
    });

    setContextCardVisible(ContextCardEnumTypes.BANK_LINK_CREATE);
  };

  const onPlaidExit = () => {
    setContextCardVisible(null);
    setShouldOpenPlaid(false);
  };

  const renderNewDepositForm = () => {
    return (
      <Formik
        initialValues={newDepositFormInitialValues}
        enableReinitialize
        validateOnMount
        validate={value => {
          if (isNaN(Number(value.transferAmount)) || Number(value.transferAmount) < 0) {
            setNewDepositFormErrors({ transferAmount: 'Amount must be a positive number' });

            return;
          }

          if (TransferConstant.MAX_AMOUNT < Number(value.transferAmount)) {
            setNewDepositFormErrors({
              transferAmount: `The maximum transfer amount for ACH is ${getCurrencyFormatter().format(
                TransferConstant.MAX_AMOUNT,
              )} per Order. To invest over ${getCurrencyFormatter().format(
                TransferConstant.MAX_AMOUNT,
              )} on an Order, please select Wire or Check transfer.`,
            });

            return;
          }
          setNewDepositFormErrors({});
        }}
        onSubmit={() => {}}>
        {form => {
          return (
            <div className={Styles.container}>
              <Row gutter={[, 16]}>
                {!hasEnoughMoneyWithDepositAmount() && (
                  <MAlert
                    type='error'
                    description={
                      <span>
                        You do not have enough money to successfully complete the order. Please add a minimum of{' '}
                        <CurrencyField value={findRemainingDepositAmount()} /> {'.'}
                      </span>
                    }
                  />
                )}
                <span className={Styles.depositTitle} data-testid={'confirm-order-transfer-ach-info'}>
                  The requested order amount requires you to transfer funds into your account.
                </span>
                <div className={Styles.transactionSection}>
                  <Col>
                    <span className={Styles.fieldTransactionLabel}>
                      <span>Cash Available</span>
                      <MTooltip title={DefinitionConstant.CASH_AVAILABLE} placement='top' />
                    </span>
                  </Col>
                  <Col>
                    <CurrencyField value={cashAvailable} />{' '}
                  </Col>
                </div>
                <div className={Styles.transactionSection}>
                  <Col>
                    <span className={Styles.fieldTransactionLabel}>From Account</span>
                  </Col>
                  <Col>
                    <Dropdown
                      {...form}
                      key='bankAccount'
                      name='bankAccount'
                      placeholder='Select'
                      value={form.values.bankAccount}
                      setFieldValue={onSelectBankAccount(form)}
                      options={bankAccountList}></Dropdown>
                  </Col>
                </div>
                <div className={Styles.transactionSection}>
                  <Col>
                    <span className={Styles.fieldTransactionLabel}>Transfer Amount</span>
                  </Col>
                  <Col>
                    <NumberInput
                      {...form}
                      placeholder='0.00'
                      name='transferAmount'
                      value={form.values.transferAmount}
                      setFieldValue={onTransferAmount(form)}
                      disabled={!form.values.bankAccount}
                      step={(0.01).toString()}
                      prefix={'$'}
                    />
                    {newDepositFormErrors?.transferAmount && (
                      <div className={Styles.maxTranferValidationMessage}>{newDepositFormErrors.transferAmount}</div>
                    )}
                  </Col>
                </div>
              </Row>
            </div>
          );
        }}
      </Formik>
    );
  };

  const renderLinkBankAccountForm = () => {
    const text = (
      <span>
        You do not have any linked bank accounts to successfully complete the order.
        <br />
        To continue, please link a bank account.
      </span>
    );

    return (
      <Card className={Styles.cardContainer}>
        <div className={Styles.linkBankContainer}>
          <div className={Styles.linkBankText}>
            <p data-testid={'confirm-order-ach-payment-text'}>{text}</p>
          </div>
          <div className={Styles.linkBankButton}>
            <MButton type='secondary' onClick={onLinkBankAccount} size='small'>
              Link Bank Account
            </MButton>
          </div>
        </div>
      </Card>
    );
  };

  const renderLinkBankModal = () => {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={{
            nickname: '',
          }}
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={values => {
            dispatch(
              createACHRelationship(accountId, {
                ...bankLinkPayload,
                nickName: values.nickname,
              }),
            );
            setContextCardVisible(null);
          }}>
          {form => {
            return (
              <MModal
                title='Disclaimer'
                customWidth={350}
                customHeight={450}
                verticalBottoms={
                  contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE ||
                  contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION
                }
                form={contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION ? undefined : form}
                visible={Boolean(contextCardVisible)}
                primaryButtonText={
                  contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION ? 'Continue' : 'Finish Linking'
                }
                tertiaryButtonText={contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION ? 'Close' : undefined}
                onPrimaryButtonClick={() => {
                  if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION) {
                    setShouldOpenPlaid(true);
                    setContextCardVisible(null);

                    return;
                  }

                  if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE) {
                    setShouldOpenPlaid(false);
                    form.submitForm();

                    return;
                  }
                }}
                onTertiaryButtonClick={() => {
                  setContextCardVisible(null);
                }}
                onClose={() => {
                  setContextCardVisible(null);
                }}>
                <div>
                  {contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE && <BankLinkCreate form={form} />}
                  {contextCardVisible !== ContextCardEnumTypes.BANK_LINK_CREATE && <BankLinkSubDisclaimer />}
                </div>
              </MModal>
            );
          }}
        </Formik>
      </>
    );
  };

  useEffect(() => {
    if (!achRelationships && !loadingAchRelationships) dispatch(getACHRelationships(accountId));
    dispatch(getConfigs());
  }, [dispatch]);

  useEffect(() => {
    const bankLinkConfig: Configs | null =
      configsList?.find((config: Configs) => config.configType === ConfigTypeEnum.BANK_LINK) || null;
    const bankLinkData: ConfigBankLink | null = bankLinkConfig?.appConfig?.bankLink || null;
    setHasBankLinkConfig(bankLinkData);
  }, [configsList]);

  useEffect(() => {
    setHasBankAccount(!_.isEmpty(achRelationships));
    const bankAccountOptions =
      (!loadingAchRelationships &&
        Array.isArray(achRelationships) &&
        achRelationships.map((ach: any) => {
          const { bankAccountType, nickName, bankAccount, id } = ach.tradingBlockACHRelationship;

          return { text: `${bankAccountType} (${nickName}) ${bankAccount}`, value: id };
        })) ||
      [];
    setBankAccountList(bankAccountOptions);
  }, [achRelationships, loadingAchRelationships]);

  useEffect(() => {
    if (!_.isEmpty(bankAccountList) && offerDetails) {
      const minValue = minimalDepositAmount.toString();

      setTransferAmount(minValue);
      setNewDepositFormInitialValues({
        bankAccount: bankAccountList[0]['value'],
        transferAmount: minValue,
      });

      dispatch(
        addOfferOrderIntent({
          ...offerOrderIntent,
          achDeposit: { amount: minValue, relationshipId: bankAccountList[0]['value'] },
        }),
      );
    }
  }, [bankAccountList, offerDetails]);

  useEffect(() => {
    isValid(
      hasBankAccount && hasEnoughMoneyWithDepositAmount() && Number(transferAmount) <= TransferConstant.MAX_AMOUNT,
    );
  }, [hasBankAccount, transferAmount, cashAvailable]);

  useEffect(() => {
    if (!plaidLinkToken) {
      dispatch(getPlaidLinkToken(accountId));
    }
  }, []);

  if (loadingAchRelationships || loadingCreateAchRelationships || isPlaidLinkBankAccountTokenLoading) {
    return <Spinner />;
  }

  if (hasBankAccount) {
    return renderNewDepositForm();
  }

  return (
    <>
      {renderLinkBankAccountForm()}
      <ModalContext.Provider value={hasBankLinkConfig?.funnelDisclaimer || ''}>{modalHolder}</ModalContext.Provider>
      {contextCardVisible && renderLinkBankModal()}
      {plaidLinkToken && shouldOpenPlaid && (
        <OpenPlaidLinkBankAccount token={plaidLinkToken} onExit={onPlaidExit} onSuccess={onPlaidSuccess} />
      )}
    </>
  );
};
