import { PropsWithChildren } from 'react';

import { cx } from '@emotion/css';
import { Col, Modal as AntModal, Row, Space } from 'antd';

import { MButton, MButtonType } from '../FormComponents/MButton/MButton';

import * as Styles from './MModal.styles';

export type MModalProps = {
  title: string;
  visible: boolean;
  onClose?: () => void;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  onTertiaryButtonClick?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  tertiaryButtonText?: string;
  isDisabledPrimaryButton?: boolean;
  isDisabledSecondaryButton?: boolean;
  isDisasbledTertiaryButton?: boolean;
  primaryButtonType?: MButtonType;
  secondaryButtonType?: MButtonType;
  tertiaryButtonType?: MButtonType;
  customStyleModalContent?: string;
  customWidth?: number;
  customHeight?: number;
  form?: any;
  closable?: boolean;
  customPadding?: number;
  centered?: boolean;
  className?: string;
  maskClosable?: boolean;
  verticalBottoms?: boolean;
};

export const MModal = ({
  title,
  visible,
  children,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onTertiaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  tertiaryButtonText,
  isDisabledSecondaryButton = false,
  isDisabledPrimaryButton = false,
  primaryButtonType = 'primary',
  secondaryButtonType = 'secondary',
  tertiaryButtonType = 'tertiary',
  customStyleModalContent,
  customWidth,
  customHeight,
  form,
  closable = true,
  customPadding,
  centered = true,
  className,
  maskClosable = true,
  verticalBottoms = false,
}: PropsWithChildren<MModalProps>) => {
  const getBottomsStyle: any = {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
  };

  const rightRowButtons: any = {
    justifyContent: 'end',
  };

  function renderFooter() {
    return (
      <Row
        style={verticalBottoms ? getBottomsStyle : tertiaryButtonText && !secondaryButtonText ? rightRowButtons : ''}
        gutter={[8, 8]}>
        <Col flex={tertiaryButtonText && !secondaryButtonText ? 'none' : 'auto'}>
          {tertiaryButtonText && (
            <MButton
              testId={`account-modal-btn-${tertiaryButtonText.toLowerCase()}`}
              type={tertiaryButtonType}
              onClick={onTertiaryButtonClick}
              className={cx(
                secondaryButtonText && primaryButtonText ? Styles.justifyLeftButton : Styles.justifyRightButton,
              )}>
              {tertiaryButtonText}
            </MButton>
          )}
        </Col>
        <Col flex={tertiaryButtonText && !secondaryButtonText ? 'none' : 'auto'}>
          <Space size={8} direction={verticalBottoms ? 'vertical' : 'horizontal'}>
            {secondaryButtonText && (
              <MButton disabled={isDisabledSecondaryButton} type={secondaryButtonType} onClick={onSecondaryButtonClick}>
                {secondaryButtonText}
              </MButton>
            )}
            {primaryButtonText && (
              <MButton
                testId={`account-modal-btn-${primaryButtonText.toLowerCase()}`}
                type={primaryButtonType}
                onClick={onPrimaryButtonClick}
                disabled={form ? !(form.isValid && form.dirty) : isDisabledPrimaryButton}>
                {primaryButtonText}
              </MButton>
            )}
          </Space>
        </Col>
      </Row>
    );
  }

  return (
    <AntModal
      data-testid={`ant-modal-${title.toLowerCase().replace(/ /g, '-')}`}
      width={customWidth}
      bodyStyle={{ height: customHeight, padding: customPadding }}
      destroyOnClose
      centered={centered}
      title={title}
      open={visible}
      onCancel={onClose}
      closable={closable}
      footer={renderFooter()}
      className={className}
      maskClosable={maskClosable}>
      <div className={cx(Styles.modalContentContainer, customStyleModalContent)}>{children}</div>
    </AntModal>
  );
};
