import { css } from '@emotion/css';

import { Color, ColorConstant, Font, ScreenBreakpoint, Spacing } from '../../../styles';

export const menuName = css`
  font-size: 1.75rem;
  line-height: 2rem;
  color: ${Color.BRAND.BRAND6};
  margin-top: 4rem;
  margin-left: 4.625rem;
`;

export const formCard = css`
  border: none;
  box-shadow: 0rem 0.25rem 1rem rgba(2, 85, 163, 0.15);
  padding: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
`;
export const formCardBlur = css`
  pointer-events: none;
  -webkit-filter: blur(8px);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

export const fieldLabel = css`
  line-height: 1.625rem;
  font-weight: 600;
  color: #3c3c3c;
`;

export const sectionHeader = css`
  margin-bottom: 1rem;

  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #3c3c3c;
`;

export const noContactPersonLabel = css`
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #3c3c3c;
`;

export const displayValue = css`
  text-align: right;
  color: ${Color.GRAYSCALE.GRAY7} !important;
  margin-left: auto;
`;

export const displayValueRadio = css`
  line-height: 1.438rem;
  letter-spacing: 0.031rem;
  color: ${Color.GRAYSCALE.GRAY8} !important;
  display: flex;
`;

export const displayValueRadioSubLabel = css`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${Color.GRAYSCALE.GRAY7} !important;
  display: flex;
`;

export const bannerLabel = css`
  line-height: 1.375rem;
  color: ${Color.BRAND.BRAND6};
  margin-left: 0.625rem;
  margin-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  > i {
    font-size: 12px;
  }

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.py0};
  }
`;

export const boldLabel = css`
  font-weight: 600;
  line-height: 1.75rem;
  color: #3c3c3c;
`;

export const normalLabel = css`
  line-height: 1.75rem;
  color: #3c3c3c;
`;

export const fullWidthCreateAccountBtn = css`
  width: 100% !important;
`;

export const alert = css`
  color: ${ColorConstant.BRAND6};
`;

export const timelineIcon = css`
  ${Font.h1}
  position: absolute;
  ${Spacing.ml4}
`;
