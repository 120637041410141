import { css } from '@emotion/css';

import { Color } from '../../../../styles';

export const bankLinkSubDisclaimer = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  color: ${Color.GRAYSCALE.GRAY7};
`;
