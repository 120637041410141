import { css, cx } from '@emotion/css';

import { Color } from '../../../styles';

export const passingRule = css`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const errorRule = cx(
  passingRule,
  css`
    color: ${Color.RED.RED6};
  `,
);
