import { css } from '@emotion/css';
import { ColorConstant, Font, Spacing } from 'src/styles';

export const description = css`
  ${Font.sm}
  color: ${ColorConstant.GRAY8};
`;

export const bankName = css`
  ${Font.xs}
  color: ${ColorConstant.GRAY6};
`;

export const descriptionSubtitle = css`
  ${Font.xs}
  color: ${ColorConstant.GRAY6};
`;

export const alert = css`
  ${Spacing.m0}
  ${Spacing.p4}
`;
