import { call, put, select, takeEvery, SagaReturnType } from 'redux-saga/effects';

import { getAccountSucceeded } from '../actions/accounts.actions';
import { State, Type } from '../actions/utils';
import { AccountsApi } from '../data-communication/AccountsApi';
import { getClientApi } from '../data-communication/ClientApi';

import { safeSaga } from './utils';

export function* getAccount() {
  const { authToken } = yield select(state => state.auth.data);

  const response: SagaReturnType<AccountsApi['getOne']> = yield call(getClientApi().accounts.getOne, { authToken });

  yield put(getAccountSucceeded(response));
}

export function* registerAccountSagas() {
  yield takeEvery(State.actionRequested(Type.GET_ACCOUNT), safeSaga(getAccount, Type.GET_ACCOUNT));
}
