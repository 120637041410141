import _ from 'lodash';

import { states } from '../../../../constants/states';
import { UserDto } from '../../../../dtos/users.dtos';
import Dropdown from '../../../../lib/FormComponents/Dropdown';
import { InputType, TextAlign } from '../../../../lib/FormComponents/Input/constants';
import Input from '../../../../lib/FormComponents/Input/Input';
import RadioGroup from '../../../../lib/FormComponents/RadioGroup';

const getFields = (radioValues: any, userInfo: UserDto) => {
  return {
    firstName: {
      label: 'First Name',
      component: Input,
      hideField: !_.isEmpty(userInfo),
      props: {
        name: 'firstName',
        placeholder: 'First Name',
        hideBorder: true,
        textAlign: TextAlign.Right,
        value: '',
      },
    },
    lastName: {
      label: 'Last Name',
      component: Input,
      hideField: !_.isEmpty(userInfo),
      props: {
        name: 'lastName',
        placeholder: 'Last Name',
        textAlign: TextAlign.Right,
        hideBorder: true,
        value: '',
      },
    },
    email: {
      label: 'Email',
      component: Input,
      hideField: !_.isEmpty(userInfo),
      props: {
        name: 'email',
        placeholder: 'Email',
        textAlign: TextAlign.Right,
        hideBorder: true,
        value: '',
      },
    },
    phoneNumber: {
      label: 'Phone Number',
      component: Input,
      hideField: !_.isEmpty(userInfo),
      props: {
        name: 'phoneNumber',
        type: InputType.PhoneNumber,
        placeholder: 'Phone',
        hideBorder: true,
        textAlign: TextAlign.Right,
        value: '',
      },
    },
    state: {
      label: 'State',
      component: Dropdown,
      hideField: !_.isEmpty(userInfo),
      props: {
        name: 'state',
        placeholder: 'Select State',
        options: states,
        hideBorder: true,
        customStyles: { width: 120, marginLeft: 'auto' },
        value: '',
        showSearch: true,
      },
    },
    investmentRanges: {
      label: 'Radio Title',
      component: RadioGroup,
      isNotListRow: true,
      props: {
        name: 'investmentRanges',
        options: radioValues,
        divided: true,
        value: '',
      },
    },
  };
};

export default getFields;
