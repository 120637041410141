import { getAuthToken } from '../sagas/utils';

export default {
  auth: {
    data: { authToken: getAuthToken() },
  },
  user: {
    signUp: {},
    patchAddress: {},
    toggleMfaConfig: {},
    authenticated: {},
    login: {},
    confirmMfaCode: {},
    resendMfaCode: {},
    forgotPassword: {},
    resetPassword: {},
    emailChange: {},
    emailConfirm: {},
    updatePhoneNumber: {},
    getSignature: {},
    signAccountAgreement: {},
  },
  accounts: {
    list: [],
    item: {},
  },
  accountDetails: {
    personalInformation: {},
    updatePersonalInformation: {},
    patchPersonalInformation: {},
    updatePhysicalAddress: {},
    patchPhysicalAddress: {},
    updateMailingAddress: {},
    patchMailingAddress: {},

    trustedContactInformation: {},
    updateTrustedContact: {},
    patchTrustedContact: {},
    removeTrustedContact: {},

    financialInformation: {},
    updateFinancialInformationAssets: {},
    patchFinancialInformationAssets: {},
    updateFinancialInformationEmployment: {},
    patchFinancialInformationEmployment: {},

    suitabilityInformation: {},
    updateSuitabilityInformation: {},
    patchSuitabilityInformation: {},

    disclosures: {},
    updateDisclosures: {},
    patchDisclosures: {},

    accountAgreements: {},
    accountSubmit: {},
    accountHolder: {},
    accountBalance: {},
    accountValue: {},
  },
  statements: {
    tradeConfirmations: {
      list: {},
      downloads: [],
    },
    monthlyStatements: {
      list: {},
      downloads: [],
    },
    taxDocuments: {
      list: {},
      downloads: [],
    },
  },
  cashiering: {
    achRelationships: {},
    createACHRelationship: {},
    editACHRelationship: {},
    unlinkACHRelationship: {},
    plaidLinkToken: {},
    transfers: {},
    transferDetails: {},
  },
  offers: {
    offersList: {},
    offerDetails: {},
    offerDocuments: {},
    options: {},
  },
  offerWatches: {
    list: {},
    createOfferWatch: {},
    deleteOfferWatch: {},
  },
  investigations: {
    investigationsList: {},
    uploadInvestigationDocument: {},
    uploadApplicationInvestigationDocument: {},
  },
  configs: {
    configsList: {},
  },
  quoteMedia: {
    snapQuote: {},
    enhancedSnapQuote: {},
    chartSnapQuote: {},
  },
  orders: {
    item: {},
    list: {},
    positions: {},
    review: {},
    created: {},
    updated: {},
  },
  offerOrders: {
    details: {},
    list: {},
    create: {},
    update: {},
    delete: {},
    createCardPaymentIntent: {},
    updateCardPaymentIntent: {},
    submitCardPayment: false,
    confirmCardPayment: {},
    intent: null,
  },
  toastMessages: {
    list: [],
    clearList: [],
    open: null,
    close: null,
  },
  app: {
    redirectToOffer: {},
    redirectToOfferOrder: {},
    redirectToEmailConfirm: null,
    onboardStep: null,
    offerInvestReferrer: null,
  },
};
