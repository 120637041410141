import * as Yup from 'yup';

const validationMessages = {
  signatureRequired: 'Signature is required',
};

export const customerAgreementValidation = Yup.object().shape({
  signature: Yup.string().required(validationMessages.signatureRequired).nullable(),
  isSubjectToBackupWithholding: Yup.boolean().default(false),
});
