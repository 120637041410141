import React, { useCallback, useState } from 'react';

import { cx } from '@emotion/css';
import { Form, InputNumber as AntInputNumber } from 'antd';

import * as Styles from './styles';

interface NumberInputProps {
  name: string;
  label?: string;
  value?: string;
  errors?: any;
  touched?: any;
  setFieldValue: Function;
  setFieldTouched: Function;
  disabled?: boolean;
  step?: string;
  min?: string;
  max?: string;
  placeholder?: string;
  customStyles?: string;
  prefix?: any;
  suffix?: any;
  hideErrorMessage?: boolean;
  bordered?: boolean;
  testId?: string;
}

const NumberInput = ({
  name,
  label,
  value = '',
  errors,
  touched,
  disabled,
  step,
  min,
  max,
  placeholder,
  customStyles,
  prefix,
  hideErrorMessage,
  bordered = false,
  setFieldTouched,
  setFieldValue,
  testId,
}: NumberInputProps) => {
  const [decimalPlaces, setDecimalPlaces] = useState<boolean>(false);

  const formatNumber = useCallback((value: string): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(value));
  }, []);

  const handleChange = useCallback(
    (value: string | null) => {
      setFieldTouched(name);
      setFieldValue(name, value, true);
    },
    [name, setFieldTouched, setFieldValue],
  );

  const calculateSelectorPosition = useCallback(
    (event: any, isDecimal: boolean = false) => {
      const decimalPointIndex = event?.target?.value?.lastIndexOf('.') || event?.target?.value?.length || 0;
      const cursorIndex = isDecimal ? decimalPointIndex + 1 : decimalPointIndex;

      if (!decimalPlaces) {
        event.currentTarget.selectionStart = cursorIndex;
        event.currentTarget.selectionEnd = cursorIndex;
      }
    },
    [decimalPlaces],
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.type === 'keydown' && event.key === '.') {
        setDecimalPlaces(true);
        calculateSelectorPosition(event, true);
        event.preventDefault();
      }
      if (event.type === 'keydown' && ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(event.key))
        event.preventDefault();
    },
    [setDecimalPlaces],
  );

  return (
    <Form.Item
      validateStatus={!!errors && errors[name] && 'error'}
      help={hideErrorMessage ? false : !!errors && touched[name] && errors[name]}
      label={label}
      colon={false}
      className={cx(Styles.inputWrapper, customStyles, disabled ? Styles.disabledInput : '')}>
      <AntInputNumber<string>
        data-testid={testId}
        className={cx(Styles.input, errors && errors[name] && touched && touched[name] ? Styles.errorInput : '')}
        formatter={value => formatNumber(value || '')}
        placeholder={placeholder}
        bordered={bordered}
        min={min}
        max={max}
        step={step}
        stringMode
        prefix={prefix}
        value={value}
        disabled={disabled}
        controls={false}
        onChange={handleChange}
        onBlur={() => setFieldTouched(name)}
        onSelectCapture={calculateSelectorPosition}
        onFocus={() => setDecimalPlaces(false)}
        onKeyDown={handleKeyDown}
      />
    </Form.Item>
  );
};

export default NumberInput;
