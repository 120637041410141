import React, { useEffect } from 'react';

import { Button, Space } from 'antd';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedUserLoginResponseDto } from 'src/dtos';

import {
  resendMfaCode,
  confirmMfaCode,
  clearConfirmMfaCode,
  clearResendMfaCode,
  doRemoveOfferInvestReferrer,
  setAuthentication,
} from '../../../actions';
import { getFormState } from '../../../actions/forms';
import Checkbox from '../../../lib/FormComponents/CheckBox';
import { InputType } from '../../../lib/FormComponents/Input/constants';
import Input from '../../../lib/FormComponents/Input/Input';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import * as Styles from '../BaseLayout.styles';
import { ActiveTab } from '../constants';
import { SignUpWithOfferInvestReferrer } from '../SignUpWithOfferInvestReferrer';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { securityConfirmValidationSchema } from './validations';

export const SecurityConfirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const email = useSelector((state: any) => state.auth.data?.email);
  const password = useSelector((state: any) => state.auth.data?.password);
  const phoneNumber = useSelector((state: any) => state.auth.data?.phoneNumber);
  const initialStoreState = useSelector((state: any) => state.user.confirmMfaCode.data);

  const confirmSucceded = useSelector((state: any) => Boolean(state.user.confirmMfaCode.__succeeded));
  const isConfirmMfaCodeLoading = useSelector((state: any) => Boolean(state.user.confirmMfaCode.__requested));
  const confirmMfaData: AuthenticatedUserLoginResponseDto | undefined = useSelector(
    (state: any) => state.user.confirmMfaCode.data,
  );

  const isResendMfaCodeLoading = useSelector((state: any) => Boolean(state.user.resendMfaCode.__requested));

  const formState = getFormState(initialStoreState, InitialValues);

  const onSubmit = (_values: any) => {
    dispatch(confirmMfaCode(_values));
  };

  useEffect(() => {
    if (confirmSucceded && !isEmpty(confirmMfaData)) {
      dispatch(doRemoveOfferInvestReferrer());

      window.gtag('event', 'sign_up_security_confirm_complete');
      const { accessToken, idToken, tbToken } = confirmMfaData;
      const authToken = `Bearer ${accessToken}; idToken ${idToken}; tbToken ${tbToken}`;
      dispatch(setAuthentication({ authToken, tbToken }));
      navigate(`/complete-account`);

      return;
    }
  }, [confirmSucceded]);

  useEffect(() => {
    return () => {
      dispatch(clearConfirmMfaCode());
      dispatch(clearResendMfaCode());
    };
  }, []);

  return (
    <SignUpWithOfferInvestReferrer
      activeTab={ActiveTab.Security}
      tabs={[ActiveTab.Register, ActiveTab.Address, ActiveTab.Security]}
      cardTitle='Multi-factor authentication'>
      <Formik
        initialValues={formState}
        validationSchema={securityConfirmValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}>
        {form => {
          return (
            <>
              {phoneNumber && (
                <p className={Styles.description}>
                  We sent a six-digit security code to {`XXX - XXX - ${phoneNumber.substr(phoneNumber.length - 4)}`}.
                  Please enter the code in the below box.
                </p>
              )}
              <Input
                tabIndex={0}
                name={FormFields.code}
                type={InputType.Number}
                maxLength={6}
                value={form.values[FormFields.code]}
                {...form}
                placeholder={FormStrings[FormFields.code]}
                customStyles={{ marginBottom: 42, marginTop: 40 }}
              />

              <Checkbox
                name={FormFields.rememberDevice}
                label={FormStrings[FormFields.rememberDevice]}
                value={form.values[FormFields.rememberDevice]}
                disabled
                hidden
                {...form}
              />
              <Button
                className={Styles.resendCodeButton}
                type='link'
                disabled={isResendMfaCodeLoading || isConfirmMfaCodeLoading}
                loading={isResendMfaCodeLoading}
                onClick={() => {
                  dispatch(resendMfaCode({ email, password }));
                }}>
                Resend Code
              </Button>

              <Space size={8} direction='horizontal' align='center' className={Styles.buttonsContainer}>
                <MButton
                  type='tertiary'
                  onClick={() => {
                    navigate('/security', {
                      state: {
                        title: 'back-from-confirm',
                      },
                    });
                  }}>
                  Back
                </MButton>
                <MButton
                  type='secondary'
                  disabled={isConfirmMfaCodeLoading || isResendMfaCodeLoading || !form.isValid}
                  loading={isConfirmMfaCodeLoading}
                  onClick={() => {
                    form.submitForm();
                  }}>
                  Verify
                </MButton>
              </Space>
            </>
          );
        }}
      </Formik>
    </SignUpWithOfferInvestReferrer>
  );
};
