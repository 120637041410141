import { css } from '@emotion/css';

import { Color } from '../../../styles';

export const tooltip = css`
  display: inline-block;
  background-color: ${Color.GRAYSCALE.GRAY2};
  position: relative;
  border: 1px solid ${Color.GRAYSCALE.GRAY4};
  text-decoration: none;
  border-radius: 3px;
  padding: 9px;
  font-size: 12px;
  line-height: 16px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 14px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: ${Color.GRAYSCALE.GRAY4};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
  }
`;
