import { css } from '@emotion/css';
import { AlignItems, ColorConstant, Font, FontWeight, JustifyContent, Spacing } from 'src/styles';

export const rowStyle = css`
  height: 64px;
  ${Spacing.mb16}
  ${AlignItems.center}
  ${JustifyContent.center}
`;

export const offerName = css`
  ${FontWeight.bold}
  ${Font.lg}
  color: ${ColorConstant.GRAY10};
`;

export const offerLogo = css`
  width: 32px;
  height: 32px;
  ${Spacing.mr16}
`;

export const radioGroupContainer = css`
  ${Spacing.mt40}
`;
