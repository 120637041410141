import { css } from '@emotion/css';

import { Color } from '../../../../styles';

export const bankLinkSubDisclaimer = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  color: ${Color.GRAYSCALE.GRAY7};
`;

export const fieldLabel = css`
  font-size: 0.938rem;
  line-height: 1.625rem;
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const editImage = {
  marginTop: 16,
  marginBottom: 16,
  width: 30,
  height: 30,
  fontSize: 30,
  color: Color.BRAND.BRAND6,
};
