import { Row, Tooltip } from 'antd';
import _ from 'lodash';
import * as Yup from 'yup';

import {
  IndividualRepresentationsCheckboxes,
  IndividualRepresentationsSubFields,
  StatementDelivery,
} from '../../../constants/accountCompletion';
import RadioGroup from '../../../lib/FormComponents/RadioGroup';
import ListRow from '../../../lib/Miscellaneous/ListRow/ListRow';
import IndividualRepresentation from '../../../lib/PortfolioComponents/IndividualRepresantations';
import { MenuNames } from '../../../models/menus.enum';
import { Color } from '../../../styles';

import * as Styles from './styles';
import { disclosuresValidation } from './validations';

export const disclosuresSectionFields = (disclosuresInformation: any) => [
  {
    label: 'Statement Delivery',
    displayValue: (
      <div className={Styles.displayValueRadio}>
        {
          _.find(StatementDelivery, {
            value: !_.isEmpty(disclosuresInformation) && (disclosuresInformation?.statementDelivery || null),
          })?.text
        }
        {!_.isEmpty(disclosuresInformation) && disclosuresInformation?.statementDelivery === 'paper' && (
          <Tooltip
            getTooltipContainer={(triggerNode: any) => triggerNode.parentNode}
            placement='top'
            title={
              'Trade Confirmations: $2.00 per confirm Monthly Statement: $5.00 per statement Tax Document: $5.00 per document'
            }
            color={Color.GRAYSCALE.GRAY1}>
            <i style={{ ...Styles.Tooltip }} className={`ri-information-line`} />
          </Tooltip>
        )}
      </div>
    ),
    component: RadioGroup,
    key: 'statementDelivery',
    readOnly: false,
    showInRow: false,
    props: {
      name: 'statementDelivery',
      options: StatementDelivery,
      value:
        !_.isEmpty(disclosuresInformation) && !_.isEmpty(disclosuresInformation.statementDelivery)
          ? disclosuresInformation.statementDelivery
          : null,
    },
  },
  {
    label: 'Individual Representations',
    displayValue: (
      <div>
        {IndividualRepresentationsCheckboxes.map((checkbox, index) => (
          <Row key={`${checkbox.value}_key`}>
            <div style={{ textAlign: 'left' }}>{checkbox.text}</div>
            <Row style={{ width: '100%' }}>
              <ListRow
                customRowStyle={{ borderWidth: 0 }}
                leftComponent={
                  <div
                    style={{
                      marginTop: 9,
                      marginBottom: 15,
                      fontSize: 13,
                      fontWeight: 600,
                      color: '#3C3C3C',
                      textAlign: 'left',
                      paddingLeft:
                        !_.isEmpty(disclosuresInformation) &&
                        !_.isEmpty(disclosuresInformation['disclosures']) &&
                        !_.isEmpty(
                          disclosuresInformation['individualRepresentations'][
                            IndividualRepresentationsSubFields[index].name
                          ],
                        )
                          ? 25
                          : 0,
                    }}>
                    {IndividualRepresentationsSubFields[index].label}
                  </div>
                }
                rightComponent={
                  <div style={{ textAlign: 'right' }}>
                    {!_.isEmpty(disclosuresInformation) &&
                    !_.isEmpty(disclosuresInformation['individualRepresentations']) &&
                    !_.isEmpty(
                      disclosuresInformation['individualRepresentations'][
                        IndividualRepresentationsCheckboxes[index].value
                      ],
                    ) &&
                    !_.isEmpty(
                      disclosuresInformation['individualRepresentations'][
                        IndividualRepresentationsCheckboxes[index].value
                      ][IndividualRepresentationsSubFields[index].name],
                    )
                      ? disclosuresInformation['individualRepresentations'][
                          IndividualRepresentationsCheckboxes[index].value
                        ][IndividualRepresentationsSubFields[index].name]
                      : '-'}
                  </div>
                }
              />
            </Row>
          </Row>
        ))}
      </div>
    ),
    component: IndividualRepresentation,
    customValidationSchema: Yup.object().shape({
      individualRepresentations: Yup.object()
        .shape({
          stakeholder: Yup.lazy(value => {
            if (value !== undefined) {
              return Yup.object().shape({
                tickerSymbol: Yup.string().required('Required'),
              });
            }

            return Yup.mixed().notRequired().default(undefined);
          }),
          industryEmployed: Yup.lazy(value => {
            if (value !== undefined) {
              return Yup.object().shape({
                firmName: Yup.string().required('Required'),
              });
            }

            return Yup.mixed().notRequired().default(undefined);
          }),
        })
        .default(null)
        .nullable(),
    }),
    key: 'individualRepresentations',
    readOnly: false,
    showInRow: false,
    passFormAsProp: true,
    props: {
      name: 'individualRepresentations',
      value:
        !_.isEmpty(disclosuresInformation) && !_.isEmpty(disclosuresInformation.individualRepresentations)
          ? disclosuresInformation.individualRepresentations
          : null,
    },
  },
];

export const getDisclosuresSections = (disclosuresInformation: any) => {
  return {
    menuName: MenuNames.DISCLOSURES,
    path: 'disclosures',
    sections: [
      {
        sectionLabel: MenuNames.DISCLOSURES,
        sectionKey: 'disclosures',
        sectionFields: [
          ...disclosuresSectionFields(
            _.isEmpty(disclosuresInformation)
              ? {}
              : {
                  statementDelivery: disclosuresInformation?.statementDelivery,
                  individualRepresentations: {
                    stakeholder:
                      disclosuresInformation?.stakeholder === false
                        ? undefined
                        : { tickerSymbol: disclosuresInformation?.stakeholder?.tickerSymbol },
                    industryEmployed:
                      disclosuresInformation?.industryEmployed === false
                        ? undefined
                        : { firmName: disclosuresInformation?.industryEmployed?.firmName },
                  },
                },
          ),
        ],
        isCompleted: disclosuresValidation?.isValidSync(
          _.isEmpty(disclosuresInformation)
            ? {}
            : {
                statementDelivery: disclosuresInformation?.statementDelivery,
                individualRepresentations: {
                  stakeholder:
                    disclosuresInformation?.stakeholder === false
                      ? undefined
                      : { tickerSymbol: disclosuresInformation?.stakeholder?.tickerSymbol },
                  industryEmployed:
                    disclosuresInformation?.industryEmployed === false
                      ? undefined
                      : { firmName: disclosuresInformation?.industryEmployed?.firmName },
                },
              },
        ),
        validationSchema: disclosuresValidation,
      },
    ],
  };
};
