import { useEffect, useState } from 'react';

import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { OfferOrderIntent } from 'src/models';

import { DefinitionConstant } from '../../../../../../constants/common.constants';
import { useFindMinimalDepositAmountForInvestment } from '../../../../../../hooks/useFindMinimalDepositAmountForInvestment';
import { InfoLinkButton } from '../../../../../../lib/InfoLinkButton/InfoLinkButton';
import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { MModal } from '../../../../../../lib/MModal/MModal';
import { MTooltip } from '../../../../../../lib/MTooltip/MTooltip';

import * as Styles from './WirePayment.styles';

export interface WirePaymentProps {
  isValid: (value: boolean) => void;
}

export const WirePayment = ({ isValid }: WirePaymentProps) => {
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const accountHolder = useSelector((state: any) => state.accountDetails.accountHolder.data);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const minimalDepositAmount = useFindMinimalDepositAmountForInvestment();

  const onInfoLinkButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderOfferWireInstructions = () => (
    <>
      <span className={Styles.subTitle}>Please send USD incoming wires as follows:</span>
      <p className={Styles.paragraph}>
        <span>BMO Harris Bank</span> <br />
        <span>111 W. Monroe St.</span> <br />
        <span>Chicago, IL 60603</span> <br />
      </p>

      <p className={Styles.paragraph}>
        <span>ABA: 071000288 (for non-domestic USD wires, use SWIFT Code: HATRUS44)</span>
        <br />
        <span>Acct: 3713286</span>
        <br />
        <span>FBO: Apex Clearing Corporation</span>
        <br />
        <span>
          FFC: {accountHolder.primaryAccountHolder.firstName} {accountHolder.primaryAccountHolder.lastName},{' '}
          {accountHolder.accountNumber}
        </span>
      </p>
      <span className={Styles.subTitle}>Please include the address for Apex Clearing on incoming wires:</span>
      <p className={Styles.paragraph}>
        <span>Apex Clearing Corporation</span>
        <br />
        <span>350 N St Paul, Ste. 1400</span>
        <br />
        <span>Dallas, TX 75201</span>
        <br />
      </p>
    </>
  );

  const renderExternalOfferWireInstructions = () => (
    <>
      <span className={Styles.subTitle}>Please send USD incoming wires as follows:</span>
      <p className={Styles.paragraph}>
        <span>{parse(offerOrderIntent.wireInstructions?.bankName ?? '')}</span>
      </p>

      <p className={Styles.paragraph}>
        <span>ABA #: {offerOrderIntent.wireInstructions?.abaNumber}</span>
        <br />
        <span>A/C #: {offerOrderIntent.wireInstructions?.accountNumber}</span>
        <br />
        <span>A/C Name: {offerOrderIntent.wireInstructions?.accountName}</span>
        <br />
        <span>Attn: {offerOrderIntent.wireInstructions?.attentionLine}</span>
      </p>
    </>
  );

  useEffect(() => {
    if (!offerOrderIntent.isExternal && !accountHolder.accountNumber) {
      isValid(false);

      return;
    }
    isValid(true);
  }, []);

  return (
    <div className={Styles.container} data-testid={'confirm-order-modal-wire'}>
      {!offerOrderIntent.isExternal && (
        <div className={Styles.section}>
          <span className={Styles.subTitle}>
            <span>Cash Available</span>
            <MTooltip title={DefinitionConstant.CASH_AVAILABLE} placement='top' />
          </span>
          <CurrencyField value={offerOrderIntent.cashAvailable} />
        </div>
      )}
      <div className={Styles.section}>
        <span className={Styles.subTitle}>Transfer Amount</span>
        <CurrencyField value={minimalDepositAmount} />
      </div>
      {offerOrderIntent.isExternal ? renderExternalOfferWireInstructions() : renderOfferWireInstructions()}
      <span className={Styles.subTitle}>IMPORTANT NOTE:</span>
      <p className={Styles.paragraph}>
        Without FFC Information, your wire may be rejected. If your bank does not have a specific FFC instruction field,
        enter the {offerOrderIntent.isExternal ? "Investor's name " : "Account Holder's Name and Account Number "}in the
        &quot;Message to recipient&quot; or &quot;Message to recipient bank&quot; field.
      </p>
      <InfoLinkButton onClick={onInfoLinkButtonClick} className={Styles.infoLinkButton}>
        More details about Wire Transfer
      </InfoLinkButton>
      <MModal
        title='Wire Transfer'
        visible={isModalOpen}
        tertiaryButtonText='OK'
        onTertiaryButtonClick={closeModal}
        onClose={closeModal}>
        <p className={Styles.paragraph}>
          Incoming wires are free and must be initiated with your financial institution. Wire transfers typically
          require 1 business day to process. Please instruct your bank to include the sender name on the wire. This
          information must be included for the transfer to be credited to{' '}
          {offerOrderIntent.isExternal ? 'the Escrow Account' : 'your account'}. A rejected wire may incur a bank fee.
        </p>
        <span className={Styles.subTitle}>Third-party Wires:</span>
        <p className={Styles.paragraph}>
          {offerOrderIntent.isExternal
            ? 'The Escrow Account typically does not accept third-party wires. If you are investing as an individual funds need to come from a individual or joint bank account, not from a Trust or other entity type. '
            : 'We typically do not accept third-party-wires. '}
          An exception MAY be made if there is confirmation of ownership and the relationship between the account
          holders. Such wire request will be subject to review, require verification prior to acceptance and may be
          returned. Third-party foreign wires are not accepted under any circumstances.
        </p>
        {!offerOrderIntent.isExternal && (
          <p className={Styles.paragraph}>
            A wire from a joint bank/brokerage account may be deposited into a account by either or both joint account
            owners. A wire from an individual bank/brokerage account may be deposited into a joint account if that
            person is one of the account owners.
          </p>
        )}
      </MModal>
    </div>
  );
};
