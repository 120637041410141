import React, { useEffect, useState } from 'react';

import { Row } from 'antd';
import { Formik } from 'formik';
import _ from 'lodash';

import { Color } from '../../../styles';
import MouseTooltip from '../../Miscellaneous/MouseTooltip';

import { FieldWrapperCompType } from './constants';
import * as Styles from './styles';

const FieldWrapper = ({
  form,
  displayValue,
  children,
  name,
  openedField,
  isSectionCompleted,
  initialMode,
  onFieldSubmit,
  readOnly = false,
  customValidationSchema,
  onFieldEdit = () => {},
  onFieldClose = () => {},
  isFieldEditing = false,
}: FieldWrapperCompType) => {
  const [mode, setMode] = useState(initialMode);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    if (isSectionCompleted && !isFieldEditing) {
      setMode('view');
    } else if (!isSectionCompleted && isFieldEditing && !readOnly) {
      setMode('edit');
    }
  }, [isSectionCompleted, displayValue, mode]);

  return mode === 'view' ? (
    <div
      onMouseOver={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      className={readOnly ? Styles.readOnlyValueContainer : Styles.valueContainer}
      style={{}}
      onDoubleClick={() => {
        if (openedField === '' && !readOnly) {
          onFieldEdit(name);
          setMode('edit');
        }
      }}>
      {displayValue && <div className={Styles.displayValue}>{displayValue}</div>}
      {tooltipVisible && (
        <div style={{ zIndex: 1 }}>
          <MouseTooltip
            visible={tooltipVisible}
            text={readOnly ? 'This is a Read-Only Field' : 'Double Click to edit'}
            offsetX={-20}
            offsetY={27}
          />
        </div>
      )}
    </div>
  ) : (
    <Formik
      enableReinitialize
      validationSchema={customValidationSchema ? customValidationSchema : null}
      initialValues={{ [name]: form.values[name] }}
      onSubmit={values => {
        onFieldSubmit(values);
      }}>
      {innerForm => {
        const formToUse = customValidationSchema ? innerForm : form;

        return (
          <div style={{ marginTop: 2 }}>
            <div
              data-testid={`account-${children.props.name}`}
              style={{
                backgroundColor:
                  isSectionCompleted && mode === 'edit' && formToUse?.errors && formToUse?.errors[name]
                    ? Color.RED.RED1
                    : undefined,
              }}>
              {children}
            </div>
            {isSectionCompleted && isFieldEditing && mode === 'edit' && (
              <Row>
                <div style={{ display: 'flex', marginTop: 4 }}>
                  <span
                    className={Styles.editButtons}
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      if (_.isEmpty(formToUse.errors)) {
                        innerForm.submitForm();
                      }
                    }}>
                    <i className='ri-check-line' style={{ fontSize: 11 }} />
                  </span>
                  <span
                    className={Styles.editButtons}
                    onClick={() => {
                      setMode('view');
                      onFieldClose();
                    }}>
                    <i className='ri-close-line' style={{ fontSize: 11 }} />
                  </span>
                </div>
              </Row>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default FieldWrapper;
