import { css } from '@emotion/css';

import {
  AlignItems,
  Color,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  Spacing,
  TextAlign,
} from '../../../../styles';

export const contentView = css`
  align-self: center;
  margin-top: 1.875rem;
  padding-horizontal: 1rem;
`;

export const amountFieldWrapper = css`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #edf2f7;
`;

export const amountInput = css`
  width: 25%;
  margin-top: 24px;
`;

export const amountLabel = css`
  width: 50%;
  margin-top: 23px;
  line-height: 26px;
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const instructionsWrapper = css`
  ${Display.flex}
  ${FlexDirection.column}
  ${JustifyContent.center}
  ${AlignItems.center}
  ${Spacing.p8}
`;

export const instructionLink = css`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${Color.BRAND.BRAND5};
  cursor: pointer;
  ${Spacing.mt8}
`;

// modal
export const senderReceiverTopLabel = css`
  ${FontWeight.semibold};
  text-transform: uppercase;
  color: ${ColorConstant.GRAY6};
`;

export const senderReceiverBottomLabel = css`
  ${Spacing.mt8};
  color: ${ColorConstant.GRAY10};
`;

export const bankLogo = css`
  width: 1.5rem;
  height: 1.5rem;
`;

export const bankCode = css`
  ${FontWeight.bold};
  ${Font.sm};
  ${Spacing.mt8};
  color: ${ColorConstant.GRAY8};
  ${TextAlign.right};
`;

export const bankNameContainer = css`
  ${Display.flex};
  ${JustifyContent.flexEnd};
  ${Spacing.mt8};
`;

export const bankName = css`
  ${Font.sm};
  ${Spacing.mr4};
  color: ${ColorConstant.GRAY6};
`;

export const nickname = css`
  ${Font.sm};
  color: ${ColorConstant.GRAY6};
`;
