import { getAccountStatusClassName } from './constants';
import * as Styles from './UserInfo.styles';

export interface UserInfoProps {
  firstName: string;
  lastName: string;
  accountStatus: string;
}

export const UserInfo = ({ firstName, lastName, accountStatus }: UserInfoProps) => {
  return (
    <div className={Styles.userInfo}>
      <div className={Styles.accountInfo}>
        {firstName && lastName && (
          <div className={(getAccountStatusClassName(accountStatus), Styles.userCircle)}>{`${firstName
            .charAt(0)
            .toUpperCase()}${lastName.charAt(0).toUpperCase()}`}</div>
        )}
        <p>
          {firstName} {lastName}
        </p>
      </div>
    </div>
  );
};
