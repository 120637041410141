export enum ACHRelationshipActionType {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
  EDIT = 'edit',
  UNLINK = 'unlink',
}

export const achRelationshipsActions = [
  {
    label: 'Edit Nickname',
    img: 'ri-edit-line',
    type: ACHRelationshipActionType.EDIT,
  },
  {
    label: 'Unlink',
    img: 'ri-link-unlink',
    type: ACHRelationshipActionType.UNLINK,
  },
];
