import { createContext, useEffect, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { getConfigs, getPlaidLinkToken, unlinkBankAccount } from '../../../../actions';
import { MButton } from '../../../../lib/FormComponents/MButton/MButton';
import Spinner from '../../../../lib/Miscellaneous/Spinner';
import { ConfigTypeEnum } from '../../../../models/configs.enum';
import { ConfigBankLink, Configs } from '../../../../models/configs.model';
import BankAccountItem from '../BankAccountItem/BankAccountItem';
import { ContextCardEnumTypes } from '../BankAccountsAndFunding/constants';

const ModalContext = createContext<string | null>(null);

const config = {
  title: 'Bank Account Linking information!',
  content: <ModalContext.Consumer>{name => `${name}`}</ModalContext.Consumer>,
};

export interface AchRelationshipsProps {
  achRelationships: any;
  selectedBankLink: any;
  loadingAchRelationships: boolean;
  setContextCardVisible: Function;
  setSelectedBankLink: Function;
  accountId: string;
  loading: boolean;
  testId?: string;
  onDeposit: (selectedBankAccount: any) => void;
  onWithdraw: (selectedBankAccount: any) => void;
}

const AchRelationships = ({
  achRelationships,
  selectedBankLink,
  loadingAchRelationships,
  setContextCardVisible,
  setSelectedBankLink,
  accountId,
  loading,
  testId,
  onDeposit,
  onWithdraw,
}: AchRelationshipsProps) => {
  const dispatch = useDispatch();
  const configsList: Configs[] = useSelector((state: any) => state.configs?.configsList?.data?.data || null);
  const [hasBankLinkConfig, setHasBankLinkConfig] = useState<ConfigBankLink | null>();
  const [modal, modalHolder] = Modal.useModal();

  useEffect(() => {
    dispatch(getConfigs());
  }, [dispatch]);

  useEffect(() => {
    const bankLinkConfig: Configs | null =
      configsList?.find((config: Configs) => config.configType === ConfigTypeEnum.BANK_LINK) || null;
    const bankLinkData: ConfigBankLink | null = bankLinkConfig?.appConfig?.bankLink || null;
    setHasBankLinkConfig(bankLinkData);
  }, [configsList]);

  if (loadingAchRelationships !== null) {
    return <Spinner />;
  }

  if (achRelationships) {
    return (
      <div data-testid={testId}>
        {achRelationships.length > 0 &&
          achRelationships.map((account: any) => (
            <BankAccountItem
              key={`${account?.tradingBlockACHRelationship?.id}_${account?.tradingBlockACHRelationship?.bankAccountType}_key`}
              account={account}
              onClick={() => {
                setSelectedBankLink(account);
              }}
              onDeposit={() => onDeposit(account)}
              onWithdrawal={() => onWithdraw(account)}
              onEdit={() => {
                setContextCardVisible(ContextCardEnumTypes.BANK_LINK_EDIT);
              }}
              onUnlink={() => {
                dispatch(
                  unlinkBankAccount({
                    accountId: selectedBankLink.tradingBlockACHRelationship.accountId,
                    relationshipId: selectedBankLink.tradingBlockACHRelationship.id,
                  }),
                );
              }}
            />
          ))}
        {achRelationships?.length < 2 && (
          <MButton
            testId={'money-transfers-btn-link-bank-account'}
            onClick={() => {
              if (hasBankLinkConfig?.disableInFunnel) return modal.info(config);
              dispatch(getPlaidLinkToken(accountId));
              setContextCardVisible(ContextCardEnumTypes.BANK_LINK_ACTION);
            }}
            disabled={loading}>
            <PlusCircleOutlined style={{ fontSize: 16, marginRight: 4 }} />
            Link a new bank account
          </MButton>
        )}
        <ModalContext.Provider value={hasBankLinkConfig?.funnelDisclaimer || ''}>{modalHolder}</ModalContext.Provider>
      </div>
    );
  }

  return null;
};

export default AchRelationships;
