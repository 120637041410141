import { useEffect, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { MTable } from 'src/lib';

import {
  getTradeConfirmationList,
  getMonthlyStatementList,
  getTaxDocumentList,
  revokeTaxDocumentDownloads,
  revokeMonthlyStatementDownloads,
  revokeTradeConfirmationDownloads,
} from '../../actions';
import { DateConstant } from '../../constants/common.constants';
import {
  AccountStatementType,
  MonthlyStatementDto,
  TaxDocumentDto,
  TradeConfirmationDto,
} from '../../dtos/statements.dtos';
import { MyAccountLayout } from '../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarOtherMenuItemKey } from '../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { AccountStatement } from '../../models/statements.models';
import { ScreenBreakpoint } from '../../styles';

import { DownloadMonthlyStatementButton } from './DownloadMonthlyStatementButton';
import { DownloadTaxDocumentButton } from './DownloadTaxDocumentButton';
import { DownloadTradeConfirmationButton } from './DownloadTradeConfirmationButton';

export const AccountStatements = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statements, setStatements] = useState<AccountStatement[]>([]);

  const accountId: number = useSelector((state: any) => state.accountDetails?.accountHolder?.data?.accountId);

  const tradeConfirmationList: TradeConfirmationDto[] | undefined = useSelector(
    (state: any) => state.statements.tradeConfirmations.list.data?.data,
  );
  const isTradeConfirmationListLoading: boolean = useSelector((state: any) =>
    Boolean(state.statements.tradeConfirmations.list.__requested),
  );

  const monthlyStatementList: MonthlyStatementDto | undefined = useSelector(
    (state: any) => state.statements.monthlyStatements.list.data?.data,
  );
  const isMonthlyStatementListLoading: boolean = useSelector((state: any) =>
    Boolean(state.statements.monthlyStatements.list.__requested),
  );

  const taxDocumentList: TaxDocumentDto | undefined = useSelector(
    (state: any) => state.statements.taxDocuments.list.data?.data,
  );
  const isTaxDocumentListLoading: boolean = useSelector((state: any) =>
    Boolean(state.statements.taxDocuments.list.__requested),
  );

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const renderType = (record: AccountStatement) => {
    if (record.type === AccountStatementType.TradeConfirmation) {
      return 'Trade Confirmation';
    }

    if (record.type === AccountStatementType.MonthlyStatement) {
      return 'Monthly Statement';
    }

    return `Tax Document (${record.form})`;
  };

  const renderAccountStatementButton = (record: AccountStatement) => {
    if (record.type === AccountStatementType.TradeConfirmation) {
      return <DownloadTradeConfirmationButton statement={record} key={record.date} />;
    }

    if (record.type === AccountStatementType.MonthlyStatement) {
      return <DownloadMonthlyStatementButton statement={record} key={record.date} />;
    }

    return <DownloadTaxDocumentButton statement={record} key={record.date} />;
  };

  useEffect(() => {
    setIsLoading(isTradeConfirmationListLoading || isMonthlyStatementListLoading || isTaxDocumentListLoading);
  }, [isTradeConfirmationListLoading, isMonthlyStatementListLoading, isTaxDocumentListLoading]);

  useEffect(() => {
    const result: AccountStatement[] = [];

    if (Array.isArray(tradeConfirmationList)) {
      result.push(...tradeConfirmationList);
    }

    if (Array.isArray(monthlyStatementList)) {
      result.push(...monthlyStatementList);
    }

    if (Array.isArray(taxDocumentList)) {
      result.push(...taxDocumentList);
    }

    setStatements(result);
  }, [tradeConfirmationList, monthlyStatementList, taxDocumentList]);

  useEffect(() => {
    dispatch(getTradeConfirmationList({ accountId }));
  }, []);

  useEffect(() => {
    dispatch(getMonthlyStatementList({ accountId }));
  }, []);

  useEffect(() => {
    dispatch(getTaxDocumentList({ accountId }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(revokeTradeConfirmationDownloads());
      dispatch(revokeMonthlyStatementDownloads());
      dispatch(revokeTaxDocumentDownloads());
    };
  }, []);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarOtherMenuItemKey.AccountStatements}
      title='Statements & Documents'>
      <MTable
        columns={[
          {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (value: string) => {
              return <>{moment(value).format(DateConstant.US_FORMAT)} </>;
            },
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (value: AccountStatementType, record: AccountStatement) => renderType(record),
          },
          {
            key: 'download',
            render: (value: undefined, record: AccountStatement) => renderAccountStatementButton(record),
          },
        ]}
        data={statements}
        pagination={{ position: ['bottomRight'] }}
        loading={isLoading}
        scroll={isMobile ? { x: 180 } : {}}
      />
    </MyAccountLayout>
  );
};
