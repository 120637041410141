import { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AuthenticatedUserLoginResponseDto, LoginResponseDto, MfaRequiredLoginResponseDto } from 'src/dtos';
import { LegacyAccountDisclaimer, MAlert } from 'src/lib';
import {
  renderTradingBlockPasswordExpirationErrorMessage,
  renderTradingBlockSamePasswordErrorMessage,
} from 'src/utils';
import { v4 as uuid } from 'uuid';

import { clearLogin, login, setAuthentication, toastMessagesAdd, toastMessagesRemove } from '../../../actions';
import { getFormState } from '../../../actions/forms';
import { InputType } from '../../../lib/FormComponents/Input/constants';
import Input from '../../../lib/FormComponents/Input/Input';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { CardLayout } from '../../../lib/Layout/CardLayout/CardLayout';
import { SpaceSize } from '../../../styles';
import { SeverityEnum } from '../../../typings/commonTypes';
import * as Styles from '../BaseLayout.styles';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { loginValidationSchema } from './validations';

export const Login = () => {
  const formState = getFormState({}, InitialValues);

  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const [loginErrorToastId, setLoginErrorToastId] = useState<string | null>(null);
  const failedLoginMessage = useSelector((state: any) => state.user.login?.message);

  const isLoginLoading: boolean = useSelector((state: any) => state.user.login.isLoading);
  const loginSucceded = useSelector((state: any) => Boolean(state.user.login.__succeeded));
  const loginData: LoginResponseDto | undefined = useSelector((state: any) => state.user.login.data);
  const [credentials, setCredentials] = useState<{ email: string; password: string } | null>(null);

  const isMfaRequired = (loginData?: LoginResponseDto): loginData is MfaRequiredLoginResponseDto => {
    if (!loginData) return false;

    return (loginData as MfaRequiredLoginResponseDto).session !== undefined;
  };

  const isLoggedIn = (loginData?: LoginResponseDto): loginData is AuthenticatedUserLoginResponseDto => {
    if (!loginData) return false;

    return (loginData as AuthenticatedUserLoginResponseDto).user !== undefined;
  };

  const removeLoginToastMessageIfFound = () => {
    if (loginErrorToastId) {
      dispatch(toastMessagesRemove({ key: setLoginErrorToastId }));
    }
  };

  const onSubmit = (_values: any) => {
    removeLoginToastMessageIfFound();
    setCredentials(_values);
    dispatch(login(_values));
  };

  const onResetPasswordRequest = () => {
    navigateTo('/forgot-password');
  };

  useEffect(() => {
    if (failedLoginMessage?.error === 'TradingBlockSamePasswordError') {
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderTradingBlockSamePasswordErrorMessage({ onClick: onResetPasswordRequest }),
        }),
      );
      setLoginErrorToastId(toastId);
    }

    if (failedLoginMessage?.error === 'TradingBlockPasswordExpirationError') {
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderTradingBlockPasswordExpirationErrorMessage({ onClick: onResetPasswordRequest }),
        }),
      );
      setLoginErrorToastId(toastId);
    }
  }, [failedLoginMessage]);

  useEffect(() => {
    if (loginSucceded && isMfaRequired(loginData)) {
      dispatch(setAuthentication({ ...loginData, ...credentials }));

      return navigateTo('/login-mfa');
    }

    if (loginSucceded && isLoggedIn(loginData)) {
      window.gtag('event', 'login', {
        login_method: 'sfa',
        user_properties: {
          user_id: loginData.user.id,
          user_email: credentials?.email,
          user_first_name: loginData.user.firstName,
          user_last_name: loginData.user.lastName,
        },
      });
      const { accessToken, idToken, tbToken } = loginData;
      const authToken = `Bearer ${accessToken}; idToken ${idToken}; tbToken ${tbToken}`;
      dispatch(setAuthentication({ authToken, tbToken }));

      return navigateTo('/');
    }
  }, [loginSucceded, loginData]);

  useEffect(() => {
    return () => {
      dispatch(clearLogin());
      removeLoginToastMessageIfFound();
    };
  }, []);

  return (
    <CardLayout
      title='Welcome!'
      subtitle='In order to Invest in an Offer, you will first need to “Sign up” or “Log in” to My IPO'>
      <Formik
        initialValues={formState}
        validationSchema={loginValidationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}>
        {form => (
          <form onSubmit={form.handleSubmit}>
            <MAlert type='info' description={<LegacyAccountDisclaimer />} />
            <div className={Styles.formItemsContainer}>
              <Input
                {...form}
                tabIndex={0}
                name={FormFields.email}
                value={form.values[FormFields.email]}
                placeholder={FormStrings[FormFields.email]}
                dataTestId={'email-testId'}
              />

              <Input
                {...form}
                name={FormFields.password}
                type={InputType.Password}
                value={form.values[FormFields.password]}
                placeholder={FormStrings[FormFields.password]}
                customStyles={{
                  marginTop: SpaceSize.is24,
                }}
                dataTestId={'password-testId'}
              />
              <Link className={Styles.link} to='/forgot-password' data-testid='login-forgot-password-testId'>
                Forgot your password?
              </Link>
            </div>

            <div className={Styles.buttonsContainer}>
              <MButton
                type='secondary'
                htmlType='submit'
                disabled={isLoginLoading || !form.isValid}
                loading={isLoginLoading}
                testId={'login-btn-login'}>
                Log in
              </MButton>
            </div>
            <div className={Styles.alreadyHaveAccountLabel}>
              <span className={Styles.space} data-testid='do-not-have-acc-testId'>
                Don’t have an account?
              </span>
              <Link to='/register' className={Styles.linkButton} data-testid='login-txt-signup-testId'>
                Sign up now.
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </CardLayout>
  );
};
