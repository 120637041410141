import React, { useState } from 'react';

import { Col, Row, Space } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { acceptAccountAgreements, declineAccountAgreements, toastMessagesAdd } from '../../../actions';
import Checkbox from '../../../lib/FormComponents/CheckBox';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { MDivider } from '../../../lib/MDivider/MDivider';
import { MModal } from '../../../lib/MModal/MModal';
import { ScreenBreakpoint } from '../../../styles';
import { SeverityEnum } from '../../../typings/commonTypes';

import { accountAgreementLinks } from './fields';
import * as Styles from './styles';
import { accountAgreementsValidation } from './validations';

export const AccountAgreements = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const accountUuid = useSelector((state: any) => state.accountDetails.accountHolder?.data?.id);
  const agreeElectronicConsent = useSelector(
    (state: any) => state.accountDetails?.accountAgreements?.data?.agreeElectronicConsent,
  );
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const onSubmit = async () => {
    dispatch(acceptAccountAgreements());
    dispatch(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Account Agreement was successfully saved',
      }),
    );
    window.gtag('event', 'account_agreements_complete');
    navigate(`/accounts/${accountUuid}/sign-application`);
  };

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.AccountAgrements}
      title={isMobile ? undefined : 'Account Agreements'}>
      <Row>
        <Col span={18} xs={24} sm={24}>
          <div className={Styles.formCard}>
            <div className={Styles.sectionHeader}>Account Agreements</div>
            <Formik
              initialValues={{
                agreeElectronicConsent: false,
              }}
              validationSchema={accountAgreementsValidation}
              onSubmit={() => {
                onSubmit();
              }}>
              {form => {
                return (
                  <>
                    <Checkbox
                      name='agreeElectronicConsent'
                      label='I agree to use Electronic Consent for agreements'
                      disabled={agreeElectronicConsent}
                      value={agreeElectronicConsent ? true : form.values.agreeElectronicConsent}
                      {...form}
                    />
                    {!agreeElectronicConsent && (
                      <div className={Styles.agreementAcceptText}>
                        By click the “Accept” button below, year agree that you have received, read and understand each
                        of the agreements below, and you agree to all the provision in each document
                      </div>
                    )}
                    {accountAgreementLinks.map(agreement => (
                      <div key={`${agreement.label}_key`} className={Styles.agreementLink}>
                        <i className='ri-download-2-fill' />
                        <a className={Styles.link} href={agreement.url} target='_blank' rel='noreferrer'>
                          {agreement.label}
                        </a>
                      </div>
                    ))}
                    <MDivider />
                    {!agreeElectronicConsent && (
                      <Space size={8} direction={'horizontal'} className={Styles.buttonsContainer} align='center'>
                        <MButton
                          testId={'account-btn-decline-account-agreements'}
                          type='tertiary'
                          danger
                          onClick={() => {
                            setIsModalVisible(true);
                          }}>
                          Decline
                        </MButton>
                        <MButton
                          testId={'account-btn-accept-account-agreements'}
                          type='secondary'
                          disabled={!form.isValid}
                          onClick={() => {
                            form.submitForm();
                          }}>
                          Accept
                        </MButton>
                      </Space>
                    )}
                  </>
                );
              }}
            </Formik>
          </div>
        </Col>

        <MModal
          visible={isModalVisible}
          title='Warning'
          primaryButtonText='Decline'
          tertiaryButtonText='Close'
          onPrimaryButtonClick={async () => {
            dispatch(declineAccountAgreements());
            setIsModalVisible(false);
            navigate('/summary');
          }}
          onTertiaryButtonClick={() => {
            setIsModalVisible(false);
          }}
          onClose={() => {
            setIsModalVisible(false);
          }}>
          If you decide to decline Account Agreements you cannot complete account creation and you will not be able to
          invest on MY IPO!
        </MModal>

        <Col span={3} />
      </Row>
    </MyAccountLayout>
  );
};
