import { css } from '@emotion/css';

import { ScreenBreakpoint } from '../../../styles';

export const popupContainer = css`
  .ant-select-dropdown {
    width: 60px !important;
  }
  &:nth-child(2) {
    margin-right: 10%;
    margin-left: 10%;
  }
  @media (max-width: ${ScreenBreakpoint.mobile.min}) {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
`;
