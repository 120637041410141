/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { confirmCardPayment, confirmCardPaymentFailed, confirmCardPaymentSucceeded } from 'src/actions';
import { ErrorCode } from 'src/constants';
import { BeforeCardPaymentError } from 'src/errors';
import { MAlert, UnknownErrorMessage } from 'src/lib';

import { UserDto } from '../../../../../../dtos/users.dtos';
import Checkbox from '../../../../../../lib/FormComponents/CheckBox';
import { MModal } from '../../../../../../lib/MModal/MModal';
import { OfferOrder } from '../../../../../../models/offer-orders.model';
import { assertNonNullable } from '../../../../../../utils/assertNonNullable';
import { handleConfirmPaymentError } from '../../../../../../utils/handleConfirmPaymentError';
import { Logger } from '../../../../../../utils/Logger';

import * as Styles from './CardPaymentForm.styles';

export interface CardPaymentFormProps {
  isValid: (value: boolean) => void;
  clientSecret: string;
}

export const CardPaymentForm = ({ isValid }: CardPaymentFormProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const user: UserDto = useSelector((state: any) => state.user.authenticated.data);
  const submitCardPayment = useSelector((state: any) => state.offerOrders.submitCardPayment);
  const newOfferOrder: OfferOrder = useSelector((state: any) => state.offerOrders.create?.data);
  const [isCardFormValid, setIsCardFormValid] = useState<boolean>(false);
  const [isAddressFormValid, setIsAddressFormValid] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [herebyDisclaimerValue, setHerebyDisclaimerValue] = useState<boolean>(false);
  const [hasUnknownLoadError, setHasUnknownLoadError] = useState<boolean>(false);

  const getOfferOrderSucceededUrl = () => {
    const currentLocation = window.location.href.split('/');
    const succeededPath = currentLocation.splice(0, currentLocation.length - 1);
    succeededPath.push(newOfferOrder.id);

    return succeededPath.join('/');
  };

  const renderModalContent = () => (
    <div>
      <ul>
        <li>
          <p>
            It is not recommended that you use borrowed funds from your credit card to make this investment. Use of a
            credit card to purchase this investment should be purely for convenience.
          </p>
        </li>
        <li>
          <p>
            Your order will remain pending and can be cancelled until the closing date. If your Order is accepted or
            completed on My IPO, you cannot cancel your Order. You will be responsible for the full purchase of the
            investment on your credit card.
          </p>
        </li>

        <li>
          <p>
            Securities purchased by credit card are funded and held outside of your My IPO account. Payment made by
            credit card will be sent to the Issuers escrow account and then sent to the Issuer if accepted once the
            closing occurs. If you Order is completed, your Shares will be issued after closing and held in electronic
            form at the Issuer’s Transfer Agent. Some brokerage firms may not hold these securities or may charge fees
            to deposit these securities.
          </p>
        </li>

        <li>
          <p>
            Debt-based funding drastically increases the pressure of making successful investment decisions, especially
            since it puts a clock on every investment choice that you make. The debt is going to come due eventually,
            and if the investment has not appreciated by then, you’ll be left profit-less and will still have the
            obligation to come up with the funds necessary to pay back your lender. Failure to repay a credit card
            company can lead to expensive interest charges, delinquency, and debilitating credit score damage.
          </p>
        </li>

        <li>
          <p>
            Credit cards have among the highest interest rates of any consumer lending product. So, if you’re running up
            a balance funding your investment, you’re going to need large potential profits just to break even.
          </p>
        </li>
      </ul>
    </div>
  );

  const onSubmit = async () => {
    try {
      assertNonNullable(stripe, 'Stripe');
      assertNonNullable(elements, 'StripeElements');

      dispatch(confirmCardPayment());

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: getOfferOrderSucceededUrl(),
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        Logger.error(`Confirm card payment error. Received: ${JSON.stringify(error)}`);
        dispatch(confirmCardPaymentFailed(handleConfirmPaymentError(error)));
        navigate(`../failed`);

        return;
      }

      dispatch(confirmCardPaymentSucceeded());
    } catch (error) {
      Logger.error(`Before confirm card payment error. Received: ${JSON.stringify(error)}`);
      dispatch(confirmCardPaymentFailed(new BeforeCardPaymentError()));
    }
  };

  const onAddressChange = (event: StripeAddressElementChangeEvent) => {
    setIsAddressFormValid(event.complete);
    isValid(event.complete && isCardFormValid && herebyDisclaimerValue);
  };

  const onPaymentChange = (event: StripePaymentElementChangeEvent) => {
    setIsCardFormValid(event.complete);
    isValid(event.complete && isAddressFormValid && herebyDisclaimerValue);
  };

  const onCreditCardTermsAndReasons = (event: any) => {
    event.stopPropagation();
    setIsVisible(true);
  };

  useEffect(() => {
    if (submitCardPayment) {
      onSubmit();
    }
  }, [submitCardPayment]);

  if (hasUnknownLoadError) {
    return (
      <MAlert
        type='error'
        description={<UnknownErrorMessage errorCode={ErrorCode.LOAD_STRIPE_ELEMENT} />}
        closable={false}
      />
    );
  }

  return (
    <>
      <AddressElement
        id='address-element'
        options={{
          mode: 'billing',
          allowedCountries: ['US'],
          blockPoBox: true,
          display: { name: 'full' },
          defaultValues: {
            name: `${user.firstName} ${user.lastName}`,
            address: {
              line1: user.account?.primaryAccountHolder?.physicalAddress?.address1,
              line2: user.account?.primaryAccountHolder?.physicalAddress?.address2,
              city: user.account?.primaryAccountHolder?.physicalAddress?.city,
              postal_code: user.account?.primaryAccountHolder?.physicalAddress?.postalCode,
              state: user.account?.primaryAccountHolder?.physicalAddress?.state,
              country: 'US',
            },
          },
        }}
        onChange={onAddressChange}
        onLoadError={error => {
          setHasUnknownLoadError(true);
          isValid(false);
          Logger.error(error);
        }}
      />
      <PaymentElement
        id='payment-element'
        options={{
          layout: 'tabs',
        }}
        onChange={onPaymentChange}
        onLoadError={error => {
          setHasUnknownLoadError(true);
          isValid(false);
          Logger.error(error);
        }}
      />
      <Row gutter={[8, 8]} className={Styles.herebyDisclaimerContainer}>
        <Col span={1}>
          <Checkbox
            testId={'offer-order-check-card-payment-disclaimer'}
            name='notBorrowingFundsConfirm'
            value={herebyDisclaimerValue}
            setFieldValue={(name: string, value: boolean) => {
              setHerebyDisclaimerValue(value);
              isValid(isCardFormValid && isAddressFormValid && value);
            }}
            setFieldTouched={() => {}}
          />
        </Col>
        <Col span={23}>
          <div className={Styles.herebyDisclaimer} data-testid={'confirm-order-txt-agree'}>
            I hereby confirm that I am not borrowing funds from my credit card to make this investment. Using my credit
            card to purchase this investment is purely for convenience. By executing a subscription agreement, I
            understand that my ability to dispute the charge on my credit card to fund this investment is unavailable
            once shares have been issued. I have reviewed{' '}
            <a onClick={onCreditCardTermsAndReasons} className={Styles.creditCardTermsAndReasonsLink}>
              Credit Card Terms and Reasons Not to Invest with a Credit Card
            </a>
          </div>
        </Col>
      </Row>
      <MModal
        title='Credit Card Terms and Reasons Not to Invest with a Credit Card'
        visible={isVisible}
        centered
        primaryButtonType='tertiary'
        primaryButtonText='OK'
        onPrimaryButtonClick={() => setIsVisible(false)}
        closable
        onClose={() => setIsVisible(false)}>
        {renderModalContent()}
      </MModal>
    </>
  );
};
