import { AccountsApi } from './AccountsApi';
import { BalancesApi } from './BalancesApi';
import { OfferOrdersApi } from './OfferOrdersApi';
import { OrdersApi } from './OrdersApi';
import { PrivateQuotesApi } from './PrivateQuotesApi';
import { QuoteMediaApi } from './QuoteMediaApi';
import { StatementsApi } from './StatementsApi';

export class ClientApi {
  accounts: AccountsApi;
  balances: BalancesApi;
  statements: StatementsApi;
  orders: OrdersApi;
  offerOrders: OfferOrdersApi;
  privateQuotes: PrivateQuotesApi;
  quoteMedia: QuoteMediaApi;

  constructor() {
    this.accounts = new AccountsApi();
    this.balances = new BalancesApi();
    this.statements = new StatementsApi();
    this.orders = new OrdersApi();
    this.offerOrders = new OfferOrdersApi();
    this.privateQuotes = new PrivateQuotesApi();
    this.quoteMedia = new QuoteMediaApi();
  }
}

const clientApi = new ClientApi();

export const getClientApi = () => {
  return clientApi;
};
