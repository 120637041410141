import { Col, Divider, Row, Space } from 'antd';
import { MTag } from 'src/lib';
import { findOfferClosedDate, isOfferClosed } from 'src/utils';

import { OfferWatchDto } from '../../../../dtos/offerWatches.dtos';
import { MButton } from '../../../../lib/FormComponents/MButton/MButton';
import { Offer } from '../../../../models/offers.models';
import { Images } from '../../../../styles';

import * as Styles from './IndicationOfInterestCard.styles';

const IndicationOfInterestCard = ({
  offerWatch,
  offer,
  onCancelIndication,
  onViewOffer,
}: {
  offerWatch: OfferWatchDto;
  offer?: Offer;
  onCancelIndication: Function;
  onViewOffer: Function;
}) => {
  return (
    <div className={Styles.formCard}>
      <Row justify='space-between'>
        <Col className={Styles.offerLogo}>
          <img alt='offer_logo' src={offer?.logoUrl || Images.DefaultOffering} />
        </Col>
        <Col className={Styles.offerName}>
          <p>{offer?.name}</p>
          <div className={Styles.tagsContainer}>
            {offer && isOfferClosed(offer) && <MTag type='red'>*Closed on {findOfferClosedDate(offer) ?? '-'}</MTag>}
            {offer && !isOfferClosed(offer) && (offer?.isComingSoon || offer?.new) && (
              <MTag type='lightblue'>{offer?.isComingSoon ? 'Coming Soon' : 'New'}</MTag>
            )}
          </div>
        </Col>
      </Row>
      <Divider className={Styles.divider} />
      <Row justify='space-between'>
        <Col>
          <div className={Styles.desiredAmountLabel}>Desired Investment Amount</div>
          <div className={Styles.desiredAmountValue}>{offerWatch?.investmentRange || 'N/A'}</div>
        </Col>
        <Col>
          <Space size={8} direction='horizontal' align='center'>
            {offer?.status === 'ACTIVE' ? null : (
              <MButton type='tertiary' onClick={() => onCancelIndication(offerWatch.id, offer?.name)}>
                Cancel Indication
              </MButton>
            )}
            <MButton onClick={() => onViewOffer(offer?.id)} type={offer?.status === 'ACTIVE' ? 'primary' : 'secondary'}>
              {offer?.status === 'ACTIVE' ? 'Invest' : 'View Offer'}
            </MButton>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default IndicationOfInterestCard;
