import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { doGetAccountBalance } from 'src/actions';

import { AccountBalanceDetails } from '../models/accountBalances.models';
import { CustomHookBaseOptions } from '../models/common.model';

export interface UseAccountBalanceReturnType {
  cashAvailable: number | typeof NaN;
  cashAvailableForWithdrawal: number | typeof NaN;
  cashBalance: number | typeof NaN;
  availableFunds: number | typeof NaN;
  availableFundsForWithdrawal: number | typeof NaN;
  ordersTotal: number | typeof NaN;
  pendingOfferOrdersTotal: number | typeof NaN;
  isAccountBalanceLoading: boolean;
  hasAccountBalanceError: boolean;
  accountBalanceError?: string;
}

export interface UseAccountBalanceOptions extends CustomHookBaseOptions {}

export const useAccountBalance = (
  { skip }: UseAccountBalanceOptions = { skip: false },
): UseAccountBalanceReturnType => {
  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.accountDetails?.accountHolder?.data);
  const accountBalance: AccountBalanceDetails | undefined = useSelector(
    (state: any) => state.accountDetails.accountBalance?.data,
  );
  const isAccountBalanceLoading: boolean = useSelector((state: any) =>
    Boolean(state.accountDetails.accountBalance?.__requested),
  );
  const hasAccountBalanceFailed = useSelector((state: any) => Boolean(state.accountDetails.accountBalance?.__failed));
  const accountBalanceError: string | undefined = useSelector(
    (state: any) => state.accountDetails.accountBalance?.__message,
  );

  useEffect(() => {
    if (!accountBalance && !isAccountBalanceLoading && !skip && !hasAccountBalanceFailed) {
      dispatch(doGetAccountBalance(account.accountId));
    }
  }, [dispatch]);

  return {
    cashAvailable: hasAccountBalanceFailed ? NaN : accountBalance?.cashAvailable ?? 0,
    cashAvailableForWithdrawal: hasAccountBalanceFailed ? NaN : accountBalance?.cashAvailableForWithdrawal ?? 0,
    cashBalance: hasAccountBalanceFailed ? NaN : accountBalance?.cashBalance ?? 0,
    availableFunds: hasAccountBalanceFailed ? NaN : accountBalance?.availableFunds ?? 0,
    availableFundsForWithdrawal: hasAccountBalanceFailed ? NaN : accountBalance?.availableFundsForWithdrawal ?? 0,
    ordersTotal: hasAccountBalanceFailed ? NaN : accountBalance?.ordersTotal ?? 0,
    pendingOfferOrdersTotal: hasAccountBalanceFailed ? NaN : accountBalance?.pendingOfferOrdersTotal ?? 0,
    isAccountBalanceLoading,
    hasAccountBalanceError: hasAccountBalanceFailed,
    accountBalanceError,
  };
};
