import { css } from '@emotion/css';

import { Color } from '../../../styles';

export const menuName = css`
  font-size: 1.75rem;
  line-height: 2rem;
  color: ${Color.BRAND.BRAND6};
  margin-top: 4rem;
  margin-left: 4.625rem;
`;

export const formCard = css`
  border: none;
  box-shadow: 0rem 0.25rem 1rem rgba(2, 85, 163, 0.15);
  padding: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const fieldLabel = css`
  line-height: 1.625rem;
  font-weight: 600;
  color: #3c3c3c;
`;

export const sectionHeader = css`
  margin-bottom: 1rem;

  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #3c3c3c;
`;

export const noContactPersonLabel = css`
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #3c3c3c;
`;

export const displayValue = css`
  text-align: right;
  color: ${Color.GRAYSCALE.GRAY7} !important;
  margin-left: auto;
`;

export const displayValueRadio = css`
  line-height: 1.438rem;
  letter-spacing: 0.031rem
  color: ${Color.GRAYSCALE.GRAY8} !important;
  display: flex;
  align-items: center;
`;

export const displayValueRadioSubLabel = css`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${Color.GRAYSCALE.GRAY7} !important;
  display: flex;
`;

export const Tooltip = {
  fontSize: 15,
  marginLeft: 6,
  cursor: 'pointer',
  color: Color.BRAND.BRAND6,
  marginTop: 3,
};
