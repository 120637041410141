import { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { isEmpty, find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AuthenticatedUserLoginResponseDto } from 'src/dtos';

import { signUp, clearSignup, clearLogin, setAuthentication } from '../../../actions';
import { getFormState } from '../../../actions/forms';
import { countryCodes } from '../../../constants/countryCodes';
import FlagsDropdown from '../../../lib/FormComponents/Dropdown/FlagsDropDown';
import { InputType, MaskType } from '../../../lib/FormComponents/Input/constants';
import Input from '../../../lib/FormComponents/Input/Input';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import PasswordStrengthBar from '../../../lib/Miscellaneous/PasswordStrengthBar';
import * as Styles from '../BaseLayout.styles';

import { FormFields, FormStrings } from './constants';
import * as RegisterStyles from './Register.styles';
import { InitialValues } from './schema';
import { registerValidationSchema } from './validations';

export const RegisterForm = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const isRegisterLoading = useSelector((state: any) => Boolean(state.user.signUp.__requested));
  const hasRegisterSucceeded = useSelector((state: any) => Boolean(state.user.signUp.__succeeded));
  const userSignup = useSelector((state: any) => state.user.signUp.data);
  const isLoginLoading = useSelector((state: any) => Boolean(state.user.login.__requested));
  const loginSucceded = useSelector((state: any) => Boolean(state.user.login.__succeeded));
  const loginData: AuthenticatedUserLoginResponseDto | undefined = useSelector((state: any) => state.user.login.data);
  const [credentials, setCredentials] = useState<{ email: string; password: string } | null>(null);

  const formState = getFormState({}, InitialValues);

  const onSubmit = (_values: any) => {
    dispatch(signUp(_values));
    setCredentials({ email: _values.email, password: _values.password });
  };

  useEffect(() => {
    if (hasRegisterSucceeded && loginSucceded && !isEmpty(loginData) && !isEmpty(userSignup)) {
      window.gtag('event', 'sign_up_register_complete', {
        user_properties: {
          user_id: loginData.user.id, // TODO: return user id from /signup and use it
          user_email: userSignup.email,
          user_first_name: userSignup.firstName,
          user_last_name: userSignup.lastName,
        },
      });
      const { accessToken, idToken, tbToken } = loginData;
      const authToken = `Bearer ${accessToken}; idToken ${idToken}; tbToken ${tbToken}`;
      dispatch(setAuthentication({ ...loginData, authToken, ...credentials }));
      navigateTo(`/address`);
    }
  }, [hasRegisterSucceeded, loginSucceded, loginData, userSignup]);

  useEffect(() => {
    return () => {
      dispatch(clearSignup());
      dispatch(clearLogin());
    };
  }, []);

  return (
    <>
      <Formik
        validationSchema={registerValidationSchema}
        initialValues={formState}
        onSubmit={onSubmit}
        validateOnBlur={false}>
        {form => {
          return (
            <>
              <div className={Styles.formItemsContainer}>
                <div className={`${RegisterStyles.inputRow} ${RegisterStyles.emailAndPhoneNumberInputRow}`}>
                  <div className={RegisterStyles.inputColumnLeft}>
                    <Input
                      {...form}
                      tabIndex={0}
                      name={FormFields.email}
                      value={form.values[FormFields.email]}
                      placeholder={FormStrings[FormFields.email]}
                    />
                  </div>
                  <div className={RegisterStyles.inputColumnRight}>
                    <div className={RegisterStyles.phoneNumberRow}>
                      <FlagsDropdown
                        name={FormFields.countryCode}
                        {...form}
                        customStyles={{ marginBottom: 0 }}
                        value={form.values[FormFields.countryCode]}
                      />

                      <div className={RegisterStyles.phoneNumberInputContainer}>
                        <Input
                          name={FormFields.phone}
                          type={InputType.PhoneNumber}
                          value={form.values[FormFields.phone]}
                          mask={MaskType.Custom}
                          customMask={
                            find(countryCodes, {
                              code: form.values[FormFields.countryCode],
                            })?.phoneMask
                          }
                          {...form}
                          placeholder={FormStrings[FormFields.phone]}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={RegisterStyles.inputRow}>
                  <div className={RegisterStyles.inputColumnLeft}>
                    <Input
                      {...form}
                      name={FormFields.firstName}
                      value={form.values[FormFields.firstName]}
                      placeholder={FormStrings[FormFields.firstName]}
                      customStyles={{
                        paddingTop: 5,
                      }}
                    />
                  </div>

                  <div className={RegisterStyles.inputColumnRight}>
                    <Input
                      {...form}
                      name={FormFields.middleName}
                      value={form.values[FormFields.middleName]}
                      placeholder={FormStrings[FormFields.middleName]}
                      customStyles={{
                        paddingTop: 5,
                      }}
                    />
                  </div>
                </div>

                <div className={RegisterStyles.inputRow}>
                  <div className={RegisterStyles.inputColumnLeft}>
                    <Input
                      {...form}
                      name={FormFields.lastName}
                      value={form.values[FormFields.lastName]}
                      placeholder={FormStrings[FormFields.lastName]}
                      customStyles={{
                        paddingTop: 5,
                      }}
                    />
                  </div>

                  <div className={RegisterStyles.inputColumnRight}>
                    <Input
                      {...form}
                      name={FormFields.suffix}
                      value={form.values[FormFields.suffix]}
                      placeholder={FormStrings[FormFields.suffix]}
                      customStyles={{
                        paddingTop: 5,
                      }}
                    />
                  </div>
                </div>

                <div className={RegisterStyles.inputRow}>
                  <div className={RegisterStyles.inputColumnLeft}>
                    <Input
                      {...form}
                      name={FormFields.password}
                      value={form.values[FormFields.password]}
                      placeholder={FormStrings[FormFields.password]}
                      type={InputType.Password}
                      hideErrorMessage
                      customStyles={{
                        paddingTop: 5,
                      }}
                    />
                  </div>
                  <div className={RegisterStyles.inputColumnRight}>
                    <Input
                      {...form}
                      name={FormFields.confirmPassword}
                      value={form.values[FormFields.confirmPassword]}
                      placeholder={FormStrings[FormFields.confirmPassword]}
                      type={InputType.Password}
                      hideErrorMessage
                      customStyles={{
                        paddingTop: 5,
                      }}
                    />
                  </div>
                </div>

                <div className={RegisterStyles.inputRow}>
                  <PasswordStrengthBar
                    password={form.values[FormFields.password]}
                    confirmPassword={form.values[FormFields.confirmPassword]}
                    firstName={form.values[FormFields.firstName]}
                    lastName={form.values[FormFields.lastName]}
                    showStrengthLabel
                    showValidationRules
                  />
                </div>
              </div>

              <div className={Styles.buttonsContainer}>
                <MButton
                  testId={'register-btn-signup'}
                  type='secondary'
                  onClick={() => {
                    form.submitForm();
                  }}
                  loading={isRegisterLoading || isLoginLoading}
                  disabled={isRegisterLoading || isLoginLoading || !form.isValid}>
                  Sign up
                </MButton>
              </div>
            </>
          );
        }}
      </Formik>
      <div className={Styles.alreadyHaveAccountLabel}>
        <span className={Styles.space}>Already have an account?</span>
        <Link to='/login' className={Styles.linkButton}>
          Log in here.
        </Link>
      </div>
    </>
  );
};
