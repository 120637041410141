import { css } from '@emotion/css';

import {
  AlignItems,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
} from '../../../../styles';

export const contentView = css`
  align-self: center;
  ${Spacing.mt32};
  padding-horizontal: 1rem;
`;

export const amountFieldWrapper = css`
  ${Display.flex};
  ${JustifyContent.spaceBetween};
  border-bottom: 1px solid ${ColorConstant.GRAY3};
`;

export const amountLabel = css`
  width: 50%;
  ${Spacing.mt24};
  color: ${ColorConstant.GRAY8};
`;

export const amountInput = css`
  width: 25%;
  ${Spacing.mt24};
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: 50%;
  }
`;

export const noAvailableFundsWrapper = css`
  ${Display.flex}
  ${FlexDirection.column};
`;

//modal
export const senderReceiverTopLabel = css`
  ${FontWeight.semibold};
  text-transform: uppercase;
  color: ${ColorConstant.GRAY6};
`;

export const senderReceiverBottomLabel = css`
  ${Spacing.mt8};
  color: ${ColorConstant.GRAY10};
`;

export const bankLogo = css`
  width: 1.5rem;
  height: 1.5rem;
`;

export const bankCode = css`
  ${FontWeight.bold};
  ${Font.sm};
  ${Spacing.mt8};
  color: ${ColorConstant.GRAY8};
  ${TextAlign.right};
`;

export const bankNameContainer = css`
  ${Display.flex};
  ${JustifyContent.flexEnd};
  ${Spacing.mt8};
`;

export const bankName = css`
  ${Font.sm};
  ${Spacing.mr4};
  color: ${ColorConstant.GRAY6};
`;

export const nickname = css`
  ${Font.sm};
  color: ${ColorConstant.GRAY6};
`;

export const spinner = (height: number) => css`
  height: ${height}px;
`;

export const cashAvailableLabel = css`
  ${Font.lg}
`;

export const cashAvailable = css`
  ${Font.lg}
  ${Display.flex}
  ${JustifyContent.flexEnd}
  ${AlignItems.flexEnd}
`;
