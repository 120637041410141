import { NavLink } from 'react-router-dom';

import Checkbox from '../../../lib/FormComponents/CheckBox';
import Signature from '../../../lib/Miscellaneous/Signature/Signature';
import { MenuNames } from '../../../models/menus.enum';
import { Color } from '../../../styles';

import * as Styles from './styles';
import { customerAgreementValidation } from './validations';

export const customerAgreementSectionFields = (signature: any) => [
  {
    label: 'Signature',

    component: Signature,
    key: 'signature',
    readOnly: false,
    showInRow: false,
    props: {
      disabled: true,
      name: 'signature',
      value: signature && signature !== '' ? signature : null,
    },
  },
];

export const getCustomerAgreementSections = (signature: any) => {
  return {
    menuName: MenuNames.SIGN_APPLICATION,
    path: 'customer-agreement',
    sections: [
      {
        sectionLabel: 'Signature',
        sectionKey: 'signature',
        sectionFields: [...customerAgreementSectionFields(signature)],
        isCompleted: customerAgreementValidation?.isValidSync(signature),
        validationSchema: customerAgreementValidation,
      },
    ],
  };
};

export const customerAgreementAcknowledge = (form: any) => (
  <div>
    <div>
      <h4 className={Styles.boldLabel}>By signing below and pressing “Create Account”, I acknowledge that:</h4>
      <ol className={Styles.normalLabel} style={{ marginLeft: -15 }}>
        <li>
          I am opening a brokerage account with Apex Clearing Corporation (&quot;Apex&quot;), Cambria Capital, LLC dba
          My IPO’s (&quot;My IPO&quot;) chosen custodian.
        </li>
        <li>I am the Primary Account Holder listed below.</li>
        <li>
          I am a U.S. Person, and the tax identification number provided is my true taxpayer identification number.
        </li>
        <li>
          I am not subject to backup withholding as a result of my failure to report all interest and dividends, or the
          Internal Revenue Service (IRS) has notified me that I am no longer subject to backup withholding.
        </li>
        <Checkbox
          customStyles={{ marginLeft: 20 }}
          name='isSubjectToBackupWithholding'
          label='Check here ONLY if you are subject to backup withholding.'
          value={form.values.isSubjectToBackupWithholding}
          {...form}
        />
        <li>
          I am a U.S. Person, and the tax identification number provided is my true taxpayer identification number. I
          agree that My IPO and Apex do not recommend any securities or investments, do not question, investigate,
          analyze, monitor, or otherwise evaluate any of the investment decisions of any investor or the decisions or
          directions that may be provided by any individual or entity with authority over this account, nor do they
          determine, disclose or research risk factors that may be applicable to investing in securities.
        </li>
        <li>
          I have received, read, and agree to the terms and conditions set forth in{' '}
          <NavLink to='/customer-agreement-review' style={{ color: Color.BRAND.BRAND6, cursor: 'pointer' }}>
            the My IPO, TradingBlock and Apex customer agreements and disclosures.
          </NavLink>
        </li>
        <li>
          My IPO, TradingBlock, and Apex will share your personal and financial information between themselves as needed
          to service your account and consistent with each firm’s privacy policy.
        </li>
      </ol>
    </div>
    <div className={Styles.normalLabel}>
      By signing below you agree to a pre-dispute
      <NavLink to='/arbitration-clauses' style={{ color: Color.BRAND.BRAND6 }}>
        {' '}
        arbitration clause{' '}
      </NavLink>
      located in the My IPO and Apex customer agreements.
      <br />
      The Internal Revenue Service does not require consent to any provision of this document other than the
      certifications required to avoid backup withholding.
    </div>
  </div>
);
