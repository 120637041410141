import { Dispatch } from 'react';

import { offerWatchesDeleteRequested } from 'src/actions';
import { OfferWatchDto, CreateOfferWatchDto, UserDto } from 'src/dtos';
import { OfferDetails } from 'src/models';

import IndicationOfInterestForm from '../../IndicationsOfInterest/components/IndicationsOfInterestForm/IndicationOfInterestForm';
import IndicationOfInterestSubmission from '../../IndicationsOfInterest/components/IndicationsOfInterestForm/IndicationOfInterestSubmission';

export enum OfferModalType {
  SUBMIT_INTEREST = 'SUBMIT_INTEREST',
  CONFIRM_INTEREST = 'CONFIRM_INTEREST',
  CANCEL_INTEREST = 'CANCEL_INTEREST',
}

export const getModalWidth = (modalToShow: string): number | undefined => {
  return modalToShow === OfferModalType.CANCEL_INTEREST ? 520 : 725;
};

export const showModalContent = (
  modalToShow: string,
  offerDetails: OfferDetails,
  form: any,
  fields: any,
  submittedInterest: CreateOfferWatchDto,
  userInfo: UserDto,
): any => {
  if (modalToShow === OfferModalType.SUBMIT_INTEREST) {
    return (
      <IndicationOfInterestSubmission offer={offerDetails} submittedInterest={submittedInterest} userInfo={userInfo} />
    );
  } else if (modalToShow === OfferModalType.CANCEL_INTEREST) {
    return <div>Are you sure you want to stop receiving updates and notifications for {offerDetails.name}?</div>;
  } else return <IndicationOfInterestForm form={form} fields={fields} userInfo={userInfo} offer={offerDetails} />;
};

export const getSecondaryButtonText = (modalToShow: string): string | undefined => {
  return modalToShow === OfferModalType.SUBMIT_INTEREST
    ? undefined
    : modalToShow === OfferModalType.CANCEL_INTEREST
    ? 'Yes'
    : 'Indicate Interest';
};

export const getLinkButtonText = (modalToShow: string): string | undefined => {
  return modalToShow === OfferModalType.CANCEL_INTEREST ? 'No' : 'Close';
};

export const onSecondaryButtonClick = (
  modalToShow: string,
  setVisibleModal: Function,
  setModalToShow: Function,
  dispatch: Dispatch<any>,
  form: any,
  offerWatch?: OfferWatchDto,
) => {
  if (modalToShow === OfferModalType.CANCEL_INTEREST && offerWatch) {
    dispatch(offerWatchesDeleteRequested(offerWatch.id));
    setVisibleModal(false);
    setModalToShow('');
  } else {
    form.submitForm();
  }
};
