import { css, cx } from '@emotion/css';

import { Color } from '../../../../styles';

export const sectionTitle = cx(
  css`
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${Color.BRAND.BRAND6};
    margin-top: 24px;
    margin-bottom: 16px;
  `,
);

export const description = cx(
  css`
    font-size: 14px;
    line-height: 20px;
    color: ${Color.GRAYSCALE.GRAY7};
    margin-bottom: 30px;
    text-align: center;
  `,
);

export const fieldLabel = cx(
  css`
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: ${Color.GRAYSCALE.GRAY8};
  `,
);

export const fieldValue = cx(
  css`
    line-height: 22px;
    text-align: right;
    color: ${Color.GRAYSCALE.GRAY8};
  `,
);

export const disclaimer = cx(
  css`
    font-size: 14px;
    line-height: 20px;
    color: #5c6572;
    margin-top: 20px;
  `,
);

export const submissionSucceeded = cx(
  css`
    font-weight: bold;
    line-height: 22px;
    display: flex;
    color: ${Color.GREEN.GREEN7};
  `,
);

export const rowStyle = cx(
  css`
    justify-content: center;
    align-items: center;
    margin-bottom: 13px;
  `,
);

export const offerName = cx(
  css`
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: ${Color.GRAYSCALE.GRAY10};
  `,
);

export const submissionDescription = css`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${Color.GRAYSCALE.GRAY7};
  margin-top: 13px;
  margin-bottom: 25px;
`;

export const offerLogo = css`
  width: 31px;
  height: 31px;
  margin-right: 15px;
`;

export const circleOutlinedStyles = css`
  color: ${Color.GREEN.GREEN7};
  font-size: 20px;
  margin-right: 10px;
`;
