export type SignatureType = {
  name: string;
  value: string;
  fullName?: string;
  errors?: any;
  touched?: any;
  disabled?: boolean;
  setFieldValue: Function;
  setFieldTouched: Function;
};

export const SIGNATURE_FONT_STYLES = ['Pacifico', 'Satisfy', 'Yellowtail'];
