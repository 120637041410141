import { css, cx } from '@emotion/css';

import { Color } from '../../../styles';

export const inputWrapper = css`
  margin-bottom: 0;
  .ant-input-number-affix-wrapper:hover {
    border-bottom: 0.0625rem solid #989ea6;
  }
  .ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
    color: #101217;
  }
  .ant-input-number-affix-wrapper-status-error:hover {
    background-color: ${Color.RED.RED1};
    border-bottom: none;
  }
`;

export const disabledInput = css`
  color: ${Color.GRAYSCALE.GRAY2};
`;

export const input = cx(
  css`
    font-size: 0.875rem !important;
    line-height: 1.75rem !important;
    color: #101217;
    width: 100% !important;
    border-radius: 0;
    box-sizing: border-box !important;
    box-shadow: none !important;
    padding-inline-start: 0;
    border-bottom: 0.0625rem solid #989ea6;
  `,
);

export const errorInput = css`
  background-color: ${Color.RED.RED1};
  border-bottom: none;
`;
