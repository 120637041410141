import { useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

type IComponentWithRouterProp = {
  [x: string]: any;
};

export default function withRouter(Component: Function): any {
  function ComponentWithRouterProp(props: IComponentWithRouterProp): JSX.Element {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }, [location]);

    return <Component {...props} router={{ location, navigate, params }} /> || null;
  }

  return ComponentWithRouterProp;
}
