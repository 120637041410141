import { DropdownItemType } from '../Dropdown/constants';

export const phoneNumbers: Array<DropdownItemType> = [
  {
    value: 'home',
    text: 'Home',
  },
  {
    value: 'mobile',
    text: 'Mobile',
  },
  {
    value: 'work',
    text: 'Work',
  },
];

export type PhoneNumber = {
  phoneNumber: string;
  type: string;
};

export const allowedPhoneNumberTypes = ['Mobile', 'Work', 'Home'];

export const findAllowedPhoneNumbers = (value: PhoneNumber[]) => {
  let allowedPhoneTypes = allowedPhoneNumberTypes;
  value.map(element => {
    if (allowedPhoneTypes.includes(element.type)) {
      allowedPhoneTypes = allowedPhoneTypes.filter(i => i !== element.type);
    }
  });

  return allowedPhoneTypes;
};

export interface PhoneNumber2Props {
  name: any;
  value: PhoneNumber[];
  setFieldValue: any;
  setFieldTouched: any;
  errors: any;
  touched: any;
}
