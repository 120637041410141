import { Row } from 'antd';

import { CurrencyRangeField } from '../CurrencyRangeField/CurrencyRangeField';

import * as Styles from './ThisOfferOrderWillDisclaimer.styles';

export interface ThisOfferOrderWillDisclaimerProps {
  min?: number;
  max?: number;
}

export const ThisOfferOrderWillDisclaimer = ({ min, max }: ThisOfferOrderWillDisclaimerProps) => {
  return (
    <Row className={Styles.container}>
      <span>
        This order will be automatically submitted if the final offering price is within the estimated share range of{' '}
        <CurrencyRangeField min={min} max={max} />
      </span>
    </Row>
  );
};
