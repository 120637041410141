import * as d3 from 'd3';

const drawDonutChart = (totalValueData: number, element: any, data: number[], colors: string[]) => {
  const boxSize = 275.5;
  const border = 14;

  // Remove the old svg
  d3.select(element).select('svg').remove();

  // Create new svg
  const svg = d3
    .select(element)
    .append('svg')
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .attr('height', '100%')
    .attr('width', '100%')
    .attr('viewBox', `0 0 ${boxSize} ${boxSize}`)
    .append('g')
    .attr('transform', `translate(${boxSize / 2}, ${boxSize / 2})`);

  const arcGenerator: any = d3
    .arc()
    .padAngle(0.02)
    .cornerRadius(16)
    .innerRadius(boxSize / 2 - border)
    .outerRadius(boxSize / 2);

  const pieGenerator = d3.pie();

  const arcs = svg.selectAll().data(pieGenerator(data)).enter();

  const valueLabel = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(totalValueData);

  arcs
    .append('path')
    .attr('d', arcGenerator)
    .style('fill', (_d: any, i: number) => colors[i % data.length]);

  svg
    .append('text')
    .attr('dy', '-30px')
    .attr('text-anchor', 'middle')
    .style('font-family', 'Source Sans Pro')
    .style('font-size', '16px')
    .style('line-height', '22px')
    .style('font-weight', '400')
    .style('fill', '#545454')
    .text('Account Value')
    .transition()
    .duration(700);

  svg
    .append('text')
    .style('text-anchor', 'middle')
    .style('font-size', '28px')
    .style('font-family', 'Source Sans Pro')
    .style('font-style', 'normal')
    .style('font-weight', '700')
    .style('line-height', '36px')
    .style('fill', '#0255A3')
    .attr('class', 'data')
    .text(valueLabel);
};

export default drawDonutChart;
