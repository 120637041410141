export const ModalStrings = {
  ssn: {
    modalTitle: 'Why do we need this?',
    secondaryButtonText: '',
    modalVisibleName: 'ssn',
  },
  accountOwnerInfo: {
    secondaryButtonText: 'Continue',
    modalVisibleName: 'account_owner_info',
  },
};

export const modalContent = (
  <>
    Once your personal information is saved you will not be able to edit your social security number, date of birth,
    middle initial or middle name and suffix. Please review your personal information to make sure everything is
    accurate.
    <br />
    <br />
    If the information is correct, please click “Continue”.
  </>
);

export const ssnInfo = (
  <>
    A social security number is required to comply with security regulatory requirements, My IPO must get information
    such as name, address, DOB, social security number, employment information and basic financial information.
    <br />
    My IPO’s site is secure, and we do not share your personal information as per our privacy policy. As part of our
    regulatory requirements, we must follow very strict security measures as a financial firm. Your social security
    number is encrypted at account creation you will notice we only show the last 4 digits of your SSN after logging
    into My IPO once your account is created.
  </>
);
