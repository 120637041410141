import { PropsWithChildren } from 'react';

import { Tooltip } from 'antd';
import type { TooltipPlacement } from 'antd/es/tooltip';
import { ColorConstant } from 'src/styles';

import * as Styles from './MTooltip.styles';

export interface MTooltipProps {
  placement: TooltipPlacement;
  title: string;
  showIcon?: boolean;
  text?: 'underline' | 'none';
}

export const MTooltip = ({
  title,
  placement,
  children,
  showIcon = true,
  text = 'none',
}: PropsWithChildren<MTooltipProps>) => (
  <Tooltip
    getTooltipContainer={(triggerNode: any) => triggerNode.parentNode}
    placement={placement}
    title={title}
    color={ColorConstant.GRAY1}
    className={Styles.tooltip(text)}>
    {showIcon && <i className={Styles.icon}></i>}
    {children}
  </Tooltip>
);
