import React, { useEffect } from 'react';

import { Card, Row, Space } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addOfferOrderIntent, getSignature } from 'src/actions';
import { OfferOrderSecurityDeliveryOptionDto } from 'src/dtos';
import { OfferOrderIntent, OfferDetails } from 'src/models';
import { Maybe, isRegDTypeCOffer } from 'src/utils';

import Checkbox from '../../../../../lib/FormComponents/CheckBox';
import { MButton } from '../../../../../lib/FormComponents/MButton/MButton';
import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import ListRow from '../../../../../lib/Miscellaneous/ListRow/ListRow';
import { ThisOfferOrderWillDisclaimer } from '../../../../../lib/ThisOfferOrderWillDisclaimer/ThisOfferOrderWillDisclaimer';
import { Images } from '../../../../../styles';
import { DOCUMENT_TYPES } from '../../../../Offers/components/OfferDocumentsTab/constants';
import { DETAILS_PATH } from '../NewOfferOrderConfirmStep/NewOfferOrderConfirmStep';
import { SECURITY_DELIVERY_INFORMATION } from '../NewOfferOrderDeliveryInformationStep/NewOfferOrderSecurityDeliveryInformationStep';

import * as Styles from './NewOfferOrderDocumentsStep.styles';
import { defaultValidation } from './validations';

export const DOCUMENTS_PATH = 'documents';

export const NewOfferOrderDocumentsStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const signature = useSelector((state: any) => state.user.getSignature?.data?.signature?.base64);
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);

  const findDeliveryOptionIfFound = (): Maybe<OfferOrderSecurityDeliveryOptionDto> => {
    if (offerOrderIntent.hasManySecurityDeliveryOptions) {
      return;
    }
    const deliveryOption = offerDetails?.externalDetails?.securityDeliveryOptions?.[0];

    if (deliveryOption) {
      return {
        label: deliveryOption.label,
        tooltip: deliveryOption.tooltip,
      };
    }
  };

  const getAreDocumentsReviewedDisclaimer = () => {
    if (isRegDTypeCOffer(offerDetails)) {
      return (
        'By clicking “Continue” I have reviewed the above offering documents and understand there are risks when investing. ' +
        'Once your order is placed, a representative from My IPO will contact you with instructions on how to complete ' +
        'the subscription agreement and any other documents needed to complete your Offer Order.'
      );
    }

    return offerDetails.subscriptionAgreementId
      ? 'I have reviewed the above offering documents and understand there are risks when investing. By clicking "Continue" I am electronically signing the offering documents.'
      : 'By clicking “Continue” I have reviewed the above offering documents and understand there are risks when investing.';
  };

  const shouldDisplaySignature = () => !isRegDTypeCOffer(offerDetails);

  useEffect(() => {
    dispatch(getSignature());
  }, [dispatch]);

  const onSubmit = () => {
    dispatch(
      addOfferOrderIntent({
        ...offerOrderIntent,
        areDocumentsReviewed: true,
        securityDeliveryOption: findDeliveryOptionIfFound(),
      }),
    );

    window.gtag('event', 'offer_order_review_documents_complete', {
      offer_id: offerDetails.id,
      offer_name: offerDetails.name,
      account_id: offerOrderIntent.accountId,
    });

    const nextPage =
      offerDetails.isExternalOffer && offerOrderIntent.hasManySecurityDeliveryOptions
        ? `../${SECURITY_DELIVERY_INFORMATION}`
        : `../${DETAILS_PATH}`;
    navigate(`${nextPage}`);
  };

  return (
    <NewOfferOrderLayout
      step='documents'
      description='Please review the information below before completing the order process.'
      hasManySecurityDeliveryOptions={offerOrderIntent.hasManySecurityDeliveryOptions}
      offerId={offerDetails.id}>
      <Formik
        enableReinitialize
        initialValues={{ areDocumentsReviewed: offerOrderIntent.areDocumentsReviewed }}
        onSubmit={() => onSubmit()}
        validationSchema={defaultValidation}>
        {form => {
          return (
            <div>
              <Row className={Styles.rowStyle}>
                <img
                  className={Styles.offerLogo}
                  src={offerDetails.logoUrl || Images.DefaultOffering}
                  alt={`${offerDetails.name}_logo`}
                />
                <span className={Styles.offerName}>{offerDetails.name}</span>
              </Row>

              <div className={Styles.fieldsContainer}>
                {offerDetails?.documents.map(document => (
                  <ListRow
                    key={document.id}
                    customRowStyle={{ borderBottom: 'none' }}
                    leftComponent={<div className={Styles.fieldLabel}>{DOCUMENT_TYPES[document.type].label}</div>}
                    rightComponent={
                      <MButton
                        key={document.id}
                        type='secondary'
                        href={document.url}
                        target='_blank'
                        size='small'
                        className={Styles.fieldValue}>
                        View
                      </MButton>
                    }
                  />
                ))}
              </div>
              {offerOrderIntent.isConditional && (
                <ThisOfferOrderWillDisclaimer min={offerDetails.minSharePrice} max={offerDetails.maxSharePrice} />
              )}
              <Checkbox
                testId={'offer-order-check-sign-documents'}
                name='areDocumentsReviewed'
                label={getAreDocumentsReviewedDisclaimer()}
                {...form}
                value={form.values.areDocumentsReviewed}
              />

              {shouldDisplaySignature() && (
                <>
                  <div className={Styles.sectionTitle}>Signature</div>
                  {signature && (
                    <Card className={Styles.signatureWrapper}>
                      <img src={signature} alt='signature' className={Styles.signature} />
                    </Card>
                  )}
                </>
              )}
              <Row
                justify='center'
                align='middle'
                className={Styles.buttonsContainer}
                data-testid={'offer-documents-disclosures-row-btns'}>
                <Space direction='horizontal'>
                  <MButton type='tertiary' onClick={() => navigate(-1)}>
                    Back
                  </MButton>
                  <MButton onClick={() => form.submitForm()}>Continue</MButton>
                </Space>
              </Row>
            </div>
          );
        }}
      </Formik>
    </NewOfferOrderLayout>
  );
};
