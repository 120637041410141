import React from 'react';

import { Col, Form, Row } from 'antd';
import _ from 'lodash';

import {
  IndividualRepresentationsCheckboxes,
  IndividualRepresentationsSubFields,
  InvestmentExperience,
} from '../../../constants/accountCompletion';
import { Color } from '../../../styles';
import { getErrorMessage } from '../../../utils/helpers';
import CheckBoxComp from '../../FormComponents/CheckBox';
import Input from '../../FormComponents/Input/Input';

const IndividualRepresentation = ({ form, name }: any) => {
  const { errors, touched } = form;
  const fieldName = name;

  return (
    <Form.Item
      validateStatus={!!errors && errors[fieldName] && 'error'}
      help={!!errors && touched[fieldName] && getErrorMessage(errors[fieldName])}
      colon={false}
      style={{
        flexDirection: 'column',
        marginBottom: 0,
      }}>
      <div
        style={{
          backgroundColor: errors && errors[fieldName] && touched && touched[fieldName] ? Color.RED.RED1 : undefined,
        }}>
        {IndividualRepresentationsCheckboxes.map((checkBox, index) => (
          <div
            key={checkBox.value}
            style={{
              borderBottomWidth: InvestmentExperience.length - 1 === index ? 0 : 1,
            }}>
            <CheckBoxComp
              {...form}
              name={checkBox.value}
              label={checkBox.text}
              isPartOfGroup
              value={
                form.values && !_.isEmpty(form.values[fieldName])
                  ? form.values[fieldName].hasOwnProperty(checkBox.value) &&
                    form.values[fieldName][checkBox.value] !== undefined
                  : null
              }
              customStyles={{ paddingLeft: 7, marginBottom: 21 }}
              customSetFieldValue={(name: any, selectedValue: boolean) => {
                if (selectedValue === true) {
                  form.setFieldValue(
                    fieldName,
                    {
                      ...form.values[fieldName],
                      [name]: index === 0 ? { tickerSymbol: '' } : { firmName: '' },
                    },
                    true,
                  );
                } else {
                  const tempValues = _.cloneDeep(form.values[fieldName]);
                  delete tempValues[name];
                  form.setFieldValue(fieldName, tempValues, false);
                  form.setTouched({ ...touched, individualRepresentations: undefined }, true);
                }
              }}
            />

            {form.values &&
              !_.isEmpty(form.values[fieldName]) &&
              form.values[fieldName].hasOwnProperty([checkBox.value]) &&
              !_.isEmpty(form.values[fieldName][checkBox.value]) &&
              form.values[fieldName][checkBox.value] !== undefined && (
                <div style={{ paddingLeft: 7, paddingRight: 7 }}>
                  <Row>
                    <Col span={12}>
                      <div
                        style={{
                          marginTop: 10,
                          marginBottom: 15,
                        }}>
                        {IndividualRepresentationsSubFields[index].label}
                      </div>
                    </Col>
                    <Col span={12}>
                      <Input
                        {...form}
                        errors={
                          form.values[fieldName][checkBox.value][IndividualRepresentationsSubFields[index].name] === ''
                            ? {
                                [IndividualRepresentationsSubFields[index].name]: 'Required',
                              }
                            : {}
                        }
                        touched={{
                          [IndividualRepresentationsSubFields[index].name]: true,
                        }}
                        name={IndividualRepresentationsSubFields[index].name}
                        placeholder={IndividualRepresentationsSubFields[index].label}
                        customSetFieldValue={(value: any) => {
                          form.setFieldValue(
                            fieldName,
                            {
                              ...{
                                ...form.values[fieldName],
                                [checkBox.value]: {
                                  ...form.values[fieldName][checkBox.value],
                                  [IndividualRepresentationsSubFields[index].name]: value,
                                },
                              },
                            },
                            true,
                          );
                        }}
                        value={
                          form.values[fieldName][checkBox.value]
                            ? form.values[fieldName][checkBox.value][IndividualRepresentationsSubFields[index].name]
                            : ''
                        }
                      />
                    </Col>
                  </Row>
                </div>
              )}
          </div>
        ))}
      </div>
    </Form.Item>
  );
};

export default IndividualRepresentation;
