import { css } from '@emotion/css';
import { ColorConstant, Display, Font, FontWeight, Spacing, TextAlign } from 'src/styles';

export const fieldLabel = css`
  ${Font.md}
  ${FontWeight.semibold}
  ${Spacing.py16}
  ${Spacing.px0}
  color: ${ColorConstant.GRAY8};
`;

export const fieldValue = css`
  ${FontWeight.normal}
  ${Font.md}
  ${Spacing.py16}
  ${Spacing.px0}
  color: ${ColorConstant.GRAY8};
`;

export const borderBottom = css`
  border-bottom: 1px solid #f0f0f0;
`;

export const alignRight = css`
  ${Display.table}
  ${TextAlign.right}
`;
