export enum RadioAlignment {
  Left = 'left',
  Right = 'right',
}

export type RadioCompType = {
  name: string;
  label?: string;
  subLabel?: string;
  value?: boolean;
  disabled?: boolean;
  errors?: any;
  setFieldValue: Function;
  setFieldTouched: Function;
  preChangeHook?: any;
  postChangeHook?: any;
  radioAlignment?: RadioAlignment;
  onPressCustom?: Function;
};
