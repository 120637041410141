import { Fragment, useEffect, useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row } from 'antd';
import { get } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { compose } from 'recompose';

import { getInvestigations, logout, getAuthenticatedUser } from '../../actions';
import { useHasAnyRequestedInvestigationDocuments } from '../../hooks/useHasAnyRequestedInvestigationDocuments';
import withRouter from '../../hooks/withRouter';
import { AccountStatusEnum } from '../../models/account-status.enum';
import { getInvestigationStatus } from '../../pages/Investigations/AccountStatus/constants';
import { MAIN_PATH } from '../../pages/Main';
import { HISTORY_PATH } from '../../pages/Main/pages/History/History';
import { SUMMARY_PATH } from '../../pages/Main/pages/Summary/Summary';
import { Color, ColorConstant, ScreenBreakpoint } from '../../styles';
import { logo } from '../../styles/images';
import { MButton } from '../FormComponents/MButton/MButton';
import { MDivider } from '../MDivider/MDivider';
import { MDrawer } from '../MDrawer/MDrawer';

import {
  completedAccountMenuItemKeyList,
  DesktopPropsType,
  getBannerText,
  HeaderComponentType,
  HeaderMenuItemKey,
  headerMenuItems,
  MobilePropsType,
} from './constants';
// eslint-disable-next-line import/order
import * as Styles from './Header.styles';

import './index.css';
import ResourceLinks from './ResourceLinks';
import { UserInfo } from './UserInfo';

export const Desktop = ({
  authToken,
  firstName,
  lastName,
  pathName,
  logout,
  accountStatus,
  isUserActionNeeded = false,
}: DesktopPropsType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setIsVisible] = useState(false);
  const accountId = useSelector((state: any) => state.accountDetails?.accountHolder?.data?.accountId);
  const accountUuid = useSelector((state: any) => state.accountDetails?.accountHolder?.data?.id);

  const accountValue = useSelector((state: any) => state.accountDetails?.accountValue?.data?.totalValue);
  const isStatusPending = getInvestigationStatus(accountStatus) === AccountStatusEnum.PENDING;
  const isStatusRejected = getInvestigationStatus(accountStatus) === AccountStatusEnum.REJECTED;
  const showAlert = isStatusPending || isStatusRejected;

  useEffect(() => {
    if (accountId) dispatch(getInvestigations(accountId));
  }, [accountId]);

  const handleVisibleChange = (visible: boolean) => {
    setIsVisible(visible);
  };

  const onHide = () => {
    setIsVisible(false);
  };

  const formatNumber = (numStr: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    }).format(numStr);
  };

  const renderAccountStatusBanner = () => {
    return (
      <Row className='infoStatusBanner' justify='center'>
        <Col span={24}>
          <i className='ri-information-line' />
          {getBannerText(isUserActionNeeded, accountUuid, navigate)}
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {authToken && firstName && lastName ? (
        <div className='resourceSection'>
          <div className='resourceWidthRestriction'>
            <div>
              <ResourceLinks className='linksSmall' isAuthenticated />
            </div>
          </div>
        </div>
      ) : null}
      <Row justify='center'>
        <Col span={24}>
          <div className='mainHeader'>
            <div className='headerWidthRestriction'>
              <Link to='/'>
                <img src={logo} alt='My IPO' className='logo' />
              </Link>
              {!authToken ? (
                <div className='signedOutLinks'>
                  <ResourceLinks className='linksMedium' />
                  <div className='authOptions'>
                    <div>
                      <MButton type='secondary' onClick={() => navigate('/register')} testId={'home-btn-signup'}>
                        Sign up
                      </MButton>
                    </div>
                    <div className='authBtnContainer'>
                      <MButton type='secondary' onClick={() => navigate('/login')} testId={'home-btn-login'}>
                        Log in
                      </MButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='headerLinkSection'>
                  <div
                    style={{
                      display: 'inline-block',
                      margin: '0 auto',
                    }}>
                    <NavLink
                      to={`/${MAIN_PATH}/${SUMMARY_PATH}`}
                      className={pathName.indexOf(`/${SUMMARY_PATH}`) > 0 ? 'linksLargeActive' : 'linksLarge'}>
                      Summary
                    </NavLink>

                    <NavLink
                      to='/offers'
                      className={pathName.startsWith('/offers') ? 'linksLargeActive' : 'linksLarge'}>
                      Offers
                    </NavLink>

                    <NavLink
                      to={`/${MAIN_PATH}/${HISTORY_PATH}`}
                      className={pathName.indexOf(`/${HISTORY_PATH}`) > 0 ? 'linksLargeActive' : 'linksLarge'}>
                      History
                    </NavLink>
                  </div>
                  <Popover
                    getPopupContainer={triggerNode => triggerNode}
                    content={
                      <div
                        style={{
                          width: 345,
                          backgroundColor: Color.GRAYSCALE.GRAY1,
                          borderRadius: 12,
                          paddingTop: 22,
                          boxShadow: '0 4px 16px rgba(2, 85, 163, 0.15)',
                        }}
                        data-testid={'summary-dropdown-account-menu'}>
                        <div
                          style={{
                            textAlign: 'center',
                          }}>
                          <label className='userName'>{firstName + ' ' + lastName}</label>
                          <div style={{ marginTop: 12, marginBottom: 16 }}>
                            <span className='accountValueNumber'>{formatNumber(accountValue || 0)}</span>
                            <br />
                            <span className='accountValueLabel'>Account Value</span>
                          </div>
                        </div>
                        <div className='menuItemsContainer'>
                          {headerMenuItems.map(menuLink => {
                            return (
                              <Fragment key={`key_${menuLink.key}`}>
                                {!accountId && completedAccountMenuItemKeyList.includes(menuLink.key) ? null : (
                                  <Link to={`${menuLink.path(accountUuid)}`} onClick={onHide}>
                                    <div
                                      className={
                                        pathName.includes(menuLink.path(accountUuid)) ? 'menuLinkActive' : 'menuLink'
                                      }>
                                      <span>{menuLink.name}</span>
                                      {menuLink.key === HeaderMenuItemKey.AccountStatus && showAlert && (
                                        <div
                                          className={
                                            isStatusPending
                                              ? 'alertContainerPending'
                                              : isStatusRejected
                                              ? 'alertContainerRejected'
                                              : ''
                                          }>
                                          <i
                                            className={`ri-error-warning-fill ${
                                              isStatusPending
                                                ? 'alertCirclePending'
                                                : isStatusRejected
                                                ? 'alertCircleRejected'
                                                : ''
                                            }`}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </Link>
                                )}
                              </Fragment>
                            );
                          })}
                          <div
                            className='logoutLink'
                            onClick={() => {
                              onHide();
                              logout();
                            }}>
                            Log out
                          </div>
                        </div>
                      </div>
                    }
                    trigger='click'
                    open={visible}
                    onOpenChange={handleVisibleChange}
                    placement='bottomRight'>
                    <div className='userInfoContainer' data-testid='summary-hamburger-menu'>
                      <UserInfo firstName={firstName} lastName={lastName} accountStatus={accountStatus} />
                      <i
                        className='ri-arrow-down-s-fill'
                        style={{ marginLeft: 2, color: Color.GRAYSCALE.GRAY6, fontSize: 30 }}
                      />
                    </div>
                  </Popover>
                </div>
              )}
            </div>
          </div>
        </Col>
        {authToken && showAlert && accountStatus && <Col span={24}>{renderAccountStatusBanner()}</Col>}
      </Row>
    </div>
  );
};

export const Mobile = ({
  authToken,
  firstName,
  lastName,
  logout,
  accountStatus,
  isUserActionNeeded = false,
}: MobilePropsType) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountId = useSelector((state: any) => state.accountDetails?.accountHolder?.data?.accountId);
  const accountUuid = useSelector((state: any) => state.accountDetails?.accountHolder?.data?.id);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const isStatusPending = getInvestigationStatus(accountStatus) === AccountStatusEnum.PENDING;
  const isStatusRejected = getInvestigationStatus(accountStatus) === AccountStatusEnum.REJECTED;
  const showAlert = isStatusPending || isStatusRejected;

  const renderAccountStatusBanner = () => {
    return (
      <Row className='infoStatusBanner mobile' justify='center'>
        <Col span={24}>
          <i className='ri-information-line' />
          {getBannerText(isUserActionNeeded, accountUuid, navigate)}
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (accountId) dispatch(getInvestigations(accountId));
  }, [accountId]);

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <div>
      <Row justify='center'>
        <Col span={24}>
          <div className='mobileHeader'>
            <Link to='/'>
              <img className='logo' src={logo} alt='My IPO' />
            </Link>
            <Button
              icon={<MenuOutlined style={{ fontSize: '20px', color: ColorConstant.BRAND6 }} />}
              onClick={() => setIsDrawerOpen(true)}
              className='mobileNavBtn'
            />
            <MDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              {!authToken ? (
                <div className='loginContainer'>
                  <Link to='/login' className='mobileLinks'>
                    Log in
                  </Link>
                  <Link to='/register' className='mobileLinks'>
                    Sign up
                  </Link>
                </div>
              ) : (
                <div className={Styles.mobileUserInfoWrapper}>
                  <UserInfo firstName={firstName} lastName={lastName} accountStatus={accountStatus} />
                  <div className={Styles.accountSubMenu}>
                    <Link to={`/accounts/${accountUuid}/personal-information`} className={Styles.subMenuLink}>
                      My account
                    </Link>
                    {accountId && (
                      <>
                        <Link to={`/accounts/${accountUuid}/money-transfers`} className={Styles.subMenuLink}>
                          Money Transfers & Preferences
                        </Link>
                        <Link to={`/accounts/${accountUuid}/account-status`} className={Styles.subMenuLink}>
                          Account Status
                        </Link>
                        <Link to={`/accounts/${accountUuid}/statements`} className={Styles.subMenuLink}>
                          Statements & Documents
                        </Link>
                        <Link to='/user/settings' className={Styles.subMenuLink}>
                          Change Email Address & Phone Number
                        </Link>
                      </>
                    )}
                    <Link to='/user/indications-of-interest' className={Styles.subMenuLink}>
                      Indication(s) of Interest
                    </Link>
                  </div>
                </div>
              )}
              <div />
              {authToken && firstName && lastName ? (
                <div className='linkContainer'>
                  <NavLink to={`/${MAIN_PATH}/${SUMMARY_PATH}`} className='mobileLinks'>
                    Summary
                  </NavLink>

                  <NavLink to='/offers' className='mobileLinks'>
                    Offers
                  </NavLink>

                  <NavLink to={`${MAIN_PATH}/${HISTORY_PATH}`} className='mobileLinks'>
                    History
                  </NavLink>
                </div>
              ) : null}
              {authToken && firstName && lastName ? <MDivider /> : null}
              <ResourceLinks className='mobileLinks' isMobile isAuthenticated={Boolean(authToken)} />
              {authToken && firstName && lastName ? <MDivider /> : null}
              {authToken && logout && (
                <div
                  className='mobileLinks mobileLogOutLink'
                  onClick={() =>
                    logout({
                      accessToken: undefined,
                      idToken: undefined,
                      tbToken: undefined,
                    })
                  }>
                  Log out
                </div>
              )}
            </MDrawer>
          </div>
        </Col>
        <Col span={24}>{authToken && showAlert && accountStatus && renderAccountStatusBanner()}</Col>
      </Row>
    </div>
  );
};

export const Header = ({ authToken, firstName, lastName, accountNumber, logout }: HeaderComponentType) => {
  const locationObj = useLocation();
  const investigationState = useSelector((state: any) => state.investigations?.investigationsList?.data?.state);
  const isUserActionNeeded = useHasAnyRequestedInvestigationDocuments();

  return (
    <div className='headerMain'>
      <MediaQuery minWidth={ScreenBreakpoint.laptop.min}>
        <Desktop
          firstName={firstName}
          lastName={lastName}
          authToken={authToken}
          pathName={locationObj.pathname}
          accountNumber={accountNumber}
          logout={logout}
          accountStatus={investigationState}
          isUserActionNeeded={isUserActionNeeded}
        />
      </MediaQuery>
      <MediaQuery maxWidth={ScreenBreakpoint.mobile.max}>
        <Mobile
          firstName={firstName}
          lastName={lastName}
          authToken={authToken}
          pathName={locationObj.pathname}
          accountNumber={accountNumber}
          logout={logout}
          accountStatus={investigationState}
          isUserActionNeeded={isUserActionNeeded}
        />
      </MediaQuery>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  authToken: get(state, 'auth.data.authToken'),
  firstName: get(state, 'user.authenticated.data.firstName'),
  lastName: get(state, 'user.authenticated.data.lastName'),
  accountNumber: get(state, 'user.authenticated.data.id'),
});

const mapDispatchToProps = {
  logout,
  getAuthenticatedUser,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

const ConnectedHeader = enhance(Header as any);

export default ConnectedHeader;
