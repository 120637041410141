import React, { useEffect, useRef, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { Formik, FormikProps } from 'formik';
import _, { get } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
  clearPatchTrustedContact,
  clearUpdateTrustedContact,
  getTrustedContact,
  patchTrustedContact,
  removeTrustedContact,
  updateTrustedContact,
  toastMessagesAdd,
  toastMessagesRemove,
  clearToastMessages,
} from '../../../actions';
import FieldWrapper from '../../../lib/FormComponents/FieldWrapper';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { MDivider } from '../../../lib/MDivider/MDivider';
import Spinner from '../../../lib/Miscellaneous/Spinner';
import { MModal } from '../../../lib/MModal/MModal';
import { Color, ScreenBreakpoint } from '../../../styles';
import { SeverityEnum } from '../../../typings/commonTypes';
import { renderAddressMismatchErrorMessage } from '../../../utils/renderAddressMismatchErrorMessage';

import { checkFieldIsHidden } from './constants';
import { getTrustedContactSections } from './fields';
import * as Styles from './TrustedContact.styles';
import { trustedContactValidation } from './validations';

const TrustedContactUnmapped = ({
  trustedContactDetails,
  getTrustedContact,
  updateTrustedContact,
  patchTrustedContact,
  removeTrustedContact,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadingTrustedContactInformation = useSelector((state: any) =>
    Boolean(state.accountDetails?.trustedContactInformation.__requested),
  );
  const succeededUpdateTrustedContact = useSelector(
    (state: any) => state?.accountDetails?.updateTrustedContact?.__succeeded,
  );
  const failedUpdateTrustedContact = useSelector((state: any) => state?.accountDetails?.updateTrustedContact?.__failed);

  const failedUpdateTrustedContactMessage = useSelector(
    (state: any) => state?.accountDetails?.updateTrustedContact?.message,
  );

  const succeededPatchTrustedContact = useSelector(
    (state: any) => state?.accountDetails?.patchTrustedContact?.__succeeded,
  );
  const failedPatchTrustedContact = useSelector((state: any) => state?.accountDetails?.patchTrustedContact?.__failed);

  const failedPatchTrustedContactMessage = useSelector(
    (state: any) => state?.accountDetails?.patchTrustedContact?.message,
  );

  const isUpdateTrustedContactLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.updateTrustedContact?.__requested),
  );

  const isPatchTrustedContactLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.patchTrustedContact?.__requested),
  );

  const isRemoveTrustedContactLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.removeTrustedContact?.__requested),
  );

  const accountUuid = useSelector((state: any) => state.accountDetails.accountHolder?.data?.id);
  const accountId = useSelector((state: any) => state.accountDetails.accountHolder?.data?.accountId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addTrustedContact, setAddTrustedContact] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFieldEditing, setIsFieldEditing] = useState(false);
  const [openedField, setOpenedField] = useState('');
  const [sectionUpdated, setSectionUpdated] = useState('');
  const menu: any = getTrustedContactSections(trustedContactDetails);

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });
  const trustedContactFormRef = useRef<FormikProps<{}> | null>(null);
  const [failedUpsertTrustedContactToastId, setFailedUpsertTrustedContactToastId] = useState<string | null>(null);

  const isUpsertLoading = isUpdateTrustedContactLoading || isPatchTrustedContactLoading;

  const isTrustedContactComplete = () => !_.isEmpty(trustedContactDetails);

  const anyUpsertRequestSucceded = () => succeededPatchTrustedContact || succeededUpdateTrustedContact;

  const initialValues = (fields: any) =>
    Object.keys(fields)
      .map(key => ({
        key: fields[key].props.name,
        value: fields[key].props.value,
      }))
      .reduce(
        (obj, keyValuePair) => ({
          ...obj,
          [keyValuePair.key]: keyValuePair.value,
        }),
        {},
      );

  const removeUpsertTrustedContactToastMessageIfFound = () => {
    if (failedUpsertTrustedContactToastId) {
      dispatch(toastMessagesRemove({ key: failedUpsertTrustedContactToastId }));
    }
  };

  const onPopulateTrustedContactAddress = () => {
    if (trustedContactFormRef.current && failedUpdateTrustedContactMessage?.error === 'UspsAddressMismatchError') {
      trustedContactFormRef.current.setFieldValue('address1', failedUpdateTrustedContactMessage.address1);
      trustedContactFormRef.current.setFieldValue('address2', failedUpdateTrustedContactMessage.address2);
      trustedContactFormRef.current.setFieldValue('city', failedUpdateTrustedContactMessage.city);
      trustedContactFormRef.current.setFieldValue('state', failedUpdateTrustedContactMessage.state);
      trustedContactFormRef.current.setFieldValue('postalCode', failedUpdateTrustedContactMessage.postalCode);
    }

    if (trustedContactFormRef.current && failedPatchTrustedContactMessage?.error === 'UspsAddressMismatchError') {
      trustedContactFormRef.current.setFieldValue('address1', failedPatchTrustedContact.address1);
      trustedContactFormRef.current.setFieldValue('address2', failedPatchTrustedContact.address2);
      trustedContactFormRef.current.setFieldValue('city', failedPatchTrustedContact.city);
      trustedContactFormRef.current.setFieldValue('state', failedPatchTrustedContact.state);
      trustedContactFormRef.current.setFieldValue('postalCode', failedPatchTrustedContact.postalCode);
    }
    removeUpsertTrustedContactToastMessageIfFound();
  };

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    const castedValues = trustedContactValidation.cast(values);
    await updateTrustedContact(castedValues, sectionUpdated);
  };

  useEffect(() => {
    if (failedUpdateTrustedContactMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertTrustedContactToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedUpdateTrustedContactMessage,
            onClick: onPopulateTrustedContactAddress,
            section: 'trusted contact',
          }),
        }),
      );
      setFailedUpsertTrustedContactToastId(toastId);
    }
  }, [failedUpdateTrustedContact]);

  useEffect(() => {
    if (failedPatchTrustedContactMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertTrustedContactToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedPatchTrustedContactMessage,
            onClick: onPopulateTrustedContactAddress,
            section: 'trusted contact',
          }),
        }),
      );
      setFailedUpsertTrustedContactToastId(toastId);
    }
  }, [failedPatchTrustedContact]);

  useEffect(() => {
    if (_.isEmpty(trustedContactDetails) && !loadingTrustedContactInformation) {
      getTrustedContact();
    }

    return () => {
      dispatch(clearPatchTrustedContact());
      dispatch(clearUpdateTrustedContact());
      dispatch(clearToastMessages());
    };
  }, []);

  useEffect(() => {
    if (isSubmitting && !isUpsertLoading) {
      setIsSubmitting(false);
    }
  }, [isPatchTrustedContactLoading, isUpdateTrustedContactLoading]);

  useEffect(() => {
    if (!accountId && isTrustedContactComplete() && succeededUpdateTrustedContact) {
      window.gtag('event', 'account_trusted_contact_complete');
    }

    if (!accountId && isTrustedContactComplete() && anyUpsertRequestSucceded()) {
      navigate(`/accounts/${accountUuid}/suitability-information`);
    }
  }, [succeededPatchTrustedContact, succeededUpdateTrustedContact, trustedContactDetails]);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.TrustedContact}
      title={isMobile ? undefined : 'Trusted Contact (Optional)'}>
      {loadingTrustedContactInformation ? (
        <Spinner />
      ) : (
        menu.sections.map((section: any) => (
          <Row key={section.sectionKey}>
            <Col span={18} xs={24} sm={24}>
              <div className={Styles.formCard}>
                {!addTrustedContact && _.isEmpty(trustedContactDetails) && (
                  <div>
                    <div className={Styles.noContactPersonLabel}>
                      You currently have no Trusted Contact Person assigned to your account.
                    </div>
                    <div
                      onClick={() => {
                        setIsModalVisible(true);
                      }}
                      className={Styles.linkContainer}>
                      <i style={{ ...Styles.Tooltip }} className={`ri-information-line`} />
                      <span className={Styles.linkText}>What is a Trusted Contact Person ?</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <MButton
                        loading={isSubmitting}
                        className={Styles.fullWidthBtn}
                        onClick={() => {
                          setAddTrustedContact(true);
                        }}>
                        <PlusCircleOutlined style={{ fontSize: 18, color: Color.GRAYSCALE.GRAY1, paddingRight: 5 }} />
                        Add a Trusted Contact Person
                      </MButton>
                    </div>
                    <MModal
                      title='What is a Trusted Contact Person?'
                      visible={isModalVisible}
                      tertiaryButtonText='OK'
                      onTertiaryButtonClick={() => {
                        setIsModalVisible(false);
                      }}
                      onClose={() => {
                        setIsModalVisible(false);
                      }}>
                      <div>
                        A Trusted Contact Person (“TCP”) is someone that you tell us we can contact if we have questions
                        about your well-being. By providing this information, you authorize us to contact the TCP and
                        disclose information about you in order to confirm the specifics of your current contact
                        information, health status and inquire if another person or entity has legal authority to act on
                        your behalf (such as legal guardian, executor, trustee or holder of a power of attorney). The
                        TCP must be at least 18 years old and will not have the ability to transact on your account.
                      </div>
                    </MModal>
                  </div>
                )}
                {((addTrustedContact && _.isEmpty(trustedContactDetails)) || section.isCompleted) && (
                  <>
                    <div className={Styles.sectionHeader}>{section.sectionLabel}</div>
                    <Formik
                      validationSchema={section.validationSchema}
                      enableReinitialize
                      validateOnChange={false}
                      validateOnBlur={false}
                      innerRef={ref => (trustedContactFormRef.current = ref)}
                      initialValues={initialValues(section.sectionFields)}
                      onSubmit={values => {
                        onSubmit(values);
                      }}>
                      {form => {
                        const formValues: any = form.values;

                        return (
                          <>
                            {section.sectionFields.map((field: any) => {
                              const JSXRenderableComp = field.component;

                              return checkFieldIsHidden(formValues, field) ? null : (
                                <>
                                  <Row key={`${field.key}_frag_`}>
                                    <Col span={12}>
                                      <div
                                        className={Styles.fieldLabel}
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 15,
                                        }}>
                                        {field.label}
                                      </div>
                                    </Col>
                                    <Col span={12} style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
                                      <FieldWrapper
                                        displayValue={field.displayValue}
                                        name={field.key}
                                        form={form}
                                        value={field.props.value}
                                        openedField={openedField}
                                        initialMode={section.isCompleted || field.readOnly ? 'view' : 'edit'}
                                        isSectionCompleted={section.isCompleted}
                                        readOnly={field.readOnly}
                                        isFieldEditing={isFieldEditing}
                                        onFieldEdit={(name: string) => {
                                          setOpenedField(name);
                                          setIsFieldEditing(true);
                                        }}
                                        onFieldSubmit={async (values: any) => {
                                          setIsSubmitting(true);
                                          setIsFieldEditing(false);
                                          setOpenedField('');
                                          setSectionUpdated(section.sectionKey);
                                          const castedValues = trustedContactValidation.cast(values);
                                          await patchTrustedContact(castedValues, section.sectionKey);
                                        }}
                                        onFieldClose={() => {
                                          setOpenedField('');
                                          form.resetForm();
                                        }}>
                                        <JSXRenderableComp
                                          key={`${field.key}_field_`}
                                          {...field.props}
                                          {...form}
                                          form={field.passFormAsProp ? form : undefined}
                                          name={field.key}
                                          value={formValues[field.key]}
                                        />
                                      </FieldWrapper>
                                    </Col>
                                  </Row>

                                  {field.key === 'email' && (
                                    <div key={uuid()} className={Styles.sectionHeader} style={{ marginTop: 16 }}>
                                      Address
                                    </div>
                                  )}
                                </>
                              );
                            })}
                            <MDivider />
                            {!section.isCompleted && (
                              <Space
                                size={8}
                                direction={'horizontal'}
                                align='center'
                                className={Styles.buttonsContainer}>
                                <MButton
                                  disabled={loadingTrustedContactInformation}
                                  type='tertiary'
                                  danger
                                  onClick={() => {
                                    setAddTrustedContact(false);
                                    form.resetForm();
                                  }}>
                                  Cancel
                                </MButton>
                                <MButton
                                  loading={isUpsertLoading}
                                  disabled={loadingTrustedContactInformation || isUpsertLoading}
                                  type='secondary'
                                  onClick={() => {
                                    form.submitForm();
                                    setSectionUpdated(section.sectionKey);
                                  }}>
                                  Save
                                </MButton>
                              </Space>
                            )}
                          </>
                        );
                      }}
                    </Formik>
                  </>
                )}
              </div>
              {section.isCompleted && !_.isEmpty(trustedContactDetails) && (
                <div style={{ width: 220, marginBottom: 20 }}>
                  <MButton
                    danger
                    loading={isRemoveTrustedContactLoading}
                    disabled={loadingTrustedContactInformation || isRemoveTrustedContactLoading}
                    type='tertiary'
                    onClick={async () => {
                      setSectionUpdated(section.sectionKey);
                      setAddTrustedContact(false);
                      await removeTrustedContact({}, sectionUpdated);
                    }}>
                    Remove Trusted Contact
                  </MButton>
                </div>
              )}
            </Col>
          </Row>
        ))
      )}
    </MyAccountLayout>
  );
};

const mapStateToProps = (state: any) => ({
  trustedContactDetails: get(state, 'accountDetails.trustedContactInformation.data'),
});

const mapDispatchToProps = {
  getTrustedContact,
  updateTrustedContact,
  patchTrustedContact,
  removeTrustedContact,
};

export const TrustedContact = connect(mapStateToProps, mapDispatchToProps)(TrustedContactUnmapped);
