import { css } from '@emotion/css';

import { ColorConstant, Display, Font, FontWeight, JustifyContent, Spacing } from '../../../../styles';

export const sectionTitle = css`
  ${Spacing.my16}
  ${Font.sm}
  color: ${ColorConstant.GRAY6};
`;

export const policyText = css`
  ${Font.sm}
  color: ${ColorConstant.GRAY6};
  ${Spacing.mb16}
`;

export const nickname = css`
  ${Font.sm}
  color: ${ColorConstant.GRAY6};
`;

export const senderReceiverBottomLabel = css`
  ${Spacing.mt8}
  color: ${ColorConstant.GRAY10};
`;

export const bankCode = css`
  ${FontWeight.bold}
  ${Font.sm}
  ${Spacing.mt8}
  color: ${ColorConstant.GRAY8};
  text-align: right;
`;

export const senderReceiverTopLabel = css`
  ${FontWeight.semibold}
  text-transform: uppercase;
  color: ${ColorConstant.GRAY6};
`;

export const bankNameContainer = css`
  ${Display.flex}
  ${JustifyContent.flexEnd}
  ${Spacing.mt8}
`;

export const bankName = css`
  ${Font.sm}
  ${Spacing.mr4}
  color: ${ColorConstant.GRAY6};
`;

export const bankLogo = css`
  width: 1.5rem;
  height: 1.5rem;
`;
