import * as Yup from 'yup';

export const validationMessages = {
  emailNameRequired: 'Email is required.',
  emailNameInvalid: 'Email is invalid.',
};

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required(validationMessages.emailNameRequired).email(validationMessages.emailNameInvalid),
});
