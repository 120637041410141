import { call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { toastMessagesAdd } from '../actions';
import { getInvestigations as getInvestigationsAction } from '../actions/investigations';
import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import { AuthReducedState } from '../typings/auth.types';
import { ResponseGenerator, SeverityEnum, TReduxAction } from '../typings/commonTypes';

import { HttpClient, replacePlaceholders, safeSaga } from './utils';

export function* getInvestigations(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const { accountId } = action?.payload;

  const url = replacePlaceholders(Url.INVESTIGATIONS_URL, {
    accountId,
  });

  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);

  yield put({
    type: State.actionSucceeded(Type.GET_INVESTIGATIONS),
    payload: response.data,
  });
}

export function* uploadInvestigationDocument(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const { accountId, investigationId, body } = action?.payload;

  const url = replacePlaceholders(Url.UPLOAD_INVESTIGATION_URL, {
    accountId,
    investigationId,
  });

  const response: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, body, authToken);

  yield put({
    type: State.actionSucceeded(Type.UPLOAD_INVESTIGATION),
    payload: response.data,
  });
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Document submitted successfully!',
    }),
  );
  yield put(getInvestigationsAction(accountId));
}

export function* uploadApplicationInvestigationDocument(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const { accountId, accountHolderId, body } = action?.payload;

  const url = replacePlaceholders(Url.UPLOAD_APPLICATION_INVESTIGATION_URL, {
    accountId,
    accountHolderId,
  });

  const response: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, body, authToken);

  yield put({
    type: State.actionSucceeded(Type.UPLOAD_APPLICATION_INVESTIGATION),
    payload: response.data,
  });
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Document submitted successfully!',
    }),
  );
  yield put(getInvestigationsAction(accountId));
}

/**
 * Investigations sagas
 */
export default function* investigationsSaga() {
  yield takeEvery(State.actionRequested(Type.GET_INVESTIGATIONS), safeSaga(getInvestigations, Type.GET_INVESTIGATIONS));
  yield takeEvery(
    State.actionRequested(Type.UPLOAD_INVESTIGATION),
    safeSaga(uploadInvestigationDocument, Type.UPLOAD_INVESTIGATION),
  );
  yield takeEvery(
    State.actionRequested(Type.UPLOAD_APPLICATION_INVESTIGATION),
    safeSaga(uploadApplicationInvestigationDocument, Type.UPLOAD_APPLICATION_INVESTIGATION),
  );
}
