import * as Yup from 'yup';

const validationMessages = {
  firstNameRequired: 'First name is required.',
  firstNameMinCharacters: 'First Name is too short',
  firstNameMaxCharacters: 'First Name can have 25 characters',
  lastNameRequired: 'Last name is required.',
  lastNameMinCharacters: 'Last Name is too short',
  lastNameMaxCharacters: 'Last Name can have 25 characters',
  investmentRanges: 'Investment Range is required.',
  emailNameRequired: 'Email is required.',
  emailNameInvalid: 'Email is invalid.',
  stateRequired: 'State is required',
  phoneRequired: 'Phone is required.',
  phoneInvalid: 'Phone number is not valid.',
};

export const defaultValidation = Yup.object().shape({
  firstName: Yup.string()
    .required(validationMessages.firstNameRequired)
    .min(2, validationMessages.firstNameMinCharacters)
    .max(30, validationMessages.firstNameMaxCharacters),

  lastName: Yup.string()
    .required(validationMessages.lastNameRequired)
    .min(2, validationMessages.lastNameMinCharacters)
    .max(30, validationMessages.firstNameMaxCharacters),

  email: Yup.string().required(validationMessages.emailNameRequired).email(validationMessages.emailNameInvalid),
  phoneNumber: Yup.string()
    .matches(/^[1-9][0-9]{9,24}$/, validationMessages.phoneInvalid)
    .min(11)
    .max(24),
  state: Yup.string().required(validationMessages.stateRequired),
  investmentRanges: Yup.string().required(validationMessages.investmentRanges),
});

export const guestValidation = Yup.object().shape({
  investmentRanges: Yup.string().required(validationMessages.investmentRanges),
});

export default defaultValidation;
