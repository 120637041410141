import { useEffect } from 'react';

import { usePlaidLink } from 'react-plaid-link';

export interface OpenPlaidLinkBankAccountProps {
  token: string;
  onSuccess: ({ publicToken, metadata }: { publicToken: string; metadata: any }) => void;
  onExit: () => void;
}

export const OpenPlaidLinkBankAccount = ({ token, onSuccess, onExit }: OpenPlaidLinkBankAccountProps) => {
  const plaidConfig = {
    onSuccess: (publicToken: string, metadata: any) => {
      onSuccess({ publicToken, metadata });
    },
    onExit: () => {
      onExit();
    },
    token,
  };

  const { open, ready } = usePlaidLink(plaidConfig);
  useEffect(() => {
    open();
  }, [ready]);

  return null;
};
