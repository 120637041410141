import { css } from '@emotion/css';

import { BaseStyle, Color } from '../../../styles';

export const menuName = css`
  font-size: 1.75rem;
  line-height: 2rem;
  color: ${Color.BRAND.BRAND6};
  margin-top: 4rem;
  margin-left: 4.625rem;
`;

export const formCard = css`
  border: none;
  box-shadow: 0rem 0.25rem 1rem rgba(2, 85, 163, 0.15);
  padding: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const fieldLabel = css`
  line-height: 1.625rem;
  font-weight: 600;
  color: #3c3c3c;
`;

export const sectionHeader = css`
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #3c3c3c;
`;

export const agreementLink = css`
  margin-bottom: 10px;
  color: ${Color.BRAND.BRAND6};
  display: flex;
`;

export const agreementAcceptText = css`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.GRAYSCALE.GRAY7};
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const link = css`
  color: inherit;
  margin-left: 5px;
`;

export const buttonsContainer = css`
  ${BaseStyle.level}
`;
