import React from 'react';

import { Row } from 'antd';

import { TextAlign } from '../../../../lib/FormComponents/Input/constants';
import Input from '../../../../lib/FormComponents/Input/Input';
import ListRow from '../../../../lib/Miscellaneous/ListRow/ListRow';

import * as Styles from './styles';

const EditNickname = ({ form }: any) => {
  return (
    <Row style={{ justifyContent: 'center' }}>
      <i className='ri-edit-line' style={Styles.editImage} />

      <div className={Styles.bankLinkSubDisclaimer}>
        Edit your bank account nickname.
        <br /> This is the only aspect of your bank account which you are allowed to change.
      </div>
      <ListRow
        customRowStyle={{ marginTop: 33 }}
        leftComponent={<div className={Styles.fieldLabel}>Nickname</div>}
        rightComponent={
          <div
            style={{
              alignItems: 'center',
            }}>
            <Input
              name='nickname'
              placeholder='Enter nickname'
              {...form}
              textAlign={TextAlign.Right}
              hideBorder
              value={form.values.nickname}
            />
          </div>
        }
      />
    </Row>
  );
};

export default EditNickname;
