import React from 'react';

import CurrencyField from '../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import ListRow from '../../../../lib/Miscellaneous/ListRow/ListRow';
import { Color, Images } from '../../../../styles';

import * as Styles from './NewTransferConfirmStep.styles';

const withdrawalPolicyText = (
  <div>
    <p data-testid={'money-transfer-text-withdrawal-policy'}>
      Once you confirm your transaction, you will be able to cancel it in the time span that the transaction is pending.
      The amount requested to be withdrawn will typically take 1 to 2 business days to be deposited in your chosen bank
      account.
    </p>
    <p>Once the transaction goes through, it will be deducted from your account value.</p>
  </div>
);

const depositPolicyText = (
  <div>
    <p data-testid={'money-transfer-text-deposit-policy'}>
      Once you confirm your transaction, you will be able to cancel it in the time span that the transaction is pending.
      Once the transaction goes through, you will be able to invest.
    </p>
  </div>
);

export interface NewTransferConfirmStepProps {
  type: 'deposit' | 'withdrawal';
  amount: string;
  accountNumber?: string;
  selectedBankAccount: any;
}

export const NewTransferConfirmStep = ({
  amount,
  selectedBankAccount,
  type,
  accountNumber,
}: NewTransferConfirmStepProps) => {
  const renderSelectedBankAccountType = () => (
    <>
      <span>{selectedBankAccount.tradingBlockACHRelationship.bankAccountType}</span>
      {selectedBankAccount.tradingBlockACHRelationship.nickName && (
        <span className={Styles.nickname}>{` (${selectedBankAccount.tradingBlockACHRelationship.nickName})`}</span>
      )}
    </>
  );

  const renderSelectedBankAccount = () => (
    <div>
      <div>
        <div className={Styles.bankNameContainer}>
          <div className={Styles.bankName}>{selectedBankAccount.tradingBlockACHRelationship.bankName}</div>
          <img
            alt='bankLogo'
            src={
              selectedBankAccount.tradingBlockPlaidInstitution?.logo
                ? `data:image/jpeg;base64,${selectedBankAccount?.tradingBlockPlaidInstitution?.logo}`
                : Images.BankPlaceholder
            }
            className={Styles.bankLogo}
          />
        </div>
      </div>
      <div className={Styles.bankCode}>{selectedBankAccount.tradingBlockACHRelationship?.bankAccount}</div>
    </div>
  );

  return (
    <div>
      <ListRow
        leftComponent={
          <div>
            <div className={Styles.senderReceiverTopLabel}>FROM</div>
            <div className={Styles.senderReceiverBottomLabel}>
              {type === 'deposit' ? renderSelectedBankAccountType() : 'My IPO'}
            </div>
          </div>
        }
        rightComponent={
          type === 'deposit' ? renderSelectedBankAccount() : <div className={Styles.bankCode}>{accountNumber}</div>
        }
      />
      <ListRow
        leftComponent={
          <div>
            <div className={Styles.senderReceiverTopLabel}>TO</div>
            <div className={Styles.senderReceiverBottomLabel}>
              <div className={Styles.senderReceiverBottomLabel}>
                {type === 'deposit' ? 'My IPO' : renderSelectedBankAccountType()}
              </div>
            </div>
          </div>
        }
        rightComponent={
          type === 'deposit' ? <div className={Styles.bankCode}>{accountNumber}</div> : renderSelectedBankAccount()
        }
      />
      <ListRow
        leftComponent={<div className={Styles.sectionTitle}>AMOUNT</div>}
        rightComponent={
          <div className={Styles.bankCode}>
            <CurrencyField value={Number(amount)} testId={'money-transfer-amount'} />
          </div>
        }
      />
      <div className={Styles.sectionTitle}>POLICY</div>
      <div className={Styles.policyText}>
        {type === 'deposit' ? depositPolicyText : withdrawalPolicyText}
        <span>You can read more on transaction policies in the</span>
        <a
          href={`/faq/#${'Account Information'.toLowerCase().replace(/\s/g, '')}`}
          style={{
            color: Color.BRAND.BRAND5,
          }}
          target='_blank'
          rel='noreferrer'>
          {' Bank Accounts & Funding Help Page'}
        </a>
        .
      </div>
    </div>
  );
};
