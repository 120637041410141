import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { useHasAnyRequestedInvestigationDocuments } from '../../../../hooks/useHasAnyRequestedInvestigationDocuments';
import { MAlert } from '../../../../lib/Miscellaneous/MAlert/MAlert';
import { AccountStatusEnum } from '../../../../models/account-status.enum';
import { getInvestigationStatus } from '../../../Investigations/AccountStatus/constants';

export interface YourAchBankLinkNotApprovedAlertMessageProps {
  isAchBankLinkPending: boolean;
}

export const YourAchBankLinkNotApprovedAlertMessage = ({
  isAchBankLinkPending,
}: YourAchBankLinkNotApprovedAlertMessageProps) => {
  const navigate = useNavigate();

  const investigationState = useSelector((state: any) => state.investigations?.investigationsList?.data?.state);
  const accountUuid = useSelector((state: any) => state.accountDetails?.accountHolder?.data?.id);

  const hasRequestedDocuments = useHasAnyRequestedInvestigationDocuments();

  const isStatusPending = getInvestigationStatus(investigationState) === AccountStatusEnum.PENDING;
  const isStatusRejected = getInvestigationStatus(investigationState) === AccountStatusEnum.REJECTED;
  const showAlert = isStatusPending || isStatusRejected;

  if (showAlert && isAchBankLinkPending && hasRequestedDocuments) {
    return (
      <MAlert
        type='warning'
        description={
          <span>
            Your ACH bank link cannot be approved until your account status has been resolved to active. Please visit{' '}
            <a onClick={() => (accountUuid ? navigate(`/accounts/${accountUuid}/account-status`) : null)}>
              Account Status
            </a>{' '}
            to submit the required information.
          </span>
        }
      />
    );
  }

  if (showAlert && isAchBankLinkPending && !hasRequestedDocuments) {
    return (
      <MAlert
        type='warning'
        description={
          <span>
            Your ACH bank link cannot be approved until your account status has been resolved to active. You can find
            more information at{' '}
            <a onClick={() => (accountUuid ? navigate(`/accounts/${accountUuid}/account-status`) : null)}>
              Account Status
            </a>
          </span>
        }
      />
    );
  }

  return null;
};
