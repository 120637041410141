import { css } from '@emotion/css';

import {
  AlignItems,
  BorderRadius,
  Color,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../../styles';

export const mainContainer = css`
  background-color: ${ColorConstant.GRAY2};

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    background: ${ColorConstant.GRAY1};
  }
`;

export const pageName = css`
  ${Spacing.mt64}
  ${Spacing.mlAuto}
  ${Spacing.mr24}
  ${Font.h3}
  color: ${ColorConstant.BRAND6} !important;

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.ml8}
  }
`;

export const menuItem = ({ isActive }: { isActive: boolean }) => css`
  ${Display.flex}
  ${AlignItems.center}
  ${Spacing.pl24}
  ${Spacing.py16}
  ${Spacing.mlAuto}
  ${Spacing.mr24}
  ${Spacing.mb16}
  ${BorderRadius.xs}s
  ${Font.lg}
  ${isActive ? FontWeight.semibold : FontWeight.normal}
  cursor: pointer;
  color: ${ColorConstant.BRAND6} !important;
  background-color: ${isActive ? ColorConstant.BRAND1 : 'inherit'};
`;

export const menuItemIconContainer = css`
  ${Spacing.mlAuto}
  ${Spacing.mr24}
  ${Display.flex}
  ${AlignItems.center}
`;

export const menuItemIcon = ({ isComplete }: { isComplete: boolean }) => css`
  ${Font.h2}
  ${FontWeight.semibold}
  color: ${isComplete ? ColorConstant.GREEN6 : ColorConstant.BRAND6};

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Font.lg}
    ${FontWeight.bold}
    ${Spacing.px4}
  }
`;

export const horizontalLine = css`
  ${Spacing.mlAuto}
  ${Spacing.mr24}
  ${Spacing.mb16}
  background: ${Color.BRAND.BRAND2};
  height: 0.063rem;
`;

export const tabsContainer = css`
  ${Spacing.ml16}
`;

export const tabLabelContainer = css`
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
  ${Font.sm}
`;
