import { useEffect } from 'react';

import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { forgotPassword, clearForgotPassword } from '../../../actions';
import Input from '../../../lib/FormComponents/Input/Input';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { CardLayout } from '../../../lib/Layout/CardLayout/CardLayout';
import { BaseStyle } from '../../../styles';
import * as Styles from '../BaseLayout.styles';

import { FormFields } from './constants';
import { mailOpenIcon } from './ForgotPassword.styles';
import { forgotPasswordValidationSchema } from './validations';

export const ForgotPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSucceeded = useSelector((state: any) => Boolean(state.user.forgotPassword.__succeeded));
  const isRequested = useSelector((state: any) => Boolean(state.user.forgotPassword.__requested));

  const renderForgotPasswordForm = (isRequested: boolean, forgotPassword: (email: string) => void) => {
    return (
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={forgotPasswordValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => forgotPassword(values.email)}>
        {form => {
          return (
            <>
              <div className={Styles.formItemsContainer}>
                <div className={Styles.formTitle}>Forgot Password</div>
                <Input
                  {...form}
                  tabIndex={0}
                  name={FormFields.email}
                  value={form.values.email}
                  placeholder='Enter your email'
                />
              </div>

              <div className={Styles.buttonsContainer}>
                <MButton
                  type='secondary'
                  loading={isRequested}
                  disabled={!form.isValid}
                  onClick={() => {
                    form.submitForm();
                  }}>
                  Reset Password
                </MButton>
              </div>
            </>
          );
        }}
      </Formik>
    );
  };

  const renderForgotPasswordSubmitted = (navigate: NavigateFunction) => {
    return (
      <>
        <div className={BaseStyle.level}>
          <div className={Styles.iconContainer}>
            <i className={`ri-mail-open-line ${mailOpenIcon}`} />
          </div>
        </div>
        <p className={Styles.afterActionTitle}>Check your email</p>
        <p className={Styles.afterActionSubtitle}>We have sent you an email with password reset instructions</p>
        <div className={Styles.buttonsContainer}>
          <MButton
            type='secondary'
            onClick={() => {
              navigate('/login');
            }}>
            Go To Log In
          </MButton>
        </div>
      </>
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearForgotPassword());
    };
  }, []);

  return (
    <CardLayout
      subtitle=' Please enter the email address for your account. An email with instructions will be sent to you. Once you have
    received the email, you will be able to choose a new password for your account.'>
      {!isSucceeded
        ? renderForgotPasswordForm(isRequested, (email: string) => dispatch(forgotPassword({ email })))
        : renderForgotPasswordSubmitted(navigate)}
    </CardLayout>
  );
};
