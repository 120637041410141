import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

export const investigationsList = (state: any = initialState.investigations.investigationsList, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_INVESTIGATIONS):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_INVESTIGATIONS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_INVESTIGATIONS):
      return succeededDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    case State.actionFailed(Type.GET_INVESTIGATIONS):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const uploadInvestigationDocument = (
  state: any = initialState.investigations.uploadInvestigationDocument,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.UPLOAD_INVESTIGATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPLOAD_INVESTIGATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPLOAD_INVESTIGATION):
      return succeededDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    case State.actionFailed(Type.UPLOAD_INVESTIGATION):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const uploadApplicationInvestigationDocument = (
  state: any = initialState.investigations.uploadApplicationInvestigationDocument,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.UPLOAD_APPLICATION_INVESTIGATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPLOAD_APPLICATION_INVESTIGATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPLOAD_APPLICATION_INVESTIGATION):
      return succeededDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    case State.actionFailed(Type.UPLOAD_APPLICATION_INVESTIGATION):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export default combineReducers({
  investigationsList,
  uploadInvestigationDocument,
  uploadApplicationInvestigationDocument,
});
