import { css } from '@emotion/css';

import { ColorConstant, FontWeight, Font } from '../../../styles';

export const yourPhoneNumberIsContainer = css`
  ${Font.lg}
  color: ${ColorConstant.GRAY7};
`;

export const phoneNumberSpan = css`
  ${FontWeight.bold}
  color: ${ColorConstant.GRAY8}
`;
