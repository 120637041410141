import { UploadProps } from 'antd';

import { AccountStatusEnum } from '../../../models/account-status.enum';
import { Color } from '../../../styles';
import { Application, Category, Investigation as InvestigationType } from '../../../typings/investigationTypes';

import * as Styles from './AccountStatus.styles';

export const TB_STATUSES = {
  Complete: 'Complete',
  Rejected: 'Rejected',
  Error: 'Error',
  New: 'New',
  Pending: 'Pending',
  InvestigationSubmitted: 'InvestigationSubmitted',
  ReadyForBackOffice: 'ReadyForBackOffice',
  BackOffice: 'BackOffice',
  AccountSetup: 'AccountSetup',
  ActionRequired: 'ActionRequired',
  Suspended: 'Suspended',
  Cancelled: 'Cancelled',
  PendingSubmit: 'PendingSubmit',
};

const accountDocNameMapper = new Map<string, string>([
  ['DriversLicense', `Driver's License`],
  ['StateIdCard', 'State ID Card'],
  ['Passport', 'Passport ID'],
  ['MilitaryId', 'Military ID'],
  ['SsnCard', 'SSN Card'],
  ['SsaLetter', 'SSA Letter'],
  ['IrsItinLetter', 'IRS ITIN Letter'],
  ['OtherGovernmentId', 'Other Government ID'],
  ['CddDocument', 'CDD Document'],
  ['AllPassingCipResults', 'All Passing Cip Results'],
  ['IrsW9Form', 'IRS W9 Form'],
  ['SignatureImage', 'Signature Image'],
  ['IdDocument', 'ID Document'],
  ['AffiliateApproval', 'Affiliate Approval'],
  ['Document', 'Document'],
  ['Other', 'Other'],
  ['GovernmentId', 'Government ID'],
]);

export const getAccountName = (documentName: string) => {
  return accountDocNameMapper.get(documentName)?.valueOf();
};

export const getInvestigationStatus = (status: string): AccountStatusEnum => {
  switch (status) {
    case TB_STATUSES.Complete:
      return AccountStatusEnum.ACTIVE;
    case TB_STATUSES.Rejected:
      return AccountStatusEnum.REJECTED;
    case TB_STATUSES.Error:
      return AccountStatusEnum.EXPIRED;
    case TB_STATUSES.New:
    case TB_STATUSES.Pending:
    case TB_STATUSES.InvestigationSubmitted:
    case TB_STATUSES.ReadyForBackOffice:
    case TB_STATUSES.BackOffice:
    case TB_STATUSES.AccountSetup:
    case TB_STATUSES.ActionRequired:
    case TB_STATUSES.Suspended:
    case TB_STATUSES.Cancelled:
    case TB_STATUSES.PendingSubmit:
      return AccountStatusEnum.PENDING;
    default:
      return AccountStatusEnum.PENDING;
  }
};

export const getInvestigationDescription = (
  status: string,
  applicationInvestigations: any[],
  investigations: any[],
): any => {
  if (status !== TB_STATUSES.Complete && applicationInvestigations === null && investigations === null) {
    return (
      <div className={Styles.descriptionLabel}>
        Your account application has been submitted. It may take one or two days to review it. Until then your account
        status will be pending. If you have any questions about your application, please contact Customer Support.
        We&apos;re here to help.
      </div>
    );
  }

  switch (status) {
    case TB_STATUSES.Complete:
      return (
        <div className={Styles.descriptionLabel}>
          Your account is now active and you may now fund your account and begin trading. If you have any questions,
          please contact us at your convenience. We look forward to working with you.
        </div>
      );
    case TB_STATUSES.Rejected:
      return (
        <div className={Styles.descriptionLabel}>
          Based on the information provided in your application, we are unable to open your account at this time. If you
          believe this result is due to an error, please submit a new account application or contact us at your
          convenience. Thank you for your interest!
        </div>
      );
    case TB_STATUSES.Error:
      return (
        <div className={Styles.descriptionLabel}>
          Please note the 30-day window for processing your application has expired. If you wish to open a new account,
          please submit a new account application. If you have any questions, please contact us at your convenience.
          Thank you for your interest!
        </div>
      );
    case TB_STATUSES.New:
    case TB_STATUSES.Pending:
    case TB_STATUSES.InvestigationSubmitted:
    case TB_STATUSES.ReadyForBackOffice:
    case TB_STATUSES.BackOffice:
    case TB_STATUSES.AccountSetup:
    case TB_STATUSES.ActionRequired:
    case TB_STATUSES.Suspended:
    case TB_STATUSES.Cancelled:
    case TB_STATUSES.PendingSubmit:
      return (
        <div className={Styles.descriptionLabel}>
          Before we can process your application, we will need the additional information requested below. If you need
          any assistance with providing the requested information or have questions about your application, please
          contact Customer Support. We&apos;re here to help.
        </div>
      );
    default:
      return null;
  }
};

export const getTagStyle = (status: string): React.CSSProperties => {
  let backgroundColor;
  let textColor;

  switch (status) {
    case TB_STATUSES.Complete:
      backgroundColor = Color.GREEN.GREEN7;
      textColor = Color.GREEN.GREEN1;
      break;
    case TB_STATUSES.Rejected:
      backgroundColor = Color.RED.RED6;
      textColor = Color.GRAYSCALE.GRAY1;
      break;
    case TB_STATUSES.Error:
      backgroundColor = Color.GRAYSCALE.GRAY6;
      textColor = Color.GRAYSCALE.GRAY1;
      break;
    case TB_STATUSES.New:
    case TB_STATUSES.Pending:
    case TB_STATUSES.InvestigationSubmitted:
    case TB_STATUSES.ReadyForBackOffice:
    case TB_STATUSES.BackOffice:
    case TB_STATUSES.AccountSetup:
    case TB_STATUSES.ActionRequired:
    case TB_STATUSES.Suspended:
    case TB_STATUSES.Cancelled:
    case TB_STATUSES.PendingSubmit:
      backgroundColor = Color.ORANGE.ORANGE6;
      textColor = Color.ORANGE.ORANGE1;
      break;
    default:
      backgroundColor = Color.ORANGE.ORANGE6;
      textColor = Color.ORANGE.ORANGE1;
      break;
  }

  return {
    borderRadius: 8,
    fontSize: 12,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 8px',
    backgroundColor,
    color: textColor,
  };
};

export const getInvestigationTitleAndDescription = (investigationName: string): JSX.Element | null => {
  switch (investigationName) {
    case 'Name':
      return (
        <div>
          <div className={Styles.investigationTitle}>Proof of Name is required</div>
          <div className={Styles.investigationDescription}>
            Additional document(s) to validate Name are required to process your application.
          </div>
        </div>
      );
    case 'DateOfBirth':
      return (
        <div>
          <div className={Styles.investigationTitle}>Proof of Date of Birth is required</div>
          <div className={Styles.investigationDescription}>
            Additional document(s) to validate Date of Birth are required to process your application.
          </div>
        </div>
      );
    case 'TaxId':
      return (
        <div>
          <div className={Styles.investigationTitle}>Proof of Tax Identification is required</div>
          <div className={Styles.investigationDescription}>
            Additional document(s) to validate your Tax Identification Number are required to process your application.
          </div>
        </div>
      );
    case 'Address':
      return (
        <div>
          <div className={Styles.investigationTitle}>Proof of Address is required</div>
          <div className={Styles.investigationDescription}>
            Additional document(s) to validate your Address are required to process your application.
          </div>
        </div>
      );
    case 'AffiliateApproval':
      return (
        <div>
          <div className={Styles.investigationTitle}>Proof of Affiliated Firm Approval (3210 Letter) is required</div>
          <div className={Styles.investigationDescription}>
            Since you stated you are employed by or a member of a registered broker-dealer, securities or futures
            exchange, or securities industry regulatory body (e.g., FINRA, NFA), a 3210 Form Letter from your employer
            is required to process your application.
          </div>
        </div>
      );
    case 'Signature':
      return (
        <div>
          <div className={Styles.investigationTitle}>Proof of Signature is required</div>
          <div className={Styles.investigationDescription}>
            Additional document(s) to validate your Signature are required to process your application.
          </div>
        </div>
      );
    case 'GovernmentId':
      return (
        <div>
          <div className={Styles.investigationTitle}>Proof of Goverment Identification is required</div>
          <div className={Styles.investigationDescription}>
            Additional document(s) to validate your Goverment Identification are required to process your application.
          </div>
        </div>
      );

    default:
      return null;
  }
};

export const getHeaderLabel = (investigations: any, applications: any, investigationState: string) => {
  if (getInvestigationStatus(investigationState) === AccountStatusEnum.PENDING) {
    const investigationRequestedDocuments: string[] =
      investigations &&
      investigations.map((investigation: InvestigationType) =>
        investigation?.cipCategories?.some((category: Category) => category.requestedDocuments !== null),
      );
    const applicationsRequestedDocuments =
      applications &&
      applications.map((application: Application) =>
        application?.appCategories?.find((category: Category) => category.requestedDocuments !== null),
      );
    if (
      investigationRequestedDocuments?.some((doc: string) => doc) ||
      applicationsRequestedDocuments?.some((doc: string) => doc)
    ) {
      return 'Additional Information';
    } else return null;
  } else if (getInvestigationStatus(investigationState) === AccountStatusEnum.ACTIVE) {
    return 'Document Uploaded';
  } else return null;
};

// this is created to bypass antd default upload
const dummyRequest = ({ onSuccess }: any) => {
  const promise = Promise.resolve('ok');
  promise.then(value => {
    onSuccess(value);
  });
};

export const uploadProps: UploadProps = {
  name: 'file',
  customRequest: dummyRequest,
  multiple: false,
  showUploadList: {
    showDownloadIcon: false,
    showRemoveIcon: false,
  },
};
