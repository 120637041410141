import React, { useRef, useState } from 'react';

import { Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { depositFunds } from '../../../../actions';
import { TransferConstant } from '../../../../constants/transfers.constants';
import { useAccount } from '../../../../hooks/useAccount';
import NumberInput from '../../../../lib/FormComponents/NumberInput/NumberInput';
import ListRow from '../../../../lib/Miscellaneous/ListRow/ListRow';
import { MModal } from '../../../../lib/MModal/MModal';
import { Images } from '../../../../styles';
import { getCurrencyFormatter } from '../../../../utils/getCurrencyFormatter';
import { NewTransferConfirmStep } from '../NewTransferConfirmStep/NewTransferConfirmStep';

import * as Styles from './NewDepositModal.styles';

const formValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Amount is required')
    .positive('Amount must be a positive number')
    .max(
      TransferConstant.MAX_AMOUNT,
      `The maximum transfer amount is ${getCurrencyFormatter().format(TransferConstant.MAX_AMOUNT)}`,
    ),
});

export interface NewDepositFormValues {
  amount: string;
}

export interface NewDepositModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedBankAccount?: any;
}

const initialFormValues: NewDepositFormValues = {
  amount: '',
};

export const NewDepositModal = ({ isOpen, onClose, selectedBankAccount }: NewDepositModalProps) => {
  const [step, setStep] = useState<'deposit' | 'confirm'>('deposit');
  const dispatch = useDispatch();
  const [isFormValid, setIsFormvalid] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<NewDepositFormValues | null>(null);
  const formRef = useRef<FormikProps<NewDepositFormValues> | null>(null);
  const { account } = useAccount();

  const renderDepositStep = () => {
    return (
      <Formik
        enableReinitialize
        validateOnMount
        validate={async values => {
          try {
            await formValidationSchema.validate(values);
            setIsFormvalid(true);

            return {};
          } catch (error: any) {
            setIsFormvalid(false);

            return { [error.path]: error.errors };
          }
        }}
        initialValues={formValues ?? initialFormValues}
        innerRef={form => (formRef.current = form)}
        onSubmit={values => {
          setFormValues(values);
        }}>
        {form => {
          return (
            <div className={Styles.contentView}>
              <ListRow
                leftComponent={
                  <div>
                    <div className={Styles.senderReceiverTopLabel}>FROM</div>
                    <div className={Styles.senderReceiverBottomLabel}>
                      <span>{selectedBankAccount.tradingBlockACHRelationship.bankAccountType}</span>
                      {selectedBankAccount.tradingBlockACHRelationship.nickName && (
                        <span
                          className={
                            Styles.nickname
                          }>{` (${selectedBankAccount.tradingBlockACHRelationship.nickName})`}</span>
                      )}
                    </div>
                  </div>
                }
                rightComponent={
                  <div>
                    <div>
                      <div className={Styles.bankNameContainer}>
                        <div className={Styles.bankName}>
                          {selectedBankAccount.tradingBlockACHRelationship.bankName}
                        </div>
                        <img
                          alt='bankLogo'
                          src={
                            selectedBankAccount.tradingBlockPlaidInstitution?.logo
                              ? `data:image/jpeg;base64,${selectedBankAccount?.tradingBlockPlaidInstitution?.logo}`
                              : Images.BankPlaceholder
                          }
                          className={Styles.bankLogo}
                        />
                      </div>
                    </div>
                    <div className={Styles.bankCode}>
                      {selectedBankAccount.tradingBlockACHRelationship?.bankAccount}
                    </div>
                  </div>
                }
              />
              <ListRow
                leftComponent={
                  <div>
                    <div className={Styles.senderReceiverTopLabel}>TO</div>
                    <div className={Styles.senderReceiverBottomLabel}>My IPO</div>
                  </div>
                }
                rightComponent={
                  <div>
                    <div>&nbsp;</div>
                    <div className={Styles.bankCode}>{account?.accountNumber}</div>
                  </div>
                }
              />
              <div className={Styles.amountFieldWrapper}>
                <div className={Styles.amountLabel}>Deposit Amount</div>
                <NumberInput
                  {...form}
                  placeholder={'0.00'}
                  customStyles={Styles.amountInput}
                  name='amount'
                  value={form.values.amount}
                  step={(0.01).toString()}
                  prefix={'$'}
                  testId={'money-transfer-input-deposit'}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    );
  };

  const renderConfirmStep = () => {
    return (
      <NewTransferConfirmStep
        type='deposit'
        accountNumber={account?.accountNumber}
        amount={formValues?.amount ?? ''}
        selectedBankAccount={selectedBankAccount}
      />
    );
  };

  const renderContent = () => {
    if (step === 'deposit') {
      return renderDepositStep();
    }

    return renderConfirmStep();
  };

  return (
    <MModal
      customWidth={750}
      customHeight={step === 'deposit' ? 300 : 450}
      visible={isOpen}
      title='New Deposit'
      primaryButtonText={step === 'deposit' ? 'Continue' : 'Transfer'}
      secondaryButtonText={step === 'confirm' ? 'Back' : undefined}
      tertiaryButtonText='Close'
      onPrimaryButtonClick={async () => {
        if (step === 'deposit') {
          formRef.current?.submitForm();
          setStep('confirm');
        }

        if (step === 'confirm' && formValues) {
          onClose();
          dispatch(
            depositFunds(selectedBankAccount.tradingBlockACHRelationship.accountId, {
              Amount: Number(formValues.amount),
              RelationshipId: selectedBankAccount.tradingBlockACHRelationship.id,
            }),
          );
        }
      }}
      onSecondaryButtonClick={() => {
        setStep('deposit');
      }}
      onTertiaryButtonClick={onClose}
      isDisabledPrimaryButton={!isFormValid}
      onClose={onClose}>
      {renderContent()}
    </MModal>
  );
};
