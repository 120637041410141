import _ from 'lodash';
import * as Yup from 'yup';

import { states } from '../../../constants/states';

import { FormFields } from './constants';

export const validationMessages = {
  countryRequired: 'Country Of Residence is required.',
  countryInvalid: 'Country Of Residence is invalid.',
  stateRequired: 'State is required',
  stateInvalid: 'State should be one of the USA states',
};

export const addressValidationSchema = Yup.object().shape({
  [FormFields.country]: Yup.string()
    .required(validationMessages.countryRequired)
    .oneOf(['USA'], validationMessages.countryInvalid),
  [FormFields.state]: Yup.string()
    .required(validationMessages.stateRequired)
    .test('compare', validationMessages.stateInvalid, function (state: string | any, schema: string | any) {
      if (schema !== undefined && schema !== null) {
        return !_.isEmpty(_.find(states, ['value', state]));
      } else {
        return false;
      }
    }),
});
