import { css } from '@emotion/css';

import { ColorConstant, Display, Font, Spacing } from '../../styles';

export const container = css`
  ${Font.sm}
  color: ${ColorConstant.GRAY7};
  ${Display.flex}
  ${Spacing.my40}
`;
