import React from 'react';

import { Row } from 'antd';

import { ListRowComponentType } from './constants';
import * as Styles from './styles';

const ListRow = ({ leftComponent, rightComponent, customRowStyle, testId }: ListRowComponentType) => {
  return (
    <Row className={Styles.listRowContainer} style={customRowStyle} data-testid={testId}>
      {leftComponent && <div className={Styles.listRowChild}>{leftComponent}</div>}
      {rightComponent && rightComponent !== null && <div className={Styles.listRowChild}>{rightComponent}</div>}
    </Row>
  );
};

export default ListRow;
