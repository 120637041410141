export const accountAgreementLinks = [
  {
    label: 'Client Relationship Summary (CRS)',
    url: '/static/docs/Cambria_Capital_Form_CRS.pdf',
  },
  {
    label: 'Cambria Capital, LLC dba My IPO Customer Agreement & Privacy Policy',
    url: '/static/docs/My_IPO_Customer_Agreement-TB_Apex_final_102122.pdf',
  },
  {
    label: 'Brokerage Services Disclosure',
    url: '/static/docs/Brokerage_Services_Disclosure_v1.0_221019_final.pdf',
  },
  {
    label: 'Apex Clearing Corporation Customer Agreement & Privacy Policy',
    url: '/static/docs/Apex_Customer_Agreement_for_My_IPO_v5.6_20200424_final.pdf',
  },
  {
    label: 'TradingBlock Privacy Policy',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/tb/Privacy_Policy_Revision_2014.pdf',
  },
  {
    label: 'Electronic Consent',
    url: '/static/docs/Electronic_Consent_Agreement_v1.3_20140713-My_IPO_final.pdf',
  },
  {
    label: 'Online Services Agreement',
    url: '/static/docs/Online_Services_Agreement_v1.4_20140713-My_IPO_final.pdf',
  },
];
