import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { CreateOfferWatchDto } from '../../../../dtos/offerWatches.dtos';
import { UserDto } from '../../../../dtos/users.dtos';
import ListRow from '../../../../lib/Miscellaneous/ListRow/ListRow';
import { OfferDetails } from '../../../../models/offers.models';
import { Images } from '../../../../styles';
import IndicationsOfInterestBanner from '../IndicationsOfInterestBanner/IndicationsOfInterestBanner';

import Disclaimer from './Disclaimer';
import * as Styles from './styles';

const IndicationOfInterestSubmission = ({
  offer,
  submittedInterest,
  userInfo,
}: {
  offer: OfferDetails;
  submittedInterest: CreateOfferWatchDto;
  userInfo: UserDto;
}) => {
  const userPersonalInformation: any = useSelector(
    (state: any) => state.accountDetails?.personalInformation?.data?.physicalAddress,
  );

  const data = [
    {
      label: 'Investor Name',
      value: `${submittedInterest?.firstName} ${submittedInterest?.lastName}`,
      hide: !userInfo,
    },
    {
      label: 'Address',
      value: userPersonalInformation
        ? `${userPersonalInformation?.address1}, ${userPersonalInformation?.city}, ${userPersonalInformation?.postalCode}, ${userPersonalInformation?.country}`
        : submittedInterest.stateCode,
      hide: !userInfo,
    },
    { label: 'Date', value: moment(new Date().toISOString()).format('MM/DD/YYYY'), hide: false },
    { label: 'First Name', value: submittedInterest?.firstName, hide: userInfo },
    { label: 'Last Name', value: submittedInterest?.lastName, hide: userInfo },
    { label: 'Email', value: submittedInterest?.email, hide: userInfo },
    { label: 'Phone Number', value: submittedInterest?.phoneNumber, hide: userInfo },
    { label: 'State', value: submittedInterest.stateCode, hide: userInfo },
    { label: 'Investment Range', value: submittedInterest.investmentRange, hide: false },
  ];

  return (
    <div>
      {!userInfo && <IndicationsOfInterestBanner offer={offer} />}
      <Row className={Styles.rowStyle}>
        <CheckCircleOutlined className={Styles.circleOutlinedStyles} />
        <span className={Styles.submissionSucceeded}>Submission Succeeded</span>
      </Row>

      <div className={Styles.submissionDescription}>
        You will receive updates and notifications when the Offer becomes for actual investment
      </div>

      <Row className={Styles.rowStyle}>
        <img className={Styles.offerLogo} src={offer?.logoUrl || Images.DefaultOffering} alt={`${offer.name}_logo`} />
        <span className={Styles.offerName}>{offer.name}</span>
      </Row>

      <div>
        {data
          .filter(item => !item.hide)
          .map(item => (
            <ListRow
              key={item.label}
              leftComponent={<div className={Styles.fieldLabel}>{item.label}</div>}
              rightComponent={<div className={Styles.fieldValue}>{item.value}</div>}
            />
          ))}
      </div>
      <Disclaimer />
    </div>
  );
};

export default IndicationOfInterestSubmission;
