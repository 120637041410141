import { css } from '@emotion/css';

import { BorderRadius, Color, Font, FontWeight, ScreenBreakpoint, Spacing, TextAlign } from '../../../../../../styles';

export const title = css`
  ${FontWeight.bold};
  ${Font.h2};
  color: ${Color.GRAYSCALE.GRAY7};
`;

export const refresh = css`
  ${FontWeight.normal};
  ${Font.sm};
  cursor: pointer;
  img {
    width: 0.75rem;
    height: 0.75rem;
    vertical-align: baseline;
  }
  @media (max-width: ${ScreenBreakpoint.mobile.min}) {
    width: 100%;
  }
`;

export const disclaimer = css`
  ${FontWeight.normal};
  ${Font.sm};
  color: ${Color.BRAND.BRAND6};
  cursor: pointer;
`;

export const dataRow = css`
  background: ${Color.GRAYSCALE.GRAY2};
  ${BorderRadius.xxs};
  ${FontWeight.semibold};
  ${Font.md}
  ${Spacing.p12};
  ${Spacing.pl8};
  i {
    cursor: pointer;
    ${Font.xs};
  }
  @media (max-width: ${ScreenBreakpoint.mobile.min}) {
    ${Spacing.px0};
    ${Spacing.py12};
  }
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.px4};
    ${Spacing.py12};
  }
`;

export const alignRight = css`
  ${TextAlign.right};
`;

export const errorInfo = css`
  ${Font.h1};
  color: ${Color.RED.RED6};
  cursor: default !important;
`;

export const popoverWrapper = css`
  width: 200px;
`;
