export enum InvestigationDocumentName {
  DriversLicense = 'DriversLicense',
  StateIdCard = 'StateIdCard',
  Passport = 'Passport',
  MilitaryId = 'MilitaryId',
  SsnCard = 'SsnCard',
  SsaLetter = 'SsaLetter',
  IrsItinLetter = 'IrsItinLetter',
  OtherGovernmentId = 'OtherGovernmentId',
  CddDocument = 'CddDocument',
  AllPassingCipResults = 'AllPassingCipResults',
  IrsW9Form = 'IrsW9Form',
  SignatureImage = 'SignatureImage',
  IdDocument = 'IdDocument',
  AffiliateApproval = 'AffiliateApproval',
  Document = 'Document',
  Other = 'Other',
  GovernmentId = 'GovernmentId',
}

export interface Category {
  name: string;
  state: string;
  requestedDocuments: InvestigationDocumentName[];
  isResolved: boolean;
}

export interface UploadedDocument {
  documentName: InvestigationDocumentName;
  state: string;
  fileName: string;
  originalFileName?: string;
  description?: string;
  takenOn: Date;
  miscDocumentId: number;
  uploadUserName?: string;
  uploadDateTime?: Date;
}

export interface Application {
  accountHolderId: number;
  firstName: string;
  lastName: string;
  state: string;
  appCategories: Category[];
  uploadedDocuments?: any;
}

export interface Investigation {
  investigationId: number;
  firstName: string;
  lastName: string;
  state: string;
  cipCategories: Category[];
  uploadedDocuments: UploadedDocument[];
}
