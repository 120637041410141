import React from 'react';

import { cx } from '@emotion/css';
import { Col } from 'antd';

import * as Styles from './ModalField.styles';

interface ModalFieldProps {
  label: string;
  value: JSX.Element | string | number;
}

export const ModalField = ({ label, value }: ModalFieldProps) => (
  <React.Fragment>
    <Col span={10} className={cx(Styles.borderBottom, Styles.fieldLabel)}>
      {label}
    </Col>
    <Col span={14} className={cx(Styles.alignRight, Styles.borderBottom, Styles.fieldValue)}>
      {value}
    </Col>
  </React.Fragment>
);
