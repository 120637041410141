import _ from 'lodash';

import {
  AnnualIncomeType,
  EmploymentType,
  LiquidNetWorthType,
  states,
  TaxBracket,
  TotalNetWorthType,
  YearsEmployed,
} from '../../../constants/accountCompletion';
import { countries } from '../../../constants/countryCodes';
import Dropdown from '../../../lib/FormComponents/Dropdown';
import { InputType } from '../../../lib/FormComponents/Input/constants';
import Input from '../../../lib/FormComponents/Input/Input';
import { MenuNames } from '../../../models/menus.enum';

import * as Styles from './styles';
import { assetsValidation, employmentInformationValidation } from './validations';

export const checkIfFieldIsHidden = (status: string, fieldKey: string) => {
  return (
    ['student', 'unemployed', 'retired'].includes(status) &&
    [
      'employerName',
      'jobTitle',
      'yearsEmployed',
      'address1',
      'address2',
      'city',
      'state',
      'country',
      'postalCode',
    ].includes(fieldKey)
  );
};

export const assetsSectionFields = (financialInformation: any) => [
  {
    label: 'Annual Income',
    component: Dropdown,
    key: 'annualIncome',
    tooltipText: 'Total amount of income earned annually before taxes.',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(AnnualIncomeType, {
            value:
              !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.annualIncome)
                ? financialInformation.annualIncome
                : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'annualIncome',
      placeholder: 'Select',
      options: AnnualIncomeType,
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.annualIncome)
          ? financialInformation.annualIncome
          : null,
    },
  },
  {
    label: 'Total Net Worth',
    component: Dropdown,
    key: 'totalNetWorth',
    tooltipText: 'Net worth is what you own minus what you owe excluding your primary residence.',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(TotalNetWorthType, {
            value:
              !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.totalNetWorth)
                ? financialInformation.totalNetWorth
                : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'totalNetWorth',
      placeholder: 'Select',
      options: TotalNetWorthType,
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.totalNetWorth)
          ? financialInformation.totalNetWorth
          : null,
    },
  },

  {
    label: 'Liquid Net Worth',
    component: Dropdown,
    key: 'liquidNetWorth',
    tooltipText:
      'Liquid net worth is the part of your assets that can readily be turned into cash. This includes investments such as stocks and mutual funds but not real estate and cars.',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(LiquidNetWorthType, {
            value:
              !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.liquidNetWorth)
                ? financialInformation.liquidNetWorth
                : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'liquidNetWorth',
      placeholder: 'Select',
      options: LiquidNetWorthType,
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.liquidNetWorth)
          ? financialInformation.liquidNetWorth
          : null,
    },
  },
  {
    label: 'Tax Bracket',
    component: Dropdown,
    key: 'taxBracket',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(TaxBracket, {
            value:
              !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.taxBracket)
                ? financialInformation.taxBracket
                : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'taxBracket',
      placeholder: 'Select',
      options: TaxBracket,
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.taxBracket)
          ? financialInformation.taxBracket
          : null,
    },
  },
];

export const employmentInformationSectionFields = (financialInformation: any) => [
  {
    label: 'Employment Status',
    component: Dropdown,
    key: 'employmentStatus',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(EmploymentType, {
            value:
              !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.employmentStatus)
                ? financialInformation.employmentStatus
                : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'employmentStatus',
      placeholder: 'Select',
      options: EmploymentType,
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.employmentStatus)
          ? financialInformation.employmentStatus
          : null,
    },
  },
  {
    label: 'Employer Name',
    component: Input,
    key: 'employerName',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(financialInformation) ? financialInformation.employerName : null}
      </div>
    ),
    readOnly: false,
    props: {
      name: 'employerName',
      placeholder: 'Enter',
      value: !_.isEmpty(financialInformation) ? financialInformation.employerName : null,
    },
  },

  {
    label: 'Job Title',
    component: Input,
    key: 'jobTitle',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.jobTitle)
          ? financialInformation.jobTitle
          : null}
      </div>
    ),
    props: {
      name: 'jobTitle',
      placeholder: 'Enter',
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.jobTitle)
          ? financialInformation.jobTitle
          : null,
    },
  },
  {
    label: 'Years Employed',
    component: Dropdown,
    key: 'yearsEmployed',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(YearsEmployed, {
            value:
              !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.yearsEmployed)
                ? financialInformation.yearsEmployed
                : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'yearsEmployed',
      placeholder: 'Select',
      options: YearsEmployed,
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation.yearsEmployed)
          ? financialInformation.yearsEmployed
          : null,
    },
  },
  {
    label: 'Address Line 1',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.address1)
          ? financialInformation?.employerAddress?.address1
          : null}
      </div>
    ),
    component: Input,
    key: 'address1',

    props: {
      name: 'address1',
      placeholder: 'Enter',
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.address1)
          ? financialInformation?.employerAddress?.address1
          : null,
    },
  },
  {
    label: 'Address Line  2 (Opt.)',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.address2)
          ? financialInformation?.employerAddress?.address2
          : '-'}
      </div>
    ),
    component: Input,
    key: 'address2',
    props: {
      name: 'address2',
      placeholder: 'Enter',
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.address2)
          ? financialInformation?.employerAddress?.address2
          : null,
    },
  },
  {
    label: 'Country',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(countries, {
            value:
              !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.country)
                ? financialInformation?.employerAddress?.country
                : null,
          })?.text
        }
      </div>
    ),
    component: Dropdown,
    key: 'country',
    props: {
      name: 'country',
      placeholder: 'Select',
      options: countries,
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.country)
          ? financialInformation?.employerAddress?.country
          : null,
    },
  },
  {
    label: 'City',
    component: Input,
    key: 'city',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.city)
          ? financialInformation?.employerAddress?.city
          : null}
      </div>
    ),
    props: {
      name: 'city',
      placeholder: 'Enter',
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.city)
          ? financialInformation?.employerAddress?.city
          : null,
    },
  },
  {
    label: 'State',
    component: Dropdown,
    key: 'state',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(states, {
            value:
              !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.state)
                ? financialInformation?.employerAddress?.state
                : null,
          })?.text
        }
      </div>
    ),
    props: {
      showSearch: true,
      name: 'state',
      placeholder: 'Select',
      options: states,
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.state)
          ? financialInformation?.employerAddress?.state
          : null,
    },
  },

  {
    label: 'Postal Code',
    component: Input,
    key: 'postalCode',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.postalCode)
          ? financialInformation?.employerAddress?.postalCode
          : null}
      </div>
    ),
    props: {
      name: 'postalCode',
      placeholder: 'Enter',
      type: InputType.Number,
      maxLength: 6,
      value:
        !_.isEmpty(financialInformation) && !_.isEmpty(financialInformation?.employerAddress?.postalCode)
          ? financialInformation?.employerAddress?.postalCode
          : null,
    },
  },
];

export const getFinancialInformationSections = (financialInformation: any) => {
  return {
    menuName: MenuNames.FINANCIAL_INFORMATION,
    path: 'financial-information',
    sections: [
      {
        sectionLabel: 'Income & Assets',
        sectionKey: 'assets',
        sectionFields: [...assetsSectionFields(financialInformation)],
        isCompleted: assetsValidation?.isValidSync(financialInformation),
        validationSchema: assetsValidation,
      },
      {
        sectionLabel: 'Employment Information',
        sectionKey: 'employment_information',
        sectionFields: [...employmentInformationSectionFields(financialInformation)],
        isCompleted: employmentInformationValidation?.isValidSync({
          employmentStatus: financialInformation?.employmentStatus,
          employerName: financialInformation?.employerName,
          jobTitle: financialInformation?.jobTitle,
          yearsEmployed: financialInformation?.yearsEmployed,
          ...financialInformation?.employerAddress,
        }),
        validationSchema: employmentInformationValidation,
      },
    ],
  };
};

export const checkIfCardOpened = (section: any, formValues: any, financialInformation: any) => {
  return (
    (section.sectionKey === 'employment_information' &&
      formValues['employmentStatus'] === 'employed' &&
      !financialInformation.employerName) ||
    !section.isCompleted
  );
};

export const checkIsSectionCompleted = (
  section: any,
  openedField: string,
  formValues: any,
  financialInformation: any,
): boolean => {
  return section.isCompleted &&
    openedField === 'employmentStatus' &&
    formValues['employmentStatus'] === 'employed' &&
    financialInformation.employerName == null
    ? false
    : section.isCompleted;
};

export const onPreChangeHook = (field: any, form: any) => {
  return (value: any) => {
    if (field.key === 'employmentStatus' && (value === 'student' || value === 'unemployed')) {
      form.setFieldValue('jobTitle', null);
      form.setFieldValue('yearsEmployed', null);
      form.setFieldValue('employerName', null);
      form.setFieldValue('country', null);
      form.setFieldValue('address1', null);
      form.setFieldValue('address2', null);
      form.setFieldValue('city', null);
      form.setFieldValue('state', null);
      form.setFieldValue('postalCode', null);
    }
  };
};
