import { Upload } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { toastMessagesAdd } from '../../actions';
import { InvestigationConstant } from '../../constants/investigations.constants';
import { SeverityEnum } from '../../typings/commonTypes';

export const validateFile = (file: any, dispatch: any) => {
  const fileSizeRatioToMaxFileSize = (file.size / InvestigationConstant.MAX_FILE_SIZE).toFixed(2);
  const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
  const isSupportedFileSize = Number(fileSizeRatioToMaxFileSize) <= 1;
  const isSupportedFileType = ['image/png', 'image/jpeg', 'application/pdf'].includes(file.type);

  if (!isSupportedFileSize) {
    dispatch(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Error,
        message: `Maximum file size allowed is 15 MB. Received ${fileSizeMB} MB file size.`,
      }),
    );
  }

  if (!isSupportedFileType) {
    dispatch(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Error,
        message: `Supported file formats are pdf, png, jpg or jpeg. Received "${file.type}" file format.`,
      }),
    );
  }

  return (isSupportedFileSize && isSupportedFileType) || Upload.LIST_IGNORE;
};
