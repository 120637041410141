import { OfferDetails } from '../../../../models/offers.models';

import * as Styles from './styles';

const IndicationsOfInterestBanner = ({ offer }: { offer: OfferDetails }) => {
  return (
    <div className={Styles.customBanner}>
      <div className={Styles.bannerTitle}>
        <i style={{ ...Styles.infoLogoStyle }} className={`ri-information-line`} />
        Information
      </div>
      <div className={Styles.bannerText}>
        Filling out the information below and placing this indication of interest for {offer.name} does not implicitly
        create an account in our platform for you. Once the Offer becomes available for actual investment, you will be
        required to Sign Up and create an account if you choose to place an order.
      </div>
    </div>
  );
};

export default IndicationsOfInterestBanner;
