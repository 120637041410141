import { RadioGroupValue } from '../../../../../lib/FormComponents/RadioGroup/constants';

import * as Styles from './NewOfferOrder.styles';

export const escrowOfferFields = ['Minimum Investment Amount', 'Share Price'];
export const normalOfferFields = ['Cash Available', 'Minimum Investment Amount', 'Share Price', 'Share Range'];

export const radioValues: Array<RadioGroupValue> = [
  {
    value: 'non_accredited_investor',
    text: 'I am a non-accredited investor and agree that the purchase price for the Securities I am purchasing in the Offering does not exceed 10% of my net worth or annual income, whichever is greater.',
    subComponent: (
      <div className={Styles.accreditedInvestor}>
        <i style={Styles.infoLogo} className={`ri-information-line`} />
        What is a non-accredited investor?
      </div>
    ),
  },

  {
    value: 'accredited_investor',
    text: 'I am an accredited investor',
    subComponent: (
      <div className={Styles.accreditedInvestor}>
        <i style={Styles.infoLogo} className={`ri-information-line`} />
        What is an accredited investor?
      </div>
    ),
  },
];

export enum InvestorType {
  Accredited = 'accredited',
  NonAccredited = 'nonAccredited',
}

export const ACCREDITED_INVESTOR_TOTAL_NET_WORTH_MIN = 1000000;
export const SINGLE_ACCREDITED_INVESTOR_ANNUAL_INCOME_MIN = 200000;
export const MARRIED_ACCREDITED_INVESTOR_ANNUAL_INCOME_MIN = 300000;
