import { css, cx } from '@emotion/css';

import { Color } from '../../../styles';

export const rowContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const labelContainer = css`
  display: flex;
  width: 80%;
`;

export const switchLabel = cx(
  css`
    font-size: 18px !important;
  `,
);

export const switchStyle = css`
  &:hover:not(:focus) {
    background-color: ${Color.BRAND.BRAND5} !important;
  }
`;
