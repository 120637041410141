import { AnyAction } from 'redux';

import { createAction, State, Type } from './utils';

export const updateAccountDetails = (payload: any, sectionKey: string): AnyAction => {
  return createAction(State.actionRequested(Type.UPDATE_ACCOUNT_DETAILS), {
    data: payload,
    sectionKey,
  });
};

export const getPersonalInformation = (): AnyAction =>
  createAction(State.actionRequested(Type.GET_PERSONAL_INFORMATION));

export const updatePersonalInformation = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.UPDATE_PERSONAL_INFORMATION), {
    data: payload,
  });
};

export const clearUpdatePersonalInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.UPDATE_PERSONAL_INFORMATION));

export const patchPersonalInformation = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_PERSONAL_INFORMATION), {
    data: payload,
  });
};

export const clearPatchPersonalInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_PERSONAL_INFORMATION));

export const updatePhysicalAddress = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.UPDATE_PHYSICAL_ADDRESS), { data: payload });
};

export const clearUpdatePhysicalAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.UPDATE_PHYSICAL_ADDRESS));

export const patchPhysicalAddress = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_PHYSICAL_ADDRESS), { data: payload });
};

export const clearPatchPhysicalAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_PHYSICAL_ADDRESS));

export const updateMailingAddress = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.UPDATE_MAILING_ADDRESS), { data: payload });
};

export const clearUpdateMailingAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.UPDATE_MAILING_ADDRESS));

export const patchMailingAddress = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_MAILING_ADDRESS), { data: payload });
};

export const clearPatchMailingAddress = (): AnyAction => createAction(State.actionCleared(Type.PATCH_MAILING_ADDRESS));

export const getTrustedContact = (): AnyAction => createAction(State.actionRequested(Type.GET_TRUSTED_CONTACT));

export const updateTrustedContact = (payload: any, sectionKey: string): AnyAction => {
  return createAction(State.actionRequested(Type.UPDATE_TRUSTED_CONTACT), {
    data: payload,
    sectionKey,
  });
};

export const clearUpdateTrustedContact = (): AnyAction =>
  createAction(State.actionCleared(Type.UPDATE_TRUSTED_CONTACT));

export const patchTrustedContact = (payload: any, sectionKey: string): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_TRUSTED_CONTACT), {
    data: payload,
    sectionKey,
  });
};

export const clearPatchTrustedContact = (): AnyAction => createAction(State.actionCleared(Type.PATCH_TRUSTED_CONTACT));

export const removeTrustedContact = (payload: any, sectionKey: string): AnyAction => {
  return createAction(State.actionRequested(Type.REMOVE_TRUSTED_CONTACT), {
    data: payload,
    sectionKey,
  });
};

export const clearRemoveTrustedContact = (): AnyAction =>
  createAction(State.actionCleared(Type.REMOVE_TRUSTED_CONTACT));

export const getSuitabilityInformation = (): AnyAction =>
  createAction(State.actionRequested(Type.GET_SUITABILITY_INFORMATION));

export const updateSuitabilityInformation = (payload: any, sectionKey: string): AnyAction => {
  return createAction(State.actionRequested(Type.UPDATE_SUITABILITY_INFORMATION), {
    data: payload,
    sectionKey,
  });
};

export const clearUpdateSuitabilityInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.UPDATE_SUITABILITY_INFORMATION));

export const patchSuitabilityInformation = (payload: any, sectionKey: string): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_SUITABILITY_INFORMATION), {
    data: payload,
    sectionKey,
  });
};

export const clearPatchSuitabilityInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_SUITABILITY_INFORMATION));

export const getFinancialInformation = (): AnyAction =>
  createAction(State.actionRequested(Type.GET_FINANCIAL_INFORMATION));

export const updateFinancialInformationAssets = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.UPDATE_FINANCIAL_INFORMATION_ASSETS), {
    data: payload,
  });
};

export const clearUpdateFinancialInformationAssets = (): AnyAction =>
  createAction(State.actionCleared(Type.UPDATE_FINANCIAL_INFORMATION_ASSETS));

export const patchFinancialInformationAssets = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_FINANCIAL_INFORMATION_ASSETS), {
    data: payload,
  });
};

export const clearPatchFinancialInformationAssets = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_FINANCIAL_INFORMATION_ASSETS));

export const updateFinancialInformationEmployment = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT), {
    data: payload,
  });
};

export const clearUpdateFinancialInformationEmployment = (): AnyAction =>
  createAction(State.actionCleared(Type.UPDATE_FINANCIAL_INFORMATION_EMPLOYMENT));

export const patchFinancialInformationEmployment = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT), {
    data: payload,
  });
};

export const clearPatchFinancialInformationEmployment = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_FINANCIAL_INFORMATION_EMPLOYMENT));

export const getDisclosures = (): AnyAction => createAction(State.actionRequested(Type.GET_DISCLOSURES));

export const updateDisclosures = (payload: any, sectionKey: string): AnyAction => {
  return createAction(State.actionRequested(Type.UPDATE_DISCLOSURES), {
    data: payload,
    sectionKey,
  });
};

export const clearUpdateDisclosures = (): AnyAction => createAction(State.actionCleared(Type.UPDATE_DISCLOSURES));

export const patchDisclosures = (payload: any, sectionKey: string): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_DISCLOSURES), {
    data: payload,
    sectionKey,
  });
};

export const clearPatchDisclosures = (): AnyAction => createAction(State.actionCleared(Type.PATCH_DISCLOSURES));

export const acceptAccountAgreements = (): AnyAction =>
  createAction(State.actionSucceeded(Type.ACCEPT_ACCOUNT_AGREEMENTS), { agreeElectronicConsent: true });

export const declineAccountAgreements = (): AnyAction =>
  createAction(State.actionSucceeded(Type.DECLINE_ACCOUNT_AGREEMENTS), { agreeElectronicConsent: false });

export const submitAccountApplication = (payload: { signature?: string }): AnyAction =>
  createAction(State.actionRequested(Type.CREATE_ACCOUNT_SUBMIT), payload);

export const getAccountHolder = (): AnyAction => createAction(State.actionRequested(Type.GET_ACCOUNT_HOLDER));

export const getAccountHolderSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_ACCOUNT_HOLDER), payload);

export const doGetAccountBalance = (accountId: number): AnyAction =>
  createAction(State.actionRequested(Type.ACCOUNTS_GET_BALANCE), { accountId });

export const doSucceededAccountBalance = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.ACCOUNTS_GET_BALANCE), payload);

export const doClearAccountBalance = (): AnyAction => createAction(State.actionCleared(Type.ACCOUNTS_GET_BALANCE), {});

export const refreshAccountBalance = (accountId: string): AnyAction =>
  createAction(Type.REFRESH_ACCOUNT_BALANCE, { accountId });

export const accountsClearValues = (): AnyAction => createAction(State.actionCleared(Type.SET_ACCOUNT_VALUE));

export const accountsSetValues = (payload: any): AnyAction =>
  createAction(State.actionSucceeded(Type.SET_ACCOUNT_VALUE), payload);
