import { Fragment, useEffect } from 'react';

import { Col, Row } from 'antd';
import { Formik } from 'formik';
import getCanvas from 'html2canvas';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { MAlert } from 'src/lib';

import { getPersonalInformation, getSignature, submitAccountApplication } from '../../../actions';
import { useAccount } from '../../../hooks/useAccount';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { MDivider } from '../../../lib/MDivider/MDivider';
import { ScreenBreakpoint } from '../../../styles';

import { customerAgreementAcknowledge, getCustomerAgreementSections } from './fields';
import * as Styles from './styles';

export const SignApplication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadingAccountSubmit = _.isDate(useSelector((state: any) => state.accountDetails.accountSubmit?.__requested));
  const succeededAccountSubmit = _.isDate(
    useSelector((state: any) => state.accountDetails?.accountSubmit?.__succeeded),
  );
  const signature = useSelector((state: any) => state.user.getSignature.data?.signature?.base64);
  const agreeElectronicConsent = useSelector(
    (state: any) => state.accountDetails?.accountAgreements?.data?.agreeElectronicConsent,
  );
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });
  const personalInformation = useSelector((state: any) => state.accountDetails?.personalInformation?.data);
  const loadingPersonalInformation = _.isDate(
    useSelector((state: any) => state.accountDetails?.personalInformation.__requested),
  );

  const { account } = useAccount();

  const initialValues = (fields: any) =>
    Object.keys(fields)
      .map(key => ({
        key: fields[key].props.name,
        value: fields[key].props.value,
      }))
      .reduce(
        (obj, keyValuePair) => ({
          ...obj,
          [keyValuePair.key]: keyValuePair.value,
        }),
        {},
      );

  const onSubmit = async (values: any) => {
    let userSignature: string | undefined;

    if (!signature) {
      const signatureToSave = await getCanvas(values.signature).then(canvas => {
        return canvas.toDataURL();
      });
      userSignature = signatureToSave;
    }

    dispatch(submitAccountApplication({ signature: userSignature }));
  };

  useEffect(() => {
    if (succeededAccountSubmit) {
      window.gtag('event', 'account', {
        accountId: account?.accountId,
      });

      return navigate(`/`);
    }
  }, [succeededAccountSubmit]);

  const menu: any = getCustomerAgreementSections(signature);

  useEffect(() => {
    if (!personalInformation?.id && !loadingPersonalInformation) {
      dispatch(getPersonalInformation());
    }
    dispatch(getSignature());
  }, []);

  const isAccountApplicationComplete = () => {
    return (
      account?.primaryAccountHolder?.firstName &&
      account?.primaryAccountHolder?.physicalAddress &&
      account?.primaryAccountHolder?.mailingAddress &&
      account?.primaryAccountHolder?.suitabilityInformation &&
      account?.primaryAccountHolder?.financialInformation?.annualIncome &&
      account?.primaryAccountHolder?.financialInformation?.employmentStatus &&
      account?.primaryAccountHolder?.disclosure &&
      agreeElectronicConsent
    );
  };

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.SignApplication}
      title={isMobile ? undefined : 'Sign Application'}>
      {menu.sections.map((section: any) => (
        <Row key={section.sectionKey}>
          <Col span={18} xs={24} sm={24}>
            <MAlert
              showIcon
              type='info'
              description={
                <>
                  <span>
                    Before you can sign the application to set up your My IPO account, you will need to complete all the
                    required information listed under the sections
                    {isMobile ? 'above' : 'on the left'}. If there is a section that has not been completed, it will
                    appear with the following pending icon
                  </span>
                  <i className={[`ri-time-line`, Styles.timelineIcon].join(' ')} />
                </>
              }
              className={Styles.alert}
            />
            <div className={Styles.formCard}>
              <div className={isAccountApplicationComplete() ? undefined : Styles.formCardBlur}>
                <Formik
                  validateOnChange
                  initialValues={initialValues(section.sectionFields)}
                  onSubmit={values => {
                    onSubmit(values);
                  }}
                  validationSchema={signature ? null : section.validationSchema}>
                  {form => {
                    const formValues: any = form.values;

                    return (
                      <>
                        {section.sectionFields.map((field: any) => {
                          const JSXRenderableComp = field.component;

                          return (
                            <Fragment key={`${field.key}_frag`}>
                              <Row key={`${field.key}_row`}>
                                {field.showInRow && (
                                  <Col span={12}>
                                    <div
                                      className={Styles.fieldLabel}
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 15,
                                      }}>
                                      {field.label}
                                    </div>
                                  </Col>
                                )}
                                <Col span={field.showInRow ? 12 : 24}>
                                  {customerAgreementAcknowledge(form)}

                                  {signature ? null : (
                                    <>
                                      <div
                                        className={Styles.normalLabel}
                                        style={{
                                          marginTop: 16,
                                          marginBottom: 16,
                                        }}>
                                        Please select a signature to use
                                      </div>
                                      <JSXRenderableComp
                                        key={`${field.key}_field`}
                                        {...field.props}
                                        {...form}
                                        name={field.key}
                                        value={formValues[field.key]}
                                        disabled={
                                          !isAccountApplicationComplete() ||
                                          loadingPersonalInformation ||
                                          loadingAccountSubmit
                                        }
                                        fullName={`${personalInformation?.firstName} ${personalInformation?.lastName}`}
                                      />
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </Fragment>
                          );
                        })}
                        <MDivider />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                          <MButton
                            testId={`account-btn-create-account-${section.sectionKey}`}
                            loading={loadingAccountSubmit}
                            disabled={loadingAccountSubmit}
                            className={Styles.fullWidthCreateAccountBtn}
                            onClick={() => {
                              form.submitForm();
                            }}>
                            Create Account
                          </MButton>
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Col>

          <Col span={3} />
        </Row>
      ))}
    </MyAccountLayout>
  );
};
