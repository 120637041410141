import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';

import { downloadTradeConfirmation } from '../../actions';
import { TradeConfirmationDto } from '../../dtos/statements.dtos';
import { AccountStatementDownload } from '../../models/statements.models';
import { getAccountStatementFilename } from '../../utils/getAccountStatementFilename';

import { DownloadAccountStatementButton } from './DownloadAccountStatementButton';

export interface DownloadTradeConfirmationButtonProps {
  statement: TradeConfirmationDto;
}

export const DownloadTradeConfirmationButton = ({ statement }: DownloadTradeConfirmationButtonProps) => {
  const dispatch = useDispatch();

  const accountId: number = useSelector((state: any) => state.accountDetails?.accountHolder?.data?.accountId);

  const tradeConfirmationDownloads: AccountStatementDownload<TradeConfirmationDto>[] = useSelector(
    (state: any) => state.statements.tradeConfirmations.downloads,
  );

  const findTradeConfirmationDownloadUrl = () => {
    const tradeConfirmationDownload = tradeConfirmationDownloads.find(
      aDownload => aDownload.data.date === statement.date,
    );

    return tradeConfirmationDownload?.data?.url ?? null;
  };

  const isButtonLoading = () => {
    const tradeConfirmationDownload = tradeConfirmationDownloads.find(
      aDownload => aDownload.data.date === statement.date,
    );

    return Boolean(tradeConfirmationDownload?.isLoading);
  };

  const onClick = () => {
    const fileUrl = findTradeConfirmationDownloadUrl();

    if (fileUrl) {
      saveAs(fileUrl, getAccountStatementFilename(statement));

      return;
    }

    dispatch(downloadTradeConfirmation({ accountId, date: statement.date }));
  };

  return <DownloadAccountStatementButton onClick={onClick} loading={isButtonLoading()} />;
};
