import { Color } from '../../../styles';

export type PasswordStrengthBarCompType = {
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  showStrengthLabel?: boolean;
  showValidationRules?: boolean;
};

export const calculatePercentage = (value: number) => {
  switch (value) {
    case 0:
      return 10;
    case 1:
      return 25;
    case 2:
      return 50;
    case 3:
      return 80;
    case 4:
      return 100;
    default:
      return 0;
  }
};

export const calculateBarColor = (value: number) => {
  switch (value) {
    case 0:
      return Color.RED.RED6;
    case 1:
      return Color.RED.RED4;
    case 2:
      return Color.ORANGE.ORANGE6;
    case 3:
      return Color.GREEN.GREEN5;
    case 4:
      return Color.GREEN.GREEN6;
    default:
      return Color.RED.RED6;
  }
};

export const calculateLabel = (value: number) => {
  switch (value) {
    case 0:
      return 'Very Weak';
    case 1:
      return 'Weak';
    case 2:
      return 'Average';
    case 3:
      return 'Strong';
    case 4:
      return 'Very Strong';
    default:
      return 'Very Weak';
  }
};
