import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

export const useHasAnyRequestedInvestigationDocuments = (): boolean => {
  const applications = useSelector((state: any) => state.investigations?.investigationsList?.data?.applications);
  const investigations = useSelector((state: any) => state.investigations?.investigationsList?.data?.investigations);
  const [isUserActionNeeded, setIsUserActionNeeded] = useState<boolean>(false);

  useEffect(() => {
    const needUserActionAccountStatus =
      (applications &&
        applications?.some((application: any) =>
          application.appCategories?.some((category: any) => !Boolean(category.isResolved)),
        )) ||
      (investigations &&
        investigations?.some((investigation: any) =>
          investigation.cipCategories?.some((category: any) => !Boolean(category.isResolved)),
        ));

    setIsUserActionNeeded(needUserActionAccountStatus);
  }, [applications, investigations]);

  return isUserActionNeeded;
};
