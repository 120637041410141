import _ from 'lodash';
import moment from 'moment';

import { citizenshipOptions, maritalStatus, states } from '../../../constants/accountCompletion';
import { countries, countryCodes } from '../../../constants/countryCodes';
import Checkbox from '../../../lib/FormComponents/CheckBox';
import DatePickerComp from '../../../lib/FormComponents/DatePicker/DateOfBirthPicker';
import Dropdown from '../../../lib/FormComponents/Dropdown';
import { InputType, MaskType } from '../../../lib/FormComponents/Input/constants';
import Input from '../../../lib/FormComponents/Input/Input';

import * as Styles from './PersonalInformation.styles';
import { accountOwnerInfoValidation, mailingAddressValidation, physicalAddressValidation } from './validations';

export const accountOwnerInfoSectionFields = (personalInformation: any) => [
  {
    label: 'First Name',
    component: Input,
    key: 'firstName',
    displayValue: (
      <div className={Styles.displayValue} data-testid={'account-firstName'}>
        {!_.isEmpty(personalInformation) ? personalInformation.firstName : null}
      </div>
    ),
    readOnly: true,
    props: {
      name: 'firstName',
      placeholder: 'Placeholder',
      value: !_.isEmpty(personalInformation) ? personalInformation.firstName : null,
    },
  },
  {
    label: 'Middle Initial or Middle Name (optional)',
    component: Input,
    key: 'middleName',
    displayValue: (
      <div className={Styles.displayValue} data-testid={'account-middleName'}>
        {!_.isEmpty(personalInformation) ? personalInformation.middleName : null}
      </div>
    ),
    readOnly: Boolean(personalInformation?.dateOfBirth) || Boolean(personalInformation?.middleName),
    props: {
      name: 'middleName',
      placeholder: 'Enter',
      value: !_.isEmpty(personalInformation) ? personalInformation.middleName : null,
    },
  },
  {
    label: 'Last Name',
    component: Input,
    key: 'lastName',
    readOnly: true,
    displayValue: (
      <div className={Styles.displayValue} data-testid={'account-lastName'}>
        {!_.isEmpty(personalInformation) ? personalInformation.lastName : null}
      </div>
    ),
    props: {
      name: 'lastName',
      placeholder: 'Placeholder',
      value: !_.isEmpty(personalInformation) ? personalInformation.lastName : null,
    },
  },
  {
    label: 'Suffix (optional)',
    component: Input,
    key: 'suffix',
    displayValue: (
      <div className={Styles.displayValue} data-testid={'account-suffix'}>
        {!_.isEmpty(personalInformation) ? personalInformation.suffix : null}
      </div>
    ),
    readOnly: Boolean(personalInformation?.dateOfBirth) || Boolean(personalInformation?.suffix),
    props: {
      name: 'suffix',
      placeholder: 'Enter',
      value: !_.isEmpty(personalInformation) ? personalInformation.suffix : null,
    },
  },
  {
    label: 'Social Security Number',
    component: Input,
    readOnly: !!personalInformation?.taxId,
    key: 'taxId',
    displayValue: (
      <div className={Styles.displayValue}>
        {personalInformation?.taxId &&
          personalInformation?.taxId.substring(0, 3) +
            ' - ' +
            personalInformation?.taxId.substring(3, 5) +
            ' - ' +
            personalInformation?.taxId.substring(5, 9)}
      </div>
    ),
    props: {
      name: 'taxId',
      mask: MaskType.Ssn,
      placeholder: '### - ## - ####',
      value: personalInformation?.taxId,
    },
  },
  {
    label: 'Date of Birth',
    component: DatePickerComp,
    readOnly: !!personalInformation?.dateOfBirth,
    key: 'dateOfBirth',
    displayValue: (
      <div className={Styles.displayValue}>{moment.utc(personalInformation?.dateOfBirth).format('MM/DD/YYYY')}</div>
    ),
    props: {
      name: 'dateOfBirth',
      placeholder: 'MM/DD/YYYY',
      value: personalInformation?.dateOfBirth,
    },
  },

  {
    label: 'Marital status',
    component: Dropdown,
    key: 'maritalStatus',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(maritalStatus, {
            value: personalInformation?.maritalStatus,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'maritalStatus',
      placeholder: 'Select',
      options: maritalStatus,
      value: personalInformation?.maritalStatus,
    },
  },

  {
    label: 'Number of Dependents',
    component: Input,
    key: 'numberOfDependents',
    displayValue: <div className={Styles.displayValue}>{personalInformation?.numberOfDependents}</div>,
    props: {
      name: 'numberOfDependents',
      placeholder: 'Enter',
      type: InputType.Number,
      value: personalInformation?.numberOfDependents,
    },
  },
];

export const physicalAddressSectionFields = (personalInformation: any) => [
  {
    label: 'Address Line 1',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.address1)
          ? personalInformation?.physicalAddress.address1
          : null}
      </div>
    ),
    component: Input,
    key: 'address1',
    props: {
      name: 'address1',
      placeholder: 'Enter',
      value:
        !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.address1)
          ? personalInformation?.physicalAddress?.address1
          : null,
    },
  },
  {
    label: 'Address Line  2 (Opt.)',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.address2)
          ? personalInformation?.physicalAddress?.address2
          : '-'}
      </div>
    ),
    component: Input,
    key: 'address2',
    props: {
      name: 'address2',
      placeholder: 'Enter',
      value:
        !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.address2)
          ? personalInformation?.physicalAddress?.address2
          : null,
    },
  },
  {
    label: 'Country',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(countries, {
            value:
              !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.country)
                ? personalInformation?.physicalAddress?.country
                : null,
          })?.text
        }
      </div>
    ),
    component: Dropdown,
    key: 'country',
    props: {
      name: 'country',
      placeholder: 'Select',
      options: countries,
      value:
        !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.country)
          ? personalInformation?.physicalAddress?.country
          : null,
    },
  },
  {
    label: 'City',
    component: Input,
    key: 'city',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.city)
          ? personalInformation?.physicalAddress?.city
          : null}
      </div>
    ),
    props: {
      name: 'city',
      placeholder: 'Enter',
      value:
        !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.city)
          ? personalInformation?.physicalAddress?.city
          : null,
    },
  },
  {
    label: 'State',
    component: Dropdown,
    key: 'state',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(states, {
            value:
              !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.state)
                ? personalInformation?.physicalAddress?.state
                : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'state',
      placeholder: 'Select',
      showSearch: true,
      options: states,
      value:
        !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.state)
          ? personalInformation?.physicalAddress?.state
          : null,
    },
  },

  {
    label: 'Postal Code',
    component: Input,
    key: 'postalCode',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.postalCode)
          ? personalInformation?.physicalAddress?.postalCode
          : null}
      </div>
    ),
    props: {
      name: 'postalCode',
      placeholder: 'Enter',
      value:
        !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation?.physicalAddress?.postalCode)
          ? personalInformation?.physicalAddress?.postalCode
          : null,
    },
  },
  {
    label: 'Citizenship',
    component: Dropdown,
    key: 'citizenshipCountry',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(citizenshipOptions, {
            value:
              !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation.citizenshipCountry)
                ? personalInformation.citizenshipCountry
                : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'citizenshipCountry',
      placeholder: 'Select',
      options: citizenshipOptions,
      value:
        !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation.citizenshipCountry)
          ? personalInformation.citizenshipCountry
          : null,
    },
  },
  {
    label: 'Birth Country',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(countryCodes, {
            code:
              !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation.birthCountry)
                ? personalInformation.birthCountry
                : null,
          })?.name
        }
      </div>
    ),

    component: Dropdown,
    key: 'birthCountry',
    props: {
      name: 'birthCountry',
      placeholder: 'Select',
      options: countryCodes.map(aCountry => ({ value: aCountry.code, text: aCountry.name })),
      showSearch: true,
      value:
        !_.isEmpty(personalInformation) && !_.isEmpty(personalInformation.birthCountry)
          ? personalInformation.birthCountry
          : null,
    },
  },
];

export const mailingAddressSectionFields = (mailingAddress: any) => [
  {
    label: '',
    displayValue: (
      <div style={{ marginRight: 'auto' }}>
        {!_.isEmpty(mailingAddress) && mailingAddress?.sameAsPhysical === true ? undefined : undefined}
      </div>
    ),
    component: Checkbox,
    showInRow: true,
    key: 'sameAsPhysical',
    props: {
      name: 'sameAsPhysical',
      label: 'Mailing address is the same as my physical address',
      value: !_.isEmpty(mailingAddress) && mailingAddress?.sameAsPhysical ? mailingAddress?.sameAsPhysical : false,
    },
  },
  {
    label: 'Address Line 1',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.address1 : null}
      </div>
    ),
    component: Input,
    key: 'address1',
    props: {
      name: 'address1',
      placeholder: 'Enter',
      value: !_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.address1 : null,
    },
  },
  {
    label: 'Address Line 2',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(mailingAddress) && !_.isEmpty(mailingAddress?.address2) ? mailingAddress?.address2 : '-'}
      </div>
    ),
    component: Input,
    key: 'address2',
    props: {
      name: 'address2',
      placeholder: 'Enter',
      value: !_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.address2 : null,
    },
  },

  {
    label: 'Country',
    key: 'country',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(countries, {
            value: !_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.country : null,
          })?.text
        }
      </div>
    ),
    component: Dropdown,
    props: {
      name: 'country',
      placeholder: 'Select',
      options: countries,
      value: !_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.country : null,
    },
  },
  {
    label: 'City',
    component: Input,
    key: 'city',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.city : null}
      </div>
    ),
    props: {
      name: 'city',
      placeholder: 'Enter',
      value: !_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.city : null,
    },
  },
  {
    label: 'State',
    component: Dropdown,
    key: 'state',
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(states, {
            value: !_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.state : null,
          })?.text
        }
      </div>
    ),
    props: {
      name: 'state',
      placeholder: 'Select',
      showSearch: true,
      options: states,
      value: !_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.state : null,
    },
  },

  {
    label: 'Postal Code',
    component: Input,
    key: 'postalCode',
    displayValue: (
      <div className={Styles.displayValue}>
        {!_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.postalCode : null}
      </div>
    ),
    props: {
      name: 'postalCode',
      placeholder: 'Enter',
      type: InputType.Number,
      value: !_.isEmpty(mailingAddress) && mailingAddress ? mailingAddress?.postalCode : null,
    },
  },
];

export const getPersonalInformationSections = (personalInformation: any) => {
  return {
    menuName: 'Personal',
    path: 'personal-information',
    sections: [
      {
        sectionLabel: 'Account owner information',
        sectionKey: 'personal',
        sectionFields: [...accountOwnerInfoSectionFields(personalInformation)],
        isCompleted: accountOwnerInfoValidation(!!personalInformation?.taxId)?.isValidSync(personalInformation),
        validationSchema: accountOwnerInfoValidation(!!personalInformation?.taxId),
      },
      {
        sectionLabel: 'Physical Address',
        sectionKey: 'physical',
        sectionFields: [...physicalAddressSectionFields(personalInformation)],
        isCompleted:
          personalInformation?.physicalAddress &&
          physicalAddressValidation?.isValidSync({
            ...personalInformation.physicalAddress,
            citizenshipCountry: personalInformation.citizenshipCountry,
            birthCountry: personalInformation.birthCountry,
          }),
        validationSchema: physicalAddressValidation,
      },
      {
        sectionLabel: 'Mailing Address',
        sectionKey: 'mailing',
        sectionFields: [...mailingAddressSectionFields(personalInformation?.mailingAddress)],
        isCompleted: mailingAddressValidation?.isValidSync(personalInformation?.mailingAddress),
        validationSchema: mailingAddressValidation,
      },
    ],
  };
};
