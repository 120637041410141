import { DropdownItemType } from '../lib/FormComponents/Dropdown/constants';
import { Images } from '../styles';

export const countries: Array<DropdownItemType> = [{ value: 'USA', text: 'United States' }];

export const countryCodes = [
  {
    name: 'United States',
    dialCode: '+1',
    code: 'USA',
    isCodeAvailable: true,
    flag: Images.Countries.US,
    phoneMask: '(999) 999-9999',
  },
  // A
  {
    name: 'Afghanistan',
    dialCode: '+93',
    code: 'AFG',
    isCodeAvailable: true,
    flag: Images.Countries.AF,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Albania',
    dialCode: '+355',
    code: 'ALB',
    isCodeAvailable: true,
    flag: Images.Countries.AL,
    phoneMask: '(99) 99-99-999',
  },
  {
    name: 'Algeria',
    dialCode: '+213',
    code: 'DZA',
    isCodeAvailable: true,
    flag: Images.Countries.DZ,
    phoneMask: '(9) 99-99-99-99',
  },
  {
    name: 'Andorra',
    dialCode: '+376',
    code: 'AND',
    isCodeAvailable: true,
    flag: Images.Countries.AD,
    phoneMask: '999-999',
  },
  {
    name: 'Angola',
    dialCode: '+244',
    code: 'AGO',
    isCodeAvailable: true,
    flag: Images.Countries.AO,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Anguilla',
    dialCode: '+1-264',
    code: 'AIA',
    isCodeAvailable: true,
    flag: Images.Countries.AI,
    phoneMask: '999-9999',
  },
  {
    name: 'Antigua and Barbuda',
    dialCode: '+1-268',
    code: 'ATG',
    isCodeAvailable: true,
    flag: Images.Countries.AG,
    phoneMask: '999-9999',
  },
  {
    name: 'Argentina',
    dialCode: '+54',
    code: 'ARG',
    isCodeAvailable: true,
    flag: Images.Countries.AR,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Armenia',
    dialCode: '+374',
    code: 'ARM',
    isCodeAvailable: true,
    flag: Images.Countries.AM,
    phoneMask: '99-999-999',
  },
  {
    name: 'Aruba',
    dialCode: '+297',
    code: 'ABW',
    isCodeAvailable: true,
    flag: Images.Countries.AW,
    phoneMask: '999-9999',
  },
  {
    name: 'Australia',
    dialCode: '+61',
    code: 'AUS',
    isCodeAvailable: true,
    flag: Images.Countries.AU,
    phoneMask: '9-9999-9999',
  },
  {
    name: 'Austria',
    dialCode: '+43',
    code: 'AUT',
    isCodeAvailable: true,
    flag: Images.Countries.AT,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Azerbaijan',
    dialCode: '+994',
    code: 'AZE',
    isCodeAvailable: true,
    flag: Images.Countries.AZ,
    phoneMask: '99-999-99-99',
  },
  // B
  {
    name: 'Bahamas',
    dialCode: '+1-242',
    code: 'BHS',
    isCodeAvailable: true,
    flag: Images.Countries.BS,
    phoneMask: '999-9999',
  },
  {
    name: 'Bahrain',
    dialCode: '+973',
    code: 'BHR',
    isCodeAvailable: true,
    flag: Images.Countries.BH,
    phoneMask: '9999-9999',
  },
  {
    name: 'Bangladesh',
    dialCode: '+880',
    code: 'BGD',
    isCodeAvailable: true,
    flag: Images.Countries.BD,
    phoneMask: '99-999-999',
  },
  {
    name: 'Barbados',
    dialCode: '+1-246',
    code: 'BRB',
    isCodeAvailable: true,
    flag: Images.Countries.BB,
    phoneMask: '999-9999',
  },
  {
    name: 'Belarus',
    dialCode: '+375',
    code: 'BLR',
    isCodeAvailable: true,
    flag: Images.Countries.BY,
    phoneMask: '(99)999-99-99',
  },
  {
    name: 'Belgium',
    dialCode: '+32',
    code: 'BEL',
    isCodeAvailable: true,
    flag: Images.Countries.BE,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Belize',
    dialCode: '+501',
    code: 'BLZ',
    isCodeAvailable: true,
    flag: Images.Countries.BZ,
    phoneMask: '999-9999',
  },
  {
    name: 'Benin',
    dialCode: '+229',
    code: 'BEN',
    isCodeAvailable: true,
    flag: Images.Countries.BJ,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Bermuda',
    dialCode: '+1-441',
    code: 'BMU',
    isCodeAvailable: true,
    flag: Images.Countries.BM,
    phoneMask: '999-9999',
  },
  {
    name: 'Bhutan',
    dialCode: '+975',
    code: 'BTN',
    isCodeAvailable: true,
    flag: Images.Countries.BT,
    phoneMask: '9-999-999',
  },
  {
    name: 'Bolivia',
    dialCode: '+591',
    code: 'BOL',
    isCodeAvailable: true,
    flag: Images.Countries.BO,
    phoneMask: '9-999-9999',
  },
  {
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    code: 'BIH',
    isCodeAvailable: true,
    flag: Images.Countries.BA,
    phoneMask: '99-99999',
  },
  {
    name: 'Botswana',
    dialCode: '+267',
    code: 'BWA',
    isCodeAvailable: true,
    flag: Images.Countries.BW,
    phoneMask: '99-999-999',
  },
  {
    name: 'Brazil',
    dialCode: '+55',
    code: 'BRA',
    isCodeAvailable: true,
    flag: Images.Countries.BR,
    phoneMask: '(99) 9999-9999',
  },
  {
    name: 'Bulgaria',
    dialCode: '+359',
    code: 'BGR',
    isCodeAvailable: true,
    flag: Images.Countries.BG,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Burkina Faso',
    dialCode: '+226',
    code: 'BFA',
    isCodeAvailable: true,
    flag: Images.Countries.BF,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Burundi',
    dialCode: '+257',
    code: 'BDI',
    isCodeAvailable: true,
    flag: Images.Countries.BI,
    phoneMask: '99-99-9999',
  },
  // C
  {
    name: 'Cambodia',
    dialCode: '+855',
    code: 'KHM',
    isCodeAvailable: true,
    flag: Images.Countries.KH,
    phoneMask: '99-999-999',
  },
  {
    name: 'Cameroon',
    dialCode: '+237',
    code: 'CMR',
    isCodeAvailable: true,
    flag: Images.Countries.CM,
    phoneMask: '9999-9999',
  },
  {
    name: 'Canada',
    dialCode: '+1',
    code: 'CAN',
    isCodeAvailable: true,
    flag: Images.Countries.CA,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Cape Verde',
    dialCode: '+238',
    code: 'CPV',
    isCodeAvailable: true,
    flag: Images.Countries.CV,
    phoneMask: '(999) 99-99',
  },
  {
    name: 'Central African Republic',
    dialCode: '+236',
    code: 'CAF',
    isCodeAvailable: true,
    flag: Images.Countries.CF,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Chad',
    dialCode: '+235',
    code: 'TCD',
    isCodeAvailable: true,
    flag: Images.Countries.TD,
    phoneMask: '99-99-99-99',
  },
  {
    name: 'Chile',
    dialCode: '+56',
    code: 'CHL',
    isCodeAvailable: true,
    flag: Images.Countries.CL,
    phoneMask: '9-9999-9999',
  },
  {
    name: 'China',
    dialCode: '+86',
    code: 'CHN',
    isCodeAvailable: true,
    flag: Images.Countries.CN,
    phoneMask: '(999) 9999-9999',
  },
  {
    name: 'Colombia',
    dialCode: '+57',
    code: 'COL',
    isCodeAvailable: true,
    flag: Images.Countries.CO,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Comoros',
    dialCode: '+269',
    code: 'COM',
    isCodeAvailable: true,
    flag: Images.Countries.KM,
    phoneMask: '99-99999',
  },
  {
    name: 'Cook Islands',
    dialCode: '+682',
    code: 'COG',
    isCodeAvailable: true,
    flag: Images.Countries.CK,
    phoneMask: '99-999',
  },
  {
    name: 'Costa Rica',
    dialCode: '+506',
    code: 'CRI',
    isCodeAvailable: true,
    flag: Images.Countries.CR,
    phoneMask: '9999-9999',
  },
  {
    name: 'Croatia',
    dialCode: '+385',
    code: 'HRV',
    isCodeAvailable: true,
    flag: Images.Countries.HR,
    phoneMask: '99-999-999',
  },
  {
    name: 'Cyprus',
    dialCode: '+357',
    code: 'CYP',
    isCodeAvailable: true,
    flag: Images.Countries.CY,
    phoneMask: '99-999-999',
  },
  {
    name: 'Czech Republic',
    dialCode: '+420',
    code: 'CZE',
    isCodeAvailable: true,
    flag: Images.Countries.CZ,
    phoneMask: '(999) 999-999',
  },
  // D
  {
    name: 'Denmark',
    dialCode: '+45',
    code: 'DNK',
    isCodeAvailable: true,
    flag: Images.Countries.DK,
    phoneMask: '99-99-99-99',
  },
  {
    name: 'Djibouti',
    dialCode: '+253',
    code: 'DJI',
    isCodeAvailable: true,
    flag: Images.Countries.DJ,
    phoneMask: '99-99-99-99',
  },
  {
    name: 'Dominica',
    dialCode: '+1-767',
    code: 'DMA',
    isCodeAvailable: true,
    flag: Images.Countries.DM,
    phoneNumber: '999-9999',
  },
  {
    name: 'Dominican Republic',
    dialCode: '+1-809',
    code: 'DOM',
    isCodeAvailable: true,
    flag: Images.Countries.DO,
    phoneNumber: '999-9999',
  },
  // E
  {
    name: 'Ecuador',
    dialCode: '+593',
    code: 'ECU',
    isCodeAvailable: true,
    flag: Images.Countries.EC,
    phoneNumber: '99-999-9999',
  },
  {
    name: 'Egypt',
    dialCode: '+20',
    code: 'EGY',
    isCodeAvailable: true,
    flag: Images.Countries.EG,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'El Salvador',
    dialCode: '+503',
    code: 'SLV',
    isCodeAvailable: true,
    flag: Images.Countries.SV,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Equatorial Guinea',
    dialCode: '+240',
    code: 'GNQ',
    isCodeAvailable: true,
    flag: Images.Countries.GQ,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Estonia',
    dialCode: '+372',
    code: 'EST',
    isCodeAvailable: true,
    flag: Images.Countries.EE,
    phoneNumber: '9999-9999',
  },
  {
    name: 'Ethiopia',
    dialCode: '+251',
    code: 'ETH',
    isCodeAvailable: true,
    flag: Images.Countries.ET,
    phoneMask: '99-999-9999',
  },
  // F
  {
    name: 'Faroe Islands',
    dialCode: '+298',
    code: 'FRO',
    isCodeAvailable: true,
    flag: Images.Countries.FO,
    phoneMask: '999-999',
  },
  {
    name: 'Fiji',
    dialCode: '+679',
    code: 'FJI',
    isCodeAvailable: true,
    flag: Images.Countries.FJ,
    phoneNumber: '99-99999',
  },
  {
    name: 'Finland',
    dialCode: '+358',
    code: 'FIN',
    isCodeAvailable: true,
    flag: Images.Countries.FI,
    phoneMask: '(999) 999-99-99',
  },
  {
    name: 'France',
    dialCode: '+33',
    code: 'FRA',
    isCodeAvailable: true,
    flag: Images.Countries.FR,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'French Guiana',
    dialCode: '+594',
    code: 'GUF',
    isCodeAvailable: true,
    flag: Images.Countries.GF,
    phoneMask: '99999-9999',
  },
  // G
  {
    name: 'Gabon',
    dialCode: '+241',
    code: 'GAB',
    isCodeAvailable: true,
    flag: Images.Countries.GA,
    phoneMask: '9-99-99-99',
  },
  {
    name: 'Gambia',
    dialCode: '+220',
    code: 'GMB',
    isCodeAvailable: true,
    flag: Images.Countries.GM,
    phoneMask: '(999) 99-99',
  },
  {
    name: 'Georgia',
    dialCode: '+995',
    code: 'GEO',
    isCodeAvailable: true,
    flag: Images.Countries.GE,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Germany',
    dialCode: '+49',
    code: 'DEU',
    isCodeAvailable: true,
    flag: Images.Countries.DE,
    phoneMask: '(9999) 999-9999',
  },
  {
    name: 'Ghana',
    dialCode: '+233',
    code: 'GHA',
    isCodeAvailable: true,
    flag: Images.Countries.GH,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Greece',
    dialCode: '+30',
    code: 'GRC',
    isCodeAvailable: true,
    flag: Images.Countries.GR,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Greenland',
    dialCode: '+299',
    code: 'GRL',
    isCodeAvailable: true,
    flag: Images.Countries.GL,
    phoneMask: '99-99-99',
  },
  {
    name: 'Grenada',
    dialCode: '+1-473',
    code: 'GRD',
    isCodeAvailable: true,
    flag: Images.Countries.GD,
    phoneMask: '999-9999',
  },
  {
    name: 'Guadeloupe',
    dialCode: '+590',
    code: 'GLP',
    isCodeAvailable: true,
    flag: Images.Countries.GP,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Guam',
    dialCode: '+1-671',
    code: 'GUM',
    isCodeAvailable: true,
    flag: Images.Countries.GU,
    phoneMask: '999-9999',
  },
  {
    name: 'Guatemala',
    dialCode: '+502',
    code: 'GTM',
    isCodeAvailable: true,
    flag: Images.Countries.GT,
    phoneMask: '9-999-9999',
  },
  {
    name: 'Guinea-Bissau',
    dialCode: '+245',
    code: 'GNB',
    isCodeAvailable: true,
    flag: Images.Countries.GW,
    phoneMask: '9-999999',
  },
  // H
  {
    name: 'Haiti',
    dialCode: '+509',
    code: 'HTI',
    isCodeAvailable: true,
    flag: Images.Countries.HT,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Honduras',
    dialCode: '+504',
    code: 'HND',
    isCodeAvailable: true,
    flag: Images.Countries.HN,
    phoneMask: '9999-9999',
  },
  {
    name: 'Hong Kong',
    dialCode: '+852',
    code: 'HKG',
    isCodeAvailable: true,
    flag: Images.Countries.HK,
    phoneMask: '9999-9999',
  },
  // I
  {
    name: 'Iceland',
    dialCode: '+354',
    code: 'ISL',
    isCodeAvailable: true,
    flag: Images.Countries.IS,
    phoneMask: '999-9999',
  },
  {
    name: 'India',
    dialCode: '+91',
    code: 'IND',
    isCodeAvailable: true,
    flag: Images.Countries.IN,
    phoneMask: '(9999) 999-999',
  },
  {
    name: 'Indonesia',
    dialCode: '+62',
    code: 'IDN',
    isCodeAvailable: true,
    flag: Images.Countries.ID,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Iraq',
    dialCode: '+964',
    code: 'IRQ',
    isCodeAvailable: true,
    flag: Images.Countries.IQ,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Ireland',
    dialCode: '+353',
    code: 'IRL',
    isCodeAvailable: true,
    flag: Images.Countries.IE,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Israel',
    dialCode: '+972',
    code: 'ISR',
    isCodeAvailable: true,
    flag: Images.Countries.IL,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Italy',
    dialCode: '+39',
    code: 'ITA',
    isCodeAvailable: true,
    flag: Images.Countries.IT,
    phoneMask: '(999) 9999-999',
  },
  {
    name: 'Ivory Coast',
    dialCode: '+225',
    code: 'CIV',
    isCodeAvailable: true,
    flag: Images.Countries.CI,
    phoneMask: '99-999-999',
  },
  // J
  {
    name: 'Jamaica',
    dialCode: '+1-876',
    code: 'JAM',
    isCodeAvailable: true,
    flag: Images.Countries.JM,
    phoneMask: '999-9999',
  },
  {
    name: 'Japan',
    dialCode: '+81',
    code: 'JPN',
    isCodeAvailable: true,
    flag: Images.Countries.JP,
    phoneMask: '99-9999-9999',
  },
  {
    name: 'Jordan',
    dialCode: '+962',
    code: 'JOR',
    isCodeAvailable: true,
    flag: Images.Countries.JO,
    phoneMask: '9-9999-9999',
  },
  // K
  {
    name: 'Kazakhstan',
    dialCode: '+7',
    code: 'KAZ',
    isCodeAvailable: true,
    flag: Images.Countries.KZ,
    phoneMask: '(999) 999-99-99',
  },
  {
    name: 'Kenya',
    dialCode: '+254',
    code: 'KEN',
    isCodeAvailable: true,
    flag: Images.Countries.KE,
    phoneMask: '999-999999',
  },
  {
    name: 'Kiribati',
    dialCode: '+686',
    code: 'KIR',
    isCodeAvailable: true,
    flag: Images.Countries.KI,
    phoneMask: '99-999',
  },
  {
    name: 'Kosovo',
    dialCode: '+383',
    code: 'XXK',
    isCodeAvailable: true,
    flag: Images.Countries.XK,
    phoneMask: '99-999-999',
  },
  {
    name: 'Kuwait',
    dialCode: '+965',
    code: 'KWT',
    isCodeAvailable: true,
    flag: Images.Countries.KW,
    phoneMask: '9999-9999',
  },
  {
    name: 'Kyrgyzstan',
    dialCode: '+996',
    code: 'KGZ',
    isCodeAvailable: true,
    flag: Images.Countries.KG,
    phoneMask: '(999) 999-999',
  },
  // L
  {
    name: 'Latvia',
    dialCode: '+371',
    code: 'LVA',
    isCodeAvailable: true,
    flag: Images.Countries.LV,
    phoneMask: '99-999-999',
  },
  {
    name: 'Lebanon',
    dialCode: '+961',
    code: 'LBN',
    isCodeAvailable: true,
    flag: Images.Countries.LB,
    phoneMask: '99-999-999',
  },
  {
    name: 'Lesotho',
    dialCode: '+266',
    code: 'LSO',
    isCodeAvailable: true,
    flag: Images.Countries.LS,
    phoneMask: '9-999-9999',
  },
  {
    name: 'Liberia',
    dialCode: '+231',
    code: 'LBR',
    isCodeAvailable: true,
    flag: Images.Countries.LR,
    phoneMask: '99-999-999',
  },
  {
    name: 'Libya',
    dialCode: '+218',
    code: 'LBY',
    isCodeAvailable: true,
    flag: Images.Countries.LY,
    phoneMask: '99-999-999',
  },
  {
    name: 'Liechtenstein',
    dialCode: '+423',
    code: 'LIE',
    isCodeAvailable: true,
    flag: Images.Countries.LI,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Lithuania',
    dialCode: '+370',
    code: 'LTU',
    isCodeAvailable: true,
    flag: Images.Countries.LT,
    phoneMask: '(999) 99-999',
  },
  {
    name: 'Luxembourg',
    dialCode: '+352',
    code: 'LUX',
    isCodeAvailable: true,
    flag: Images.Countries.LU,
    phoneMask: '(999) 999-999',
  },
  // M
  {
    name: 'Madagascar',
    dialCode: '+261',
    code: 'MDG',
    isCodeAvailable: true,
    flag: Images.Countries.MG,
    phoneMask: '99-99-99999',
  },
  {
    name: 'Malawi',
    dialCode: '+265',
    code: 'MWI',
    isCodeAvailable: true,
    flag: Images.Countries.MW,
    phoneMask: '9-9999-9999',
  },
  {
    name: 'Malaysia',
    dialCode: '+60',
    code: 'MYS',
    isCodeAvailable: true,
    flag: Images.Countries.MY,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Maldives',
    dialCode: '+960',
    code: 'MDV',
    isCodeAvailable: true,
    flag: Images.Countries.MV,
    phoneMask: '999-9999',
  },
  {
    name: 'Mali',
    dialCode: '+223',
    code: 'MLI',
    isCodeAvailable: true,
    flag: Images.Countries.ML,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Malta',
    dialCode: '+356',
    code: 'MLT',
    isCodeAvailable: true,
    flag: Images.Countries.MT,
    phoneMask: '9999-9999',
  },
  {
    name: 'Martinique',
    dialCode: '+596',
    code: 'MTQ',
    isCodeAvailable: true,
    flag: Images.Countries.MQ,
    phoneMask: '(999) 99-99-99',
  },
  {
    name: 'Mauritania',
    dialCode: '+222',
    code: 'MRT',
    isCodeAvailable: true,
    flag: Images.Countries.MR,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Mauritius',
    dialCode: '+230',
    code: 'MUS',
    isCodeAvailable: true,
    flag: Images.Countries.MU,
    phoneMask: '999-9999',
  },
  {
    name: 'Mexico',
    dialCode: '+52',
    code: 'MEX',
    isCodeAvailable: true,
    flag: Images.Countries.MX,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Moldova',
    dialCode: '+373',
    code: 'MDA',
    isCodeAvailable: true,
    flag: Images.Countries.MD,
    phoneMask: '9999-9999',
  },
  {
    name: 'Monaco',
    dialCode: '+377',
    code: 'MCO',
    isCodeAvailable: true,
    flag: Images.Countries.MC,
    phoneMask: '99-999-999',
  },
  {
    name: 'Mongolia',
    dialCode: '+976',
    code: 'MNG',
    isCodeAvailable: true,
    flag: Images.Countries.MN,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Montenegro',
    dialCode: '+382',
    code: 'MNE',
    isCodeAvailable: true,
    flag: Images.Countries.ME,
    phoneMask: '99-999-999',
  },
  {
    name: 'Montserrat',
    dialCode: '+1-664',
    code: 'MSR',
    isCodeAvailable: true,
    flag: Images.Countries.MS,
    phoneMask: '999-9999',
  },
  {
    name: 'Morocco',
    dialCode: '+212',
    code: 'MAR',
    isCodeAvailable: true,
    flag: Images.Countries.MA,
    phoneMask: '99-9999-999',
  },
  {
    name: 'Mozambique',
    dialCode: '+258',
    code: 'MOZ',
    isCodeAvailable: true,
    flag: Images.Countries.MZ,
    phoneMask: '99-999-999',
  },
  {
    name: 'Myanmar',
    dialCode: '+95',
    code: 'MMR',
    isCodeAvailable: true,
    flag: Images.Countries.MM,
    phoneMask: '99-999-999',
  },
  // N
  {
    name: 'Namibia',
    dialCode: '+264',
    code: 'NAM',
    isCodeAvailable: true,
    flag: Images.Countries.NA,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Nepal',
    dialCode: '+977',
    code: 'NPL',
    isCodeAvailable: true,
    flag: Images.Countries.NP,
    phoneMask: '99-999-999',
  },
  {
    name: 'Netherlands',
    dialCode: '+31',
    code: 'NLD',
    isCodeAvailable: true,
    flag: Images.Countries.NL,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Netherlands Antilles',
    dialCode: '+599',
    code: 'ANT',
    isCodeAvailable: true,
    flag: Images.Countries.AN,
    phoneMask: '999-9999',
  },
  {
    name: 'New Caledonia',
    dialCode: '+687',
    code: 'NCL',
    isCodeAvailable: true,
    flag: Images.Countries.NC,
    phoneMask: '99-9999',
  },
  {
    name: 'New Zealand',
    dialCode: '+64',
    code: 'NZL',
    isCodeAvailable: true,
    flag: Images.Countries.NZ,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Nicaragua',
    dialCode: '+505',
    code: 'NIC',
    isCodeAvailable: true,
    flag: Images.Countries.NI,
    phoneMask: '9999-9999',
  },
  {
    name: 'Niger',
    dialCode: '+227',
    code: 'NER',
    isCodeAvailable: true,
    flag: Images.Countries.NE,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Nigeria',
    dialCode: '+234',
    code: 'NGA',
    isCodeAvailable: true,
    flag: Images.Countries.NG,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'North Macedonia',
    dialCode: '+389',
    code: 'MKD',
    isCodeAvailable: true,
    flag: Images.Countries.MK,
    phoneMask: '99-999-999',
  },
  {
    name: 'Norway',
    dialCode: '+47',
    code: 'NOR',
    isCodeAvailable: true,
    flag: Images.Countries.NO,
    phoneMask: '(999) 99-999',
  },
  // O
  {
    name: 'Oman',
    dialCode: '+968',
    code: 'OMN',
    isCodeAvailable: true,
    flag: Images.Countries.OM,
    phoneMask: '99-999-999',
  },
  // P
  {
    name: 'Pakistan',
    dialCode: '+92',
    code: 'PAK',
    isCodeAvailable: true,
    flag: Images.Countries.PK,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Palau',
    dialCode: '+680',
    code: 'PLW',
    isCodeAvailable: true,
    flag: Images.Countries.PW,
    phoneMask: '999-9999',
  },
  {
    name: 'Palestine',
    dialCode: '+970',
    code: 'PSE',
    isCodeAvailable: true,
    flag: Images.Countries.PS,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Panama',
    dialCode: '+507',
    code: 'PAN',
    isCodeAvailable: true,
    flag: Images.Countries.PA,
    phoneMask: '999-9999',
  },
  {
    name: 'Papua New Guinea',
    dialCode: '+675',
    code: 'PNG',
    isCodeAvailable: true,
    flag: Images.Countries.PG,
    phoneMask: '(999) 99-999',
  },
  {
    name: 'Paraguay',
    dialCode: '+595',
    code: 'PRY',
    isCodeAvailable: true,
    flag: Images.Countries.PY,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Peru',
    dialCode: '+51',
    code: 'PER',
    isCodeAvailable: true,
    flag: Images.Countries.PE,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Philippines',
    dialCode: '+63',
    code: 'PHL',
    isCodeAvailable: true,
    flag: Images.Countries.PH,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Poland',
    dialCode: '+48',
    code: 'POL',
    isCodeAvailable: true,
    flag: Images.Countries.PL,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Portugal',
    dialCode: '+351',
    code: 'PRT',
    isCodeAvailable: true,
    flag: Images.Countries.PT,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Puerto Rico',
    dialCode: '+1-939',
    code: 'PRI',
    isCodeAvailable: true,
    flag: Images.Countries.PR,
    phoneMask: '',
  },
  // Q
  {
    name: 'Qatar',
    dialCode: '+974',
    code: 'QAT',
    isCodeAvailable: true,
    flag: Images.Countries.QA,
    phoneMask: '9999-9999',
  },
  // R
  {
    name: 'Republic of the Congo',
    dialCode: '+242',
    code: 'COG',
    isCodeAvailable: true,
    flag: Images.Countries.CG,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Reunion',
    dialCode: '+262',
    code: 'REU',
    isCodeAvailable: true,
    flag: Images.Countries.RE,
    phoneMask: '99999-9999',
  },
  {
    name: 'Romania',
    dialCode: '+40',
    code: 'ROU',
    isCodeAvailable: true,
    flag: Images.Countries.RO,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Russia',
    dialCode: '+7',
    code: 'RUS',
    isCodeAvailable: true,
    flag: Images.Countries.RU,
    phoneMask: '(999) 999-99-99',
  },
  {
    name: 'Rwanda',
    dialCode: '+250',
    code: 'RWA',
    isCodeAvailable: true,
    flag: Images.Countries.RW,
    phoneMask: '(999) 999-999',
  },
  // S
  {
    name: 'Saint Kitts and Nevis',
    dialCode: '+1-869',
    code: 'KNA',
    isCodeAvailable: true,
    flag: Images.Countries.KN,
    phoneMask: '999-9999',
  },
  {
    name: 'Saint Lucia',
    dialCode: '+1-758',
    code: 'LCA',
    isCodeAvailable: true,
    flag: Images.Countries.LC,
    phoneMask: '999-9999',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dialCode: '+1-784',
    code: 'VCT',
    isCodeAvailable: true,
    flag: Images.Countries.VC,
    phoneMask: '999-9999',
  },
  {
    name: 'Samoa',
    dialCode: '+685',
    code: 'WSM',
    isCodeAvailable: true,
    flag: Images.Countries.WS,
    phoneMask: '99-9999',
  },
  {
    name: 'Sao Tome and Principe',
    dialCode: '+239',
    code: 'STP',
    isCodeAvailable: true,
    flag: Images.Countries.ST,
    phoneMask: '99-99999',
  },
  {
    name: 'Saudi Arabia',
    dialCode: '+966',
    code: 'SAU',
    isCodeAvailable: true,
    flag: Images.Countries.SA,
    phoneMask: '9-999-9999',
  },
  {
    name: 'Senegal',
    dialCode: '+221',
    code: 'SEN',
    isCodeAvailable: true,
    flag: Images.Countries.SN,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Serbia',
    dialCode: '+381',
    code: 'SRB',
    isCodeAvailable: true,
    flag: Images.Countries.RS,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Seychelles',
    dialCode: '+248',
    code: 'SYC',
    isCodeAvailable: true,
    flag: Images.Countries.SC,
    phoneMask: '9-999-999',
  },
  {
    name: 'Sierra Leone',
    dialCode: '+232',
    code: 'SLE',
    isCodeAvailable: true,
    flag: Images.Countries.SL,
    phoneMask: '99-999999',
  },
  {
    name: 'Singapore',
    dialCode: '+65',
    code: 'SGP',
    isCodeAvailable: true,
    flag: Images.Countries.SG,
    phoneMask: '9999-999',
  },
  {
    name: 'Slovakia',
    dialCode: '+421',
    code: 'SVK',
    isCodeAvailable: true,
    flag: Images.Countries.SK,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Slovenia',
    dialCode: '+386',
    code: 'SVN',
    isCodeAvailable: true,
    flag: Images.Countries.SI,
    phoneMask: '99-999-999',
  },
  {
    name: 'Solomon Islands',
    dialCode: '+677',
    code: 'SLB',
    isCodeAvailable: true,
    flag: Images.Countries.SB,
    phoneMask: '999-9999',
  },
  {
    name: 'Somalia',
    dialCode: '+252',
    code: 'SOM',
    isCodeAvailable: true,
    flag: Images.Countries.SO,
    phoneMask: '99-999-999',
  },
  {
    name: 'South Africa',
    dialCode: '+27',
    code: 'ZAF',
    isCodeAvailable: true,
    flag: Images.Countries.ZA,
    phoneMask: '99-999-9999',
  },
  {
    name: 'South Korea',
    dialCode: '+82',
    code: 'KOR',
    isCodeAvailable: true,
    flag: Images.Countries.KR,
    phoneMask: '99-999-9999',
  },
  {
    name: 'South Sudan',
    dialCode: '+211',
    code: 'SDN',
    isCodeAvailable: true,
    flag: Images.Countries.SS,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Spain',
    dialCode: '+34',
    code: 'ESP',
    isCodeAvailable: true,
    flag: Images.Countries.ES,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Sri Lanka',
    dialCode: '+94',
    code: 'LKA',
    isCodeAvailable: true,
    flag: Images.Countries.LK,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Suriname',
    dialCode: '+597',
    code: 'SUR',
    isCodeAvailable: true,
    flag: Images.Countries.SR,
    phoneMask: '999-9999',
  },
  {
    name: 'Swaziland',
    dialCode: '+268',
    code: 'SWZ',
    isCodeAvailable: true,
    flag: Images.Countries.SZ,
    phoneMask: '99-99-9999',
  },
  {
    name: 'Sweden',
    dialCode: '+46',
    code: 'SWE',
    isCodeAvailable: true,
    flag: Images.Countries.SE,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Switzerland',
    dialCode: '+41',
    code: 'CHE',
    isCodeAvailable: true,
    flag: Images.Countries.CH,
    phoneMask: '99-999-9999',
  },
  // T
  {
    name: 'Taiwan',
    dialCode: '+886',
    code: 'TWN',
    isCodeAvailable: true,
    flag: Images.Countries.TW,
    phoneMask: '9-9999-9999',
  },
  {
    name: 'Tajikistan',
    dialCode: '+992',
    code: 'TJK',
    isCodeAvailable: true,
    flag: Images.Countries.TJ,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Tanzania',
    dialCode: '+255',
    code: 'TZA',
    isCodeAvailable: true,
    flag: Images.Countries.TZ,
    phoneMask: '99-999-999',
  },
  {
    name: 'Thailand',
    dialCode: '+66',
    code: 'THA',
    isCodeAvailable: true,
    flag: Images.Countries.TH,
    phoneMask: '99-999-9999',
  },
  {
    name: 'East Timor',
    dialCode: '+670',
    code: 'TLS',
    isCodeAvailable: true,
    flag: Images.Countries.TL,
    phoneMask: '999-9999',
  },
  {
    name: 'Togo',
    dialCode: '+228',
    code: 'TGO',
    isCodeAvailable: true,
    flag: Images.Countries.TG,
    phoneMask: '99-999-999',
  },
  {
    name: 'Tonga',
    dialCode: '+676',
    code: 'TON',
    isCodeAvailable: true,
    flag: Images.Countries.TO,
    phoneMask: '99999',
  },
  {
    name: 'Trinidad and Tobago',
    dialCode: '+1-868',
    code: 'TTO',
    isCodeAvailable: true,
    flag: Images.Countries.TT,
    phoneMask: '999-9999',
  },
  {
    name: 'Tunisia',
    dialCode: '+216',
    code: 'TUN',
    isCodeAvailable: true,
    flag: Images.Countries.TN,
    phoneMask: '99-999-999',
  },
  {
    name: 'Turkey',
    dialCode: '+90',
    code: 'TUR',
    isCodeAvailable: true,
    flag: Images.Countries.TR,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Turkmenistan',
    dialCode: '+993',
    code: 'TKM',
    isCodeAvailable: true,
    flag: Images.Countries.TM,
    phoneMask: '9-999-9999',
  },
  // U
  {
    name: 'Uganda',
    dialCode: '+256',
    code: 'UGA',
    isCodeAvailable: true,
    flag: Images.Countries.UG,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Ukraine',
    dialCode: '+380',
    code: 'UKR',
    isCodeAvailable: true,
    flag: Images.Countries.UA,
    phoneMask: '(99) 999-99-99',
  },
  {
    name: 'United Arab Emirates',
    dialCode: '+971',
    code: 'ARE',
    isCodeAvailable: true,
    flag: Images.Countries.AE,
    phoneNumber: '9-999-9999',
  },
  {
    name: 'United Kingdom',
    dialCode: '+44',
    code: 'GBR',
    isCodeAvailable: true,
    flag: Images.Countries.GB,
    phoneMask: '99-9999-9999',
  },
  {
    name: 'Uruguay',
    dialCode: '+598',
    code: 'URY',
    isCodeAvailable: true,
    flag: Images.Countries.UY,
    phoneMask: '9-999-99-99',
  },
  {
    name: 'Uzbekistan',
    dialCode: '+998',
    code: 'UZB',
    isCodeAvailable: true,
    flag: Images.Countries.UZ,
    phoneMask: '99-999-9999',
  },
  // V
  {
    name: 'Vanuatu',
    dialCode: '+678',
    code: 'VUT',
    isCodeAvailable: true,
    flag: Images.Countries.VU,
    phoneMask: '99-99999',
  },
  {
    name: 'Venezuela',
    dialCode: '+58',
    code: 'VEN',
    isCodeAvailable: true,
    flag: Images.Countries.VE,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Vietnam',
    dialCode: '+84',
    code: 'VNM',
    isCodeAvailable: true,
    flag: Images.Countries.VN,
    phoneMask: '99-9999-999',
  },
  {
    name: 'U.S. Virgin Islands',
    dialCode: '+1-340',
    code: 'VIR',
    isCodeAvailable: true,
    flag: Images.Countries.VI,
    phoneMask: '999-9999',
  },
  // X

  // Y
  {
    name: 'Yemen',
    dialCode: '+967',
    code: 'YEM',
    isCodeAvailable: true,
    flag: Images.Countries.YE,
    phoneMask: '999-999-999',
  },
  // Z
  {
    name: 'Zambia',
    dialCode: '+260',
    code: 'ZMB',
    isCodeAvailable: true,
    flag: Images.Countries.ZM,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Zimbabwe',
    dialCode: '+263',
    code: 'ZWE',
    isCodeAvailable: true,
    flag: Images.Countries.ZW,
    phoneMask: '9-999999',
  },
];
