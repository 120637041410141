import React from 'react';

import { Checkbox as AntCheckbox, Form, Row } from 'antd';

import './Checkbox.css';
import { CheckBoxCompType } from './constants';

const Checkbox = ({
  name,
  label = '',
  subLabel = '',
  value,
  disabled,
  errors,
  setFieldValue,
  setFieldTouched,
  preChangeHook,
  postChangeHook,
  customStyles,
  customSetFieldValue,
  hidden = false,
  testId,
}: CheckBoxCompType) => (
  <Form.Item
    data-testid={testId}
    hidden={hidden}
    validateStatus={!!errors && errors[name] && 'error'}
    help={!!errors && errors[name]}
    colon={false}
    style={{ flexDirection: 'column', margin: 0, ...customStyles }}>
    <AntCheckbox
      name={name}
      defaultChecked={value}
      onChange={event => {
        if (preChangeHook) {
          preChangeHook(event.target.checked);
        }

        setFieldTouched(name, true, true);

        if (customSetFieldValue) {
          customSetFieldValue(name, event.target.checked);
        } else {
          setFieldValue(name, event.target.checked, true);
        }

        // setFieldValue(name, event.target.checked);
        if (postChangeHook) {
          postChangeHook(event.target.checked);
        }
      }}
      disabled={disabled}>
      {label}
    </AntCheckbox>
    {subLabel && (
      <Row>
        <span className={'subLabel'}>{subLabel}</span>
      </Row>
    )}
  </Form.Item>
);

export default Checkbox;
