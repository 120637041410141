import { cx } from '@emotion/css';

import { AddressDto } from '../dtos/accounts.dtos';
import { Font, Spacing } from '../styles';

export const renderAddressMismatchErrorMessage = ({
  dto,
  onClick,
  section,
}: {
  dto: AddressDto;
  onClick: () => void;
  section: string;
}): JSX.Element => (
  <div>
    Invalid {section} address. USPS found the following address: <br />
    {dto.address1}, <br />
    {dto.address2 && `${dto.address2},`} {dto.address2 && <br />}
    {dto.city}, <br />
    {dto.state}, <br />
    {dto.postalCode}. <br />
    If address is correct, please click{' '}
    <a onClick={onClick} className={cx(Spacing.p4, Font.lg)}>
      here
    </a>{' '}
    to update the form.
  </div>
);
