import { useState } from 'react';

import { DownOutlined, FilterOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Button, Col, Row, Space, Tag } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { FilterBarConstant } from 'src/constants';

import { ScreenBreakpoint } from '../../../styles';
import { MButton } from '../../FormComponents/MButton/MButton';
import { MDivider } from '../../MDivider/MDivider';
import { MDrawer } from '../../MDrawer/MDrawer';
import { CheckboxFilter, CheckboxGroup } from '../CheckboxFilter/CheckboxFilter';
import { RadioFilter } from '../RadioFilter/RadioFilter';
import { TimelineFilter, TimelineFilterValueType } from '../TimelineFilter/TimelineFilter';

import * as Styles from './FilterBar.styles';

export interface FilterBarCheckboxGroupOption {
  label: string | JSX.Element;
  value: string;
  tag?: { text: string; color: string; background: string };
}

export interface FilterBarCheckboxGroup extends Omit<CheckboxGroup, 'options'> {
  options: FilterBarCheckboxGroupOption[];
}

export interface FilterBarSample {
  sortBy?: number;
  timeline?: number;
  customTimeline?: [any, any];
  filterBy?: CheckboxValueType[];
  show?: CheckboxValueType[];
}

interface FilterBarProps {
  onApprove: (filters: FilterBarSample) => any;
  defaultValue?: FilterBarSample;
  showFilter?: FilterBarCheckboxGroup[];
  byFilter: FilterBarCheckboxGroup[];
  initValue?: FilterBarSample;
}

// TODO: move sort by filter options outside of FilterBar component
const sortByOptions = [
  { label: FilterBarConstant.SortByLabel.Newest, value: FilterBarConstant.SortByCategory.Newest },
  { label: FilterBarConstant.SortByLabel.Oldest, value: FilterBarConstant.SortByCategory.Oldest },
];

export const mapFilterBarSortCategoryToLabel = (
  value: FilterBarConstant.SortByCategory,
): FilterBarConstant.SortByLabel => {
  if (value === FilterBarConstant.SortByCategory.Newest) {
    return FilterBarConstant.SortByLabel.Newest;
  }

  return FilterBarConstant.SortByLabel.Oldest;
};

export const FilterBar = ({ onApprove, defaultValue, initValue, showFilter, byFilter }: FilterBarProps) => {
  const [selectedFilters, setSelectedFilters] = useState<FilterBarSample>({ ...defaultValue, ...initValue });

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const onSortByFilterSelected = (value: number) => {
    onDone({ sortBy: value });
  };

  const onShowSelected = (values: CheckboxValueType[]) => {
    onDone({ show: values, filterBy: [] });
  };

  const onFilterBySelected = (values: CheckboxValueType[]) => {
    onDone({ filterBy: values });
  };

  const onDone = (filters: FilterBarSample) => {
    setSelectedFilters({ ...selectedFilters, ...filters });
    onApprove({ ...selectedFilters, ...filters });
  };

  const onDoneTimeline = (value: TimelineFilterValueType) => {
    const { fixedTimeline, customTimeline } = value;
    onDone({ timeline: fixedTimeline, customTimeline });
  };

  const showSortByLabel = () => {
    if (selectedFilters?.sortBy === undefined || selectedFilters?.sortBy === null)
      return (
        <Space size={4} direction='horizontal'>
          <i className='ri-sort-desc' />
          <span>Sort</span>
        </Space>
      );

    return (
      <Space size={4} direction='horizontal'>
        <i className='ri-sort-desc' />
        <span>{`Sort: ${mapFilterBarSortCategoryToLabel(selectedFilters?.sortBy)}`}</span>
      </Space>
    );
  };

  const onRemoveShowItem = () => {
    if (!selectedFilters?.show || selectedFilters.show.length === 0) return;
    selectedFilters?.show?.shift();
    onShowSelected(selectedFilters.show);
  };

  const onRemoveFilterByItem = () => {
    if (!selectedFilters?.filterBy || selectedFilters.filterBy.length === 0) return;
    selectedFilters?.filterBy?.shift();
    onFilterBySelected(selectedFilters.filterBy);
  };

  const onReset = () => {
    onDone({ ...defaultValue });
  };

  const renderSelectedTag = ({ tag, label, onClose }: FilterBarCheckboxGroupOption & { onClose: () => void }) => {
    if (tag) {
      const { text, ...style } = tag;

      return (
        <Tag className={Styles.tagDefaultStyle({ ...style })}>
          <Space direction='horizontal' size={8}>
            {text}
            <i className={cx('ri-close-fill', Styles.closeStyle)} onClick={onClose} />
          </Space>
        </Tag>
      );
    }

    return (
      <Tag className={Styles.tagDefaultStyle({})}>
        <Space direction='horizontal' size={8}>
          {label}
          <i className={cx('ri-close-fill', Styles.closeStyle)} onClick={onClose} />
        </Space>
      </Tag>
    );
  };

  const showLabel = () => {
    if (!selectedFilters.show || _.isEmpty(selectedFilters.show))
      return (
        <Space size={4} direction='horizontal'>
          <DownOutlined />
          <span>Show</span>
        </Space>
      );

    const firstShowFilterValue = selectedFilters.show[0];
    const firstShowGroup = showFilter?.find(aGroup =>
      aGroup.options.find(anOption => anOption.value === firstShowFilterValue),
    );
    const option = firstShowGroup?.options.find(anOption => anOption.value === firstShowFilterValue);

    if (option) {
      return (
        <Space direction='horizontal' size={4}>
          <DownOutlined />
          {renderSelectedTag({ ...option, onClose: onRemoveShowItem })}
          {selectedFilters.show && selectedFilters.show.length > 1 ? <span>{`...`}</span> : null}
        </Space>
      );
    }

    return (
      <Space size={4} direction='horizontal'>
        <DownOutlined />
        <span>Show</span>
      </Space>
    );
  };

  const filterByLabel = () => {
    if (!selectedFilters.filterBy || _.isEmpty(selectedFilters.filterBy))
      return (
        <Space direction='horizontal' size={4}>
          <DownOutlined /> <span>Filter By Status</span>
        </Space>
      );

    const firstByFilterValue = selectedFilters.filterBy?.[0];
    const firstShowGroup = byFilter?.find(aGroup =>
      aGroup.options.find(anOption => anOption.value === firstByFilterValue),
    );
    const option = firstShowGroup?.options.find(anOption => anOption.value === firstByFilterValue);

    if (option) {
      return (
        <Space direction='horizontal' size={4}>
          <DownOutlined />
          {renderSelectedTag({ ...option, onClose: onRemoveFilterByItem })}
          {selectedFilters?.filterBy && selectedFilters.filterBy.length > 1 ? <span>{`...`}</span> : null}
        </Space>
      );
    }

    return (
      <Space direction='horizontal' size={4}>
        <DownOutlined /> <span>Filter By Status</span>
      </Space>
    );
  };

  const renderContent = () => {
    return (
      <Row gutter={16} data-testid={'history-filters-row'}>
        <Col className={Styles.filterItemContainer({ isMobile })}>
          <RadioFilter
            label={isMobile ? undefined : showSortByLabel()}
            options={sortByOptions}
            onSelect={onSortByFilterSelected}
            selectedValue={selectedFilters.sortBy}
            title={isMobile ? 'Sort By' : undefined}
            testId={'history-radio-list-sort-by-date'}
          />
          {isMobile && <MDivider />}
        </Col>
        {showFilter && showFilter.length > 0 && (
          <Col className={Styles.filterItemContainer({ isMobile })}>
            <CheckboxFilter
              label={isMobile ? 'Show' : showLabel()}
              groups={showFilter}
              onSelect={onShowSelected}
              selectedValues={selectedFilters?.show}
              testId={'history-checkbox-list-show-by-type'}
            />
            {isMobile && <MDivider />}
          </Col>
        )}
        <Col className={Styles.filterItemContainer({ isMobile })}>
          <CheckboxFilter
            label={isMobile ? 'Filter By Status' : filterByLabel()}
            groups={byFilter}
            onSelect={onFilterBySelected}
            selectedValues={selectedFilters?.filterBy}
            testId={'history-checkbox-list-filter-by-status'}
          />
          {isMobile && <MDivider />}
        </Col>
        <Col className={Styles.filterItemContainer({ isMobile })}>
          <TimelineFilter
            onSelect={onDoneTimeline}
            defaultValue={{ fixedTimeline: defaultValue?.timeline, customTimeline: defaultValue?.customTimeline }}
            selectedValue={{
              fixedTimeline: selectedFilters?.timeline,
              customTimeline: selectedFilters?.customTimeline,
            }}
          />
        </Col>
        {!isMobile && (
          <Col className={Styles.filterItemContainer({ isMobile })}>
            <MButton
              onClick={onReset}
              type='tertiary'
              disabled={JSON.stringify({ ...defaultValue }) === JSON.stringify(selectedFilters)}>
              Clear All
            </MButton>
          </Col>
        )}
      </Row>
    );
  };

  const renderInMobile = () => (
    <>
      <Button icon={<FilterOutlined />} onClick={() => setIsDrawerOpen(true)} className={Styles.filterButton} />
      <MDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        header={
          <Row justify='center' align='middle'>
            <MButton
              type='tertiary'
              onClick={onReset}
              disabled={JSON.stringify({ ...defaultValue }) === JSON.stringify(selectedFilters)}>
              Clear All
            </MButton>
          </Row>
        }>
        {renderContent()}
      </MDrawer>
    </>
  );

  const render = () => (
    <Space direction='horizontal' size={16}>
      {renderContent()}
    </Space>
  );

  return <>{isMobile ? renderInMobile() : render()}</>;
};
