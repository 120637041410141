import React, { Dispatch } from 'react';

import { createACHRelationship, editNickname } from '../../../../actions';

export enum ContextCardEnumTypes {
  BANK_LINK_CREATE = 'bankLink',
  BANK_ACCOUNT_TYPE = 'bankAccountType',
  BANK_LINK_ACTION = 'bankLinkActions',
  BANK_LINK_EDIT = 'bankLinkEdit',
  BANK_LINK_BROKEN = 'bankLinkBrokenActions',
  FILTER_TRANSACTIONS = 'filterTransactions',
  BANK_LINK_DISCLAIMER = 'bankLinkDisclaimer',
  EXISTING_BANK_ERROR = 'exitingBankError',
  EXISTING_BANK_WARNING = 'exitingBankWarning',
}

export const getModalTitle = (contextCardVisible: string): string => {
  switch (contextCardVisible) {
    case ContextCardEnumTypes.BANK_LINK_EDIT:
      return 'Edit Nickname';

    default:
      return 'Disclaimer';
  }
};

export const getSecondaryButtonText = (contextCardVisible: string): string | undefined => {
  switch (contextCardVisible) {
    case ContextCardEnumTypes.BANK_LINK_CREATE:
      return 'Finish Linking';

    case ContextCardEnumTypes.BANK_LINK_EDIT:
      return 'Save';

    default:
      return 'Continue';
  }
};

export const getLinkButtonText = (contextCardVisible: string): string => {
  switch (contextCardVisible) {
    case ContextCardEnumTypes.BANK_LINK_CREATE:
      return '';
    case ContextCardEnumTypes.BANK_LINK_EDIT:
      return 'No';
    default:
      return 'Close';
  }
};

export const getModalWidth = (contextCardVisible: string): number | undefined => {
  switch (contextCardVisible) {
    case ContextCardEnumTypes.BANK_LINK_EDIT:
      return 306;
    case ContextCardEnumTypes.EXISTING_BANK_WARNING:
    case ContextCardEnumTypes.EXISTING_BANK_ERROR:
      return 400;
    default:
      return 306;
  }
};

export const getModalHeight = (contextCardVisible: string): number | undefined => {
  switch (contextCardVisible) {
    case ContextCardEnumTypes.BANK_LINK_EDIT:
      return 300;
    case ContextCardEnumTypes.EXISTING_BANK_WARNING:
    case ContextCardEnumTypes.EXISTING_BANK_ERROR:
      return 100;
    default:
      return 550;
  }
};

export const onFormSubmit = (
  accountId: string,
  contextCardVisible: string,
  dispatch: Dispatch<any>,
  selectedBankLink: any,
  values: any,
  setContextCardVisible: React.Dispatch<React.SetStateAction<string>>,
  bankLinkPayload: any,
) => {
  if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_EDIT) {
    dispatch(
      editNickname(
        selectedBankLink.tradingBlockACHRelationship.accountId,
        selectedBankLink.tradingBlockACHRelationship.id,
        {
          nickName: values.nickname,
        },
      ),
    );

    setContextCardVisible('');
  } else if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE) {
    dispatch(
      createACHRelationship(accountId, {
        ...bankLinkPayload,
        nickName: values.nickname,
      }),
    );
    setContextCardVisible('');
  }
};

export const onModalSecondaryButtonClick = (
  form: any,
  setContextCardVisible: React.Dispatch<React.SetStateAction<string>>,
  ready: boolean,
  open: Function,
  setBankLinkPayload: any,
  data: any,
) => {
  if (data.contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE) {
    form.submitForm();
  } else if (data.contextCardVisible === ContextCardEnumTypes.BANK_LINK_EDIT) {
    form.submitForm();
  } else if (data.contextCardVisible === ContextCardEnumTypes.EXISTING_BANK_WARNING) {
    setBankLinkPayload({
      plaidPublicToken: data.plaidBankLinkSuccessPayload.publicToken,
      institutionId: data.plaidBankLinkSuccessPayload.metadata.institution.institution_id,
      bankAccounts: data.plaidAccounts.filter((item: any) => item.bankAccountType !== data.selectedWrongBankType),
    });

    setContextCardVisible(ContextCardEnumTypes.BANK_LINK_CREATE);
    setContextCardVisible('');
  } else {
    if (ready) {
      open();
    }
  }
};

export const onModalLinkButtonClick = (
  contextCardVisible: string,
  form: { submitForm: () => void; resetForm: () => void },
  setContextCardVisible: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE) {
    form.submitForm();
    setContextCardVisible('');
  }

  if (contextCardVisible === ContextCardEnumTypes.BANK_LINK_EDIT) {
    form.resetForm();
    setContextCardVisible('');
  } else {
    setContextCardVisible('');
  }
};

export const getInitialValues = (contextCardVisible: string, selectedBankLink: any): any => {
  switch (contextCardVisible) {
    case ContextCardEnumTypes.BANK_LINK_CREATE:
      return {
        nickname: '',
      };
    case ContextCardEnumTypes.BANK_LINK_EDIT:
      return {
        nickname: selectedBankLink.tradingBlockACHRelationship?.nickName,
      };
    default:
      return {};
  }
};
